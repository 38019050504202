import { Button, IconButton } from "@scrapadev/scrapad-front-sdk";
import { useState } from "react";
import ModalFactory from "../../../components/ui/modal/ModalFactory";
import { useTranslation } from "../../../utils/hooks";

/**
 * Invite user button
 * @returns {JSX.Element}
 */
const InviteUser = ({ uuidOrg, expanded = false, fetchUsers }) => {
  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation("common");

  const handleOpenModal = () => setOpenModal(true);

  return (
    <>
      {expanded ? (
        <>
          <Button
            component="button"
            mode="success"
            reduced
            onClick={handleOpenModal}
            text={t("invite_user")}
          />
        </>
      ) : (
        <IconButton
          outlined
          name={"person_add"}
          onClick={handleOpenModal}
          style={{
            width: 45,
            height: 45,
            background: "#F5F5F5",
            border: "0.5px solid #E1E1E1",
            borderRadius: "50%",
            color: "#7E7E7E",
            transform: "scaleX(-1)",
            margin: 0,
          }}
          iconStyle={{ fontSize: 18 }}
        />
      )}

      {openModal && (
        <ModalFactory
          type="invite_user"
          open={openModal}
          setOpen={setOpenModal}
          uuidOrg={uuidOrg}
          fetchUsers={fetchUsers}
        />
      )}
    </>
  );
};

export default InviteUser;
