import {
  AddressItem,
  Empty,
  Heading,
  Padding,
  Paper,
  Row,
} from "@scrapadev/scrapad-front-sdk/dist/index";
import { useTranslation } from "../../../utils/hooks";
import WidgetAddressNew from "./New";
import { handleCRUD } from "../../../utils/crud/functions";
import { useEffect, useRef, useState } from "react";
import { useSetDataUser, useSignOut } from "../../../providers/AuthHooks";
import { modalTypes } from "../../../utils/variables";
import ModalFactory from "../../../components/ui/modal/ModalFactory";
import { renderComponentWithPermission } from "../../../utils/functions";
import { buildAddresData, cleanAddressState } from "./functions";
import { useGQL } from "../../../utils/gql/hooks";
import { getCatalogNoTerm } from "../../../model/filters";
import _ from "lodash";
import { DataProvider } from "../../../providers/data/DataContext";

const WidgetAddressesPickDelivery = ({
  id,
  state,
  dispatch,
  actions,
  isDirty,
  handleDiscard,
  bufferState,
  fetchData,
  loading,
  setLoading,
  checkIfStateIsDirty,
  extraData,
}) => {
  const { t, i18n } = useTranslation(["talk", "business", "common"]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const signOut = useSignOut();
  const setDataUser = useSetDataUser();
  const [openDeleteWarning, setDeleteWarning] = useState(false);
  const [deleteUuid, setDeleteUuid] = useState(false);
  const { permissionManage, permissionRead } = extraData || {};
  const [editAddress, setEditAddress] = useState(false);
  const [editIndex, setEditIndex] = useState(0);
  const portRef = useRef();

  const { data: catalogData, loading: catalogLoading } = useGQL(
    getCatalogNoTerm,
    {
      locale: i18n.language,
      name: "equipments",
    }
  );

  const handleDeleteAddress = async (uuid, e) => {
    e.stopPropagation();
    setDeleteUuid(uuid);
    setDeleteWarning(true);
  };

  const handleSubmitWidget = (index) => async (e) => {
    e.preventDefault();
    setLoading(true);
    setSubmitLoading(true);
    const data = { ...state?.getOrgLocations?.[index] };
    delete data.__typename;
    const parsedData = buildAddresData(data);
    await handleCRUD({
      endpoint: `org/${id}/location/${data.id}`,
      method: "PUT",
      data: { address: parsedData },
      successCallback: async () => {
        await fetchData();
        setLoading(false);
      },
      messageOk: t("action-ok"),
      signOut,
      setDataUser,
    });
  };

  useEffect(() => {
    if (!_.isEmpty(state?.getOrgLocations)) {
      if (
        state?.getOrgLocations[editIndex]?.["is-port"] &&
        portRef.current !== state?.getOrgLocations[editIndex]?.["is-port"]
      ) {
        portRef.current = state?.getOrgLocations[editIndex]?.["is-port"];
        cleanNonPortFields();
      }
    }
  }, [state]);

  const cleanNonPortFields = () => {
    setTimeout(() => {
      cleanAddressState(
        editIndex,
        [
          "city",
          "country",
          "countryCode",
          "facilities",
          "postalCode",
          "prefix",
          "state",
          "street",
          "tel",
          "timetables",
        ],
        [
          undefined,
          undefined,
          undefined,
          [],
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          [],
        ],
        state,
        dispatch
      );
    }, 10);
  };

  return (
    <>
      <Paper
        style={{
          padding: "15px 25px",
          gap: "15px",
          alignItems: "stretch",
        }}
      >
        <Row justify="space-between">
          <Heading type={3} style={{ marginBottom: 0 }}>
            {t("addresses")}
          </Heading>
          {renderComponentWithPermission(
            permissionManage,
            <WidgetAddressNew id={id} fetchData={fetchData} minimal />
          )}
        </Row>
        {!state?.getOrgLocations ||
        state?.getOrgLocations?.length === 0 ||
        catalogLoading ? (
          <Padding style={{ paddingTop: 80 }}>
            <Empty
              heading={t("section-empty")}
              icon="bo"
              iconWidth={220}
              iconHeight={220}
            />
          </Padding>
        ) : (
          state?.getOrgLocations?.map((address, index) => {
            const alias = address?.["alias"] || t("address");

            let facilitiesNames = [];
            if (!catalogLoading) {
              if (
                typeof catalogData === "object" &&
                !_.isEmpty(catalogData) &&
                _.isEmpty(catalogData?.errors)
              ) {
                facilitiesNames = catalogData
                  ?.filter(
                    (cd) =>
                      address?.facilities?.filter((fc) => fc === cd.uuid)
                        .length > 0
                  )
                  .map((e) => e.name);
              }
            }

            return (
              <AddressItem
                key={`edit_address_${address.id}`}
                address={address}
                alias={alias}
                canManageAddress={true}
                facilitiesNames={facilitiesNames}
                defaultTooltip={t("default_address")}
                onDelete={(e) => handleDeleteAddress(address.id, e)}
                onEdit={() => {
                  setEditIndex(index);
                  setEditAddress(true);
                }}
                t={t}
              />
            );
          })
        )}
      </Paper>
      {editAddress ? (
        <>
          <DataProvider
            initialValue={{
              getOrgLocations: [{ ...state?.getOrgLocations[editIndex] }],
            }}
          >
            <ModalFactory
              type={modalTypes.DASHBOARD_NEW_ADDRESS}
              open={editAddress}
              setOpen={setEditAddress}
              id={state?.getOrgLocations?.[editIndex]?.id}
              fetchData={fetchData}
              isEdit={true}
            />
          </DataProvider>
        </>
      ) : (
        <></>
      )}

      <ModalFactory
        key={deleteUuid}
        type={modalTypes.USER_WARNING}
        open={openDeleteWarning}
        setOpen={setDeleteWarning}
        data={{ key: deleteUuid }}
        onSubmit={async (key) => {
          setLoading(true);
          await handleCRUD({
            endpoint: `org/${id}/location/${key}`,
            method: "DELETE",
            successCallback: async () => {
              await fetchData();
              setLoading(false);
            },
            messageOk: t("action-ok"),
            signOut,
            setDataUser,
          });
        }}
      />
    </>
  );
};

export default WidgetAddressesPickDelivery;
