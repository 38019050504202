import {
  BREAKPOINT_MD,
  Button,
  Modal,
  Typography,
  useMatchMedia,
} from "@scrapadev/scrapad-front-sdk";
import { useTranslation } from "../../utils/hooks";
import { useEffect, useState } from "react";

/**
 * Widget for save discard data.
 * @param {Object} props - Component properties.
 * @param {Boolean} props.isDirty - Widget is dirty, must show the buttons.
 * @param {Function} props.handleDiscard - Widget reset function.
 * @param {Boolean} props.doubleFactor - Shows confirmation modal.
 * @param {Boolean} props.alt - Alternative text.
 * @returns {JSX.Element}
 */
const WidgetFieldSaveDiscard = ({
  isDirty,
  handleDiscard,
  loading,
  permission,
  doubleFactor,
  title,
  description,
  alt,
}) => {
  const { t } = useTranslation(["common"]);
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_MD}px)`);
  const [openWarn, setOpenWarn] = useState(false);

  useEffect(() => {
    if (!isDirty) {
      setOpenWarn(false);
    }
  }, [isDirty]);

  return (
    isDirty && (
      <>
        <div className="row">
          <div
            className="col-12"
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              flexDirection: matches ? "column" : "row",
            }}
          >
            <Button
              fullwidth={matches ? true : undefined}
              component="button"
              type="button"
              mode="normal"
              text={t("discard-changes")}
              onClick={handleDiscard}
              ultrawide
              style={{
                marginRight: matches ? 0 : "1.5rem",
                marginBottom: matches ? "1.5rem" : 0,
              }}
            />
            <Button
              disabled={!permission()}
              fullwidth={matches ? true : undefined}
              component="button"
              type="submit"
              text={t("save-changes")}
              loading={loading}
              ultrawide
              style={
                loading ? { paddingLeft: "4rem", paddingRight: "4rem" } : {}
              }
              onClick={
                doubleFactor
                  ? (e) => {
                      e?.preventDefault();
                      setOpenWarn(true);
                    }
                  : undefined
              }
            />
          </div>
        </div>
        <Modal
          open={openWarn}
          setOpen={setOpenWarn}
          title={
            title || alt ? t("sure-apply-changes") : t("save-before-leave")
          }
          hasForm={false}
          hasActions={true}
          cancelText={alt ? t("cancel") : t("discard-changes")}
          submitText={alt ? t("save-changes") : t("save_and_exit")}
          actionsMode="end"
          reverseActions={alt}
          maxWidth={800}
          modalStyles={{ width: matches ? "auto" : 800 }}
          submitButtonMode={"success"}
          onSubmit={() => {
            setOpenWarn(false);
          }}
          onCancel={handleDiscard}
          BodyComponent={
            <>
              <Typography>
                {description || alt
                  ? t("need-client-consent")
                  : t("save_and_exit_desc")}
              </Typography>
            </>
          }
        />
      </>
    )
  );
};

export default WidgetFieldSaveDiscard;
