import {
  DropdownIconActions,
  Flex,
  Heading,
  IconItem,
  Separator,
  TransactionsIcon,
  Typography,
  WalletIcon,
  WARM_NEUTRAL_50,
  WARM_NEUTRAL_80,
} from "@scrapadev/scrapad-front-sdk";
import { useState } from "react";
import { useParams } from "react-router-dom";
import ModalFactory from "../../../components/ui/modal/ModalFactory";
import { useTranslation } from "../../../utils/hooks";
import {
  GRAY_NEUTRAL_40,
  GREEN_COLOR_100,
  modalTypes,
  RED_COLOR_100,
  TEXT_COLOR,
  TEXT_COLOR_LIGHT,
  YELLOW_COLOR_100,
} from "../../../utils/variables";
import {
  getMovementStatus,
  parseTypeToWalletTitle,
} from "../../../utils/wallet/functions";
import { useWalletActions } from "../../../utils/wallet/hooks";
import { walletStatus, walletTypesOut } from "../../../utils/wallet/variables";

const mappedValues = {
  [walletStatus.APPROVED]: GREEN_COLOR_100,
  [walletStatus.DENEGATED]: RED_COLOR_100,
  [walletStatus.DENIED]: RED_COLOR_100,
  [walletStatus.PENDING]: YELLOW_COLOR_100,
  [walletStatus.ERROR_LEMONWAY]: RED_COLOR_100,
  [walletStatus.ERROR_PSP]: RED_COLOR_100,
  [walletStatus.VALIDATION_PENDING]: YELLOW_COLOR_100,
  [walletStatus.REQUESTED]: YELLOW_COLOR_100,
};

const WidgetWalletMovement = ({
  uuid,
  type = "wallet",
  origin,
  title,
  answer,
  amount,
  status,
  hasActions = true,
  containerStyles = {},
  infoStyles = {},
  iban_id,
  fetchData,
  hideIcon = false,
}) => {
  const { id } = useParams();
  const { t } = useTranslation(["common", "transactions", "wallet"]);
  const {
    open,
    setOpen,
    modalTitle,
    modalDescription,
    okText,
    loading,
    setLoading,
    handleAprove,
    handleDenegate,
    type: actionType,
    types,
    handleAproveAction,
    handleDenegateAction,
  } = useWalletActions();
  const [uuidMovement, setUuidMovement] = useState();

  const checkIfCanApproveDenegate = () => {
    if (!hasActions) return false;
    return (
      status === walletStatus.REQUESTED && type === walletTypesOut["money-out"]
    );
  };

  return (
    <>
      <Flex
        alignItems={"flex-start"}
        justifyContent={"flex-start"}
        style={{ marginBottom: 15, gap: 15, ...containerStyles }}
      >
        {!hideIcon && (
          <Flex>
            {origin === "wallet" ? <WalletIcon /> : <TransactionsIcon />}
          </Flex>
        )}

        <Flex
          justifyContent={"space-between"}
          style={{
            borderBottom: "1px solid #CFCFCF",
            flexGrow: 1,
            flexWrap: "wrap",
            marginTop: 5,
            paddingBottom: 18,
            gap: 15,
            ...infoStyles,
          }}
        >
          <Flex alignItems={"flex-start"} flexDirection={"column"}>
            {title && (
              <Heading type={6} style={{ marginBottom: 12, fontSize: 14 }}>
                {parseTypeToWalletTitle(t, title)}
              </Heading>
            )}
            {answer && (
              <Typography
                type={2}
                style={{
                  fontWeight: 400,
                  fontSize: 14,
                  lineHeight: "21px",
                  color: WARM_NEUTRAL_50,
                  marginBottom: 0,
                }}
              >
                {answer}
              </Typography>
            )}
          </Flex>
          <Flex
            flexDirection={"column"}
            alignItems={"flex-end"}
            style={{ flexGrow: 1 }}
          >
            {amount && (
              <Typography
                type={2}
                style={{
                  marginBottom: 10,
                  fontWeight: 600,
                  fontSize: 14,
                  lineHeight: "21px",
                  color: WARM_NEUTRAL_80,
                }}
              >
                {amount}
              </Typography>
            )}
            {status && (
              <Flex>
                <Typography
                  type={2}
                  style={{
                    marginBottom: 0,
                    fontWeight: 500,
                    fontSize: 12,
                    lineHeight: "18px",
                    color: WARM_NEUTRAL_50,
                  }}
                >
                  <span
                    style={{
                      width: 7,
                      height: 7,
                      marginRight: 7,
                      display: "inline-block",
                      borderRadius: "50%",
                      backgroundColor:
                        mappedValues[getMovementStatus(type, status)],
                    }}
                  ></span>
                  {t(status)}
                </Typography>
                {checkIfCanApproveDenegate() && (
                  <>
                    <Separator hideOnMobile={false} />
                    <DropdownIconActions
                      topOffset={30}
                      leftOffset={-20}
                      iconStyles={{
                        margin: 0,
                        padding: 0,
                        backgroundColor: "#fff",
                      }}
                      items={[
                        {
                          component: (
                            <IconItem
                              name={"check"}
                              color={TEXT_COLOR_LIGHT}
                              text={t("aprove")}
                              onClick={() => {
                                setUuidMovement(uuid);
                                handleAprove();
                              }}
                            />
                          ),
                        },
                        {
                          component: (
                            <IconItem
                              name={"close"}
                              color={TEXT_COLOR_LIGHT}
                              text={t("denegate")}
                              onClick={() => {
                                setUuidMovement(uuid);
                                handleDenegate();
                              }}
                            />
                          ),
                        },
                      ]}
                      iconColor={GRAY_NEUTRAL_40}
                    />
                  </>
                )}
              </Flex>
            )}
          </Flex>
        </Flex>
      </Flex>
      <ModalFactory
        key={uuid}
        uuid={uuid}
        iban_id={iban_id}
        type={modalTypes.WALLET_BALACE_ACTIONS}
        open={open}
        setOpen={setOpen}
        title={modalTitle}
        description={modalDescription}
        okText={okText}
        fetchData={fetchData}
        uuidOrg={id}
        loading={loading}
        setLoading={setLoading}
        onSubmit={
          actionType === types.APPROVE
            ? handleAproveAction
            : handleDenegateAction
        }
      />
    </>
  );
};

export default WidgetWalletMovement;
