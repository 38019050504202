import { Button, InputText, Spacer } from "@scrapadev/scrapad-front-sdk";
import { useTranslation } from "../../utils/hooks";
import LoginInput from "../ui/login/LoginInput";
import LoginLabel from "../ui/login/LoginLabel";
import LoginLink from "../ui/login/LoginLink";

export const RecoverForm = (props) => {
  const { t } = useTranslation(["login", "common"]);

  return (
    <>
      <form method="POST" onSubmit={props.doRecover}>
        <LoginLabel text={t("email")} small>
          <LoginInput
            element={
              <InputText
                mode="underline"
                type="email"
                required={true}
                name="email"
                id="email"
              />
            }
          />
        </LoginLabel>
        <Spacer height={10} />
        <LoginLabel>
          <Button
            component="button"
            fullwidth={true}
            type="submit"
            text={t("recover", { ns: "login" })}
          />
        </LoginLabel>
      </form>
      <LoginLink href={"/login"} underlined={false}>
        <>
          {t("already-account-1")} <span style={{ width: "5px" }}></span>
          <strong>
            <u>{t("already-account-2")}</u>
          </strong>
        </>
      </LoginLink>
    </>
  );
};
