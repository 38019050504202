import {
  Flex,
  Icon,
  Typography,
  WARM_NEUTRAL_10,
  WARM_NEUTRAL_50,
  WARM_NEUTRAL_60,
  WARM_NEUTRAL_80,
} from "@scrapadev/scrapad-front-sdk";
import React from "react";

const IconAvatarContainer = ({ children }) => {
  return (
    <Flex
      style={{
        width: 24,
        maxWidth: 24,
        height: 24,
        backgroundColor: WARM_NEUTRAL_10,
        borderRadius: "50%",
        flexGrow: 1,
        flexShrink: 0,
      }}
    >
      {children}
    </Flex>
  );
};

/**
 * Helper component to show a text with an icon or avatar to the left, and other component, chip mainly, to the right.
 * @param {object} props
 * @param {string} [props.text] - Text.
 * @param {string} [props.color=WARM_NEUTRAL_80] - Text color.
 * @param {string} [props.icon] - Left icon.
 * @param {string} [props.avatar] - User avatar.
 * @param {React.ReactNode} [props.leading] - Leading component.
 * @param {React.ReactNode} [props.trailing] - Trailing component.
 * @param {number} [props.gap=50] - Items gap.
 * @param {boolean} [props.widthAuto=false] - Tells the component not to expand al 100%.
 * @returns {React.ReactNode}
 */
const InfoText = ({
  text,
  color = WARM_NEUTRAL_80,
  icon,
  avatar,
  leading,
  trailing,
  gap = 50,
  widthAuto = false,
}) => {
  return (
    <Flex
      justifyContent={"flex-start"}
      style={{ width: widthAuto ? "auto" : "100%", gap: gap, marginBottom: 25 }}
    >
      <Flex
        justifyContent={"flex-start"}
        style={{ width: widthAuto ? "auto" : "100%", gap: 10 }}
      >
        {icon ? (
          <IconAvatarContainer>
            <Icon
              name={icon}
              color={WARM_NEUTRAL_60}
              style={{
                fontSize: 16,
              }}
            />
          </IconAvatarContainer>
        ) : avatar ? (
          <IconAvatarContainer>
            <img
              src={avatar}
              style={{
                width: 24,
                height: 24,
                borderRadius: "50%",
              }}
            />
          </IconAvatarContainer>
        ) : (
          <IconAvatarContainer>
            <Icon
              name={"person"}
              color={WARM_NEUTRAL_50}
              style={{
                fontSize: 16,
              }}
            />
          </IconAvatarContainer>
        )}
        {leading || <></>}
        <Typography
          typography="large"
          noMargin
          style={{
            overflow: "hidden",
            maxWidth: widthAuto ? "none" : "85%",
            textOverflow: "ellipsis",
            color: color,
          }}
        >
          {text}
        </Typography>
      </Flex>
      {trailing || <></>}
    </Flex>
  );
};

export default InfoText;
