import Steps from "../../../components/ui/steps/Steps";
import { useStepsTransaction } from "../../../utils/hooks";

const TransactionSteps = ({ id, currentStep = "historical" }) => {
  const { steps } = useStepsTransaction(id);
  return (
    <Steps
      steps={steps}
      currentStep={currentStep}
      checkCurrentByLabel
      minimal
      noBorderBottom
    />
  );
};

export default TransactionSteps;
