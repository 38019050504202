import {
  Chip,
  Heading,
  Row,
  Typography,
  WARM_NEUTRAL_60,
} from "@scrapadev/scrapad-front-sdk";
import React from "react";
import { useTranslation } from "../../../utils/hooks";

const LoadPossibilities = ({
  title,
  description,
  handleClick,
  typeFn,
  showIconFn,
  data,
  extraStyles,
}) => {
  const { t } = useTranslation(["common"]);
  const properties = {
    title: title || "",
    description: description || "",
    handleClick: handleClick || function () {},
    typeFn:
      typeFn ||
      function () {
        return false;
      },
    showIconFn:
      showIconFn ||
      function () {
        return false;
      },
    data: data || [],
    extraStyles: extraStyles || {},
  };

  return (
    <>
      <Heading type={6}>{properties.title}</Heading>

      <Typography
        small
        color="light"
        style={{
          fontWeight: 500,
          color: WARM_NEUTRAL_60,
          marginBottom: "10px",
        }}
      >
        {properties.description}
      </Typography>

      <Row gap={10} wrap>
        {data.map(({ type, text }) => {
          const selected = properties.showIconFn({ uuid: type }, text);
          return (
            <Chip
              key={type}
              type={
                properties.typeFn(
                  {
                    uuid: type,
                  },
                  text
                ) == true
                  ? "outlined"
                  : "normal"
              }
              showIcon={selected}
              text={t(text)}
              handleClick={() => {
                properties.handleClick(
                  {
                    uuid: type,
                  },
                  text
                );
              }}
              style={{
                justifyContent: "center",
                ...properties.extraStyles,
              }}
            />
          );
        })}
      </Row>
    </>
  );
};

export default LoadPossibilities;
