import { useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Chip,
  Container,
  Flex,
  Heading,
  Row,
  Spacer,
  WARM_NEUTRAL_10,
} from "@scrapadev/scrapad-front-sdk";
import Header from "../../components/ui/header/Header";
import NavBar from "../../components/ui/navbar/NavBar";
import Steps from "../../components/ui/steps/Steps";
import LayoutDetail from "../../layout/detail/Detail";
import { getBusinessAbout, getHeaderInfo } from "../../model/business";
import WidgetBusinessInfo from "../../widgets/business/about/Info";
import WidgetDataWrapper from "../../widgets/wrappers/DataWrapper";
import styles from "./BusinessAbout.module.scss";
import { useDocumentTitle } from "../../utils/document/hooks";
import HistoryBlockWrapper from "../../wrappers/HistoryBlockWrapper";
import { useTranslation, useSteps } from "../../utils/hooks";
import { useHeaderProvider } from "../../providers/header/HeaderContext";
import { useOperationsProvider } from "../../providers/organization/OperationsContext";
import { printVerified, sleep } from "../../utils/functions";
import { useBusinessUpdateInfo } from "../../utils/business/hooks";

const ChipContainer = ({ children }) => {
  return (
    <Flex
      alignItems={"flex-start"}
      justifyContent={"flex-start"}
      flexWrap={"wrap"}
      style={{
        gap: "15px",
      }}
    >
      {children}
    </Flex>
  );
};

const BusinessAbout = () => {
  const { id } = useParams();
  const { t, values, i18n } = useTranslation([
    "common",
    "transactions",
    "business",
  ]);
  const navigate = useNavigate();
  const [isDirty, setIsDirty] = useState([0, 0, 0, 0, 0]);
  useDocumentTitle(`${t("general_information")}`, values);
  const { state } = useHeaderProvider(i18n.language, id, getHeaderInfo);
  const { canManageOrg, canReadOrg } = useOperationsProvider();
  const { steps } = useSteps(id);
  const { updateBasicData } = useBusinessUpdateInfo();
  const saveInfoStateRef = useRef({});

  const submitCallback =
    (modalOpen, setModalOpen) =>
    async (e, loading, setLoading, unblockModal) => {
      setLoading(true);
      await updateBasicData({
        id,
        state: saveInfoStateRef.current,
        successCallback: unblockModal,
        errorCallback: () => {
          setLoading(false);
          setModalOpen(false);
        },
        setLoading,
      });
    };

  const handleUpdateStateCallback = (index) => (state, childIsDirty) => {
    const newDirty = [...isDirty];
    newDirty[index] = childIsDirty ? 1 : 0;
    setIsDirty(newDirty);
    saveInfoStateRef.current = { ...state };
  };

  const handleChildrenStatusAndDirty = (index) => (state, isDirty) => {};

  return (
    <>
      <NavBar />
      <LayoutDetail
        backgroundColor={"#fff"}
        headerStyle={{
          marginBottom: 40,
        }}
        headerComponent={
          <Container>
            <div className="row">
              <div className="col-12">
                <Header
                  title={
                    <Row gap={10}>
                      {printVerified(state?.verificationState, t)}
                      <span>{state?.basicData?.orgName}</span>
                    </Row>
                  }
                  goBack
                  breadcrumbs={[
                    {
                      description: t("business"),
                      onClick: () => navigate("/business", { replace: true }),
                    },
                    {
                      description: state?.basicData?.orgName,
                      onClick: () =>
                        navigate(`/business/${id}`, { replace: true }),
                    },
                    {
                      description: t("general_information"),
                    },
                  ]}
                  subHeaderLeft={
                    <Steps
                      steps={steps}
                      currentStep={"general_information"}
                      checkCurrentByLabel
                    />
                  }
                  fullSubHeaderLeft
                />
              </div>
            </div>
          </Container>
        }
        bodyComponent={
          <HistoryBlockWrapper
            mustBlockLeaving={isDirty.find((f) => f === 1)}
            submitCallback={submitCallback}
            saveAndExit
          >
            <div className={styles["sc-business--about"]}>
              <Container>
                <div className="row">
                  <div className="col-12 col-lg-9">
                    <Box
                      style={{
                        padding: 15,
                        borderRadius: 10,
                        backgroundColor: WARM_NEUTRAL_10,
                      }}
                    >
                      <Heading type={4} style={{ marginBottom: "15px" }}>
                        {t("general_information")}
                      </Heading>
                      <Box
                        style={{
                          padding: "20px 25px",
                          borderRadius: 10,
                          backgroundColor: "white",
                        }}
                      >
                        <Heading type={5} style={{ marginBottom: 25 }}>
                          {t("company_data")}
                        </Heading>
                        <WidgetDataWrapper
                          id={id}
                          fnData={getBusinessAbout}
                          Component={WidgetBusinessInfo}
                          fnParameters={{
                            locale: i18n.language,
                            org: id,
                            module: "basicData",
                          }}
                          extraData={{
                            permissionManage: canManageOrg,
                            permissionRead: canReadOrg,
                          }}
                          updateStateCallback={handleUpdateStateCallback(0)}
                          parentCallback={handleChildrenStatusAndDirty(0)}
                        />
                      </Box>
                    </Box>
                  </div>
                </div>
              </Container>
            </div>
          </HistoryBlockWrapper>
        }
      />
    </>
  );
};

export default BusinessAbout;
