import { Center, Empty, Spacer } from "@scrapadev/scrapad-front-sdk";
import { useNavigate } from "react-router-dom";
import Ads from "../../../components/ui/ads/Ads";
import CardBox from "../../../components/ui/box/CardBox";
import ResultsText from "../../../components/ui/text/ResultsText";
import { adsContainerTypes } from "../../../utils/ads/variables";
import { useTranslation } from "../../../utils/hooks";
import WidgetBusinessEmpty from "./Empty";

/**
 * Ads widget for dashboard.
 * @param {Object} props - Component properties.
 * @param {String} props.id - Business id.
 * @returns {JSX.Element}
 */
const WidgetBusinessAds = ({ id, state, extraData }) => {
  const { t } = useTranslation(["common", "transactions"]);
  const navigate = useNavigate();
  const { permission } = extraData || {};

  const handleNavigate = () => {
    navigate(`/business/${id}/ads`);
  };

  return (
    <CardBox
      title={t("ads")}
      action={t("see-more")}
      href={`/business/${id}/ads`}
      hasOverflow={false}
    >
      {state?.ads?.length > 0 ? (
        <>
          <Ads
            data={state}
            emptyMessageCode={"empty-section"}
            link={permission ? (permission() ? `/ads` : undefined) : `/ads`}
            containerType={adsContainerTypes.FLEX}
          />
          <Spacer height={15} />
          <ResultsText
            initial={1}
            current={6}
            total={state?.pagination?.total}
          />
        </>
      ) : (
        <Center>
          <WidgetBusinessEmpty type={"ads"} text={t("no-ads")} />
        </Center>
      )}
    </CardBox>
  );
};

export default WidgetBusinessAds;
