import React, { useState } from "react";
import WidgetFieldSaveDiscard from "../../fields/Save";
import { useTranslation } from "../../../utils/hooks";
import { useSetDataUser, useSignOut } from "../../../providers/AuthHooks";
import { useHeaderProvider } from "../../../providers/header/HeaderContext";
import { orgStatus } from "../../../utils/variables";
import { handleCRUD } from "../../../utils/crud/functions";
import {
  InputEmail,
  InputText,
  Label,
  Row,
} from "@scrapadev/scrapad-front-sdk";
import {
  handleOnChange,
  renderComponentWithPermission,
  renderDisabledComponentWithTooltip,
} from "../../../utils/functions";
import { useOperationsProvider } from "../../../providers/organization/OperationsContext";
import _ from "lodash";

function LegalRepresentative({
  id,
  state,
  dispatch,
  actions,
  isDirty,
  handleDiscard,
  handleParentCallback,
  fetchData,
  extraData,
}) {
  const { t, i18n } = useTranslation(["business", "common"]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const signOut = useSignOut();
  const setDataUser = useSetDataUser();
  const { state: headerState } = useHeaderProvider();
  const { permissionManage = null, permissionRead = null } = extraData || {};
  const verified = headerState?.verificationState === orgStatus.KYC_COMPLETE;
  const { canManageOrg } = useOperationsProvider();

  const handleSubmitWidget = async (e) => {
    e.preventDefault();
    handleParentCallback();
    //const data = { ...state, lang: i18n.language };
    setSubmitLoading(true);
    const data = _.cloneDeep(state);

    if (_.isEmpty(state?.id)) {
      delete data?.id;
    }

    delete data?.__typename;

    await handleCRUD({
      endpoint: _.isEmpty(state?.id)
        ? `org/${id}/representative-legal`
        : `org/${id}/representative-legal/${state?.id}`,
      method: _.isEmpty(state?.id) ? "POST" : "PUT",
      data: data,
      successCallback: () => fetchData(),
      messageOk: t("action-ok"),
      signOut,
      setDataUser,
      setLoading: setSubmitLoading,
    });
  };

  return (
    <form onSubmit={handleSubmitWidget}>
      <Row gap={20}>
        {renderComponentWithPermission(
          permissionRead,

          <Label small light text={t("name")} noPadding>
            {renderDisabledComponentWithTooltip(
              <InputText
                mode="underline"
                readOnly={
                  verified
                    ? verified
                    : permissionManage
                    ? !permissionManage()
                    : false
                }
                value={state?.firstName}
                onChange={handleOnChange("firstName", dispatch)}
                placeholder={t("write-here")}
              />,
              verified,
              t
            )}
          </Label>
        )}
        {renderComponentWithPermission(
          permissionRead,

          <Label small light text={t("first-surname")} noPadding>
            <InputText
              mode="underline"
              value={state?.lastName}
              onChange={handleOnChange("lastName", dispatch)}
              placeholder={t("write-here")}
              readOnly={permissionManage ? !permissionManage() : false}
            />
          </Label>
        )}
      </Row>
      <Row gap={20}>
        {renderComponentWithPermission(
          permissionRead,

          <Label small light text={t("first-surname")} noPadding>
            <InputEmail
              required
              value={state?.email || ""}
              onChange={handleOnChange("email", dispatch)}
              placeholder={t("write-here")}
              readOnly={permissionManage ? !permissionManage() : false}
              mode="underline"
            />
          </Label>
        )}
        {renderComponentWithPermission(
          permissionRead,

          <Label small light text={t("dni-number")} noPadding>
            <InputEmail
              required
              value={state?.identityCard || ""}
              onChange={handleOnChange("identityCard", dispatch)}
              placeholder={t("write-here")}
              readOnly={permissionManage ? !permissionManage() : false}
              mode="underline"
            />
          </Label>
        )}
      </Row>

      <WidgetFieldSaveDiscard
        permission={canManageOrg}
        isDirty={isDirty}
        handleDiscard={handleDiscard}
        loading={submitLoading}
      />
    </form>
  );
}

export default LegalRepresentative;
