import React from "react";

function AddressIcon() {
  return (
    <svg
      width="52"
      height="52"
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="1" y="1" width="50" height="50" rx="10" fill="#EBF3FF" />
      <rect
        x="1"
        y="1"
        width="50"
        height="50"
        rx="10"
        stroke="#D4E7FF"
        stroke-width="0.5"
      />
      <mask
        id="mask0_4018_4358"
        style={{
          maskType: "alpha",
        }}
        maskUnits="userSpaceOnUse"
        x="9"
        y="9"
        width="33"
        height="34"
      >
        <rect x="9" y="9.5" width="33" height="33" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_4018_4358)">
        <path
          d="M25.5 26C26.2563 26 26.9036 25.7307 27.4422 25.1922C27.9807 24.6536 28.25 24.0063 28.25 23.25C28.25 22.4937 27.9807 21.8464 27.4422 21.3078C26.9036 20.7693 26.2563 20.5 25.5 20.5C24.7437 20.5 24.0964 20.7693 23.5578 21.3078C23.0193 21.8464 22.75 22.4937 22.75 23.25C22.75 24.0063 23.0193 24.6536 23.5578 25.1922C24.0964 25.7307 24.7437 26 25.5 26ZM25.5 39.75C21.8104 36.6104 19.0547 33.6943 17.2328 31.0016C15.4109 28.3089 14.5 25.8167 14.5 23.525C14.5 20.0875 15.6057 17.349 17.8172 15.3094C20.0286 13.2698 22.5896 12.25 25.5 12.25C28.4104 12.25 30.9714 13.2698 33.1828 15.3094C35.3943 17.349 36.5 20.0875 36.5 23.525C36.5 25.8167 35.5891 28.3089 33.7672 31.0016C31.9453 33.6943 29.1896 36.6104 25.5 39.75Z"
          fill="#63A1FF"
        />
      </g>
    </svg>
  );
}

export default AddressIcon;
