import { Link, useLocation } from "react-router-dom";
import { checkActiveRouteClass } from "../../../utils/routes/functions";
import styles from "./Menu.module.scss";

const MenuItem = ({ to, icon, text }) => {
  const location = useLocation();
  return (
    <>
      {icon && (
        <i
          className={`${styles["c-navbar__menu-icon"]} large material-icons-outlined icon`}
        >
          {icon}
        </i>
      )}
      <Link
        to={to}
        title={text}
        className={checkActiveRouteClass([to], location, styles)}
      >
        {text}
      </Link>
    </>
  );
};

export default MenuItem;
