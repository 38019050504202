import { Center, Empty, TableManager } from "@scrapadev/scrapad-front-sdk";
import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import CardBox from "../../../components/ui/box/CardBox";
import { useTranslation } from "../../../utils/hooks";
import { transactionTypeInfo } from "../../../utils/variables";
import WidgetBusinessEmpty from "./Empty";

/**
 * Transactions widget for dashboard.
 * @param {Object} props - Component properties.
 * @param {String} props.id - Business id.
 * @param {Object} props.state - Data state.
 * @returns {JSX.Element}
 */
const WidgetBusinessTransactions = ({
  id,
  state,
  dispatch,
  actions,
  loading,
}) => {
  const { t, i18n } = useTranslation(["common", "transactions"]);
  const navigate = useNavigate();
  const sortedField = useRef(null);
  const sortedDirection = useRef(null);
  const derivedState = useMemo(() => {
    return {
      header: state.columns,
      body: state.rows,
    };
  }, [state.rows]);

  const navigateTo = () => navigate(`/business/${id}/transactions`);

  return (
    <CardBox
      title={t("transactions")}
      action={t("see-more")}
      href={`/business/${id}/transactions`}
      hasOverflow={false}
    >
      {state?.rows?.length > 0 ? (
        <TableManager
          loading={loading}
          data={derivedState}
          setData={(newData) =>
            dispatch({ type: actions.UPDATE_INPUT, payload: newData })
          }
          emptyText={t("empty-transactions")}
          rowTriggerPageChange={false}
          onRowClick={(uuid) => {
            navigate(`/transactions/${uuid}`);
          }}
          onAfterSort={(field, sortedAsc) => {
            sortedField.current = { ...field };
            sortedDirection.current = sortedAsc;
          }}
          itemsProps={{
            chip: {
              mapTransactionValues: transactionTypeInfo,
              translate: (code) => {
                if (!code) return "";
                if (code.includes("#")) {
                  const parsed = code.split("#");
                  const literals = parsed[1].split(",");
                  return literals.map((l) => t(l)).join(", ");
                }
                return t(code);
              },
            },
            split: {
              mapTransactionValues: transactionTypeInfo,
            },
            date: {
              languageData: i18n.getLanguageVariable(i18n.language),
            },
          }}
        />
      ) : (
        <Center>
          <WidgetBusinessEmpty
            type={"transactions"}
            text={t("no_transactions")}
          />
        </Center>
      )}
    </CardBox>
  );
};

export default WidgetBusinessTransactions;
