import React, { useEffect, useState } from "react";
import { useTranslation } from "../../utils/hooks";
import { useSetDataUser, useSignOut, useUser } from "../../providers/AuthHooks";
import { gqlWrapper } from "../../utils/gqlwrapper";
import { getFavAddresses } from "../../model/business";
import _ from "lodash";
import {
  Dropdown,
  InputText,
  Label,
  Padding,
  Spinner,
} from "@scrapadev/scrapad-front-sdk";
import InputCountries from "../../components/ui/input/country/Country";
import styles from "./AddressFormV3.module.scss";
import { printAddress } from "../../utils/functions";

function AddressFormV3({
  id,
  state,
  readOnly,
  type,
  markFavorite,
  streetProps = {},
  zipProps = {},
  townProps = {},
  // provinceProps = {},
  countryProps = {},
  savedDirectionProps = {},
  avoidPort,
  uuidOrg,
  onFacilitiesAlreadySetted = () => {},
}) {
  const { t } = useTranslation(["common", "talk"]);
  const [favDirections, setFavDirections] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const user = useUser();
  const { i18n } = useTranslation();
  const setDataUser = useSetDataUser();
  const signOut = useSignOut();
  const [showSaved, setShowSaved] = useState(false);

  useEffect(() => {
    if (state?.address?.id) {
      setShowSaved(true);
    }
  }, [state?.address?.id]);

  useEffect(() => {
    fetchData();
  }, [type, uuidOrg]);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const fn = await gqlWrapper(getFavAddresses, setDataUser, signOut);
      const response = await fn({
        locale: i18n.language,
        module: "locations",
        org: uuidOrg,
      });

      const res = response.getOrgLocations;
      let parseAddress = res.map((loc) => ({
        ...{
          uuid: loc.id,
          place: loc.alias,
        },
        ...loc,
      }));

      setFavDirections(parseAddress);
      if (parseAddress.length === 0 || !id) {
        if (_.isEmpty(state?.address)) {
          markFavorite(null)();
        }
      }

      let filteredAddress = favDirections;
      filteredAddress = filterByPort(filteredAddress);

      if (!_.isEmpty(filteredAddress[0]?.facilities)) {
        onFacilitiesAlreadySetted(true);
      }

      if (!_.isEmpty(parseAddress)) {
        let fav = undefined;
        fav = filterByPort(parseAddress);
        if (!_.isEmpty(fav)) {
          if (_.isEmpty(state?.address)) {
            markFavorite(fav[0]?.uuid, fav[0]?.facilities)();
            setShowSaved(true);
          }
        }
      }
    } catch (err) {
      setFavDirections([]);

      if (_.isEmpty(state?.address)) {
        markFavorite(null)();
      }
    } finally {
      setIsLoading(false);
    }
  };

  const filterByPort = (items = []) => {
    if (avoidPort) {
      return items.filter((a) => _.isEmpty(a?.port));
    }
    return items;
  };

  let filteredAddress = favDirections;
  filteredAddress = filterByPort(filteredAddress);

  const favAddressData = favDirections?.find(
    (f) => f.uuid === savedDirectionProps?.val
  );

  return !showSaved ? (
    <div className="container" style={{ padding: 0 }}>
      <div className="row">
        <>
          <div className="col-12 col-md-7">
            <Label text={t("street-number")} style={{ paddingBottom: 0 }}>
              <InputText mode="underline" autocomplete="off" {...streetProps} />
            </Label>
          </div>
          <div className="col-12 col-md-5">
            <Label text={t("postal-code")} style={{ paddingBottom: 0 }}>
              <InputText mode="underline" autocomplete="off" {...zipProps} />
            </Label>
          </div>
          <div className="col-12 col-md-6">
            <Label text={t("town")} reduced={true} style={{ paddingBottom: 0 }}>
              <InputText mode="underline" autocomplete="off" {...townProps} />
            </Label>
          </div>
          <div className="col-12 col-md-6">
            <Label
              text={t("country")}
              reduced={true}
              style={{ paddingBottom: 0 }}
            >
              <div>
                <InputCountries
                  handleItemClick={(option, open, setOpen) => {
                    if (countryProps?.onChangeCountry)
                      countryProps?.onChangeCountry(option, open, setOpen);
                  }}
                  skipRequiredHelp
                  style={{ width: "100%" }}
                  {...countryProps}
                />
                {isLoading ? (
                  <Padding>
                    <Spinner />
                  </Padding>
                ) : (
                  <></>
                )}
              </div>
            </Label>
          </div>
        </>

        {!readOnly && (
          <div className="col-12">
            <button
              type="button"
              className={`${styles["sc-addresses-delivery-button"]} ${styles["sc-addresses-use-saved"]}`}
              onClick={() => {
                markFavorite()();
                setShowSaved(true);
              }}
            >
              {t("stored-address")}
            </button>
          </div>
        )}
      </div>
    </div>
  ) : (
    <div className="col-12">
      <div className={styles["sc-addresses"]}>
        <span style={{ width: "100%" }}>
          {isLoading ? (
            <Spinner />
          ) : (
            <>
              <Dropdown
                mode="underline"
                name="favourite_address"
                required={true}
                options={filteredAddress.map((fd) => ({
                  uuid: fd.uuid,
                  text: printAddress(fd), //`${fd.street} ${fd.state} ${fd.city} - ${fd.place}`,
                  facilities: fd?.facilities,
                }))}
                value={
                  favAddressData
                    ? printAddress(favAddressData) //`${favAddressData?.street} ${favAddressData?.state} ${favAddressData?.city} - ${favAddressData?.place}`
                    : ""
                }
                handleItemClick={(option, open, setOpen) => {
                  if (savedDirectionProps.onChange)
                    savedDirectionProps.onChange(option, open, setOpen);
                }}
                autocomplete="off"
                {...savedDirectionProps}
              />
            </>
          )}
        </span>
      </div>
      {!readOnly && (
        <button
          type="button"
          className={styles["sc-addresses-delivery-button"]}
          onClick={() => {
            markFavorite(null, [])();
            setShowSaved(false);
          }}
        >
          {t("enter-not-saved-address", { ns: "talk" })}
        </button>
      )}
    </div>
  );
}

export default AddressFormV3;
