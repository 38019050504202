import {
  BODY_SMALL_REG,
  HEADING_5,
  ListItem,
  WARM_NEUTRAL_20,
} from "@scrapadev/scrapad-front-sdk";

/**
 * Component to show the list items.
 * @param {object} props - List item props.
 * @returns {React.ReactNode}
 */
const NotificationsListItem = ({ ...props }) => {
  return (
    <ListItem
      titleStyle={{
        ...HEADING_5,
      }}
      descriptionStyle={{
        ...BODY_SMALL_REG,
      }}
      {...props}
      style={{
        borderBottom: `0.5px solid ${WARM_NEUTRAL_20}`,
        borderRadius: 0,
        ...(props?.style ?? {}),
      }}
    />
  );
};

export default NotificationsListItem;
