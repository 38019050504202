import {
  InputPassword,
  Button,
  InputText,
  Spacer,
} from "@scrapadev/scrapad-front-sdk";
import { useTranslation } from "../../utils/hooks";
import LoginLink from "../../components/ui/login/LoginLink";
import LoginLabel from "../ui/login/LoginLabel";
import LoginInput from "../ui/login/LoginInput";

export const LoginForm = (props) => {
  const { t } = useTranslation(["login", "common"]);

  return (
    <>
      <form method="POST" onSubmit={props.doLogin}>
        <LoginLabel text={t("email-business")} small>
          <LoginInput
            element={
              <InputText
                mode="underline"
                outlined
                type="email"
                data-cy="email"
                required={true}
                name="email"
                id="email"
              />
            }
          />
        </LoginLabel>

        <LoginLabel text={t("password")} small hint={t("password-policy")}>
          <LoginInput
            element={
              <InputPassword
                mode="underline"
                data-cy="password"
                name="password"
                required={true}
                id="password"
              />
            }
          />
        </LoginLabel>
        <Spacer height={10} />
        <LoginLabel>
          <Button
            data-cy="submit"
            component="button"
            fullwidth={true}
            type="submit"
            text={t("access-account")}
            loading={props.isLoading}
            reduced
          />
        </LoginLabel>
        <LoginLink href={"/recover"} underlined>
          <strong>{t("forgot-pwd")}</strong>
        </LoginLink>
      </form>
    </>
  );
};
