import {
  TEXT_COLOR_LIGHT,
  Typography,
  WARM_NEUTRAL_60,
} from "@scrapadev/scrapad-front-sdk";
import { useTranslation } from "../../../utils/hooks";

/**
 * Helper component to render "Viewing results".
 * @param {object} props - Component poperties.
 * @param {number} [props.initial=1] - Initial value, defaults to 1.
 * @param {number} props.current - Current results.
 * @param {number} props.total - Total resuls.
 * @returns {React.ReactNode}
 */
const ResultsText = ({ initial = 1, current, total }) => {
  const { t } = useTranslation(["common"]);
  return (
    <Typography
      typography="small-reg"
      style={{ color: TEXT_COLOR_LIGHT }}
      noMargin
    >
      {t("viewing-results-of")}{" "}
      <span style={{ color: WARM_NEUTRAL_60 }}>{initial}</span>
      {"-"}
      <span style={{ color: WARM_NEUTRAL_60 }}>{current}</span> {t("of")}{" "}
      <span style={{ color: WARM_NEUTRAL_60 }}>{total}</span>
    </Typography>
  );
};

export default ResultsText;
