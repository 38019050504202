import NavBar from "../../components/ui/navbar/NavBar";
import { useSteps, useTranslation } from "../../utils/hooks";
import Header from "../../components/ui/header/Header";
import {
  checkPermission,
  dataURLtoFile,
  printVerified,
  searchOnTable,
  uploadFilesFromBase64,
} from "../../utils/functions";
import styles from "./BusinessUsers.module.scss";
import { useNavigate } from "react-router-dom";
import Steps from "../../components/ui/steps/Steps";
import InviteUser from "./invite/Invite";
import ModalFactory from "../../components/ui/modal/ModalFactory";
import { useState } from "react";
import SearchFilterExport from "../../components/ui/search-filter-export/SearchFilterExport";
import { useDocumentTitle } from "../../utils/document/hooks";
import {
  BREAKPOINT_MD,
  Center,
  Container,
  Flex,
  Heading,
  Paper,
  Row,
  Spacer,
  Spinner,
  useMatchMedia,
  UserCard,
  WARM_NEUTRAL_60,
} from "@scrapadev/scrapad-front-sdk";
import { useTableDataProvider } from "../../providers/TableContext";
import { QUERY_UPPER_LIMIT, tableModules } from "../../utils/variables";
import GQLFetchWrapper from "../../wrappers/FetchWrapper";
import { getHeaderInfo, getUserData } from "../../model/business";
import { handleCRUD } from "../../utils/crud/functions";
import { useSetDataUser, useSignOut, useUser } from "../../providers/AuthHooks";
import { useHeaderProvider } from "../../providers/header/HeaderContext";
import { useOperationsProvider } from "../../providers/organization/OperationsContext";
import { getUsers } from "../../model/tables";
import WidgetBusinessEmpty from "../../widgets/business/dashboard/Empty";

/**
 * Business users page.
 * @returns {JSX.Element}
 */
const BusinessUsers = ({ id }) => {
  const { t, i18n, values } = useTranslation(["common"]);
  const isMobile = useMatchMedia(`(max-width: ${BREAKPOINT_MD}px)`);
  const navigate = useNavigate();
  useDocumentTitle(`${t("users")}`, values);
  const [modalOpen, setModalOpen] = useState(false);
  const [uuidUser, setUuidUser] = useState(null);
  const user = useUser();
  const signOut = useSignOut();
  const setDataUser = useSetDataUser();
  const { steps } = useSteps(id);
  const isManagedAccount = false;
  const { data, setData, dataBuffer, loading, setLoading, fetchData } =
    useTableDataProvider();
  const { state } = useHeaderProvider(i18n.language, id, getHeaderInfo);
  // const handleRoleActionClick = (item) => async (uuid) => {
  //   await handleCRUD({
  //     endpoint: `org/${id}/users/${uuid}?from_dashboard=true`,
  //     method: "PUT",
  //     data: { role: item },
  //     successCallback: () => fetchData(),
  //     messageOk: t("action-ok"),
  //     signOut,
  //     setDataUser,
  //   });
  // };
  // const handleStatusActionClick = (item) => async (uuid) => {
  //   await handleCRUD({
  //     endpoint: `org/${id}/users/${uuid}?from_dashboard=true`,
  //     method: "PUT",
  //     data: { status: item },
  //     successCallback: () => fetchData(),
  //     messageOk: t("action-ok"),
  //     signOut,
  //     setDataUser,
  //   });
  // };
  const { canManageUsers, canReadUsers } = useOperationsProvider();

  // const renderRoleActions = () => {
  //   return userRoleOptions.map((role) => ({
  //     uuid: role?.uuid,
  //     label: t(role?.text),
  //     callBack: handleRoleActionClick(role?.uuid),
  //   }));
  // };

  // const renderStatusActions = () => {
  //   return orgStatusOptions.map((status) => ({
  //     uuid: status?.uuid,
  //     label: t(status?.text),
  //     callBack: handleStatusActionClick(status?.uuid),
  //   }));
  // };

  const ownUserUuid = user?.userUuid;

  return (
    <>
      <NavBar />
      <Container>
        <div className="row">
          <div className="col-12">
            <Header
              title={
                <Row gap={10}>
                  {printVerified(state?.verificationState, t)}
                  <span>{state?.basicData?.orgName}</span>
                </Row>
              }
              goBack
              breadcrumbs={[
                {
                  description: t("business"),
                  onClick: () => navigate(`/business/${id}`, { replace: true }),
                },
                {
                  description: state?.basicData?.orgName,
                  onClick: () => navigate("/business", { replace: true }),
                },
                { description: t("users") },
              ]}
              subHeaderLeft={
                <>
                  <Spacer height={10} />
                  <div className={styles["sc-business--steps"]}>
                    <Steps
                      steps={steps}
                      currentStep={"users"}
                      checkCurrentByLabel
                    />
                  </div>
                  {/* <SearchFilterExport
                    isLocalSearch
                    searchPlaceholder={t("search")}
                    searchOnChange={searchOnTable(
                      data,
                      setData,
                      dataBuffer.current,
                      i18n
                    )}
                    exportProps={{
                      fnData: getUsers,
                      fnParameters: {
                        locale: i18n.language,
                        limit: QUERY_UPPER_LIMIT,
                        tableModule: tableModules.USERS,
                        uuidModule: id,
                      },
                      fileName: "users",
                    }}
                    hasFilters={false}
                    hasSeparator={false}
                    extraComponents={
                      JSON.parse(process.env.REACT_APP_INVITE_USER) &&
                      checkPermission(canManageUsers) ? (
                        <InviteUser uuidOrg={id} />
                      ) : undefined
                    }
                  /> */}
                </>
              }
              fullSubHeaderLeft
            />
          </div>
        </div>
      </Container>
      <Container>
        <Paper
          style={{
            padding: "15px 25px",
            gap: "15px",
            alignItems: "stretch",
          }}
        >
          <Row justify="space-between">
            <Heading type={3} style={{ marginBottom: 0 }}>
              {t("users_of")} {state?.basicData?.orgName}
            </Heading>
            <Row gap={10}>
              {JSON.parse(process.env.REACT_APP_INVITE_USER) &&
              checkPermission(canManageUsers) ? (
                <InviteUser uuidOrg={id} fetchUsers={fetchData} expanded />
              ) : undefined}
              <SearchFilterExport
                isLocalSearch
                searchPlaceholder={t("search")}
                searchOnChange={searchOnTable(
                  data,
                  setData,
                  dataBuffer.current,
                  i18n
                )}
                hasExport={!isManagedAccount}
                hasSearch={false}
                exportProps={{
                  fnData: getUsers,
                  fnParameters: {
                    locale: i18n.language,
                    limit: QUERY_UPPER_LIMIT,
                    tableModule: tableModules.USERS,
                    uuidModule: id,
                  },
                  fileName: "users",
                }}
                hasFilters={false}
                hasSeparator={false}
              />
            </Row>
          </Row>

          {loading ? (
            <Center>
              <Spinner />
            </Center>
          ) : isManagedAccount ? (
            <Flex>
              <WidgetBusinessEmpty
                type={"users"}
                text={t("managed-account-without-users")}
                textStyle={{
                  maxWidth: 190,
                  color: WARM_NEUTRAL_60,
                }}
              />
            </Flex>
          ) : (
            <Flex
              flexDirection="row"
              justifyContent="flex-start"
              alignItems="stretch"
              style={{ flexWrap: "wrap", gap: "15px" }}
            >
              {data?.body?.map((user) => {
                const rowData = data?.body
                  ?.filter((row) => row.uuid === user.uuid)
                  ?.filter(
                    (row) =>
                      row?.cells?.filter(
                        (cell) =>
                          cell.colRef === "status" &&
                          cell.value !== "invitation-requested"
                      ).length > 0
                  );
                return (
                  <div
                    key={`user_card_${user.uuid}`}
                    style={{
                      width: isMobile ? "100%" : "calc(50% - 10px)",
                    }}
                  >
                    <UserCard
                      t={t}
                      avatarUrl={
                        user?.cells
                          ?.find((e) => e.colRef === "user_name")
                          ?.value?.split("#")[0] !== "no_avatar"
                          ? user?.cells
                              ?.find((e) => e.colRef === "user_name")
                              ?.value?.split("#")[0]
                          : undefined
                      }
                      name={`${
                        user?.cells
                          ?.find((e) => e.colRef === "user_name")
                          ?.value?.split("#")[1]
                      } ${
                        user?.cells?.find((e) => e.colRef === "user_surname")
                          ?.value
                      }`}
                      email={`${
                        user?.cells?.find((e) => e.colRef === "email")?.value
                      }`}
                      info={[
                        `${
                          user?.cells?.find((e) => e.colRef === "role")?.value
                        }`,
                        `${
                          user?.cells?.find((e) => e.colRef === "phone")?.value
                        }`,
                      ]}
                      role={
                        user?.cells?.find((e) => e.colRef === "role")?.value
                      }
                      status={
                        user?.cells?.find((e) => e.colRef === "status")?.value
                      }
                      onEdit={
                        canManageUsers() && rowData.length > 0
                          ? () => {
                              setUuidUser(user.uuid);
                              setModalOpen(true);
                            }
                          : undefined
                      }
                      onDelete={
                        user?.cells?.find((e) => e.colRef === "status")
                          ?.value === "banned" || user.uuid === ownUserUuid
                          ? undefined
                          : canManageUsers() && rowData.length > 0
                          ? () => {
                              // setDeleteWarn(user.uuid);
                            }
                          : undefined
                      }
                    />
                  </div>
                );
              })}
            </Flex>
          )}
        </Paper>

        <div className="row">
          <div className="col-12">
            {uuidUser && (
              <GQLFetchWrapper
                key={uuidUser}
                fnData={getUserData}
                fnParameters={{
                  locale: i18n.language,
                  org: id,
                  user: uuidUser,
                }}
                Component={ModalFactory}
                type="user_info"
                open={modalOpen}
                setOpen={setModalOpen}
                uuidUser={uuidUser}
                deps={[uuidUser]}
                delegateLoading
                permissionManage={canManageUsers}
                permissionRead={canReadUsers}
                onSubmit={async (state, imageLoading, setImageLoading) => {
                  setImageLoading(true);
                  const data = { ...state };
                  data.lang = i18n.language;
                  data.job_position = data?.jobPosition;
                  if (data?.avatar?.includes("data:image")) {
                    let extension = data.avatar.split(";")[0].split("/")[1];
                    if (extension === "jpeg") extension = "jpg";
                    const files = await uploadFilesFromBase64({
                      files: [
                        dataURLtoFile(
                          data.avatar,
                          `avatar-${uuidUser}-${new Date().getTime()}.${extension}`
                        ),
                      ],
                      fileType: "user-profile",
                      setDataUser,
                      signOut,
                      setLoading: () => {},
                      t,
                    });
                    if (files.length > 0) data.profileImgId = files?.[0]?.uuid;
                  }
                  delete data.avatar;
                  delete data.jobPosition;
                  handleCRUD({
                    endpoint: `org/${id}/users/${uuidUser}`,
                    method: "PUT",
                    data: data,
                    successCallback: async () => {
                      await fetchData();
                      setImageLoading(false);
                    },
                    errorCallback: () => {
                      setImageLoading(false);
                    },
                    messageOk: t("action-ok"),
                    signOut,
                    setDataUser,
                    setLoading: setModalOpen,
                  });
                }}
              />
            )}
          </div>
        </div>
      </Container>
    </>
  );
};

export default BusinessUsers;
