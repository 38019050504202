import React from "react";
import { getValidationInfo } from "../../../model/business";
import { useTranslation } from "../../../utils/hooks";
import { verificationTypes } from "../../../utils/verification/variables";
import GQLFetchWrapper from "../../../wrappers/FetchWrapper";
import BasicVerificationValidation from "./BasicValidation";
import CompleteVerificationValidation from "./CompleteValidation";

/**
 * Helper component to gather verification info and render the correct switch to update that type of verification.
 * @param {object} props - Component properties.
 * @param {string} props.id - Org id.
 * @param {"basic"|"complete"} [props.type="basic"] - The type of verification we want to update.
 * @param {boolean} [props.showLabel=true] - Show or hides the switch label.
 * @returns {React.ReactNode}
 */
const VerificationValidationSwitch = ({
  id,
  type = verificationTypes.BASIC,
  showLabel = true,
}) => {
  const { i18n } = useTranslation();
  const component =
    type === verificationTypes.COMPLETE
      ? CompleteVerificationValidation
      : BasicVerificationValidation;
  return (
    <GQLFetchWrapper
      fnData={getValidationInfo}
      fnParameters={{
        locale: i18n.language,
        org: id,
      }}
      id={id}
      Component={component}
      showLabel={showLabel}
    />
  );
};

export default VerificationValidationSwitch;
