import {
  BREAKPOINT_LG,
  Button,
  Container,
  IconItem,
  Row,
  Spacer,
  TableManager,
  useMatchMedia,
  WARM_NEUTRAL_80,
} from "@scrapadev/scrapad-front-sdk";
import { useRef, useState } from "react";
import ModalFactory from "../../components/ui/modal/ModalFactory";
import LayoutTable from "../../layout/table/Table";
import { getTable } from "../../model/tables";
import { DataProvider } from "../../providers/data/DataContext";
import { usePaginationProvider } from "../../providers/pagination/PaginationContext";
import { useFiltersProvider } from "../../providers/filters/FiltersContext";
import { useTableDataProvider } from "../../providers/TableContext";
import { useDocumentTitle } from "../../utils/document/hooks";
import { useTranslation } from "../../utils/hooks";
import { useTableActions, useTableConfig } from "../../utils/tables/hooks";
import { modalTypes, TABLE_LIMIT, tableModules } from "../../utils/variables";
import { useOperationsProvider } from "../../providers/organization/OperationsContext";
import { useSortProvider } from "../../providers/sort/SortContext";
import { applySortToFilters } from "../../utils/tables/functions";

const InnerLogisticsProvider = ({ baseTableParams }) => {
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_LG}px)`);
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const rowData = useRef({});
  const rowUuid = useRef(null);
  const { t, i18n } = useTranslation(["logistics", "common"], true);
  const { data, setData, loading, setLoading, fetchData } =
    useTableDataProvider();
  const { handlePagination } = usePaginationProvider();
  const { buildFiltersAndFetch, getStoredFilters } = useFiltersProvider();
  const { state: sortState, handleSorting, buildSort } = useSortProvider();
  const filters = buildFiltersAndFetch(getStoredFilters() || {});
  const { canManageLogistics } = useOperationsProvider();
  const sortFields = buildSort(sortState);

  useTableActions({
    paginationCallback: (paginationState) => {
      const newFilters = buildFiltersAndFetch(getStoredFilters() || {});
      const params = {
        ...baseTableParams,
        sort: sortState?.field ? sortState : undefined,
        offset: paginationState?.current,
      };
      if (Object.keys(newFilters).length > 0) {
        params.filter = newFilters;
      }
      fetchData({
        ...params,
        filter: applySortToFilters(
          newFilters,
          sortFields,
          "REACT_APP_SORT_LOGISTICS"
        ),
      });
    },
  });

  useDocumentTitle(`${t("logistics")}`);

  const handleManageLogistics = () => {
    if (!canManageLogistics) return true;
    return canManageLogistics();
  };

  const submitcallback = async () => {
    setLoading(true);
    await fetchData();
    setLoading(false);
  };

  return (
    <Container isFluid>
      <Spacer height={25} />
      <Row gap={15} justify="space-between">
        <span></span>
        <Button
          component="button"
          type="button"
          mode={"info"}
          ultrawide
          text={t("add-provider")}
          onClick={() => setOpen(!open)}
        />
      </Row>
      <Spacer height={25} />
      <div className="row">
        <div className="col-12">
          <TableManager
            isInfoTable
            loading={loading}
            data={data}
            setData={setData}
            emptyText={t("empty-data")}
            rowTriggerPageChange={false}
            onClickActionCallback={(uuid, index, row) => {
              const cells = row?.cells || [];
              rowUuid.current = uuid;
              rowData.current = {
                name: cells?.[0]?.value,
                email: cells?.[1]?.value,
                activity: cells?.[2]?.value,
                equipment: cells?.[3]?.value,
                origin: cells?.[4]?.value,
                service: cells?.[5]?.value,
                coverage: cells?.[6]?.value,
                notification_language: cells?.[7]?.value,
              };
            }}
            externalSort={
              JSON.parse(process.env.REACT_APP_SORT_LOGISTICS)
                ? handleSorting
                : undefined
            }
            sorting={buildSort(sortState)}
            itemsProps={{
              text: {
                translate: (value, colRef) => {
                  if (colRef === "notification_language") return t(value);
                  return value;
                },
              },
            }}
            pagination={
              data?.pagination
                ? {
                    currentPage: Math.ceil(
                      data?.pagination?.current / TABLE_LIMIT
                    ),
                    totalPages: Math.ceil(
                      data?.pagination?.total / TABLE_LIMIT
                    ),
                    threshold: 3,
                    onChangePage: handlePagination,
                    onChangePrev: handlePagination,
                    onChangeNext: handlePagination,
                  }
                : undefined
            }
            actions={
              handleManageLogistics()
                ? [
                    {
                      component: (
                        <IconItem
                          name="edit"
                          text={t("edit")}
                          color={WARM_NEUTRAL_80}
                          textStyle={{ fontWeight: 300 }}
                          onClick={() => {
                            setIsEdit(true);
                            setOpen(!open);
                          }}
                        />
                      ),
                    },
                    {
                      component: (
                        <IconItem
                          name="delete"
                          text={t("delete")}
                          color={WARM_NEUTRAL_80}
                          textStyle={{ fontWeight: 300 }}
                          onClick={() => setOpenDelete(!openDelete)}
                        />
                      ),
                    },
                  ]
                : []
            }
          />
        </div>
      </div>
      {handleManageLogistics() ? (
        <>
          {open && (
            <DataProvider>
              <ModalFactory
                key={rowUuid.current}
                type={modalTypes.LOGISTICS_ADD_EDIT_PROVIDER}
                open={open}
                setOpen={() => {
                  setOpen(false);
                  setIsEdit(false);
                  rowUuid.current = isEdit
                    ? `reset_new_row_0`
                    : `reset_edit_row_0`;
                }}
                isEdit={isEdit}
                data={rowData.current}
                uuid={rowUuid.current}
                fetchData={fetchData}
                submitCallback={submitcallback}
              />
            </DataProvider>
          )}

          <ModalFactory
            key={rowUuid.current}
            type={modalTypes.LOGISTICS_DELETE_PROVIDER}
            open={openDelete}
            setOpen={setOpenDelete}
            uuid={rowUuid.current}
            fetchData={fetchData}
            submitCallback={submitcallback}
          />
        </>
      ) : (
        <></>
      )}
    </Container>
  );
};

const LogisticsProviders = () => {
  const { i18n } = useTranslation();
  const baseParams = {
    locale: i18n.language,
    limit: TABLE_LIMIT,
    tableModule: tableModules.TRANSPORTERS,
  };
  const { tableParams } = useTableConfig(baseParams, {
    sortingFlag: "REACT_APP_SORT_LOGISTICS",
  });

  return (
    <LayoutTable fnData={getTable} fnParameters={tableParams}>
      <InnerLogisticsProvider baseTableParams={baseParams} />
    </LayoutTable>
  );
};

export default LogisticsProviders;
