import React from "react";

function OpportunitiesIcon() {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="50" height="50" rx="10" fill="#EBF3FF" />
      <mask
        id="mask0_4018_4364"
        style={{
          maskType: "alpha",
        }}
        maskUnits="userSpaceOnUse"
        x="11"
        y="11"
        width="27"
        height="28"
      >
        <rect x="11" y="11.5" width="27" height="27" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_4018_4364)">
        <path
          d="M31.6719 26.5469V23.4532H37.4657V26.5469H31.6719ZM33.8938 34.8719L29.2532 31.3844L31.1376 28.9094L35.75 32.3688L33.8938 34.8719ZM31.1376 21.0907L29.2532 18.6157L33.8938 15.1282L35.75 17.6313L31.1376 21.0907ZM14.9094 33.8313V29.3313H14.6282C13.7282 29.2188 12.9875 28.825 12.4063 28.15C11.825 27.475 11.5344 26.6875 11.5344 25.7875V24.2125C11.5344 23.2375 11.8813 22.4032 12.575 21.7094C13.2688 21.0157 14.1032 20.6688 15.0782 20.6688H18.9594L25.7938 16.5625V33.4376L18.9594 29.3313H18.6219V33.8313H14.9094ZM26.9188 29.7251V20.275C27.7063 20.8 28.3297 21.4797 28.7891 22.3141C29.2485 23.1485 29.4782 24.0438 29.4782 25C29.4782 25.9563 29.2485 26.8516 28.7891 27.686C28.3297 28.5204 27.7063 29.2001 26.9188 29.7251Z"
          fill="#63A1FF"
        />
      </g>
    </svg>
  );
}

export default OpportunitiesIcon;
