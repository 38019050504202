import { eachDayOfInterval, endOfWeek, format, startOfWeek } from "date-fns";
import { saveAs } from "file-saver";
import { postClient } from "./restclient";
import { actions } from "../providers/data/actions/data";
import {
  BLUE_10,
  BLUE_30,
  BLUE_60,
  Icon,
  SCRAPBACK_BLUE_60,
  toastError,
  toastSuccess,
  TooltipSc,
  WARM_NEUTRAL_20,
} from "@scrapadev/scrapad-front-sdk";
import { handleCRUD } from "./crud/functions";
import _ from "lodash";
import {
  currencyOptions,
  MAX_UPLOAD_SIZE,
  orgStatus,
  termsConditionsTypes,
} from "./variables";
import { FlagMap } from "../components/ui/icons/general/Flags";

/**
 * No operation function.
 */
export const noop = () => {};

/**
 * Function to get offset positioning of an element.
 * @param {HTMLElement} el - DOM Node.
 * @returns {{ top: Number, left: Number }}
 */
export const getOffset = (el) => {
  const rect = el.getBoundingClientRect();
  return {
    left: rect.left + window.scrollX,
    top: rect.top + window.scrollY,
  };
};

/**
 * Gets a cookie and returns the cookies value, if not exits returns blank ""
 * @param {String} c_name - Cookie name.
 * @returns {String}
 */
export const getCookie = (name) => {
  if (document.cookie.length > 0) {
    let c_start = document.cookie.indexOf(name + "=");
    if (c_start != -1) {
      c_start = c_start + name.length + 1;
      let c_end = document.cookie.indexOf(";", c_start);
      if (c_end == -1) {
        c_end = document.cookie.length;
      }
      return unescape(document.cookie.substring(c_start, c_end));
    }
  }
  return "";
};

/**
 * Sets a cookie with given parameters.
 * @param {String} name
 * @param {String} value
 * @param {Date} expires
 */
export const setCookie = (
  name,
  value,
  expireDays,
  path = "/",
  domain = "scrapad.com"
) => {
  let exdate = new Date();
  exdate.setDate(exdate.getDate() + expireDays * (60 * 60 * 24));
  document.cookie =
    name +
    "=" +
    escape(value) +
    (expireDays == null ? "" : "; expires=" + exdate.toUTCString()) +
    `; domain=.${domain}` +
    `; path=${path}`;
};

/**
 * CFhecks to see if a cookie exists.
 * @param {String} name - Cookie name.
 * @returns {Boolean}
 */
export const checkCookie = (name) => {
  name = getCookie(name);
  return name != null && name != "" ? true : false;
};

export const clearCookies = () => {
  document.cookie.split(";").forEach(function (c) {
    document.cookie = c
      .replace(/^ +/, "")
      .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
  });
};

/**
 * Returns the value of a localstorage item.
 * @param {String} name - Cookie name.
 * @returns {Boolean}
 */
export const getLSValueByPartialKey = (text) => {
  if (typeof window !== "undefined") {
    const lskeys = Object.keys(window.localStorage);
    const item = lskeys.filter((lsk) => lsk.includes(text));
    if (item.length > 0) {
      if (typeof window !== "undefined") {
        return window.localStorage.getItem(item[0]);
      }
    }
  }

  return false;
};

export const easeInOutQuad = (t, b, c, d) => {
  t /= d / 2;
  if (t < 1) return (c / 2) * t * t + b;
  t--;
  return (-c / 2) * (t * (t - 2) - 1) + b;
};

export const scrollTo = (element, to, duration) => {
  var start = element.scrollTop,
    change = to - start,
    currentTime = 0,
    increment = 20;

  var animateScroll = function () {
    currentTime += increment;
    var val = easeInOutQuad(currentTime, start, change, duration);
    element.scrollTop = val;

    if (currentTime < duration) {
      window.requestAnimationFrame(animateScroll);
    }
  };
  window.requestAnimationFrame(animateScroll);
};

/**
 * Function to get month names as a list, based on locale.
 * @param {String|Array} locales - Locales
 * @param {("long"|"short")} format - Month text format
 * @returns {JSX.Element}
 */
export const getMonthList = (locales, format = "long") => {
  const year = new Date().getFullYear(); // 2020
  const monthList = [...Array(12).keys()]; // [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
  const formatter = new Intl.DateTimeFormat(locales, {
    month: format,
  });

  const getMonthName = (monthIndex) =>
    formatter.format(new Date(year, monthIndex));

  return monthList.map(getMonthName);
};

export const getWeekDays = (locale, dateFormat = "iiii") => {
  const now = new Date();
  const weekDays = [];
  const start = startOfWeek(now, { locale });
  const end = endOfWeek(now, { locale });
  eachDayOfInterval({ start, end }).forEach((day) => {
    weekDays.push(format(day, dateFormat, { locale: locale || undefined }));
  });
  return weekDays;
};

export const handlesTableSort = (data, setData) => (field) => {
  const sort_key = field.colRef;
  const sorted = data.body.sort((a, b) => {
    if (a[0].cells[0][sort_key] > b[0].cells[0][sort_key]) return 1;
    if (a[0].cells[0][sort_key] < b[0].cells[0][sort_key]) return -1;
    return 0;
  });
  setData({
    header: [...data.header],
    body: [...sorted],
  });
};

export const getHoursForDropdown = () => {
  return [
    { uuid: "00:00", text: "00:00" },
    { uuid: "01:00", text: "01:00" },
    { uuid: "02:00", text: "02:00" },
    { uuid: "03:00", text: "03:00" },
    { uuid: "04:00", text: "04:00" },
    { uuid: "05:00", text: "05:00" },
    { uuid: "06:00", text: "06:00" },
    { uuid: "07:00", text: "07:00" },
    { uuid: "08:00", text: "08:00" },
    { uuid: "09:00", text: "09:00" },
    { uuid: "10:00", text: "10:00" },
    { uuid: "11:00", text: "11:00" },
    { uuid: "12:00", text: "12:00" },
    { uuid: "13:00", text: "13:00" },
    { uuid: "14:00", text: "14:00" },
    { uuid: "15:00", text: "15:00" },
    { uuid: "16:00", text: "16:00" },
    { uuid: "17:00", text: "17:00" },
    { uuid: "18:00", text: "18:00" },
    { uuid: "19:00", text: "19:00" },
    { uuid: "20:00", text: "20:00" },
    { uuid: "21:00", text: "21:00" },
    { uuid: "22:00", text: "22:00" },
    { uuid: "23:00", text: "23:00" },
  ];
};

/**
 * Función para nombrar a los ficheros que descargamos del back.
 * @param {String} name - Name of file to append.
 * @returns {String}
 */
export const getAFileName = (name = "") => {
  const d = new Date();
  const month = d.getMonth() + 1;
  const day = d.getDay();
  const year = d.getFullYear();
  const hour = d.getHours();
  const min = d.getMinutes();
  return `${name}-${day}-${month}-${year}_${hour}-${min}`;
};

/**
 * Function to export data to csv.
 * @param {String} filename - Name of file
 * @param {Array} data - Data to convert to to csv
 * @param {String} [separator="|"] - CSV Separator
 */
export const exportCSV = (filename, data, separator = "|") => {
  let csvContent = data
    .map((e) => {
      return e.join(separator);
    })
    .join("\n");
  const blob = new Blob([csvContent], {
    type: "data:text/csv;charset=utf-8",
  });
  saveAs(blob, filename + ".csv");
};

/**
 * Lookup function to show the correct value in csv.
 * @param {String} f Type of field
 * @param {String} value Value to render.
 * @param {{ language: String, getLanguageVariable: Function }} i18n - i18n data.
 * @returns {JSX.Element}
 */
export const csvLookUp = (f, value, i18n) => {
  if (value === null) return;
  switch (f) {
    case "text":
    case "text_double":
    case "bold":
    case "link":
    case "dropdown":
      return value;
    case "chip":
    case "chip_double":
    case "chip_dropdown":
      return value;
    case "date":
      return format(Number(value), "P", {
        locale: i18n.getLanguageVariable(i18n.language),
      });
    case "slplit":
    case "split":
      const parsedValue = value.split("#");
      return parsedValue[1];
    case "avatar":
      const avatarValue = value.split("#");
      return avatarValue[1];
  }
};

export const searchOnTable = (data, setData, buffer, i18n, t) => (e) => {
  const val = e.target.value.toLowerCase();
  const textFormatKeys = ["text", "bold", "date", "slplit", "split", "chip"];
  if (val !== "") {
    const results = buffer.body.filter((d) => {
      return d.cells.find((cell) => {
        if (!textFormatKeys.includes(cell.format)) return false;
        switch (cell.format) {
          case "avatar":
          case "text":
          case "text_double":
          case "bold":
          case "link":
          case "dropdown":
            return cell.value.toLowerCase().indexOf(val) > -1;
          case "date":
            return (
              format(Number(cell.value), "P", {
                locale: i18n.getLanguageVariable(i18n.language),
              }).indexOf(val) > -1
            );
          case "slplit":
          case "split":
            const parsedValue = cell.value.split("#");
            return parsedValue[1].toLowerCase().indexOf(val) > -1;
          case "chip":
            if (!t) return cell.value.toLowerCase().indexOf(val) > -1;
            return cell.value.toLowerCase().indexOf(t(val)) > -1;
        }
      });
    });
    setData(
      results.length === 0
        ? { header: [], body: [...results] }
        : { header: [...buffer.header], body: [...results] }
    );
  } else {
    setData(buffer);
  }
};

export const searchOnAds = (data, setData, buffer, i18n) => (e) => {
  const val = e.target.value.toLowerCase();
  const excludeKeys = ["uuid", "images", "adImage"];
  if (val !== "") {
    const results = buffer.ads.filter((d) => {
      const keys = Object.keys(d).filter((f) => !excludeKeys.includes(f));
      return (
        keys.filter((key) => d[key] && d[key].toLowerCase().indexOf(val) > -1)
          .length > 0
      );
    });
    setData(
      results.length === 0
        ? {
            pagination: { ...buffer },
            ads: [],
          }
        : {
            pagination: { ...buffer },
            ads: results,
          }
    );
  } else {
    setData(buffer);
  }
};

export const searchOnLog =
  (notPinned, setNotPinned, buffer, t, i18n) => (e) => {
    const val = e.target.value.toLowerCase();
    if (val !== "") {
      const resultsIndexes = {};
      buffer.filter((log, logIndex) => {
        log.logItems.forEach((item, itemIndex) => {
          const flatContentData = item.contentData
            ?.map((m) => [m.key.toLowerCase(), m.value.toLowerCase()])
            ?.flat();
          if (
            flatContentData?.find((f) => f.indexOf(val) > -1) ||
            item.content.toLowerCase().indexOf(val) > -1 ||
            t(item.originatedBy).toLowerCase().indexOf(val) > -1 ||
            t(item.phase.toLowerCase()).toLowerCase().indexOf(val) > -1
          ) {
            if (resultsIndexes[logIndex]) {
              resultsIndexes[logIndex] = [
                ...resultsIndexes[logIndex],
                itemIndex,
              ];
            } else {
              resultsIndexes[logIndex] = [itemIndex];
            }
          }
        });
      });
      let newResults = [];
      Object.keys(resultsIndexes).forEach((element, index) => {
        const parsedKey = parseInt(element);
        const filteredItems = [...buffer[parsedKey].logItems];
        newResults.push({
          date: buffer[parsedKey].date,
          logItems: filteredItems.filter((f, index) =>
            resultsIndexes[element].includes(index)
          ),
        });
      });
      resultsIndexes.length === 0 ? setNotPinned([]) : setNotPinned(newResults);
    } else {
      setNotPinned(buffer);
    }
  };

/**
 * Helper to format dates.
 * @param {Number} date - Date to format.
 * @param {{ language: String, getLanguageVariable: Function }} i18n - i18n data.
 * @param {Boolean} needParse - Tells if date need a number parse. When date comes in cientific notation.
 * @param {String} formatter - Date format.
 * @returns {String}
 */
export const formatDate = (date, i18n, needParse = true, formatter = "P") => {
  if (!date) return "";
  const parsed = needParse ? Number(date) : date;
  return format(parsed, formatter, {
    locale: i18n.getLanguageVariable(i18n.language),
  });
};

/**
 * Handler for ok forms
 * @param {Function} t - Translate function
 * @param {Function} fetchData - Fetch data function
 */
export const handleFormOK = (t, fetchData) => {
  toastSuccess(t("action-ok"));
  if (fetchData) fetchData(true);
};

/**
 * Helper to show value in dropdown.
 * @param {Object} state - Current form state.
 * @param {Array} options - Dropdown options.
 * @returns {String}
 */
export const dropdownGetValueFromUuid = (state, options) =>
  options.find((f) => f.uuid === state?.state)?.text;

/**
 * Helper for download contracts.
 * @param {String} endpoint - Document path and url
 */
export const handleDownloadDocument = async (
  endpoint,
  signOut,
  setDataUser,
  navigate
) => {
  try {
    const response = await postClient({
      external: true,
      url: `${process.env.REACT_APP_MARKETPLACE_URL}/${process.env.REACT_APP_SCRAPAD_API_VERSION}${endpoint}`,
      method: "GET",
      signOut,
      setDataUser,
    });
    if (response) {
      if (Array.isArray(response)) {
        const termsConditionsIncludes = Object.values(termsConditionsTypes);
        const contract = response.find(
          (f) => !termsConditionsIncludes.includes(f?.document_type)
        );
        if (contract && contract.link)
          window.open(contract.link, "_blank").focus();
      } else {
        if (response.link) {
          window.open(response.link, "_blank").focus();
        } else {
          // When we dont have a response.link its means its a redirection to an url
          navigate ? navigate(response) : (window.location.href = response);
        }
      }
    }
  } catch (error) {
    toastError("Error en la descarga del archivo");
  }
};

/**
 * Helper for getting a style of an element.
 * @param {Node} element - Node to get style.
 * @param {String} property - Property to search
 * @returns {Number}
 */
export const getComputedStyle = (element, property) => {
  return parseInt(
    window.getComputedStyle(element, null).getPropertyValue(property)
  );
};

export const handleOnChange = (key, dispatch) => (e) => {
  const obj = {};
  obj[key] = e.target.value;
  dispatch({ type: actions.UPDATE_INPUT, payload: obj });
};

export const handleOnChangeDeep =
  (key, parent, dispatch = () => {}) =>
  (e) => {
    const obj = {};
    obj[parent] = {};
    obj[parent][key] = e?.target?.value;
    dispatch({ type: actions.UPDATE_INPUT_MERGED, payload: obj });
  };

export const handleOnChangeDeepWithoutMergeData =
  (key, parent, dispatch = () => {}, state = {}) =>
  (e) => {
    const obj = {};
    obj[parent] = { ...state };
    obj[parent][key] = e?.target?.value;
    dispatch({
      type: actions.UPDATE_INPUT,
      payload: obj,
    });
  };

export const handlePrfixChange = (key, dispatch) => (code, country, e) => {
  const obj = {};
  const value = `${country?.format?.substring(0, 1)}${
    country?.dialCode || code
  }`;
  obj[key] = value;
  dispatch({
    type: actions.UPDATE_INPUT,
    payload: obj,
  });
};

export const handlePrefixOnChangeDeep =
  (key, parent, dispatch = () => {}) =>
  (code, country) => {
    const obj = {};
    obj[parent] = {};
    const value = `${country?.format?.substring(0, 1)}${code}`;
    obj[parent][key] = value;
    dispatch({
      type: actions.UPDATE_INPUT_MERGED,
      payload: obj,
    });
  };

export const bodyOfHandleItemClick = (key, dispatch, option, open, setOpen) => {
  const obj = {};
  obj[key] = option.uuid;
  dispatch({ type: actions.UPDATE_INPUT, payload: obj });
  setTimeout(() => {
    setOpen(false);
  }, 5);
};

export const handleItemClick = (key, dispatch) => (option, open, setOpen) => {
  const obj = {};
  obj[key] = option.uuid;
  dispatch({ type: actions.UPDATE_INPUT, payload: obj });
  setTimeout(() => {
    setOpen(false);
  }, 5);
};

export const handleItemSelectedDeep =
  (key, parent, dispatch) => (option, open, setOpen) => {
    const obj = {};
    obj[parent] = {};
    obj[parent][key] = option.uuid;
    dispatch({
      type: actions.UPDATE_INPUT_MERGED,
      payload: obj,
    });
    setTimeout(() => {
      setOpen(!open);
    }, 100);
  };

export const handleCountryClick =
  (key, dispatch) => (option, open, setOpen) => {
    const countryId = option.uuid.split("-")[0];
    const obj = {};
    obj[key] = parseInt(countryId);
    dispatch({ type: actions.UPDATE_INPUT, payload: obj });
    setTimeout(() => {
      setOpen(false);
    }, 10);
  };

export const handleCountryClickDeep =
  (key, parent, dispatch) => (option, open, setOpen) => {
    const countryId = option.uuid.split("-")[0];
    const obj = {};
    obj[parent] = {};
    obj[parent][key] = parseInt(countryId);
    dispatch({ type: actions.UPDATE_INPUT_MERGED, payload: obj });
    setTimeout(() => {
      setOpen(!open);
    }, 10);
  };

export const handleMultipleItemClick =
  (key, state, dispatch) => (option, open, setOpen, e) => {
    e.stopPropagation();
    const obj = {};
    const alreadySelected = state[key]?.includes(option.uuid);
    if (alreadySelected) {
      obj[key] = state[key].filter((f) => f !== option.uuid);
    } else {
      obj[key] = state[key] ? [...state[key], option.uuid] : [option.uuid];
    }
    dispatch({ type: actions.UPDATE_INPUT, payload: obj });
  };

export const handleDate = (key, dispatch) => (date) => {
  const obj = {};
  obj[key] = date.getTime();
  dispatch({
    type: actions.UPDATE_INPUT,
    payload: obj,
  });
};

export const debounce = (func, wait, immediate) => {
  let timeout;
  return function () {
    let context = this,
      args = arguments;
    let later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    let callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

/**
 * Helper function to check if an state has changed some of it's values.
 * @param {Object} state - State.
 * @param {Object} buffer - Buffer to compare.
 */
export const checkIfStateIsDirty = (state, buffer) => {
  return !_.isEqual(state, buffer);
};

/**
 * Helper function for upload documents in the verification step.
 * @param {Array} files - Files to add
 * @param {Object} state - State of widget
 * @param {Function} dispatch - Dispatch function
 * @param {Object} actions - Provider actions
 */
export const handleUploadWidgetFiles = (files, state, dispatch, actions) => {
  dispatch({
    type: actions.UPDATE_INPUT,
    payload: {
      files: [
        ...state.files,
        ...files.map((file) => ({
          ...file,
          ["new"]: true,
        })),
      ],
    },
  });
};

/**
 * Helper to capitalize first letter of a string.
 * @param {String} string - String to capitalize.
 * @returns {String}
 */
export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

/** Helper function for sorting tables.
 * @param {Array} fields - Table fields
 * @param {String} sort_key - Key for sorting
 * @param {Boolean} sortedAsc - Sorted asc or desc
 * @returns {Array}
 */
export const sortTable = (fields, sort_key, sortedAsc = false) => {
  return fields.sort((a, b) => {
    const firstValue = a.cells.find((f) => f.colRef === sort_key);
    const secondValue = b.cells.find((f) => f.colRef === sort_key);
    let value = 0;
    if (firstValue.value > secondValue.value) value = sortedAsc ? 1 : -1;
    if (firstValue.value < secondValue.value) value = sortedAsc ? -1 : 1;
    return value;
  });
};

export function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

export const uploadFile = async ({
  file,
  type,
  allowedExtensions,
  t = (e) => e,
  limitSize = false,
  maxSize = MAX_UPLOAD_SIZE,
}) => {
  const extension = getFileExtension(file.name);

  if (
    allowedExtensions &&
    allowedExtensions.length > 0 &&
    !allowedExtensions.includes(extension)
  ) {
    toastError(t("file-format-error"));
    return undefined;
  }

  if (limitSize) {
    if (file?.size > maxSize) {
      toastError(t("file-too-big"));
      return undefined;
    }
  }

  const response1 = await postClient({
    url: `/v2/upload`,
    method: "POST",
    body: {
      file_type: type,
      extension: getFileExtension(file.name),
      original_name: file.name,
    },
  });

  const response2 = await postClient({
    url: response1.location,
    method: "PUT",
    extraHeaders: {
      "Content-Type": file.type,
    },
    body: file,
    external: true,
    avoidAuth: true,
    avoidParsing: true,
  });

  return [response1, response2];
};

export const getFileExtension = (name) => {
  if (name) {
    const splitted = name.split(".");
    return splitted[splitted.length - 1];
  }
  return undefined;
};

export const getFileNameWithoutExtension = (filename) => {
  return filename.replace(/\.[^/.]+$/, "");
};

export const uploadFilesFromBase64 = async ({
  files,
  fileType,
  setLoading,
  t,
  signOut,
  setDataUser,
}) => {
  const parsedFiles = await Promise.all(
    files.map(async (f, index) => {
      let allocResponse;
      let uploadResponse;
      const extension =
        "." + f.name.substr(f.name.lastIndexOf("\\") + 1).split(".")[1];
      try {
        allocResponse = await postClient({
          external: true,
          url: `${process.env.REACT_APP_MARKETPLACE_URL}/${process.env.REACT_APP_SCRAPAD_API_VERSION}/upload`,
          method: "POST",
          body: {
            file_type: fileType,
            extension: extension.replace(".", ""),
            original_name: f.name,
          },
          signOut,
          setDataUser,
        });
        if (!allocResponse || !allocResponse.location) {
          throw t("error-get-file");
        }
        uploadResponse = await postClient({
          external: true,
          url: allocResponse.location,
          method: "PUT",
          body: f,
          extraHeaders: {
            "Content-Type": f.type,
          },
          avoidAuth: true,
          avoidParsing: true,
          signOut,
          setDataUser,
        });
        toastSuccess(t("file-uploaded-ok"), {
          toastId: "generic_upload_file_success",
        });
        if (setLoading) setLoading(false);
        return {
          uuid: allocResponse.uuid_file,
          name: f.name,
          location: allocResponse.location,
          file: f,
        };
      } catch (error) {
        toastError(error.message);
        return undefined;
      }
    })
  );
  return parsedFiles;
};

export const banOrUnBanOrganization = async ({
  id,
  status,
  signOut,
  setDataUser,
  setLoading,
  fetchData,
}) => {
  setLoading(true);
  await handleCRUD({
    endpoint: `org/${id}/basic-data?from_dashboard=true`,
    method: "PUT",
    data: { status },
    successCallback: async () => {
      await fetchData();
      setLoading(false);
    },
    signOut,
    setDataUser,
  });
};

export const renderDisabledComponentWithTooltip = (cmp, verified, t) => {
  if (verified) {
    return (
      <TooltipSc
        alignment={"bottom"}
        content={verified ? <>{t("cant-edit-already-verified")}</> : undefined}
        action="hover"
        hide={false}
        style={{
          justifyContent: "center",
          minWidth: 0,
        }}
      >
        {cmp}
      </TooltipSc>
    );
  }
  return cmp;
};

export const renderComponentWithPermission = (permission, cmp) => {
  if (!permission) return cmp;
  return permission() ? cmp : <></>;
};

export const checkPermission = (permission) => {
  if (!permission) return true;
  return permission();
};
export const isValidDate = (newDate) => !isNaN(new Date(newDate));

export const countryCodesProps = (value, t, i18n, underline = true) => {
  let styles = {};
  if (underline) {
    styles = {
      buttonStyle: {
        borderRadius: 0,
        borderTop: 0,
        borderLeft: 0,
        borderRight: 0,
        width: "100%",
        height: "35.89px",
        borderColor: WARM_NEUTRAL_20,
      },
    };
  }

  return {
    language: i18n.language,
    inputStyle: {
      fontSize: 14,
      lineHeight: "21px",
      borderColor: "#d8d4d0",
      color: "#282a3a",
      fontWeight: 300,
      width: "1px",
      opacity: 0,
      pointerEvents: "none",
      paddingLeft: "0",
      height: "45.89px",
    },
    buttonStyle: {
      borderTopLeftRadius: 5,
      borderBottomLeftRadius: 5,
      borderColor: "#d8d4d0",
    },
    searchPlaceholder: t("search"),
    searchNotFound: t("empty-data"),
    enableSearch: true,
    searchStyle: {
      color: "#282a3a",
      fontWeight: 300,
    },
    disableSearchIcon: true,
    ...styles,
  };
};

export const phoneTranslator = (t = (e) => e) => {
  return {
    generic: t("error"),
    select_option: t("select_an_option"),
    too_long: t("number_too_long"),
    too_short: t("number_too_short"),
  };
};

/**
 * Helper funtion to render an address in a string format.
 * @param {object} address - Address object.
 * @param {string} address.street - Strret.
 * @param {string} address.postalCode - Postal code.
 * @param {string} address.city - City.
 * @param {string} address.state - State.
 * @param {string} address.country - Country.
 * @param {object} address.port - Port object.
 * @param {string} address.port.name - Name of the port.
 * @returns {string}
 */
export const printAddress = (address) => {
  const parts = [];
  if (address.port) {
    parts.push(address?.port?.name);
  } else {
    if (!_.isEmpty(address?.street)) {
      parts.push(address?.street);
    }
    if (!_.isEmpty(address?.postalCode)) {
      parts.push(address?.postalCode);
    }
    if (!_.isEmpty(address?.city)) {
      parts.push(address?.city);
    }
    if (!_.isEmpty(address?.state)) {
      parts.push(address?.state);
    }
    if (!_.isEmpty(address?.country)) {
      parts.push(address?.country);
    }
    if (!_.isEmpty(address?.port?.name)) {
      parts.push(address?.port?.name);
    }
    if (!_.isEmpty(address?.place)) {
      parts.push(address?.place);
    }
  }
  return parts.join(", ");
};

/**
 * Helper function to check if an url belongs to an image
 * @param {string} url - Image url
 * @returns Promise<boolean>
 */
export const isImageUrl = async (url) => {
  const img = new Image();
  img.src = url;
  return new Promise((resolve) => {
    img.onerror = () => resolve(false);
    img.onload = () => resolve(true);
  });
};

export const getUuidPartsFromTransportData = (data = {}) => {
  let uuidBuyer = undefined;
  let uuidSeller = undefined;
  if (!_.isEmpty(data?.transportData?.advertiser)) {
    if (
      data?.transportData?.advertiser?.orgName === data?.aboutTransaction?.buyer
    ) {
      uuidBuyer = data?.transportData?.advertiser?.orgUuid;
      uuidSeller = data?.transportData?.interested?.orgUuid;
    } else if (
      data?.transportData?.advertiser?.orgName ===
      data?.aboutTransaction?.seller
    ) {
      uuidSeller = data?.transportData?.advertiser?.orgUuid;
      uuidBuyer = data?.transportData?.interested?.orgUuid;
    }
  }

  return { uuidBuyer, uuidSeller };
};

export const getOrgBuyerOrgSellerFromTransactionDetail = (data = {}) => {
  let uuidBuyer = undefined;
  let uuidSeller = undefined;

  if (!_.isEmpty(data?.transportData?.advertiser)) {
    if (
      data?.transportData?.advertiser?.orgName === data?.aboutTransaction?.buyer
    ) {
      uuidBuyer = data?.transportData?.advertiser?.orgUuid;
      uuidSeller = data?.transportData?.interested?.orgUuid;
    } else if (
      data?.transportData?.advertiser?.orgName ===
      data?.aboutTransaction?.seller
    ) {
      uuidSeller = data?.transportData?.advertiser?.orgUuid;
      uuidBuyer = data?.transportData?.interested?.orgUuid;
    }
  }

  return {
    uuidSeller,
    uuidBuyer,
  };
};

/**
 * Helper function to get currency name.
 * @param {string} currency - Current currency
 * @param {Array<{uuid: string, name: string}>} currencyItems - All the currencies.
 * @returns {string}
 */
export const getCurrency = (currency, currencyItems) => {
  const options = (currencyItems || currencyOptions || []).map((c) => {
    return {
      ...c,
      icon: !_.isEmpty(FlagMap[item?.uuid]) ? (
        <span>{FlagMap[item?.uuid]}</span>
      ) : (
        <></>
      ),
    };
  });
  const item = options.find((f) => f?.uuid === currency);
  return item && item.uuid ? item.text : currency;
};

export const getShortUuid = (uuid) => uuid.split("-")?.[0];

export const sleep = async (milliseconds) => {
  return new Promise((resolve) => {
    setTimeout(resolve, milliseconds);
  });
};

export const trackingTransformer = (trackingData, i18n, type = "sale") => {
  if (!trackingData) {
    return [];
  }

  let output = [];
  let lastPhaseReached = false;

  if (trackingData?.tracking) {
    trackingData?.tracking?.forEach((data) => {
      const result = {
        heading: data?.desc,
        events: data?.entries?.map((event) => ({
          label: `${event?.desc || ""} ${
            _.isEmpty(event?.estimatedHours)
              ? event?.date
                ? format(Number(event?.date), "dd/MM/yyyy - kk:mm")
                : ""
              : format(Number(event?.date), "dd/MM/yyyy") +
                " " +
                event?.estimatedHours?.join(" - ")
          }`,
          extra: format(Number(event?.date), "dd/MM/yyyy - kk:mm", {
            locale: i18n.getLanguageVariable(i18n.language),
          }),
          animated: !_.isEmpty(event?.estimatedHours),
        })),
        status:
          data?.phase === "ticket"
            ? "error"
            : data?.phase === trackingData?.lastPhase
            ? "current"
            : lastPhaseReached
            ? "none"
            : "success",
        animated: data?.entries?.find((e) => e?.estimatedHours?.length > 0),
      };

      if (data?.phase === trackingData?.lastPhase) {
        lastPhaseReached = true;
      }

      output.push(result);
    });
  } else {
    output.push({
      heading: `${trackingData?.advertiser?.orgName}`,
      events: [
        {
          label: `${trackingData?.advertiser?.address} ${trackingData?.advertiser?.portAddress}`,
          icon: (
            <Icon
              name={"location_on"}
              color="#9E9E9E"
              style={{
                fontSize: "15px",
                paddingTop: "4px",
                paddingRight: "5px",
              }}
            />
          ),
        },
      ],
      status: "none",
    });

    if (type === "sale") {
      output.push({
        heading: `${trackingData?.interested?.orgName}`,
        events: interestedTransform(trackingData?.interested),
        status: "none",
      });
    } else {
      output.unshift({
        heading: `${trackingData?.interested?.orgName}`,
        events: interestedTransform(trackingData?.interested),
        status: "none",
      });
    }
  }

  return output;
};

export const interestedTransform = (interested = []) => {
  let output = [];

  if (interested?.portAddress) {
    output.push({
      label: `${interested?.portAddress}`,
      icon: (
        <Icon
          name={"directions_boat"}
          color="#9E9E9E"
          style={{
            fontSize: "15px",
            paddingTop: "4px",
            paddingRight: "5px",
          }}
        />
      ),
    });
  }

  if (interested?.address) {
    output.push({
      label: `${interested?.address}`,
      icon: (
        <Icon
          name={"local_shipping"}
          color="#9E9E9E"
          style={{
            fontSize: "15px",
            paddingTop: "4px",
            paddingRight: "5px",
          }}
        />
      ),
    });
  }

  return output;
};

export const printVerified = (verificationState, t = (e) => e) => {
  switch (verificationState) {
    case orgStatus.KYC_COMPLETE:
      return (
        <Icon
          name={"verified"}
          filled={true}
          color={SCRAPBACK_BLUE_60}
          title={t("full_verified")}
          style={{
            cursor: "pointer",
          }}
        />
      );
    case orgStatus.ACTIVE:
      return (
        <Icon
          name={"verified"}
          color={SCRAPBACK_BLUE_60}
          title={t("verified")}
          style={{
            cursor: "pointer",
          }}
        />
      );
    case orgStatus.BANNED:
      return (
        <Icon
          name={"block"}
          color={"red"}
          title={t("banned")}
          style={{
            cursor: "pointer",
          }}
        />
      );
    case orgStatus.INCOMPLETE:
    default:
      return <></>;
  }
};

export const getChipFormat = (
  active = false,
  isMobile = false,
  extraStyles = {}
) => {
  let chipFormat = {
    justifyContent: "center",
  };
  let chipSelectedFormat = {};
  if (isMobile) {
    chipFormat = {
      ...chipFormat,
      width: "calc(50% - 10px)",
      borderRadius: "4px",
    };
    chipSelectedFormat = {
      backgroundColor: BLUE_10,
      color: BLUE_60,
      borderColor: BLUE_30,
    };
    if (active) {
      chipFormat = {
        ...chipFormat,
        ...chipSelectedFormat,
      };
    }
  }
  return { ...chipFormat, ...extraStyles };
};
