import {
  CheckBox,
  SCRAPBACK_BLUE_40,
  WARM_NEUTRAL_40,
} from "@scrapadev/scrapad-front-sdk";

/**
 * UI Checkbox component.
 * @param {object} props - Component properties.
 * @param {object} [props.text=""] - Checkbox text.
 * @param {object} [props.itemSpanStyle={}] - Checbox container styles.
 * @param {object} [props.itemCheckStyle={}] - Checbox styles.
 * @param {object} [props.itemCheckedStyle={}] - Checbox checked styles.
 * @param {object} [props.textStyles={}] - Checkbox text styles.
 * @returns {JSX.Element}
 */
const CheckBoxSc = ({
  text = "",
  itemSpanStyle = {},
  itemCheckStyle = {},
  itemCheckedStyle = {},
  textStyles = {},
  ...props
}) => {
  const defaultSpanStyle = {
    minWidth: 16,
    width: 16,
    height: 16,
    marginRight: 13,
    paddingRight: 0,
  };
  const defaultCheckStyle = {
    paddingRight: 0,
    border: `2px solid ${props?.checked ? SCRAPBACK_BLUE_40 : WARM_NEUTRAL_40}`,
    borderRadius: 2,
    minWidth: 16,
    width: 16,
    height: 16,
  };
  return (
    <>
      <span style={{ ...defaultSpanStyle, ...itemSpanStyle }}>
        <CheckBox
          checked={props?.checked}
          style={
            props?.checked
              ? { ...defaultCheckStyle, ...itemCheckedStyle }
              : { ...defaultCheckStyle, ...itemCheckStyle }
          }
          {...props}
        />
      </span>
      <span style={textStyles}>{text}</span>
    </>
  );
};

export default CheckBoxSc;
