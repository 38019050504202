import { useNavigate, useParams } from "react-router-dom";
import Container from "../../components/ui/container/Container";
import Header from "../../components/ui/header/Header";
import NavBar from "../../components/ui/navbar/NavBar";
import Steps from "../../components/ui/steps/Steps";
import LayoutDetail from "../../layout/detail/Detail";
import {
  ADS_LIMIT,
  QUERY_UPPER_LIMIT,
  selectedOrgKey,
  tableModules,
  TABLE_LIMIT,
  TEXT_COLOR_LIGHT,
} from "../../utils/variables";
import { useSteps, useTranslation } from "../../utils/hooks";
import Text from "../../components/ui/text/Text";
import styles from "./BusinessAds.module.scss";
import { useState } from "react";
import { useTableDataProvider } from "../../providers/TableContext";
import { getHeaderInfo } from "../../model/business";
import LayoutTable from "../../layout/table/Table";
import Loading from "../../components/ui/loading/Loading";
import GQLFetchWrapper from "../../wrappers/FetchWrapper";
import { getAllAdsEdit } from "../../model/ads";
import Filters from "../../components/ui/filters/Filters";
import { useDocumentTitle } from "../../utils/document/hooks";
import { Flex, Row, Spacer } from "@scrapadev/scrapad-front-sdk";
import { useFiltersProvider } from "../../providers/filters/FiltersContext";
import { useHeaderProvider } from "../../providers/header/HeaderContext";
import { getTable } from "../../model/tables";
import WidgetAdsGrid from "../../widgets/ads/Grid";
import AdsSearchExport from "../../widgets/ads/SearchExport";
import WidgetAdsTable from "../../widgets/ads/Table";
import ModeSwitch from "../../widgets/fields/ModeSwitch";
import { usePaginationProvider } from "../../providers/pagination/PaginationContext";
import { useOperationsProvider } from "../../providers/organization/OperationsContext";
import { useDateFilter } from "../../utils/filters/hooks";
import {
  adStatusItems,
  adTypeFilterItems,
} from "../../utils/filters/variables";
import { useSortProvider } from "../../providers/sort/SortContext";
import { applySortToFilters } from "../../utils/tables/functions";
import { printVerified } from "../../utils/functions";

const areDataBasedOnMode = (mode, cardData, tableData) => {
  if (mode === "table") {
    return tableData?.rows?.length > 0;
  }
  return cardData?.ads?.length > 0;
};

const InnerBusinessAds = ({
  loading,
  state,
  dispatch,
  actions,
  bufferState,
  fetchData,
}) => {
  const { id } = useParams();
  const { t, i18n, values } = useTranslation(["common", "ads"], true);
  const navigate = useNavigate();
  const {
    data,
    setData,
    dataBuffer,
    loading: tableLoading,
    fetchData: tableFetchData,
  } = useTableDataProvider();
  const { filterChanged, forceFiltersFromStore } = useFiltersProvider();
  const [open, setOpen] = useState(true);
  const [mode, setMode] = useState("grid");
  const { paginationChanged } = usePaginationProvider();
  useDocumentTitle(`${t("ads")}`, values);
  const { state: headerState } = useHeaderProvider(
    i18n.language,
    id,
    getHeaderInfo
  );
  const { canManageAds } = useOperationsProvider();
  const { steps } = useSteps(id);
  const dateFilterCreated = useDateFilter("createdAt", t("creation-date"));
  const dateFilterUpdated = useDateFilter("updatedAt", t("update-date"));
  const { state: sortState, buildSort } = useSortProvider();
  const { getStoredFilters, buildFiltersAndFetch } = useFiltersProvider();
  const filters = buildFiltersAndFetch(getStoredFilters() || {});
  const sortFields = buildSort(sortState);

  const exportParams = {
    locale: i18n.language,
    limit: QUERY_UPPER_LIMIT,
    offset: 0,
  };

  if (mode === "table") {
    exportParams.tableModule = tableModules.ADS;
  }

  if (Object.keys(filters).length > 0) {
    exportParams.filter = filters;
    exportParams.filter?.ops.push({ field: "byOrgId", value: id });
  } else {
    exportParams.filter = { ops: [{ field: "byOrgId", value: id }] };
  }

  return (
    <>
      <NavBar />
      <LayoutDetail
        backgroundColor="#fff"
        headerStyle={{ marginBottom: 0 }}
        headerComponent={
          <Container>
            <div className="row">
              <div className="col-12">
                <Header
                  title={
                    <Row gap={10}>
                      {printVerified(headerState?.verificationState, t)}
                      <span>{headerState?.basicData?.orgName}</span>
                    </Row>
                  }
                  goBack
                  breadcrumbs={[
                    {
                      description: t("business"),
                      onClick: () => navigate("/business", { replace: true }),
                    },
                    {
                      description: headerState?.basicData?.orgName,
                      onClick: () =>
                        navigate(`/business/${id}`, { replace: true }),
                    },
                    { description: t("ads") },
                  ]}
                  lowerLeftStyles={{ margin: "0 0 10px 0" }}
                  subHeaderLeft={
                    <div className={styles["sc-ads--subheader"]}>
                      <Steps
                        steps={steps}
                        currentStep={"ads"}
                        checkCurrentByLabel
                      />
                      <div className={styles["sc-ads--filters-search"]}>
                        {
                          <Flex flexDirection="column" alignItems="stretch">
                            <Filters
                              uuidOrg={id}
                              initialFilters={{
                                adText: {
                                  external: true,
                                },
                                adType: {
                                  label: t("ad-type"),
                                  multiple: true,
                                  items: adTypeFilterItems.map((type) => ({
                                    uuid: type.uuid,
                                    name: t(type.name),
                                  })),
                                },
                                createdAt: dateFilterCreated,
                                updatedAt: dateFilterUpdated,
                                status: {
                                  label: t("status"),
                                  multiple: true,
                                  items: adStatusItems.map((status) => ({
                                    uuid: status,
                                    name: t(status),
                                  })),
                                },
                              }}
                            />
                          </Flex>
                        }
                      </div>
                      <Row
                        justify="space-between"
                        align="flex-end"
                        style={{ paddingBottom: "15px" }}
                      >
                        <ModeSwitch
                          defaultActive={0}
                          setModeOnTable={() => {
                            setMode("table");
                            paginationChanged.current = true;
                          }}
                          setModeOnGrid={() => {
                            setMode("grid");
                            paginationChanged.current = true;
                          }}
                        />
                        <AdsSearchExport
                          mode={mode}
                          state={state}
                          dispatch={dispatch}
                          actions={actions}
                          bufferState={bufferState}
                          data={data}
                          setData={setData}
                          dataBuffer={dataBuffer}
                          exportProps={{
                            fnData: mode === "table" ? getTable : getAllAdsEdit,
                            fnParameters: {
                              ...exportParams,
                              filter: applySortToFilters(
                                exportParams.filter,
                                sortFields,
                                "REACT_APP_SORT_ADS"
                              ),
                            },
                            fileName: "ads",
                          }}
                        />
                      </Row>
                    </div>
                  }
                  fullSubHeaderLeft
                />
              </div>
            </div>
          </Container>
        }
        bodyComponent={
          <Container>
            <div className="row">
              {loading ? (
                <Loading />
              ) : mode === "grid" ? (
                <>
                  {areDataBasedOnMode(mode, state, data) && (
                    <div className="col-12">
                      <Text
                        style={{
                          display: "inline-flex",
                          justifyContent: "flex-end",
                          width: "100%",
                          marginBottom: "1rem",
                          fontSize: 14,
                          fontWeight: 300,
                          color: TEXT_COLOR_LIGHT,
                        }}
                      >
                        {t("sort-recent-first")}
                      </Text>
                    </div>
                  )}
                  <WidgetAdsGrid
                    loading={loading}
                    state={state}
                    dispatch={dispatch}
                    actions={actions}
                    bufferState={bufferState}
                    withPagination={false}
                    fetchData={fetchData}
                    adsProps={{
                      onOffersClick: undefined,
                      fetchData: undefined,
                    }}
                    uuidOrg={id}
                    fiexdFilters={{ field: "byOrgId", value: id }}
                    permission={canManageAds}
                  />
                </>
              ) : (
                <WidgetAdsTable
                  data={data}
                  setData={setData}
                  dataBuffer={dataBuffer}
                  loading={tableLoading}
                  fetchData={tableFetchData}
                  adsProps={{
                    actions: undefined,
                    onClickActionCallback: undefined,
                  }}
                  uuidOrg={id}
                  permission={canManageAds}
                />
              )}
            </div>
          </Container>
        }
      />
    </>
  );
};

const BusinessAds = () => {
  const { i18n } = useTranslation();
  const { id } = useParams();
  const { getStoredFilters, buildFiltersAndFetch } = useFiltersProvider();
  const { state: sortState, buildSort } = useSortProvider();
  const { getStoredPaginationIfBackPressed } = usePaginationProvider();
  const hasDifferentUuidOrg =
    getStoredFilters()?.[selectedOrgKey] &&
    getStoredFilters()?.[selectedOrgKey]?.data !== id;
  const storedfilters = buildFiltersAndFetch(getStoredFilters() || {});
  const sortFields = buildSort(sortState);
  const filters =
    Object.keys(storedfilters).length === 0 || hasDifferentUuidOrg
      ? { ops: [{ field: "byOrgId", value: id }] }
      : {
          ...storedfilters,
          ops: [...(storedfilters?.ops || []), { field: "byOrgId", value: id }],
        };

  const tableParams = {
    locale: i18n.language,
    limit: TABLE_LIMIT,
    tableModule: tableModules.ADS,
    offset:
      getStoredPaginationIfBackPressed()?.uuidOrg &&
      getStoredPaginationIfBackPressed()?.uuidOrg !== id
        ? 0
        : getStoredPaginationIfBackPressed()?.current
        ? getStoredPaginationIfBackPressed()?.current
        : 0,
  };
  const adsParams = {
    locale: i18n.language,
    limit: ADS_LIMIT,
    offset:
      getStoredPaginationIfBackPressed()?.uuidOrg &&
      getStoredPaginationIfBackPressed()?.uuidOrg !== id
        ? 0
        : getStoredPaginationIfBackPressed()?.current
        ? getStoredPaginationIfBackPressed()?.current
        : 0,
  };

  if (Object.keys(filters).length > 0) {
    tableParams.filter = filters;
    adsParams.filter = filters;
  }

  return (
    <LayoutTable
      fnData={getTable}
      fnParameters={{
        ...tableParams,
        filter: applySortToFilters(
          tableParams.filter,
          sortFields,
          "REACT_APP_SORT_ADS"
        ),
      }}
    >
      <GQLFetchWrapper
        Component={InnerBusinessAds}
        fnData={getAllAdsEdit}
        fnParameters={adsParams}
        delegateLoading
      />
    </LayoutTable>
  );
};

export default BusinessAds;
