import {
  Icon,
  WARM_NEUTRAL_30,
  TEXT_COLOR,
  TEXT_COLOR_LIGHT,
} from "@scrapadev/scrapad-front-sdk";
import { useState } from "react";
import { useTranslation } from "../../../../utils/hooks";
import Search from "./Search";

/**
 * Search input growable.
 * @param {object} props - Search props.
 * @returns {JSX.Element}
 */
const GrowSearch = ({ ...props }) => {
  const { t } = useTranslation(["common"]);
  const [mustGrow, setMustGrow] = useState(false);

  const getPropOnGrow = (propWithText, propWithNoText) => {
    if (props?.value !== "") {
      return propWithText;
    }
    return !mustGrow ? propWithNoText : propWithText;
  };

  return (
    <Search
      {...props}
      hasTrailingSearch={false}
      leadingIcon={
        <Icon
          name={"search"}
          color={WARM_NEUTRAL_30}
          style={{
            position: "relative",
            top: -3,
            pointerEvents: "none",
            cursor: "text",
          }}
        />
      }
      leadingStyles={{
        pointerEvents: "none",
      }}
      trailinCancelIcon={
        <Icon
          name={"close"}
          style={{
            width: 20,
            height: 20,
            background: "white",
            border: `0.5px solid ${WARM_NEUTRAL_30}`,
            borderRadius: "50%",
            color: TEXT_COLOR_LIGHT,
            margin: 0,
            position: "relative",
            top: -3,
            fontSize: 14,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        />
      }
      placeholder={getPropOnGrow(t("write-to-search"), undefined)}
      style={{
        backgroundColor: "#fff",
        border: `1px solid ${WARM_NEUTRAL_30}`,
        borderRadius: 18,
        fontSize: 14,
        fontFamily: "Silka",
        lineHeight: "21px",
        fontWeight: 400,
        color: TEXT_COLOR,
        padding: getPropOnGrow("7px 10px 7px 43px", "7px 10px"),
        width: getPropOnGrow(250, 33),
        boxSizing: getPropOnGrow("border-box", "content-box"),
      }}
      onFocus={() => {
        setMustGrow(true);
      }}
      onBlur={() => {
        setMustGrow(false);
      }}
    />
  );
};

export default GrowSearch;
