import {
  BREAKPOINT_LG,
  IconItem,
  toastError,
  toastSuccess,
  useMatchMedia,
  WARM_NEUTRAL_40,
} from "@scrapadev/scrapad-front-sdk";
import { useState } from "react";
import ModalFactory from "../../../components/ui/modal/ModalFactory";
import { useCRUD } from "../../../utils/crud/hooks";
import { useTranslation } from "../../../utils/hooks";
import { modalTypes } from "../../../utils/variables";

/**
 * Delete a verification file.
 * @param {object} props - Component propoerties.
 * @param {string} props.id - Org uuid.
 * @param {string} props.fileUuid - File uuid.
 * @param {Function} props.fetchData - Fetch data callback.
 * @param {Function} props.fetchData - Widget wrapper loading setter.
 * @returns {JSX.Element}
 */
const WidgetVerificationDeleteFile = ({
  id,
  fileUuid,
  fetchData,
  setLoading,
}) => {
  const [warningOpen, setWarningOpen] = useState();
  const { t } = useTranslation(["common"]);
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_LG}px)`);
  const { CRUD } = useCRUD();

  const handleSubmit = async (key) => {
    setLoading(true);
    await CRUD({
      endpoint: `org/${id}/verification/${key}`,
      method: "DELETE",
      errorCallback: (error) => {
        toastError(error);
        setLoading(false);
        setWarningOpen(false);
      },
      successCallback: async () => {
        setWarningOpen(false);
        await fetchData();
        toastSuccess(
          t("action-ok"),
          {
            toastId: "remove_verification_file",
          },
          "check_circle"
        );
        setLoading(false);
      },
    });
  };

  return (
    <>
      <IconItem
        name={"delete"}
        onClick={() => {
          setWarningOpen(!warningOpen);
        }}
        text={t("remove")}
        iconStyle={{
          fontSize: 16,
        }}
        textStyle={{
          color: WARM_NEUTRAL_40,
          fontWeight: 600,
          fontSize: 13,
          lineHeight: "20px",
          textDecoration: "none",
        }}
        buttonStyle={{
          whiteSpace: "nowrap",
          marginRight: matches ? 16 : 0,
        }}
        color={WARM_NEUTRAL_40}
      />
      <ModalFactory
        type={modalTypes.USER_WARNING}
        open={warningOpen}
        setOpen={setWarningOpen}
        onSubmit={handleSubmit}
        data={{ key: fileUuid }}
      />
    </>
  );
};

export default WidgetVerificationDeleteFile;
