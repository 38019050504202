import _ from "lodash";
import { useState } from "react";
import ModalWithdrawOpportunity from "../../components/modals/opportunities/Withdraw";
import LayoutTable from "../../layout/table/Table";
import { getTable } from "../../model/tables";
import { useUser } from "../../providers/AuthHooks";
import { useTableDataProvider } from "../../providers/TableContext";
import { useTranslation } from "../../utils/hooks";
import { navigateToOpportunity } from "../../utils/opportunities/functions";
import {
  useOpportunitiesParticipatingActions,
  useParticipatingFilters,
} from "../../utils/opportunities/hooks";
import { useTableConfig } from "../../utils/tables/hooks";
import { tableModules, TABLE_LIMIT } from "../../utils/variables";
import { getRoomSlug, WS_ROOM } from "../../utils/websockets/functions";
import WebsocketsWrapper from "../websockets/Wrapper";
import WidgetOpportunitiesLayout from "./OpportunitiesLayout";

/**
 * Table of opportunities in wich the bo user participates.
 * @param {object} props - Component properties.
 * @param {object} baseTableParams - Table params.
 * @returns {JSX.Element}
 */
const InnerWidgetOpportunitiesParticipating = ({ baseTableParams }) => {
  const [openDeleteWithdraw, setOpenDeleteWithdraw] = useState(false);
  const { deps, initialFilters } = useParticipatingFilters();
  const { fetchData } = useTableDataProvider();
  const user = useUser();
  const { onRowClick, selectedRow } = useOpportunitiesParticipatingActions();

  return (
    <div data-cy="opportunities-participating-table">
      <WidgetOpportunitiesLayout
        filterProps={{
          deps,
          initialFilters,
        }}
        tableProps={{
          baseTableParams,
          selectedRow,
          onRowClick,
        }}
      />
      <ModalWithdrawOpportunity
        open={openDeleteWithdraw}
        setOpen={setOpenDeleteWithdraw}
        data={{
          key: selectedRow.current.uuid || "",
        }}
      />
      {user && (
        <WebsocketsWrapper
          callback={async (data) => {
            if (
              !_.isEmpty(data) &&
              data?.subscribeRoom?.type === WS_ROOM.opportunity_participating
            ) {
              await fetchData();
            }
          }}
          room={getRoomSlug("/opportunities_participating")}
        />
      )}
    </div>
  );
};

const WidgetOpportunitiesParticipating = () => {
  const { i18n } = useTranslation();
  const baseParams = {
    locale: i18n.language,
    limit: TABLE_LIMIT,
    tableModule: tableModules.OPPORTUNITIES_PARTICIPATING,
  };
  const { tableParams } = useTableConfig(baseParams);

  return (
    <LayoutTable fnData={getTable} fnParameters={tableParams}>
      <InnerWidgetOpportunitiesParticipating baseTableParams={baseParams} />
    </LayoutTable>
  );
};

export default WidgetOpportunitiesParticipating;
