import {
  Spinner,
  Row,
  Heading,
  Padding,
  Center,
  ClearButton,
  BUTTON_LINK,
  SCRAPBACK_BLUE_40,
  WARM_NEUTRAL_20,
  BODY_SMALL_REG,
  HEADING_5,
} from "@scrapadev/scrapad-front-sdk";
import { format } from "date-fns";
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useNotificationProvider } from "../../../providers/notifications/NotificationsContext";
import { useTranslation } from "../../../utils/hooks";
import EmptyNotifications from "../empty/Notifications";
import MarkAsRead from "../mark-as-read/MarkAsRead";
import styles from "./NotificationsPreview.module.scss";
import NotificationsList from "../notifications/List";
import NotificationsListItem from "../notifications/ListItem";

function NotificationsPreview({ onChanged, setOpenNotifications = () => {} }) {
  return (
    <NotificationPreviewEntry
      onChanged={onChanged}
      setOpenNotifications={setOpenNotifications}
    />
  );
}

function Divider() {
  return <hr style={{ margin: "-10px 0" }} />;
}

function NotificationPreviewEntry({
  onChanged = () => {},
  // eslint-disable-next-line no-unused-vars
  setOpenNotifications = () => {},
}) {
  const { t } = useTranslation("notifications");
  const { state, updateDataCall, readAll } = useNotificationProvider();
  const navigate = useNavigate();

  useEffect(() => {
    updateDataCall({ unread: true });
  }, []);

  const read = async (e) => {
    e.stopPropagation();
    readAll(t, 0, true);
    onChanged();
    //setOpenNotifications(false);
  };

  return (
    <div className={styles["sc-n-preview"]}>
      <div className={styles["header"]}>
        <Row gap={15} justify="space-between">
          <Heading type={6} style={{ margin: 0 }}>
            {t("notifications")}
          </Heading>
          <Link to="/notifications">
            <ClearButton style={{ ...BUTTON_LINK, color: SCRAPBACK_BLUE_40 }}>
              {t("see-all")}
            </ClearButton>
          </Link>
        </Row>
      </div>
      {state.isLoading ? (
        <>
          <Padding>
            <Center>
              <Spinner />
            </Center>
          </Padding>
        </>
      ) : (
        <>
          <div className={styles["body"]}>
            <NotificationsList>
              {state.unreadNotifications?.length > 0 ? (
                <>
                  {state.unreadNotifications?.map((notification, i) => (
                    <>
                      <NotificationsListItem
                        style={{
                          padding: "18px 16px",
                        }}
                        key={notification?.id}
                        badgeActive={!notification?.read}
                        title={notification?.title}
                        headerRightSide={`${t(
                          notification.category
                        )} - ${format(
                          Number(notification?.createdDate),
                          "dd/MM/yyyy HH:mm"
                        )}`}
                        headerColumnMode
                        onClick={() => {
                          notification?.link
                            ? (window.location.href = notification?.link)
                            : navigate("/notifications");
                        }}
                      />
                    </>
                  ))}
                </>
              ) : (
                <EmptyNotifications />
              )}
            </NotificationsList>
          </div>
          {!state.isLoading && state.unreadNotifications?.length > 0 && (
            <div className={styles["footer"]}>
              <MarkAsRead onClick={read} />
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default NotificationsPreview;
