import { Button, Icon, SCRAPBACK_BLUE_40 } from "@scrapadev/scrapad-front-sdk";
import { useMarkAsCompleted } from "../../../utils/finances/hooks";
import { useTranslation } from "../../../utils/hooks";

/**
 * Mark received payment component
 * @param {object} props - Component properties.
 * @param {Function} props.fetchData - Function to fetch data.
 * @param {string} props.uuidDeposit - Uuid deposit.
 * @param {string} props.uuidEvent - Transaction uuid.
 * @returns {JSX.Element}
 */
const MarkReceivedButton = ({ fetchData, uuidDeposit, uuidEvent }) => {
  const { loading, setLoading, handleMarkReceived } =
    useMarkAsCompleted(fetchData);
  const { t } = useTranslation("finances");

  return (
    <Button
      type="button"
      component="button"
      mode="outline-success"
      loading={loading}
      style={{
        padding: loading ? "7px 60px 7px 17px" : "7px 17px",
        fontSize: 14,
      }}
      prefix={
        <Icon
          name={"check_circle"}
          color={SCRAPBACK_BLUE_40}
          style={{
            display: "flex",
            marginRight: "0.625rem",
            transform: "none",
          }}
        />
      }
      text={t("mark-as-received")}
      onClick={() => {
        handleMarkReceived(uuidEvent, uuidDeposit);
      }}
    />
  );
};

export default MarkReceivedButton;
