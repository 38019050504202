import SearchFilterExport from "../../components/ui/search-filter-export/SearchFilterExport";
import { useTranslation } from "../../utils/hooks";

const AdsSearchExport = ({
  filterKey = "adText",
  open,
  setOpen,
  mode,
  exportProps,
}) => {
  const { t } = useTranslation(["common"]);

  return (
    <SearchFilterExport
      searchPlaceholder={t("search")}
      open={open}
      setOpen={setOpen}
      exportProps={exportProps}
      hasSeparator={false}
      //hasFilters={false}
      hasExport={mode === "table"}
    />
  );
};

export default AdsSearchExport;

