import { useTranslation } from "../../../utils/hooks";
import InputCountries from "../../../components/ui/input/country/Country";
import {
  countryCodesProps,
  handleItemClick,
  handleOnChange,
  handlePrfixChange,
  phoneTranslator,
  renderComponentWithPermission,
  renderDisabledComponentWithTooltip,
} from "../../../utils/functions";
import InputNif from "../../../components/ui/input/nif/Nif";
import { useState } from "react";
import WidgetFieldSaveDiscard from "../../fields/Save";
import { useHeaderProvider } from "../../../providers/header/HeaderContext";
import { orgStatus } from "../../../utils/variables";
import InputActivity from "../../../components/ui/input/activity/Activity";
import {
  InputPhoneWithPrefix,
  InputText,
  Label,
  Row,
} from "@scrapadev/scrapad-front-sdk";
import { useBusinessUpdateInfo } from "../../../utils/business/hooks";

const WidgetBusinessInfo = ({
  id,
  state,
  dispatch,
  actions,
  isDirty,
  handleDiscard,
  handleParentCallback,
  fetchData,
  extraData,
}) => {
  const { t, i18n } = useTranslation(["business", "common"]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const { updateBasicData } = useBusinessUpdateInfo();
  const { state: headerState } = useHeaderProvider();
  const { permissionManage = null, permissionRead = null } = extraData || {};

  const handleSubmitWidget = async (e) => {
    e.preventDefault();
    handleParentCallback();
    setSubmitLoading(true);
    await updateBasicData({
      id,
      state,
      successCallback: () => fetchData(),
      setLoading: setSubmitLoading,
    });
  };

  const handleNifAndCountry = (key, fiscalKey) => (option, open, setOpen) => {
    const countryData = option.uuid.split("-");
    const countryId = countryData[0];
    const countryName = countryData[1];
    const newNif = state?.fiscalId?.replace(/^.{2}/g, countryName);
    dispatch({
      type: actions.UPDATE_INPUT,
      payload: { ...state, [key]: parseInt(countryId), [fiscalKey]: newNif },
    });
    setOpen(false);
  };

  const verified = headerState?.verificationState === orgStatus.KYC_COMPLETE;

  return (
    <form onSubmit={handleSubmitWidget}>
      <Row gap={20}>
        {renderComponentWithPermission(
          permissionRead,

          <Label text={t("official-name")} noPadding>
            {renderDisabledComponentWithTooltip(
              <InputText
                mode="underline"
                readOnly={
                  verified
                    ? verified
                    : permissionManage
                    ? !permissionManage()
                    : false
                }
                value={state?.orgName}
                onChange={handleOnChange("orgName", dispatch)}
                placeholder={t("write-here")}
              />,
              verified,
              t
            )}
          </Label>
        )}
        {renderComponentWithPermission(
          permissionRead,

          <Label text={t("comercial-name")} noPadding>
            <InputText
              mode="underline"
              value={state?.commercialName}
              onChange={handleOnChange("commercialName", dispatch)}
              placeholder={t("write-here")}
              readOnly={permissionManage ? !permissionManage() : false}
            />
          </Label>
        )}
      </Row>

      <Row gap={20}>
        {renderComponentWithPermission(
          permissionRead,
          <Label text={t("legal-form")} noPadding>
            <InputText
              mode="underline"
              readOnly
              value={t(state?.legalForm)}
              onChange={handleOnChange("legalForm", dispatch)}
              placeholder={t("write-here")}
            />
          </Label>
        )}
        {renderComponentWithPermission(
          permissionRead,
          <Label text={t("nif-number")} noPadding>
            {renderDisabledComponentWithTooltip(
              <InputNif
                readOnly={
                  verified
                    ? verified
                    : permissionManage
                    ? !permissionManage()
                    : false
                }
                value={state?.fiscalId}
                onChange={handleOnChange("fiscalId", dispatch)}
                placeholder={t("write-here")}
              />,
              verified,
              t
            )}
          </Label>
        )}
      </Row>

      <Row gap={20}>
        {renderComponentWithPermission(
          permissionRead,
          <Label text={t("country")} noPadding>
            {renderDisabledComponentWithTooltip(
              <InputCountries
                readOnly={
                  verified
                    ? verified
                    : permissionManage
                    ? !permissionManage()
                    : false
                }
                handleItemClick={handleNifAndCountry("countryCode", "fiscalId")}
                value={state?.countryCode}
              />,
              verified,
              t
            )}
          </Label>
        )}
        {renderComponentWithPermission(
          permissionRead,
          <Label text={t("phone")} noPadding>
            <InputPhoneWithPrefix
              prefixValue={state?.prefix || ""}
              prefixOnchange={handlePrfixChange("prefix", dispatch)}
              phoneValue={state?.phone || ""}
              phoneOnChange={handleOnChange("phone", dispatch)}
              readOnlyPhone={permissionManage ? !permissionManage() : false}
              readOnlyPrefix={permissionManage ? !permissionManage() : false}
              codesProps={countryCodesProps(state?.prefix, t, i18n, {
                buttonStyle: {
                  borderRadius: 0,
                  borderTop: 0,
                  borderLeft: 0,
                  borderRight: 0,
                },
              })}
              labelInput={state?.prefix?.length > 4 ? "" : t("phone")}
              prefixLabel="+XX"
              mode="underline"
              translation={phoneTranslator(t)}
            />
          </Label>
        )}
      </Row>
      <Row gap={20}>
        {renderComponentWithPermission(
          permissionRead,
          <Label
            text={`${t("business-activity")} ${
              state?.legalForm === "legal-entity" ? "*" : ""
            }`}
            noPadding
          >
            {renderDisabledComponentWithTooltip(
              <InputActivity
                readOnly={
                  verified
                    ? verified
                    : permissionManage
                    ? !permissionManage()
                    : false
                }
                handleItemClick={handleItemClick("activity", dispatch)}
                value={state?.activity}
                skipRequiredHelp
                mode={"underline"}
              />,
              verified,
              t
            )}
          </Label>
        )}
        {renderComponentWithPermission(
          permissionRead,
          <Label text={t("website")} noPadding>
            {renderDisabledComponentWithTooltip(
              <InputText
                mode="underline"
                readOnly={
                  verified
                    ? verified
                    : permissionManage
                    ? !permissionManage()
                    : false
                }
                value={state?.webSite}
                onChange={handleOnChange("webSite", dispatch)}
                placeholder={t("write-here")}
              />,
              verified,
              t
            )}
          </Label>
        )}
      </Row>

      <WidgetFieldSaveDiscard
        permission={permissionManage}
        isDirty={isDirty}
        handleDiscard={handleDiscard}
        loading={submitLoading}
        doubleFactor
        alt={true}
      />
    </form>
  );
};

export default WidgetBusinessInfo;
