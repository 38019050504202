import { useEffect, useState } from "react";
import { useTranslation } from "../../../utils/hooks";
import {
  BREAKPOINT_LG,
  CheckBox,
  Container,
  Flex,
  Heading,
  Modal,
  Paper,
  useMatchMedia,
  WARM_NEUTRAL_05,
  WARM_NEUTRAL_20,
} from "@scrapadev/scrapad-front-sdk/dist/index";
import { useSetDataUser, useSignOut } from "../../../providers/AuthHooks";
import { useDataProvider } from "../../../providers/data/DataContext";
import { actions } from "../../../providers/data/actions/data";
import WidgetAddressAlias from "../../../widgets/business/addresses/Alias";
import WidgetAddressCollectionDelivery from "../../../widgets/business/addresses/CollectionDelivery";
import WidgetAddressCountries from "../../../widgets/business/addresses/Countries";
import WidgetAddressPhone from "../../../widgets/business/addresses/Phone";
import WidgetAddressLocation from "../../../widgets/business/addresses/Location";
import WidgetAddressWarehouse from "../../../widgets/business/addresses/Warehouse";
import WidgetAddressEquipment from "../../../widgets/business/addresses/Equipment";
import { handleCRUD } from "../../../utils/crud/functions";
import {
  buildAddresData,
  dispatchAction,
  dispatchActionMultipleKey,
} from "../../../widgets/business/addresses/functions";
import WidgetAddressesBySea from "../../../widgets/business/addresses/BySea";
import WidgetAddressSelectedPort from "../../../widgets/business/addresses/SelectedPort";
import { useOperationsProvider } from "../../../providers/organization/OperationsContext";

/**
 * Modal for adding a new address
 * @param {Object} props - Component properties.
 * @param {Object} [props.open=false] - Open/close modal status.
 * @param {Function} props.setOpen - Setter function to open / close.
 * @returns {JSX.Element}
 */
const ModalNewAddress = ({ open = false, setOpen, isEdit, ...props }) => {
  const { t } = useTranslation(["common"]);
  const [loading, setLoading] = useState(false);
  const setDataUser = useSetDataUser();
  const signOut = useSignOut();
  const { state, dispatch } = useDataProvider();
  const { id, fetchData } = props;
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_LG}px)`);
  const { canManageOrg } = useOperationsProvider();

  const loadEmptyState = () => {
    dispatch({
      type: actions.INITIAL_LOAD,
      payload: {
        getOrgLocations: [
          {
            alias: "",
            city: "",
            state: "",
            street: "",
            postalCode: "",
            countryCode: null,
            type: "both",
            timetables: [
              {
                from: "",
                to: "",
                daysWeek: [],
              },
            ],
            tel: "",
            prefix: "",
            isDeliveryDefault: false,
            isCollectionDefault: false,
            facilities: [],
            port: null,
          },
        ],
      },
    });
  };

  useEffect(() => {
    if (!isEdit) {
      loadEmptyState();
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const parsedData = buildAddresData(state?.getOrgLocations?.[0]);
    await handleCRUD({
      endpoint: isEdit ? `org/${id}/location/${id}` : `org/${id}/location`,
      method: isEdit ? "PUT" : "POST",
      data: { address: parsedData },
      successCallback: () => {
        fetchData();
        setOpen(false);
        if (!isEdit) {
          loadEmptyState();
        }
      },
      messageOk: t("action-ok"),
      signOut,
      setDataUser,
      setLoading,
    });
  };

  return (
    <Modal
      t={t}
      open={open}
      setOpen={() => {
        setOpen(false);
        if (!isEdit) {
          loadEmptyState();
        }
      }}
      title={t("new_address")}
      hasForm={true}
      onSubmit={handleSubmit}
      submitText={t("save")}
      cancelText={t("cancel")}
      loading={loading}
      maxWidth={900}
      submitStyles={{ minWidth: 200 }}
      cancelStyles={{ minWidth: 200 }}
      actionsMode={"end"}
      modalStyles={{ width: matches ? "auto" : 900 }}
      BodyComponent={state?.getOrgLocations?.map((address, i) => {
        const isPort = address?.port && address?.port !== "empty";
        return (
          <Paper
            key={`new_address_paper_${i}`}
            style={{
              backgroundColor: WARM_NEUTRAL_05,
              border: `.5px solid ${WARM_NEUTRAL_20}`,
            }}
          >
            <Flex
              key={`new_address_org_${i}`}
              flexDirection="column"
              alignItems="stretch"
              style={{ gap: "15px", width: "100%" }}
            >
              <div className="row">
                <div className="col-12">
                  <WidgetAddressesBySea
                    alternative
                    text={t("port")}
                    checked={isPort}
                    onChange={(e) => {
                      dispatchAction(
                        0,
                        "port",
                        e.target.checked
                          ? {
                              uuid: "",
                              name: "",
                            }
                          : null,
                        state,
                        dispatch
                      );
                    }}
                    style={{ paddingBottom: 0 }}
                  />
                </div>
              </div>
              {isPort ? (
                <>
                  <Paper
                    whiteMode
                    style={{
                      paddingTop: 15,
                      paddingBottom: 5,
                      paddingLeft: 15,
                      paddingRight: 15,
                      display: "block",
                      marginBottom: 0,
                    }}
                  >
                    <Heading type={5}>{t("select_port")}</Heading>
                    <WidgetAddressSelectedPort
                      address={address}
                      index={0}
                      state={state}
                      dispatch={dispatch}
                      actions={actions}
                      selectedPort={
                        isPort
                          ? address?.selectedPort || address?.port
                          : undefined
                      }
                      onItemClick={(option) => {
                        dispatchAction(
                          0,
                          "port",
                          { uuid: option.uuid, name: option.name },
                          state,
                          dispatch
                        );
                      }}
                    />
                  </Paper>
                  <Flex justifyContent={"flex-start"}>
                    <CheckBox
                      text={t("use_as_default_port_address")}
                      id={`address_is_default_id`}
                      checked={address?.isCollectionDefault}
                      onChange={(e) => {
                        dispatchActionMultipleKey(
                          0,
                          ["isCollectionDefault", "isDeliveryDefault"],
                          e.target.checked,
                          state,
                          dispatch
                        );
                      }}
                    />
                  </Flex>
                </>
              ) : (
                <>
                  <Paper
                    whiteMode
                    style={{
                      paddingTop: 15,
                      paddingBottom: 20,
                      paddingLeft: 15,
                      paddingRight: 15,
                      display: "block",
                    }}
                  >
                    <Heading type={5}>{t("address_data")}</Heading>
                    <WidgetAddressLocation
                      address={address}
                      index={0}
                      state={state}
                      dispatch={dispatch}
                      extraElement={
                        <WidgetAddressCountries
                          address={address}
                          index={0}
                          state={state}
                          dispatch={dispatch}
                          required
                        />
                      }
                    />
                  </Paper>
                  <Paper
                    whiteMode
                    style={{
                      alignItems: "stretch",
                      paddingTop: 15,
                      paddingBottom: 20,
                      paddingLeft: 15,
                      paddingRight: 15,
                    }}
                  >
                    <div className="row">
                      <div className="col-12">
                        <WidgetAddressEquipment
                          alternative
                          address={address}
                          index={0}
                          state={state}
                          dispatch={dispatch}
                          permissionManage={canManageOrg}
                        />
                      </div>
                    </div>
                  </Paper>
                  <Flex justifyContent={"flex-start"}>
                    <CheckBox
                      text={t("use_as_default_address")}
                      id={`address_is_default_id`}
                      checked={address?.isCollectionDefault}
                      onChange={(e) => {
                        dispatchActionMultipleKey(
                          0,
                          ["isCollectionDefault", "isDeliveryDefault"],
                          e.target.checked,
                          state,
                          dispatch
                        );
                      }}
                    />
                  </Flex>
                </>
              )}
            </Flex>
          </Paper>
        );

        return (
          <Container>
            <div className="row">
              <WidgetAddressAlias
                alias={address?.alias}
                index={0}
                state={state}
                dispatch={dispatch}
                required
                skipRequiredHelp
              />
            </div>
            <div className="row">
              <WidgetAddressCollectionDelivery
                address={address}
                index={0}
                state={state}
                dispatch={dispatch}
                skipRequiredHelp
              />
            </div>
            <div className="row">
              <div className="col-12">
                <WidgetAddressesBySea
                  text={t("is-by-sea")}
                  checked={isPort}
                  onChange={(e) => {
                    dispatchAction(
                      0,
                      "port",
                      e.target.checked
                        ? {
                            uuid: "",
                            name: "",
                          }
                        : null,
                      state,
                      dispatch
                    );
                  }}
                />
              </div>
            </div>
            {isPort ? (
              <WidgetAddressSelectedPort
                address={address}
                index={0}
                state={state}
                dispatch={dispatch}
                actions={actions}
                selectedPort={isPort ? address?.port : undefined}
                onItemClick={(option) => {
                  dispatchAction(
                    0,
                    "port",
                    { uuid: option.uuid, name: option.name },
                    state,
                    dispatch
                  );
                }}
              />
            ) : (
              <>
                <div className="row">
                  <div className="col-12 col-lg-6">
                    <WidgetAddressCountries
                      address={address}
                      index={0}
                      state={state}
                      dispatch={dispatch}
                      required
                      skipRequiredHelp
                    />
                  </div>
                  <div className="col-12 col-lg-6">
                    <WidgetAddressPhone
                      address={address}
                      index={0}
                      state={state}
                      dispatch={dispatch}
                    />
                  </div>
                </div>
                <WidgetAddressLocation
                  address={address}
                  index={0}
                  state={state}
                  dispatch={dispatch}
                />
                <WidgetAddressWarehouse
                  address={address}
                  index={0}
                  state={state}
                  dispatch={dispatch}
                />
                <div className="row">
                  <div className="col-12">
                    <WidgetAddressEquipment
                      address={address}
                      index={0}
                      state={state}
                      dispatch={dispatch}
                    />
                  </div>
                </div>
              </>
            )}
          </Container>
        );
      })}
    />
  );
};

export default ModalNewAddress;
