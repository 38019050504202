import { MiniTabs } from "@scrapadev/scrapad-front-sdk";
import React, { useState } from "react";
import CardBox from "../../../components/ui/box/CardBox";
import FiltersWrapper from "../../../components/ui/filters/Wrapper";
import { PaginationProvider } from "../../../providers/pagination/PaginationContext";
import { SortProvider } from "../../../providers/sort/SortContext";
import { useTranslation } from "../../../utils/hooks";
import WidgetOpportunitiesByMe from "./OpportunitiesByMe";
import WidgetOpportunitiesParticipating from "./OpportunitiesParticipating";

const keyOpportunitiesMyMe = "dashboardOpportunitiesByMe";
const keyOpportunitiesParticipating = "dashboardOpportunitiesParticipating";

const WidgetBusinessOpportunities = ({ id }) => {
  const { t, i18n } = useTranslation(["common"]);
  const [currentTab, setCurrentTab] = useState(0);
  const tabs = [
    {
      tab: <>{t("own")}</>,
      panel: (
        <PaginationProvider storageKey={keyOpportunitiesMyMe} keysMerged>
          <FiltersWrapper storageKey={keyOpportunitiesMyMe} keysMerged>
            <SortProvider storageKey={keyOpportunitiesMyMe} keysMerged>
              <WidgetOpportunitiesByMe id={id} />
            </SortProvider>
          </FiltersWrapper>
        </PaginationProvider>
      ),
    },
    {
      tab: <>{t("participating")}</>,
      panel: (
        <PaginationProvider
          storageKey={keyOpportunitiesParticipating}
          keysMerged
        >
          <FiltersWrapper storageKey={keyOpportunitiesParticipating} keysMerged>
            <SortProvider storageKey={keyOpportunitiesParticipating} keysMerged>
              <WidgetOpportunitiesParticipating id={id} />
            </SortProvider>
          </FiltersWrapper>
        </PaginationProvider>
      ),
    },
  ];
  return (
    <CardBox
      title={t("opportunities")}
      action={t("see-more")}
      hasOverflow={false}
    >
      <MiniTabs
        tabsProps={{
          style: { position: "static" },
        }}
        tabPanelProps={{ style: { position: "static" } }}
        tabs={tabs}
        currentTab={currentTab}
        changeTab={(index) => {
          setCurrentTab(index);
        }}
      />
    </CardBox>
  );
};

export default WidgetBusinessOpportunities;
