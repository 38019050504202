import React, { useEffect, useState } from "react";
import WidgetFieldSaveDiscard from "../../fields/Save";
import { useTranslation } from "../../../utils/hooks";
import { useSetDataUser, useSignOut } from "../../../providers/AuthHooks";
import { useHeaderProvider } from "../../../providers/header/HeaderContext";
import { orgStatus } from "../../../utils/variables";
import { handleCRUD } from "../../../utils/crud/functions";
import {
  BREAKPOINT_LG,
  Container,
  Heading,
  Icon,
  InputText,
  INPUT_TAG,
  Label,
  Row,
  SUCCESS_COLOR,
  TEXT_COLOR_LIGHT,
  TooltipSc,
  Typography,
  useMatchMedia,
  WARM_NEUTRAL_40,
} from "@scrapadev/scrapad-front-sdk";
import {
  handleOnChange,
  renderDisabledComponentWithTooltip,
} from "../../../utils/functions";
import { useOperationsProvider } from "../../../providers/organization/OperationsContext";
import _ from "lodash";
import WidgetDataWrapper from "../../wrappers/DataWrapper";
import { getVerificationFilesModule } from "../../../model/business";
import WidgetVerificationFiles from "./Files";
import WidgetVerificationFilesLocal from "./FilesLocal";

function BankAccount({
  id,
  state,
  dispatch,
  actions,
  handleDiscard,
  handleParentCallback,
  fetchData = () => {},
  extraData,
  completeFields = [],
  deleteDocument = () => {},
  isDirty,
  setIsDirty = () => {},
}) {
  const { t } = useTranslation(["business", "common"]);
  const [loading, setLoading] = useState(false);
  const signOut = useSignOut();
  const setDataUser = useSetDataUser();
  const { state: headerState } = useHeaderProvider();
  const { permissionManage = null, permissionRead = null } = extraData || {};
  const verified = headerState?.verificationState === orgStatus.KYC_COMPLETE;
  const { canManageKYC, canManageOrg } = useOperationsProvider();
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_LG}px)`);

  useEffect(() => {
    fetchData();
  }, []);

  const handleSubmitWidget = (e) => {
    e.preventDefault();
    setLoading(true);

    const stateCpy = _.cloneDeep(state);
    if (!state?.uuid) {
      delete stateCpy.uuid;
    }

    let filesCpy = _.cloneDeep(state?.files);
    if (!filesCpy) {
      alert(`${completeFields[0]?.description} ${t("required")}`);
      setLoading(false);
      return;
    }

    delete stateCpy?.files;

    handleCRUD({
      endpoint: `org/${id}/verification`,
      method: "POST",
      data: {
        files: [...filesCpy],
        walletData: {
          ...stateCpy,
        },
      },
      successCallback: async () => {
        await fetchData();
        setLoading(false);
        handleParentCallback();
      },
      messageOk: t("action-ok"),
      signOut,
      setDataUser,
      setLoading,
      t: t,
    });
  };

  return (
    <form onSubmit={handleSubmitWidget}>
      <Container>
        <Heading type={6}>{t("bank_account")}</Heading>
        <Row gap={25} wrap={matches}>
          <Label small light text={t("swift")} style={{ paddingBottom: 0 }}>
            {renderDisabledComponentWithTooltip(
              <InputText
                required
                value={state?.bicCode}
                onChange={handleOnChange("bicCode", dispatch)}
                placeholder={t("write-here")}
                readOnly={false || !canManageOrg()}
                mode="underline"
              />,
              false,
              t
            )}
          </Label>
          <Label small light text={t("iban")} style={{ paddingBottom: 0 }}>
            {renderDisabledComponentWithTooltip(
              <InputText
                required
                value={state?.viban}
                onChange={handleOnChange("viban", dispatch)}
                placeholder={t("write-here")}
                readOnly={false || !canManageOrg()}
                mode="underline"
              />,
              false,
              t
            )}
          </Label>
        </Row>
        <Heading type={6} style={{ marginTop: "15px" }}>
          {t("account_owner")}
        </Heading>
        <Label small light text={t("name")} style={{ paddingBottom: 0 }}>
          {renderDisabledComponentWithTooltip(
            <InputText
              required
              value={state?.holder}
              onChange={handleOnChange("holder", dispatch)}
              placeholder={t("write-here")}
              readOnly={false || !canManageOrg()}
              mode="underline"
            />,
            false,
            t
          )}
        </Label>
        <Label
          small
          light
          text={t("full_direction_account_owner")}
          style={{ paddingBottom: 0 }}
        >
          {renderDisabledComponentWithTooltip(
            <InputText
              required
              value={state?.address}
              onChange={handleOnChange("address", dispatch)}
              placeholder={t("write-here")}
              readOnly={false || !canManageOrg()}
              mode="underline"
            />,
            false,
            t
          )}
        </Label>
        {completeFields?.map((document, index) => (
          <div key={`cfm_ba_${index}`}>
            <Label
              text={
                <Row gap={5}>
                  <span style={{ ...INPUT_TAG, color: TEXT_COLOR_LIGHT }}>
                    {document?.description}
                  </span>
                  {document?.helpContent && (
                    <TooltipSc
                      alignment={matches ? "left" : undefined}
                      content={document?.helpContent}
                    >
                      <Icon
                        name="help_outline"
                        color={WARM_NEUTRAL_40}
                        style={{
                          fontSize: "18px",
                        }}
                      />
                    </TooltipSc>
                  )}
                </Row>
              }
              style={{ padding: 0, marginTop: "15px" }}
            ></Label>

            <WidgetDataWrapper
              id={id}
              fnData={getVerificationFilesModule}
              fnParameters={{
                files: document?.documents ? [...document?.documents] : [],
              }}
              Component={WidgetVerificationFilesLocal}
              permission={canManageKYC}
              afterUploadInterceptor={(e) => {
                //(files) => {
                let value = [];
                if (!_.isEmpty(state?.files)) {
                  value = [
                    ...state.files,
                    { id: e?.[0]?.uuid, type: document.type },
                  ];
                } else {
                  value = [{ id: e?.[0]?.uuid, type: document.type }];
                }

                handleOnChange(
                  "files",
                  dispatch
                )({
                  target: {
                    value: value,
                  },
                });
                setIsDirty(true);
              }}
              extraData={{
                canEdit: true,
                permission: canManageKYC,
                description: document?.description,
                afterUploadAllFiles: (e) => {
                  //(files) => {
                  let value = [];
                  if (!_.isEmpty(state?.files)) {
                    value = [
                      ...state.files,
                      { id: e?.[0]?.uuid, type: document.type },
                    ];
                  } else {
                    value = [{ id: e?.[0]?.uuid, type: document.type }];
                  }

                  handleOnChange(
                    "files",
                    dispatch
                  )({
                    target: {
                      value: value,
                    },
                  });
                  setIsDirty(true);
                  // if (files && files.length > 0) {
                  //   setLoading(true);
                  //   handleCRUD({
                  //     endpoint: `org/${id}/verification`,
                  //     data: {
                  //       files: files.map((f) => ({
                  //         id: f.uuid,
                  //         type: document?.type,
                  //       })),
                  //     },
                  //     method: "POST",
                  //     setDataUser,
                  //     signOut,
                  //     successCallback: async () => {
                  //       await fetchData();
                  //       setLoading(false);
                  //     },
                  //   });
                  // }
                },
                fetchData: fetchData,
                setLoading: setLoading,
                id: id,
              }}
            />
          </div>
        ))}
        {canManageOrg() && (
          <WidgetFieldSaveDiscard
            isDirty={isDirty}
            handleDiscard={handleDiscard}
            saveText={t("send_to_verify")}
            permission={canManageKYC}
          />
        )}
      </Container>
    </form>
  );
}

export default BankAccount;
