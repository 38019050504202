import { BREAKPOINT_LG } from "@scrapadev/scrapad-front-sdk";
import { useOperationsProvider } from "../../../providers/organization/OperationsContext";
import { useMatchMedia, useTranslation } from "../../../utils/hooks";
import TextLogo from "../text-logo/TextLogo";
import Info from "./Info";
import MenuItem from "./Item";
import styles from "./Menu.module.scss";
import SubMenu from "./SubMenu";

const MoreItemsDesktop = () => {
  const { t } = useTranslation(["common", "logistics"]);
  const { canReadAds, canReadFinances, canReadLogistics } =
    useOperationsProvider();

  const getMenuItems = () => {
    let items = [];
    if (canReadAds())
      items.push({
        component: <MenuItem to={"/ads"} icon={"sell"} text={t("ads")} />,
      });
    if (canReadFinances())
      items.push({
        component: (
          <MenuItem to={"/finances"} icon={"show_chart"} text={t("finances")} />
        ),
      });
    if (canReadLogistics())
      items.push({
        component: (
          <MenuItem
            to={"/logistics"}
            icon={"directions_boat"}
            text={t("logistics")}
          />
        ),
      });
    return items;
  };

  const items = getMenuItems();

  if (items.length === 0) {
    return <></>;
  }

  return (
    <li className={styles["c-navbar__item-submenu"]}>
      <SubMenu items={items} label={t("more")} />
    </li>
  );
};

const MoreItemsMobile = () => {
  const { t } = useTranslation(["common", "logistics"]);
  const { canReadAds, canReadFinances, canReadLogistics } =
    useOperationsProvider();
  return (
    <>
      {canReadAds() && (
        <li>
          <MenuItem to={"/ads"} icon={"sell"} text={t("ads")} />
        </li>
      )}
      {canReadFinances() && (
        <li>
          <MenuItem to={"/finances"} icon={"show_chart"} text={t("finances")} />
        </li>
      )}
      {canReadLogistics() && (
        <li>
          <MenuItem
            to={"/logistics"}
            icon={"directions_boat"}
            text={t("logistics")}
          />
        </li>
      )}
    </>
  );
};

/**
 * Private Menu component.
 * @param {Object} props - Component properties.
 * @param {Boolean} [props.withLogo=false] - Tells the component if the logo must be shown.
 * @returns {JSX.Element}
 */
const Menu = ({ withLogo = true }) => {
  const { t } = useTranslation("common");
  const matches = useMatchMedia(`(min-width: ${BREAKPOINT_LG}px)`);
  const { canReadTransaction, canReadBusiness, canManageOpportunities } =
    useOperationsProvider();

  return (
    <>
      <div className={[styles["c-navbar__menu"]]}>
        {withLogo && <TextLogo />}
        <div className={styles["c-navbar__menu-inner"]}>
          <ul>
            {canReadBusiness() && (
              <li className={styles["c-navbar__item-submenu"]}>
                <MenuItem
                  to={"/business"}
                  icon={"business"}
                  text={t("business")}
                />
                <SubMenu
                  items={[
                    {
                      component: (
                        <MenuItem
                          to={"/register-incomplete"}
                          text={t("register-incomplete")}
                        />
                      ),
                    },
                  ]}
                  containerStyle={{
                    padding: 0,
                  }}
                  iconStyle={{ paddingLeft: 15 }}
                />
              </li>
            )}
            {canManageOpportunities() && (
              <li>
                <MenuItem
                  to={"/opportunities"}
                  icon={"campaign"}
                  text={t("opportunities")}
                />
              </li>
            )}
            {canReadTransaction() && (
              <li>
                <MenuItem
                  to={"/transactions"}
                  icon={"swap_horiz"}
                  text={t("transactions")}
                />
              </li>
            )}
            {matches ? <MoreItemsDesktop /> : <MoreItemsMobile />}
          </ul>
          {matches && <Info showBusinnes={false} />}
        </div>
      </div>
    </>
  );
};

export default Menu;
