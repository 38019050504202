import { Flex, Typography } from "@scrapadev/scrapad-front-sdk";
import React from "react";
import AddressIcon from "../../../components/ui/icons/general/Address";
import AdsIcon from "../../../components/ui/icons/general/Ads";
import FavoritesIcon from "../../../components/ui/icons/general/Favorites";
import OpportunitiesIcon from "../../../components/ui/icons/general/Opportunities";
import TransactionsIcon from "../../../components/ui/icons/general/Transactions";
import UsersIcon from "../../../components/ui/icons/general/User";

/**
 * Helper component to render empty status in business dashboard.
 * @param {object} props - Component properties.
 * @param {"users"|"favorite"} props.type - Icon type.
 * @param {object} props.text - Description text.
 * @param {React.CSSProperties} [props.textStyle={}] - Text style.
 * @returns {React.ReactNode}
 */
const WidgetBusinessEmpty = ({ type, text, textStyle = {} }) => {
  const icons = {
    users: <UsersIcon />,
    address: <AddressIcon />,
    favorite: <FavoritesIcon />,
    opportunities: <OpportunitiesIcon />,
    transactions: <TransactionsIcon />,
    ads: <AdsIcon />,
  };
  const icon = icons[type] || <></>;
  return (
    <Flex flexDirection={"column"} style={{ gap: 10, height: "100%" }}>
      {icon}
      <Typography
        typography="small-reg"
        style={{ textAlign: "center", ...textStyle }}
      >
        {text}
      </Typography>
    </Flex>
  );
};

export default WidgetBusinessEmpty;
