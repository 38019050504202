import {
  BODY_MEDIUM_REG,
  CheckBox,
  Icon,
  SearchAutoComplete,
  TEXT_COLOR,
  WARM_NEUTRAL_40,
} from "@scrapadev/scrapad-front-sdk";
import { forwardRef, useState } from "react";
import { checkIfMultiple } from "../../../utils/filters/functions";
import { useTranslation } from "../../../utils/hooks";
import FilterSearch from "./FilterSearch";

const FilterAutoComplete = (
  {
    filter,
    catalogName,
    filterKey,
    options = [],
    handleItemClick,
    itemSpanStyle,
    itemCheckStyle,
    itemCheckedStyle,
    textStyles,
    inputProps = {},
  },
  ref
) => {
  const [innerValue, setInnerValue] = useState("");
  const { t } = useTranslation("common");
  const isMultipleFilter = checkIfMultiple(filter);
  const defaultInputProps = {
    useSearch: typeof catalogName !== "undefined",
    trailingIcon: <></>,
    multiple: isMultipleFilter,
    position: "relative",
    permanentOpen: isMultipleFilter,
    handleIsChecked: isMultipleFilter
      ? (option) =>
          filter?.selected?.filter((f) => f.uuid === option.uuid)?.length > 0
      : undefined,
    renderItem:
      typeof catalogName !== "undefined"
        ? undefined
        : isMultipleFilter
        ? (option, innerOptions, handleClick, handleIsChecked) => {
            const isChecked = handleIsChecked(option, innerOptions);
            return (
              <li
                key={option.uuid}
                onClick={handleClick(option)}
                style={{
                  justifyContent: "flex-start",
                  padding: 7,
                  marginBottom: 5,
                  color: TEXT_COLOR,
                  ...BODY_MEDIUM_REG,
                }}
              >
                <span style={itemSpanStyle}>
                  <CheckBox
                    checked={isChecked}
                    style={isChecked ? itemCheckedStyle : itemCheckStyle}
                  />
                </span>
                <span style={textStyles}>{`${option.name}`}</span>
              </li>
            );
          }
        : undefined,
    ...inputProps,
  };
  if (catalogName && filterKey) {
    return (
      <FilterSearch
        catalogName={catalogName}
        filterKey={filterKey}
        permanentOpen
        inputProps={defaultInputProps}
        ref={ref}
        remote
      />
    );
  }
  return (
    <SearchAutoComplete
      placeholder={t("write-to-search")}
      handleOnChange={(e, open, setOpen, loading, setLoading) => {
        setInnerValue(e.target.value);
      }}
      value={[innerValue]}
      options={options}
      model={{ uuid: "uuid", text: "name" }}
      handleItemClick={(option, open, setOpen) => {
        if (handleItemClick) handleItemClick(option, open, setOpen);
      }}
      defaultOpen
      inputProps={{
        placeholder: t("write-to-search"),
      }}
      iconProps={{
        color: WARM_NEUTRAL_40,
      }}
      {...defaultInputProps}
    />
  );
};

export default forwardRef(FilterAutoComplete);
