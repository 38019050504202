import { InputFile, toastError } from "@scrapadev/scrapad-front-sdk";
import { useState } from "react";
import { useSetDataUser, useSignOut } from "../../../../providers/AuthHooks";
import { useTranslation } from "../../../../utils/hooks";
import { postClient } from "../../../../utils/restclient";
import { MAX_UPLOAD_SIZE } from "../../../../utils/variables";
import { getFileExtension } from "../../../../utils/functions";

const InputFileWrapper = ({
  files,
  multiple,
  dropPlaceholder,
  placeholder,
  beforeUpload,
  afterUpload,
  iconStyles = {},
  translation,
  allowedExtension,
  fileType,
  extension,
  afterUploadAllFiles,
  dataAdapter,
  localMode = false,
  ...props
}) => {
  const { t } = useTranslation(["common"]);
  const [loading, setLoading] = useState(false);
  const signOut = useSignOut();
  const setDataUser = useSetDataUser();
  const handleUpload = async (e) => {
    if (localMode) {
      afterUpload(e.target.files);
      return;
    }

    let error = false;
    if (beforeUpload) {
      beforeUpload();
    }
    const parsedFiles = await Promise.all(
      Array.prototype.map.call(e.target.files, async (f, index) => {
        let allocResponse;
        let uploadResponse;
        const extension =
          "." + getFileExtension(f.name.substr(f.name.lastIndexOf("\\") + 1));
        if (
          allowedExtension &&
          allowedExtension.length > 0 &&
          !allowedExtension.includes(extension)
        ) {
          setLoading(false);
          toastError(t("file-format-error"));
          error = true;
          return undefined;
        }
        setLoading(true);
        if (f?.size > MAX_UPLOAD_SIZE) {
          setLoading(false);
          toastError(t("file-too-big"));
          error = true;
          return undefined;
        }
        try {
          allocResponse = await postClient({
            external: true,
            url: `${process.env.REACT_APP_MARKETPLACE_URL}/${process.env.REACT_APP_SCRAPAD_API_VERSION}/upload`,
            method: "POST",
            body: {
              file_type: fileType,
              extension: extension.replace(".", ""),
              original_name: f.name,
            },
            signOut,
            setDataUser,
          });
          if (!allocResponse || !allocResponse.location) {
            throw t("error-get-file");
          }
          uploadResponse = await postClient({
            external: true,
            url: allocResponse.location,
            method: "PUT",
            body: f,
            extraHeaders: {
              "Content-Type": f.type,
            },
            avoidAuth: true,
            avoidParsing: true,
            signOut,
            setDataUser,
          });
          // toastSuccess(t("file-uploaded-ok"), {
          //   toastId: "upload_file_success",
          // });
          const returnFile = {
            uuid: allocResponse.uuid_file,
            name: f.name,
            location: allocResponse.location,
            file: f,
          };
          setLoading(false);
          return dataAdapter
            ? dataAdapter(returnFile, allocResponse)
            : returnFile;
        } catch (error) {
          toastError(error.message);
          error = true;
          return undefined;
        }
      })
    );
    if (!error && afterUploadAllFiles) {
      afterUploadAllFiles(parsedFiles);
    }
    if (!error && afterUpload) {
      afterUpload(parsedFiles);
    }
  };
  return (
    <InputFile
      multiple={multiple}
      loading={loading}
      placeholder={placeholder}
      dropPlaceholder={dropPlaceholder}
      handleUpload={handleUpload}
      iconStyles={iconStyles}
      translation={translation}
      labelStyles={{ backgroundColor: props?.readOnly ? "#CFCFCF" : "#F8F8F8" }}
      dashed
      mode="dashed"
      {...props}
    />
  );
};

export default InputFileWrapper;
