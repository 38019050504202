import {
  Button,
  ClearButton,
  Flex,
  Icon,
  Spacer,
  Label,
} from "@scrapadev/scrapad-front-sdk";
import { forwardRef } from "react";
import { actions } from "../../../providers/filters/actions";
import { useFiltersProvider } from "../../../providers/filters/FiltersContext";
import { useTranslation } from "../../../utils/hooks";
import { adsDefaults } from "../../../utils/variables";
import BaseCategory from "../ads/BaseCategory";
import BaseMaterials from "../ads/BaseMaterials";
import BaseType from "../ads/BaseType";

const FilterLabel = ({ children }) => {
  return (
    <>
      <Label noPadding small bold>
        {children}
      </Label>
      <Spacer height={12} />
    </>
  );
};

const FilterForMaterials = ({ filterKey }, ref) => {
  const { state, dispatch, filterChanged } = useFiltersProvider();
  const { t } = useTranslation(["common", "filters"]);
  const data = state?.[filterKey]?.data;

  if (!state) return <></>;

  return (
    <Flex flexDirection="column">
      <Flex
        flexDirection="column"
        alignItems={"flex-start"}
        style={{ flexGrow: 1, width: "100%" }}
      >
        <FilterLabel>{t("category")}</FilterLabel>
        <BaseCategory
          state={data}
          dispatch={({ type, payload }) => {
            dispatch({ type, payload: { ...payload, slug: "materials" } });
          }}
          actions={actions}
        />
      </Flex>
      {data?.[filterKey]?.material?.open && (
        <Flex
          flexDirection="column"
          alignItems={"flex-start"}
          style={{ flexGrow: 1, width: "100%" }}
        >
          <FilterLabel>{t("material")}</FilterLabel>
          <BaseMaterials
            state={data}
            dispatch={({ type, payload }) => {
              dispatch({ type, payload: { ...payload, slug: "materials" } });
            }}
            actions={actions}
          />
        </Flex>
      )}
      {data?.[filterKey]?.type?.open && (
        <Flex
          flexDirection="column"
          alignItems={"flex-start"}
          style={{ flexGrow: 1, width: "100%" }}
        >
          <FilterLabel>{t("type")}</FilterLabel>
          <BaseType
            state={data}
            dispatch={({ type, payload }) => {
              dispatch({ type, payload: { ...payload, slug: filterKey } });
            }}
            actions={actions}
          />
        </Flex>
      )}
      {data?.[filterKey]?.material?.uuidSelected?.length > 0 && (
        <Flex flexDirection="column" style={{ flexGrow: 1, width: "100%" }}>
          <Flex
            justifyContent={"flex-end"}
            style={{ flexGrow: 1, width: "100%" }}
          >
            <ClearButton
              style={{ justifyContent: "flex-end" }}
              onClick={(e) => {
                e.stopPropagation();
                dispatch({
                  type: actions.CLEAR_INPUT,
                  payload: {
                    slug: filterKey,
                    category: {
                      ...data?.[filterKey]?.category,
                      uuidSelected: [],
                      nameSelected: [],
                      values: [],
                    },
                    material: adsDefaults,
                    type: adsDefaults,
                  },
                });
              }}
            >
              <Icon name="close" color="#777986" style={{ fontSize: 16 }} />
              <span
                style={{
                  textDecoration: "underline",
                  fontSize: 12,
                  lineHeight: "18px",

                  color: "#777986",
                }}
              >
                {t("restore-selection")}
              </span>
            </ClearButton>
          </Flex>
          <Spacer height={20} />
          <Button
            data-cy="filter-material-apply"
            component={"button"}
            type="button"
            text={t("apply")}
            fullwidth
            onClick={(e) => {
              e.stopPropagation();
              if (ref?.current?.handleClose) ref.current?.handleClose(false);
              filterChanged.current = true;
              let selected = [];
              if (
                data?.[filterKey]?.category?.uuidSelected?.length > 0 &&
                data?.[filterKey]?.material?.uuidSelected?.length > 0 &&
                data?.[filterKey]?.type?.uuidSelected?.length > 0
              ) {
                selected = data?.[filterKey]?.type?.uuidSelected?.map(
                  (uuid, index) => {
                    return {
                      uuid,
                      name: data?.[filterKey]?.type?.nameSelected[index],
                    };
                  }
                );
              } else if (
                data?.[filterKey]?.category?.uuidSelected?.length > 0 &&
                data?.[filterKey]?.material?.uuidSelected?.length > 0 &&
                data?.[filterKey]?.type?.uuidSelected?.length === 0
              ) {
                selected = data?.[filterKey]?.material?.uuidSelected?.map(
                  (uuid, index) => {
                    return {
                      uuid,
                      name: data?.[filterKey]?.material?.nameSelected[index],
                    };
                  }
                );
              } else {
                selected = data?.[filterKey]?.category?.uuidSelected?.map(
                  (uuid, index) => {
                    return {
                      uuid,
                      name: data?.[filterKey]?.category?.nameSelected[index],
                    };
                  }
                );
              }
              if (selected.length > 0) {
                dispatch({
                  type: actions.ADD_MULTIPLE_FILTER,
                  payload: {
                    filter: { slug: filterKey },
                    item: selected,
                  },
                });
              }
            }}
          />
        </Flex>
      )}
    </Flex>
  );
};

export default forwardRef(FilterForMaterials);
