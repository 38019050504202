import styles from "./Add.module.scss";
import { useEffect, useState } from "react";
import {
  BREAKPOINT_LG,
  Container,
  Icon,
  Modal,
  Spacer,
  useMatchMedia,
  WARM_NEUTRAL_10,
  WARM_NEUTRAL_30,
} from "@scrapadev/scrapad-front-sdk/dist/index";
import Label from "../../ui/input/label/Label";
import AdsCategories from "../../ui/ads/Categories";
import AdsMAterial from "../../ui/ads/Material";
import AdsType from "../../ui/ads/Type";
import AdsSelected from "../../ui/ads/Selected";
import { useTranslation } from "../../../utils/hooks";
import { useDataProvider } from "../../../providers/data/DataContext";
import AdsAdType from "../../ui/ads/AdType";
import { actions } from "../../../providers/data/actions/data";
import { adsDefaults } from "../../../utils/variables";
import CatalogSearch from "../../ui/input/search/CatalogSearch";

/**
 * Modal for invite a new user to the organization
 * @param {Object} props - Component properties.
 * @param {Object} [props.open=false] - Open/close modal status.
 * @param {Function} props.setOpen - Setter function to open / close.
 * @returns {JSX.Element}
 */
const ModalAddMaterial = ({ open = false, setOpen, ...props }) => {
  const { t } = useTranslation(["filters", "common", "business"]);
  const [loading, setLoading] = useState(true);
  const { state, dispatch } = useDataProvider();
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_LG}px)`);
  const submitEnabled =
    (state?.materials?.type?.uuidSelected.length > 0 &&
      state?.materials?.adType?.sale) ||
    state?.materials?.adType?.purchase;

  useEffect(() => {
    initialLoad();
    setLoading(false);
  }, []);

  const initialLoad = () => {
    dispatch({
      type: actions.INITIAL_LOAD,
      payload: {
        materials: {
          category: adsDefaults,
          material: adsDefaults,
          type: adsDefaults,
          adType: { sale: false, purchase: false },
        },
      },
    });
  };

  const handleSetOpen = () => {
    initialLoad();
    setOpen(false);
  };

  const handleSubmit = async (e) => {
    if (props.onSubmit) props.onSubmit(state, loading, setLoading);
    handleSetOpen();
  };

  return (
    <Modal
      open={open}
      setOpen={handleSetOpen}
      title={t("add-favorite-material")}
      hasForm={true}
      onSubmit={handleSubmit}
      submitText={t("add")}
      cancelText={t("cancel")}
      submitDisabled={!submitEnabled}
      loading={loading}
      maxWidth={900}
      modalStyles={{ width: matches ? "auto" : 900 }}
      BodyComponent={
        <div className={styles["sc-materials--add"]}>
          <Container>
            <div className="row">
              <div className="col-12">
                <CatalogSearch
                  multiple
                  inputProps={{
                    required: true,
                    multiple: true,
                    newMultipleItemFormat: true,
                    style: {
                      border: `0.5px solid ${WARM_NEUTRAL_10}`,
                    },
                    trailingIcon: <></>,
                    leadingIcon: (
                      <Icon
                        name={"search"}
                        color={WARM_NEUTRAL_30}
                        style={{
                          position: "relative",
                          top: -7,
                          left: 0,
                          fontSize: 18,
                        }}
                      />
                    ),
                    handleIsChecked: (option, innerOptions) => {
                      return state?.materials?.type?.uuidSelected?.includes(
                        option.uuid
                      );
                    },
                  }}
                  catalogName={"material-autocomplete"}
                  onClickCallback={(option, open, setOpen) => {
                    const alreadySelected =
                      state?.materials?.type?.uuidSelected.includes(
                        option.uuid
                      );
                    if (alreadySelected) {
                      dispatch({
                        type: actions.UPDATE_INPUT,
                        payload: {
                          materials: {
                            ...state.materials,
                            type: {
                              ...state.materials.type,
                              uuidSelected:
                                state.materials.type.uuidSelected.filter(
                                  (f) => f !== option.uuid
                                ),
                              nameSelected:
                                state.materials.type.nameSelected.filter(
                                  (f) => f !== option.name
                                ),
                              values: state.materials.type.values.filter(
                                (f) => f !== option.name
                              ),
                            },
                          },
                        },
                      });
                    } else {
                      dispatch({
                        type: actions.UPDATE_INPUT,
                        payload: {
                          materials: {
                            ...state.materials,
                            type: {
                              ...state.materials.type,
                              uuidSelected: [
                                ...state.materials.type.uuidSelected,
                                option.uuid,
                              ],
                              nameSelected: [
                                ...state.materials.type.nameSelected,
                                option.name,
                              ],
                              values: [
                                ...state.materials.type.values,
                                option.name,
                              ],
                            },
                          },
                        },
                      });
                    }
                  }}
                />
                <Spacer height={5} />
              </div>
              <div className="col-12">
                <AdsSelected />
              </div>
              <div className="col-12">
                <AdsAdType />
              </div>
            </div>
          </Container>
        </div>
      }
    />
  );
};

export default ModalAddMaterial;
