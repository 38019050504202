import styles from "./Transport.module.scss";
import Container from "../../ui/container/Container";
import { useTransactionsFormsProvider } from "../../../providers/transactions/TransactionsFormContext";
import { actions } from "../../../providers/transactions/actions/transactions-forms";
import { useTranslation } from "../../../utils/hooks";
import { buildFormTransportCost, buildFormTransportLoad } from "./functions";
import {
  checkPermission,
  dropdownGetValueFromUuid,
  getHoursForDropdown,
  handleFormOK,
  handleMultipleItemClick,
} from "../../../utils/functions";
import { postClient } from "../../../utils/restclient";
import {
  useSetDataUser,
  useSignOut,
  useUser,
} from "../../../providers/AuthHooks";
import {
  formEvents,
  shippingTypesStates,
  TEXT_COLOR_LIGHT,
  transportBoardingStates,
  transportCostStates,
  transportLoadUnloadStates,
} from "../../../utils/variables";
import {
  toastError,
  DropDown,
  Button,
  InputDate,
  Loading,
  SearchAutoComplete,
  GREEN_COLOR_100,
  Icon,
  ClearButton,
  Flex,
  ChipDropdown,
  useMatchMedia,
  BREAKPOINT_LG,
  Label,
  Paper,
  InputText,
  InputNumber,
  Row,
  Radio,
  FONT_FAMILY_SECONDARY,
} from "@scrapadev/scrapad-front-sdk";
import { getCatalogNoTerm } from "../../../model/filters";
import { useGQL } from "../../../utils/gql/hooks";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import {
  handleTransactionsRawSlugs,
  parseTransactionsRawEventData,
} from "../../../utils/transactions/functions";
import { useRawEventBO } from "../../../utils/transactions/hooks";
import TabPanelWrap from "../../../pages/transactions/wrappers/TabPanelWrap";
import CatalogIncoterm from "../../ui/catalogs/Incoterm";
import LandSeaTransportEquipment from "../../ui/catalogs/Equipment";
import _ from "lodash";
import CatalogSearch from "../../ui/input/search/CatalogSearch";

/**
 * Transport forms for transactions.
 * @param {Object} props - Component properties.
 * @param {("cost"|"load"|"unload"|"boarding")} [props.type="cost"] - Type of form.
 * @returns {JSX.Element}
 */
const TransportForm = ({
  fetchData,
  type = "cost",
  permission,
  onClose = () => {},
  uuidConversation,
  onlyForm,
}) => {
  let { id } = useParams();

  if (uuidConversation) {
    id = uuidConversation;
  }

  const { t, i18n } = useTranslation(["common", "transactions", "logistics"]);
  const { state, dispatch } = useTransactionsFormsProvider();
  const user = useUser();
  const signOut = useSignOut();
  const setDataUser = useSetDataUser();
  const checkdPermission = checkPermission(permission);
  const serachValueRef = useRef("");
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_LG}px)`);
  const stateTransportTypeRef = useRef();
  const {
    rawData,
    rawLoading,
    eventData,
    fetchData: rawFetchData,
  } = useRawEventBO({
    uuidConversation: id,
    slug: handleTransactionsRawSlugs(type),
    eventAdapter: parseTransactionsRawEventData,
    actions,
    dispatch,
  });

  const { data: catalog, loading: catalogLoading } = useGQL(getCatalogNoTerm, {
    locale: i18n.language,
    name: "transporters",
  });

  useEffect(() => {
    rawFetchData();
  }, [type]);

  useEffect(() => {
    if (stateTransportTypeRef.current !== state?.transportType) {
      if (state?.transportType === shippingTypesStates.LAND) {
        handleOnChange("originPort")({
          target: {
            value: undefined,
          },
        });
      }
    }
    stateTransportTypeRef.current = state?.transportType;
  }, [state?.transportType]);

  useEffect(() => {
    if (onlyForm) {
      const eventData = rawData?.event
        ? parseTransactionsRawEventData
          ? parseTransactionsRawEventData(JSON.parse(rawData?.event)?.EventData)
          : JSON.parse(rawData?.event)?.EventData
        : null;

      setTimeout(() => {
        dispatch({
          type: actions.UPDATE_INPUT,
          payload: eventData,
        });
      }, 50);
    }
  }, [rawData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch({ type: actions.UPDATE_INPUT, payload: { loading: true } });
    const data =
      type === "cost"
        ? buildFormTransportCost(
            user.userUuid,
            document.getElementById("slug").value,
            state
          )
        : buildFormTransportLoad(
            user.userUuid,
            document.getElementById("slug").value,
            state,
            type
          );
    try {
      await postClient({
        external: true,
        url: `${process.env.REACT_APP_API_EVENTS}`,
        method: "POST",
        body: data,
        signOut,
        setDataUser,
      });
      handleFormOK(t, fetchData);
    } catch (error) {
      toastError(error.message);
    } finally {
      rawFetchData();
      dispatch({
        type: actions.UPDATE_INPUT,
        payload: { loading: false },
      });
    }
  };
  const renderTitle = () => {
    let title = "";
    let items = [];
    let text = "";
    switch (type) {
      case "cost":
        title = t("quotation-coordination");
        items = [...optionsCost];
        text = dropdownGetValueFromUuid(state, optionsCost);
        break;
      case "load":
        title = t("load");
        items = [...optionsLoad];
        text = dropdownGetValueFromUuid(state, optionsLoad);
        break;
      case "unload":
        title = t("unload");
        items = [...optionsUnload];
        text = dropdownGetValueFromUuid(state, optionsUnload);
        break;
      case "boarding":
        title = t("boarding");
        items = [...optionsBoarding];
        text = dropdownGetValueFromUuid(state, optionsBoarding);
        break;
      default:
        break;
    }
    return (
      <>
        <Flex
          alignItems={"center"}
          justifyContent={"flex-start"}
          style={{
            paddingLeft: "12px",
            paddingRight: "12px",
          }}
        >
          <h3 className={styles["sc-form--title"]}>{title}</h3>
          <ChipDropdown
            items={items.map((item, index) => (
              <ClearButton
                key={item.uuid}
                onClick={() => {
                  dispatch({
                    type: actions.UPDATE_INPUT,
                    payload: {
                      state: item?.uuid,
                    },
                  });
                }}
              >
                {t(item.text)}
              </ClearButton>
            ))}
            type="clear"
            text={text || t("select-value")}
          />
        </Flex>
        <div style={{ paddingLeft: "12px", marginTop: "-5px" }}>
          <InputText
            id="Destination"
            required
            value={!_.isEmpty(state?.state) ? 1 : undefined}
            customValidityMessage={t("required-field")}
            style={{
              visibility: "hidden",
              height: 0,
              padding: 0,
              margin: `0 0 .5rem 0`,
            }}
          />
        </div>
      </>
    );
  };
  const handleDate = (key) => (date) => {
    const obj = {};
    obj[key] = date.getTime();
    dispatch({
      type: actions.UPDATE_INPUT,
      payload: obj,
    });
  };
  const handleOnChange = (key) => (e) => {
    const obj = {};
    obj[key] = e.target.value;
    dispatch({ type: actions.UPDATE_INPUT, payload: obj });
  };
  const handleItemClick = (key) => (option, open, setOpen) => {
    const obj = {};
    obj[key] = option.uuid;
    dispatch({
      type: actions.UPDATE_INPUT,
      payload: obj,
    });
    setTimeout(() => {
      setOpen(!open);
    }, 10);
  };
  const handleFromToClick = (key) => (option, open, setOpen) => {
    const obj = { estimatedSchedule: { ...state?.estimatedSchedule } };
    obj.estimatedSchedule[key] = option.uuid;
    dispatch({
      type: actions.UPDATE_INPUT,
      payload: obj,
    });
    setTimeout(() => {
      setOpen(!open);
    }, 10);
  };
  const handleSupplier = (value, uuid) => {
    const obj = {};
    obj.suplier = value;
    if (uuid) {
      obj.suplierUuid = uuid;
    }
    dispatch({ type: actions.UPDATE_INPUT, payload: obj });
  };

  const shippingTypes = [
    { uuid: shippingTypesStates.SEA, text: t("by-sea") },
    { uuid: shippingTypesStates.LAND, text: t("ground-only") },
  ];
  const optionsCost = [
    {
      uuid: transportCostStates.PENDING,
      text: t("cost_pending"),
    },
    {
      uuid: transportCostStates.LAUNCHED,
      text: t("cost_sended"),
    },
    {
      uuid: transportCostStates.EXPIRATED,
      text: t("cost_expired"),
    },
    {
      uuid: transportCostStates.PENDING_COORDINATION,
      text: t("pending-coordinated"),
    },
    {
      uuid: transportCostStates.COORDINATE,
      text: t("coordinated"),
    },
  ];
  const optionsLoad = [
    {
      uuid: transportLoadUnloadStates.PENDING_COORDINATION,
      text: t("pending-coordinated"),
    },
    {
      uuid: transportLoadUnloadStates.COORDINATE,
      text: t("coordinated-2"),
    },
    {
      uuid: transportLoadUnloadStates.CONFIRMED,
      text: t("confirmed-f"),
    },
    // {
    //   uuid: transportLoadUnloadStates.SHIPMENT_PENDING,
    //   text: t("shipment-pending"),
    // },
    // {
    //   uuid: transportLoadUnloadStates.BL_DRAFT_SENDED,
    //   text: t("draft-bl-sended"),
    // },
    // {
    //   uuid: transportLoadUnloadStates.SHIPMENT_CONFIRMED,
    //   text: t("shipment-confirmed"),
    // },
    // {
    //   uuid: transportLoadUnloadStates.BL_SENDED,
    //   text: t("bl-sended"),
    // },
    // {
    //   uuid: transportLoadUnloadStates.DOCUMENTATION_SENDED,
    //   text: t("documentation-sended"),
    // },
    {
      uuid: transportLoadUnloadStates.TICKET,
      text: t("ticket"),
    },
  ];
  const optionsUnload = [
    {
      uuid: transportLoadUnloadStates.PENDING_PORT_ARRIVAL,
      text: t("port-arrival-pending"),
    },
    {
      uuid: transportLoadUnloadStates.CONFIRMED_PORT_ARRIVAL,
      text: t("port-arrival-confirmed"),
    },
    {
      uuid: transportLoadUnloadStates.PENDING_DISPATCH,
      text: t("dispatch-pending"),
    },
    {
      uuid: transportLoadUnloadStates.DISPATCHED,
      text: t("dispatched"),
    },
    {
      uuid: transportLoadUnloadStates.PENDING_COORDINATION,
      text: t("pending-coordinated"),
    },
    {
      uuid: transportLoadUnloadStates.COORDINATE,
      text: t("coordinated-2"),
    },
    {
      uuid: transportLoadUnloadStates.CONFIRMED,
      text: t("confirmed-f"),
    },
    {
      uuid: transportLoadUnloadStates.TICKET,
      text: t("ticket"),
    },
  ];
  const optionsBoarding = [
    {
      uuid: transportBoardingStates.PENDING_DOCUMENTATION,
      text: t("documentation_pending"),
    },
    {
      uuid: transportBoardingStates.PENDING_DISPATCH,
      text: t("dispatch_pending"),
    },
    {
      uuid: transportBoardingStates.DOCUMENTATION_SENDED,
      text: t("documentation_sended"),
    },
    {
      uuid: transportBoardingStates.DISPATCHED,
      text: t("dispatched"),
    },
    {
      uuid: transportBoardingStates.BOARDING_CONFIRMED,
      text: t("confirmed"),
    },
    {
      uuid: transportBoardingStates.TICKET,
      text: t("ticket"),
    },
  ];

  const checkCostSlug = () => {
    return eventData ? formEvents.UPDATE_LOGISTIC : formEvents.ADD_LOGISTIC;
  };

  const checkLoadUnloadSlug = () => {
    switch (type) {
      case "load":
        return formEvents.TRANSPORT_COLLECTION_DATE_UPDATED;
      case "unload":
        return formEvents.TRANSPORT_DELIVERY_DATE_UPDATED;
      case "boarding":
        return formEvents.TRANSPORT_TRANSIT_DATE_UPDATED;
      default:
        return formEvents.TRANSPORT_DELIVERY_DATE_UPDATED;
    }
  };

  const submitButton = (hideSpacerMobile) => {
    return (
      <Row gap={10}>
        <span style={{ marginRight: "auto" }}></span>
        <Button
          component="button"
          reduced
          mode="normal"
          text={t("cancel")}
          loading={state.loading}
          onClick={(e) => {
            e.preventDefault();
            onClose();
          }}
        />
        <Button
          disabled={!checkdPermission}
          component="button"
          text={t("save")}
          loading={state?.loading}
          mode="info"
          reduced
        />
      </Row>
    );
  };

  const renderCostForm = () => {
    return (
      <>
        <div className="col-12 col-lg-6">
          <Label text={t("provider")} noPadding></Label>
          {catalogLoading ? (
            <Loading />
          ) : (
            <SearchAutoComplete
              mode="underline"
              readOnly={!checkdPermission}
              required
              value={state?.suplier}
              options={catalog?.errors ? [] : catalog}
              trailingIcon={
                state?.suplier ? (
                  <ClearButton
                    onClick={(e) => {
                      dispatch({
                        type: actions.UPDATE_INPUT,
                        payload: {
                          suplier: "",
                          suplierUuid: null,
                        },
                      });
                    }}
                  >
                    <Icon
                      name="close"
                      color={TEXT_COLOR_LIGHT}
                      style={{ fontSize: 20 }}
                    />
                  </ClearButton>
                ) : (
                  <></>
                )
              }
              trailingStyles={{
                pointerEvents: "initial",
              }}
              inputProps={{ placeholder: t("write-or-select") }}
              placeholder={t("select-a-value")}
              model={{ uuid: "uuid", text: "name" }}
              handleItemClick={(option, open, setOpen) => {
                handleSupplier(option?.name, option?.uuid);
                setTimeout(() => {
                  setOpen(!open);
                }, 10);
              }}
              handleOnChange={(e) => {
                serachValueRef.current = e.target.value;
              }}
              renderNoResultsItem={() => {
                if (
                  serachValueRef.current !== "" ||
                  serachValueRef.current !== null
                )
                  return (
                    <>
                      <li
                        style={{
                          fontFamily: FONT_FAMILY_SECONDARY,
                          fontSize: 12,
                          lineHeight: "18px",
                        }}
                        onClick={() => {
                          handleSupplier(serachValueRef.current);
                        }}
                      >
                        <strong>
                          {t("use-as-provider-1")}{" "}
                          <span
                            style={{
                              color: GREEN_COLOR_100,
                              padding: 0,
                            }}
                          >
                            {serachValueRef.current}
                          </span>{" "}
                          {t("use-as-provider-2")}
                        </strong>
                      </li>
                      <li
                        style={{
                          height: 1,
                          padding: "0 15px",
                        }}
                      >
                        <hr
                          style={{
                            opacity: 1,
                            width: "100%",
                            borderTop: "1px solid #E0E2EB",
                          }}
                        />
                      </li>
                      <li>{t("empty-data")}</li>
                    </>
                  );
                return <li>{t("empty-data")}</li>;
              }}
            />
          )}
        </div>
        <div className="col-12 col-lg-6">
          <Label text={t("transport-type")} noPadding>
            <DropDown
              readOnly={!checkdPermission}
              required
              placeholder={t("select-value")}
              options={shippingTypes}
              value={
                shippingTypes?.find((f) => f?.uuid === state?.transportType)
                  ?.text
              }
              handleItemClick={handleItemClick("transportType")}
              mode="underline"
            />
          </Label>
        </div>
        {state?.transportType === shippingTypesStates.SEA ? (
          <>
            <div className="col-12">
              <Label text={`${t("port_origin")} *`} noPadding>
                <CatalogSearch
                  remote
                  mode="underline"
                  id="originPortId"
                  inputProps={{
                    required: true,
                    placeholder: t("write-or-select"),
                  }}
                  initialValue={
                    state?.originPort && state?.originPort !== "empty"
                      ? state?.originPort?.name
                      : undefined
                  }
                  catalogName={"ports"}
                  avoidInitalLoad={true}
                  onClickCallback={(option, open, setOpen, value, setValue) => {
                    handleItemClick("originPort")(option);
                    setTimeout(() => {
                      setOpen(false);
                    }, 100);
                  }}
                />
              </Label>
            </div>
          </>
        ) : (
          <></>
        )}
        <div className="col-12 col-lg-6">
          <Label text={t("incoterm")} noPadding>
            <CatalogIncoterm
              handleItemClick={handleItemClick("incoterm")}
              value={state?.incoterm}
              autoCompleteProps={{
                mode: "underline",
                newMultipleItemFormat: false,
                multiple: false,
                useSearch: false,
                required: true,
                readOnly: !checkdPermission,
              }}
            />
          </Label>
        </div>
        <div className="col-12 col-lg-6">
          <Label text={t("scrapad_can_dispatch")} noPadding></Label>
          <Row
            gap={25}
            style={{
              paddingTop: "10px",
            }}
          >
            <span>
              <Radio
                id="canDispatch_yes"
                name="canDispatch"
                text={t("yes")}
                onChange={() => {
                  handleOnChange("canDispatch")({
                    target: {
                      value: true,
                    },
                  });
                }}
                checked={state?.canDispatch === true}
                required
              />
            </span>
            <span>
              <Radio
                id="canDispatch_no"
                name="canDispatch"
                text={t("no")}
                onChange={() => {
                  handleOnChange("canDispatch")({
                    target: {
                      value: false,
                    },
                  });
                }}
                checked={state?.canDispatch === false}
                required
              />
            </span>
          </Row>
        </div>
        <div className="col-12">
          <Label text={t("transport-equipment")} noPadding>
            <LandSeaTransportEquipment
              handleItemClick={handleMultipleItemClick(
                "equipment",
                state,
                dispatch
              )}
              value={state?.equipment}
              autoCompleteProps={{
                mode: "underline",
                multiple: false,
                readOnly: !checkdPermission,
                required: true,
              }}
            />
          </Label>
        </div>
        <div className="col-12 col-lg-6">
          <Label text={`${t("units")}`} noPadding>
            <InputText
              readOnly={!checkdPermission}
              required
              min={0}
              placeholder={t("write-here")}
              step={"1"}
              value={state?.numLote}
              onChange={handleOnChange("numLote")}
              mode="underline"
            />
          </Label>
        </div>
        <div className="col-12 col-lg-6">
          <Label text={`${t("unit-price")}`} noPadding></Label>
          <InputNumber
            readOnly={!checkdPermission}
            required
            min={0}
            step={".01"}
            placeholder={t("write-here")}
            value={state?.unitPrice}
            onChange={handleOnChange("unitPrice")}
            mode="underline"
          />
        </div>
        <div className="col-12 col-lg-6">
          <Label text={t("total-cost")} noPadding></Label>
          <InputText
            min={0}
            readOnly={true}
            placeholder={t("write-here")}
            value={state?.numLote * state?.unitPrice}
            mode="underline"
          />
        </div>
        <div className="col-12 col-lg-6">
          <Label text={t("expire-date")} noPadding>
            <InputDate
              readOnly={!checkdPermission}
              required
              placeholder={t("write-here")}
              value={state?.untilDate}
              handleOnChange={handleDate("untilDate")}
              languageCode={i18n.language}
              mode="underline"
            />
          </Label>
        </div>
        <div className="col-12">
          <Label text={t("transit_time_in_days")} noPadding>
            <InputNumber
              required
              min={1}
              placeholder={t("write-here")}
              mode="underline"
              value={state?.shippingDeadline}
              onChange={handleOnChange("shippingDeadline")}
            />
          </Label>
        </div>
        {submitButton()}
        <input
          type="hidden"
          id={"slug"}
          name={"slug"}
          value={checkCostSlug()}
        />
      </>
    );
  };
  const renderLoadUnloadForm = () => {
    return (
      <>
        <div className="col-12 col-lg-6 ">
          <Label text={t("estimated-date")} noPadding>
            <InputDate
              readOnly={!checkdPermission}
              required
              placeholder={t("write-here")}
              value={state?.estimatedDate}
              handleOnChange={handleDate("estimatedDate")}
              languageCode={i18n.language}
              mode="underline"
            />
          </Label>
        </div>
        {/* <div className="col-12 col-lg-6">
          <Label text={t("time-range")} noPadding></Label>
          <div className={styles["sc-form--range"]}>
            <div>
              <span>{t("from")}</span>
              <DropDown
                readOnly={!checkdPermission}
                required
                placeholder={t("select-value")}
                options={getHoursForDropdown()}
                value={state?.estimatedSchedule?.from}
                handleItemClick={handleFromToClick("from")}
                mode="underline"
              />
            </div>
            <div>
              <span>{t("to")}</span>
              <DropDown
                readOnly={!checkdPermission}
                required
                placeholder={t("select-value")}
                options={getHoursForDropdown()}
                value={state?.estimatedSchedule?.to}
                handleItemClick={handleFromToClick("to")}
                mode="underline"
              />
            </div>
          </div>
        </div> */}
        {type === "load" && (
          <div className="col-12 col-lg-6">
            <Label text={`${t("net_weight")} (kg)`} noPadding>
              <InputNumber
                readOnly={!checkdPermission}
                min={0}
                step={".01"}
                placeholder={t("write-here")}
                value={state?.newWeight}
                onChange={handleOnChange("newWeight")}
                mode="underline"
                style={{ marginTop: "10px" }}
              />
            </Label>
          </div>
        )}
        <div className="col-12">{submitButton(true)}</div>
        <input
          type="hidden"
          id={"slug"}
          name={"slug"}
          value={checkLoadUnloadSlug()}
        />
      </>
    );
  };
  const renderForm = () => {
    switch (type) {
      case "cost":
        return renderCostForm();
      case "load":
      case "unload":
      case "boarding":
        return renderLoadUnloadForm();
    }
  };

  if (rawLoading) return <Loading />;

  if (onlyForm) {
    return (
      <form onSubmit={handleSubmit} className={styles["sc-form"]}>
        {renderTitle()}
        <Container style={{ paddingTop: "15px" }}>
          <div className="row">{renderForm()}</div>
        </Container>
      </form>
    );
  }

  return (
    <>
      <Paper
        whiteMode
        style={{
          alignItems: "stretch",
          marginLeft: "-20px",
          marginRight: "-20px",
          padding: "15px",
        }}
      >
        <form onSubmit={handleSubmit} className={styles["sc-form"]}>
          <TabPanelWrap title={renderTitle()} onClose={onClose}>
            <Container>
              <div className="row">{renderForm()}</div>
            </Container>
          </TabPanelWrap>
        </form>
      </Paper>
    </>
  );
};
export default TransportForm;
