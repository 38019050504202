import React, { useState } from "react";
import Header from "../../components/ui/header/Header";
import NavBar from "../../components/ui/navbar/NavBar";
import { useTranslation } from "../../utils/hooks";
import { useTableDataProvider } from "../../providers/TableContext";
import Filters from "../../components/ui/filters/Filters";
import SearchFilterExport from "../../components/ui/search-filter-export/SearchFilterExport";
import { useDocumentTitle } from "../../utils/document/hooks";
import {
  Container,
  Icon,
  TableManager,
  ClearButton,
  WARM_NEUTRAL_20,
  ERROR_20,
  WARNING_20,
  SUCCESS_20,
} from "@scrapadev/scrapad-front-sdk";
import {
  addressesEquipment,
  mapSlugToChip,
  modalTypes,
  QUERY_UPPER_LIMIT,
  TABLE_LIMIT,
} from "../../utils/variables";
import { useNavigate } from "react-router-dom";
import { useFiltersProvider } from "../../providers/filters/FiltersContext";
import ModalFactory from "../../components/ui/modal/ModalFactory";
import { useOperationsProvider } from "../../providers/organization/OperationsContext";
import { usePaginationProvider } from "../../providers/pagination/PaginationContext";
import LayoutTable from "../../layout/table/Table";
import { getOrganizations } from "../../model/tables";
import { useDateFilter, useMaterialsFilter } from "../../utils/filters/hooks";
import { businessVerificationItems } from "../../utils/filters/variables";
import { useTableActions, useTableConfig } from "../../utils/tables/hooks";
import { useSortProvider } from "../../providers/sort/SortContext";
import { applySortToFilters } from "../../utils/tables/functions";
import passkey from "../../assets/img/icons/passkey.png";

const splitValues = {
  verified: {
    color: SUCCESS_20,
    name: "verified",
    short_name: "",
  },
  pending: {
    color: WARNING_20,
    name: "pending",
    short_name: "",
  },
  not_started: {
    color: WARM_NEUTRAL_20,
    name: "not_started",
    short_name: "",
  },
  documentation_error: {
    color: ERROR_20,
    name: "documentation_error",
    short_name: "",
  },
  closed: {
    color: ERROR_20,
    name: "closed_payment_account",
    short_name: "",
  },
};

/**
 * Business page
 * @returns {JSX.Element}
 */
const InnerBusiness = () => {
  const { t, i18n, values } = useTranslation(
    ["common", "users", "transactions", "business", "verification"],
    true
  );
  const { data, setData, loading, setLoading, fetchData } =
    useTableDataProvider();
  const { getStoredFilters, buildFiltersAndFetch } = useFiltersProvider();
  const { handlePagination } = usePaginationProvider();
  const { state: sortState, handleSorting, buildSort } = useSortProvider();
  const navigate = useNavigate();
  const [uuidOrg, setUuidOrg] = useState("");
  const [blockOpen, setBlockOpen] = useState(false);
  const { canManageOrg } = useOperationsProvider();
  const filters = buildFiltersAndFetch(getStoredFilters() || {});
  const dateFilter = useDateFilter("createdAt", t("creation-date"));
  const materialsFilter = useMaterialsFilter(
    "materials",
    t("favorite-material")
  );
  const sortFields = buildSort(sortState);
  const filterVerificationItems = businessVerificationItems?.map((item) => ({
    uuid: item,
    name: t(item?.replace("_", "-")),
  }));

  const handleBlockBusiness = (e) => {
    setBlockOpen(!blockOpen);
  };

  const actions = [
    {
      component: (
        <ClearButton onClick={handleBlockBusiness}>
          <Icon
            name={"not_interested"}
            color={"#7E7E7E"}
            style={{ marginRight: "1rem" }}
          />
          <span>{t("block")}</span>
        </ClearButton>
      ),
    },
  ];

  useDocumentTitle(`${t("business")}`, values);

  useTableActions({
    paginationCallback: (paginationState) => {
      const newFilters = buildFiltersAndFetch(
        getStoredFilters() || {},
        undefined
      );
      fetchData({
        filter: applySortToFilters(
          newFilters,
          sortFields,
          "REACT_APP_SORT_BUSINESS"
        ),
        offset: paginationState?.current,
      });
    },
  });

  return (
    <>
      <NavBar />
      <Container isFluid={true}>
        <div className="row">
          <div className="col-12">
            <Header
              title={t("business")}
              titleRightComponent={
                <SearchFilterExport
                  filterKey="orgText"
                  searchPlaceholder={t("search")}
                  hasSeparator={false}
                  exportProps={{
                    fnData: getOrganizations,
                    fnParameters: {
                      locale: i18n.language,
                      limit: QUERY_UPPER_LIMIT,
                      offset: 0,
                      filter: applySortToFilters(
                        filters,
                        sortFields,
                        "REACT_APP_SORT_BUSINESS"
                      ),
                    },
                    fileName: "organizations",
                  }}
                />
              }
              subHeaderLeft={
                <Filters
                  initialFilters={{
                    orgText: {
                      external: true,
                    },
                    status: {
                      label: t("acc-status"),
                      multiple: true,
                      items: [
                        {
                          uuid: "incomplete-register",
                          name: t("incomplete-register"),
                        },
                        {
                          uuid: "unverified",
                          name: t("unverified"),
                        },
                        {
                          uuid: "active",
                          name: t("active"),
                        },
                        {
                          uuid: "banned",
                          name: t("blocked"),
                        },
                      ],
                    },
                    createdAt: dateFilter,
                    basicVerification: {
                      label: t("basic-verification"),
                      multiple: true,
                      items: filterVerificationItems,
                    },
                    fullVerification: {
                      label: t("full-verification"),
                      multiple: true,
                      items: filterVerificationItems,
                    },
                    materials: materialsFilter,
                    facilities: {
                      label: t("load-means"),
                      items: addressesEquipment.map((f) => ({
                        uuid: f.uuid,
                        name: t(f.label),
                      })),
                    },
                  }}
                />
              }
              fullSubHeaderLeft
              middleStyles={{
                marginBottom: 30,
              }}
              containerStyles={{
                paddingBottom: "15px",
              }}
            />
          </div>
        </div>
      </Container>
      <Container isFluid={true}>
        <div className="row">
          <div className="col-12">
            <TableManager
              loading={loading}
              data={data}
              setData={setData}
              emptyText={t("empty-org")}
              rowTriggerPageChange={true}
              navigate={(uuid) => {
                navigate(uuid);
              }}
              externalSort={
                JSON.parse(process.env.REACT_APP_SORT_BUSINESS)
                  ? handleSorting
                  : undefined
              }
              sorting={buildSort(sortState)}
              actions={canManageOrg ? (canManageOrg() ? actions : []) : actions}
              onClickActionCallback={(uuid) => setUuidOrg(uuid)}
              pagination={{
                currentPage: Math.ceil(data?.pagination?.current / TABLE_LIMIT),
                totalPages: Math.ceil(data?.pagination?.total / TABLE_LIMIT),
                threshold: 3,
                onChangePage: handlePagination,
                onChangePrev: handlePagination,
                onChangeNext: handlePagination,
              }}
              itemsProps={{
                chip: {
                  mapSlugValues: mapSlugToChip,
                  translate: (code) => t(code),
                },
                split: {
                  mapTransactionValues: splitValues,
                },
                date: {
                  languageData: i18n.getLanguageVariable(i18n.language),
                },
                icon: {
                  icon: () => <img src={passkey} />,
                  tooltip: () => t("managed_account"),
                },
              }}
            />
          </div>
        </div>
      </Container>
      {uuidOrg && (
        <ModalFactory
          type={modalTypes.BLOCK_ORG_WARNING}
          id={uuidOrg}
          fetchData={fetchData}
          loading={loading}
          setLoading={setLoading}
          open={blockOpen}
          setOpen={setBlockOpen}
        />
      )}
    </>
  );
};

const Business = () => {
  const { tableParams } = useTableConfig(
    {},
    {
      sortingFlag: "REACT_APP_SORT_BUSINESS",
    }
  );

  return (
    <LayoutTable fnData={getOrganizations} fnParameters={tableParams}>
      <InnerBusiness />
    </LayoutTable>
  );
};

export default Business;
