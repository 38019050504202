/**
 * Helper function to steps component in the business pages.
 * @param {String} id - Organization uuid
 * @returns {{label: String, to: String}[]}
 */
export const businessSteps = (id) => {
  return [
    { label: "general_information", to: `/business/${id}/about` },
    { label: "addresses", to: `/business/${id}/addresses` },
    { label: "users", to: `/business/${id}/users` },
    { label: "verification", to: `/business/${id}/verification` },
    { label: "ads", to: `/business/${id}/ads` },
    { label: "transactions", to: `/business/${id}/transactions` },
    { label: "favorites", to: `/business/${id}/favourites` },
    { label: "wallet", to: `/business/${id}/wallet` },
    { label: "configuration", to: `/business/${id}/preferences` },
  ];
};
