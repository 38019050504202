import {
  Chip,
  ERROR_COLOR,
  Flex,
  Padding,
  SUCCESS_20,
  Typography,
  WARM_NEUTRAL_40,
  WARM_NEUTRAL_80,
} from "@scrapadev/scrapad-front-sdk";
import CardBox from "../../../components/ui/box/CardBox";
import UsersIcon from "../../../components/ui/icons/general/User";
import InfoText from "../../../components/ui/text/InfoText";
import { useTranslation } from "../../../utils/hooks";
import { orgStatus } from "../../../utils/variables";
import WidgetBusinessEmpty from "./Empty";

const UserChip = ({ role, status }) => {
  if (!role.toLowerCase().includes("adm")) {
    return <></>;
  }
  if (status === orgStatus.BANNED) {
    return (
      <Chip
        small
        text={role}
        style={{
          backgroundColor: ERROR_COLOR,
          color: "white",
        }}
      />
    );
  }
  return (
    <Chip
      small
      text={role}
      style={{
        backgroundColor: SUCCESS_20,
        color: "white",
      }}
    />
  );
};

/**
 * Users business widget for dashboard.
 * @param {Object} props - Component properties.
 * @param {String} props.id - Business id.
 * @param {Object} props.state - Data state.
 * @returns {JSX.Element}
 */
const WidgetBusinessUser = ({ id, state }) => {
  const { t } = useTranslation(["common", "transactions"]);
  const isManagedUser = false;
  return (
    <CardBox
      title={t("users")}
      action={t("see-more")}
      href={`/business/${id}/users`}
      hasEmptyChild={isManagedUser}
      maxHeight={200}
    >
      {isManagedUser ? (
        <WidgetBusinessEmpty
          type={"users"}
          text={t("managed-account-without-users")}
          textStyle={{
            maxWidth: 190,
          }}
        />
      ) : (
        state?.usersByOrg?.map((user, index) => {
          const k = user.uuidPerson || index;
          const avatar = user?.avatar || "";
          const userName = user?.userName || "";
          const role = user?.role || "";
          const status = user?.status || "";
          return (
            <Padding style={{ padding: "0 10px 0 0" }}>
              <InfoText
                key={k}
                avatar={avatar}
                text={userName}
                color={
                  status === orgStatus.BANNED
                    ? WARM_NEUTRAL_40
                    : WARM_NEUTRAL_80
                }
                trailing={
                  <UserChip status={status} role={role} type={"info"} />
                }
              />
            </Padding>
          );
        })
      )}
    </CardBox>
  );
};

export default WidgetBusinessUser;
