import {
  CheckBox,
  Typography,
  WARM_NEUTRAL_50,
} from "@scrapadev/scrapad-front-sdk";
import { useTranslation } from "../../utils/hooks";
import ValidateGroup from "../../components/ui/input/validation/ValidateGroup";
import styles from "./Type.module.scss";
import React from "react";

/**
 * Helper widget to show ad or favorite type checboxes.
 * @param {Object} props - Component properties.
 * @param {Boolean} props.required - Is a required field.
 * @param {String} props.labelText - Label text.
 * @param {Boolean} props.isSaleChecked - Sale checkbox checked state.
 * @param {Boolean} props.isBuyChecked - Purchase checkbox checked state.
 * @param {Function} props.handleOnChecked - Checboxes handle function.
 * @param {Function} props.handleRequiredValue - Function to control the input hidden value use for valdiation.
 * @param {React.CSSProperties} [props.containerStyle={}] - Container inline styles.
 * @returns {JSX.Element}
 */
const WidgetAdOrSearchType = ({
  required,
  labelText,
  isSaleChecked,
  isBuyChecked,
  handleOnChecked,
  handleRequiredValue,
  containerStyle = {},
}) => {
  const { t } = useTranslation(["business", "validation"]);
  const requiredLabel = required ? " *" : "";
  return (
    <div className={styles["sc-ads--adtype"]} style={containerStyle}>
      <Typography typography="large" style={{ color: WARM_NEUTRAL_50 }}>
        {labelText + requiredLabel}
      </Typography>
      <div>
        <CheckBox
          checked={isSaleChecked}
          onChange={handleOnChecked("sale")}
          id="sellmaterial"
          text={t("buy-ad")}
        />
      </div>
      <div>
        <CheckBox
          checked={isBuyChecked}
          onChange={handleOnChecked("purchase")}
          id="buymaterial"
          text={t("sell-ad")}
        />
      </div>
      {required && (
        <ValidateGroup
          value={handleRequiredValue()}
          customValidityMessage={t("add-material-type")}
        />
      )}
    </div>
  );
};

export default WidgetAdOrSearchType;
