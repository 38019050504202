import {
  Flex,
  Chip,
  ClearButton,
  useMatchMedia,
  BREAKPOINT_SM,
  ChipDropdown,
  SCRAPBACK_BLUE_40,
} from "@scrapadev/scrapad-front-sdk/dist/index";
import React, { useEffect } from "react";
import { useFiltersProvider } from "../../../providers/filters/FiltersContext";
import { selectedOrgKey } from "../../../utils/variables";
import FilterGroupedChips from "./FilterChipsGrouped";
import FilterNested from "./FilterNested";
import styles from "./Filters.module.scss";
import FiltersSelectedBelow from "./FiltersSelectedBelow";
import FilterSelectionMultiple from "./Multiple";
import RestoreFilters from "./RestoreFilters";
import RestoreTable from "./RestoreTable";
import { useTableDataProvider } from "../../../providers/TableContext";
import ExportTable from "../table/Export";
import ExportData from "../export/Export";

/**
 * UI Filters component
 * @param {Object} props - Component properties
 * @param {boolean} [props.showChips=true] - Show selected filters as chips.
 * @param {boolean} [props.chipsBelowFilters=false] - Show selected filters all at the same tome below the filter components.
 * @param {object} props.initialFilters - The filters.
 * @param {Array} [props.deps=[]] - Mount useeffect dependencies. Useful when filters has to be loaded from network.
 * @param {string} [props.removeText=""] - Remove filters literal.
 * @param {Function} props.addFilter - Add filter function.
 * @param {Function} props.removeFilter - Remove filter function.
 * @param {Function} props.clearAll - Clear all filters function.
 * @param {string} props.uuidOrg - Org uuid. Used in some filters
 * @param {React.CSSProperties} [props.containerStyles={}] - Container inline styles.
 * @param {React.CSSProperties} [props.innerContainerStyles={}] - Inner container inline styles.
 * @param {boolean} [props.useRestoreTable=true] - -Show restore table button instead of restore filters.
 * @param {object} [props.filtersSelectionMultipleProps={}] - Props for selection multiple filters.
 * @param {object} [props.restoreProps={}] - Props for restore filters/table.
 * @param {boolean} [props.canDownload=false] - Download CSV button
 * @param {string} [props.fileName=""] - Name for downloable csv file.
 * @param {object} [props.exportProps={}] - Export data from network params
 * @returns {JSX.Element}
 */
const Filters = ({
  showChips = true,
  chipsBelowFilters = false,
  initialFilters,
  deps = [],
  removeText = "",
  addFilter,
  removeFilter,
  clearAll,
  uuidOrg,
  containerStyles = {},
  innerContainerStyles = {},
  useRestoreTable = true,
  filtersSelectionMultipleProps = {},
  restoreProps = {},
  canDownload,
  fileName,
  exportProps,
  type,
}) => {
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_SM}px)`);
  const {
    state: filters,
    dispatch,
    actions,
    getStoredFilters,
    filterChanged,
  } = useFiltersProvider();
  const { data } = useTableDataProvider();

  useEffect(() => {
    if (initialFilters) {
      let newFilters = {};
      const parsed = getStoredFilters();
      const hasDifferentUuidOrg =
        parsed?.[selectedOrgKey] && parsed?.[selectedOrgKey]?.data !== uuidOrg;
      Object?.keys(initialFilters)?.forEach((key) => {
        const initial = initialFilters[key];
        newFilters[key] = {
          slug: key,
          selected: hasDifferentUuidOrg
            ? []
            : parsed && parsed[key]?.selected?.length > 0
            ? parsed[key]?.selected
            : [],
          items: initial?.items || [],
        };
        if (initial.data) newFilters[key].data = initial.data;
        if (initial.label) newFilters[key].label = initial.label;
        if (
          typeof initial.multiple !== undefined &&
          typeof initial.multiple !== null
        )
          newFilters[key].multiple = initial.multiple;
        if (initial.subItems) newFilters[key].subItems = initial.subItems;
        if (initial.groupedChips)
          newFilters[key].groupedChips = initial.groupedChips;
        if (initial.external) newFilters[key].external = initial.external;
        if (initial.removeFilter)
          newFilters[key].removeFilter = initial.removeFilter;
        if (initial.addFilter) newFilters[key].addFilter = initial.addFilter;
        if (initial.ref) newFilters[key].ref = initial.ref;
        if (initial.catalogName)
          newFilters[key].catalogName = initial.catalogName;
        if (initial.dropDownProps)
          newFilters[key].dropDownProps = initial.dropDownProps;
        if (initial.externalComponent)
          newFilters[key].externalComponent = initial.externalComponent;
        if (initial.withSearch) newFilters[key].withSearch = initial.withSearch;
      });
      const finalFilters = { ...newFilters };
      if (uuidOrg) {
        finalFilters[selectedOrgKey] = {
          external: true,
          selected: [],
          data: uuidOrg,
        };
      }
      dispatch({
        type: actions.INITIAL_LOAD,
        payload: finalFilters,
      });
    }
  }, [...deps]);

  return (
    <>
      <Flex
        justifyContent="space-between"
        className={styles["sc-filters--container"]}
        style={containerStyles}
      >
        <Flex
          justifyContent="flex-start"
          style={{
            flexWrap: "wrap",
            maxWidth: matches ? "70%" : "80%",
            gap: 20,
            ...innerContainerStyles,
          }}
          className={styles["sc-filters"]}
        >
          {canDownload && (
            <>
              {exportProps ? (
                <ExportData
                  {...exportProps}
                  style={{
                    border: "1px solid rgb(207, 207, 207)",
                    padding: "15px 25px",
                    borderRadius: "50px",
                  }}
                />
              ) : (
                <ExportTable
                  data={data}
                  style={{
                    border: "1px solid rgb(207, 207, 207)",
                    padding: "15px 25px",
                    borderRadius: "50px",
                  }}
                  fileName={fileName}
                />
              )}
            </>
          )}
          {filters ? (
            Object.keys(filters)?.map((key) => {
              const filter = filters[key];
              if (filter?.external) return <></>;
              return (
                <Flex
                  className={styles["sc-filter"]}
                  key={key}
                  justifyContent={matches ? "flex-start" : "center"}
                  style={{
                    gap: matches ? 10 : 0,
                    flexWrap: matches ? "wrap" : "nowrap",
                  }}
                  data-cy={filter.slug}
                >
                  {filter?.externalComponent ? (
                    <ChipDropdown
                      text={
                        filter.label && typeof filter.label === "function"
                          ? filter.label(filter)
                          : filter?.label
                      }
                      customRenderItem={(
                        item,
                        openDropdown,
                        setOpenDropdown
                      ) => {
                        return (
                          <ClearButton
                            style={{
                              padding: 0,
                            }}
                            onClick={(e) => {
                              if (filter?.multiple) {
                                e.stopPropagation();
                              }
                              if (filter.addFilter) {
                                filter.addFilter(item, filter);
                              } else {
                                if (addFilter && !item.component) {
                                  addFilter(item, filter);
                                }
                              }
                            }}
                          >
                            {item.component}
                          </ClearButton>
                        );
                      }}
                      items={filter?.items}
                      {...(filter.dropDownProps ? filter.dropDownProps : {})}
                    />
                  ) : filter?.subItems ? (
                    <FilterNested filter={filter} addFilter={addFilter} />
                  ) : (
                    <FilterSelectionMultiple
                      filter={filter}
                      addFilter={addFilter}
                      type={type}
                      withSearch={filter?.withSearch}
                      groupedStyle={
                        filter?.groupedChips
                          ? {
                              backgroundColor: SCRAPBACK_BLUE_40,
                              color: "#fff",
                            }
                          : {}
                      }
                      {...filtersSelectionMultipleProps}
                    />
                  )}
                  {showChips && !chipsBelowFilters && (
                    <Flex
                      justifyContent="flex-start"
                      flexWrap="wrap"
                      style={{ flexWrap: "wrap", gap: 10 }}
                      className={styles["sc-filter--selected"]}
                    >
                      {filter?.groupedChips ? (
                        <FilterGroupedChips filter={filter} />
                      ) : (
                        filter?.selected?.map((selected) => {
                          return (
                            <Chip
                              key={`${key}-${selected?.uuid}`}
                              type="outlined"
                              text={selected?.name}
                              showIcon
                              handleClick={() => {
                                if (filter.removeFilter) {
                                  filter.removeFilter(
                                    selected,
                                    filter,
                                    dispatch,
                                    actions,
                                    filterChanged
                                  );
                                } else {
                                  if (removeFilter)
                                    removeFilter(selected, filter);
                                  else {
                                    filterChanged.current = true;
                                    dispatch({
                                      type: actions.REMOVE_FILTER,
                                      payload: { item: selected, filter },
                                    });
                                  }
                                }
                              }}
                            />
                          );
                        })
                      )}
                    </Flex>
                  )}
                </Flex>
              );
            })
          ) : (
            <></>
          )}
        </Flex>
        {useRestoreTable ? (
          <RestoreTable
            removeText={removeText}
            clearAll={clearAll}
            {...restoreProps}
          />
        ) : (
          <RestoreFilters
            removeText={removeText}
            clearAll={clearAll}
            {...restoreProps}
          />
        )}
      </Flex>
      {showChips && chipsBelowFilters && (
        <FiltersSelectedBelow removeFilter={removeFilter} />
      )}
    </>
  );
};

export default Filters;
