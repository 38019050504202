import deepmerge from "deepmerge";
import { actions } from "../../../providers/data/actions/data";

export const dispatchAction = (index, key, value, state, dispatch) => {
  const newState = deepmerge([], state?.getOrgLocations);
  newState[index][key] = value;
  dispatch({
    type: actions.UPDATE_INPUT,
    payload: { getOrgLocations: newState },
  });
};

export const dispatchActionMultipleKey = (
  index,
  key = [],
  value,
  state,
  dispatch
) => {
  const newState = deepmerge([], state?.getOrgLocations);
  key.forEach((k) => {
    newState[index][k] = value;
  });

  dispatch({
    type: actions.UPDATE_INPUT,
    payload: { getOrgLocations: newState },
  });
};

export const cleanAddressState = (
  index,
  keys = [],
  values = [],
  state,
  dispatch
) => {
  const newState = deepmerge([], state?.getOrgLocations);
  keys.forEach((key, i) => {
    newState[index][key] = values[i];
  });

  dispatch({
    type: actions.UPDATE_INPUT,
    payload: { getOrgLocations: newState },
  });
};

export const handleOnChange = (index, key, state, dispatch) => (e) => {
  dispatchAction(index, key, e.target.value, state, dispatch);
};

export const handlePrfixChange =
  (index, key, state, dispatch) => (code, country, e) => {
    const value = `${country?.format?.substring(0, 1)}${
      country?.dialCode || code
    }`;
    dispatchAction(index, key, value, state, dispatch);
  };

export const buildAddresData = (data) => {
  if (!data?.port || data?.port?.uuid === "") {
    return {
      ...data,
      port: data?.port?.uuid,
    };
  }

  return {
    alias: data?.alias || "",
    isCollectionDefault: data?.isCollectionDefault || false,
    isDeliveryDefault: data?.isDeliveryDefault || false,
    type: data?.type || "both",
    port: data?.port?.uuid,
    countryCode: 0,
  };
};

export const checkIfAddressIsDirty = (
  address,
  index,
  bufferState,
  checkIfStateIsDirty
) => {
  if (!address?.port || address?.port?.uuid === "") {
    return checkIfStateIsDirty(
      address,
      bufferState.current?.getOrgLocations[index]
    );
  }
  const toCompareAddress = {
    port: address?.port?.uuid,
    alias: address?.alias,
    isCollectionDefault: address?.isCollectionDefault,
    isDeliveryDefault: address?.isDeliveryDefault,
    selectedPort: address?.selectedPort,
    type: address?.type,
  };
  const toCompareBuffer = {
    port: bufferState.current?.getOrgLocations[index]?.port?.uuid,
    alias: bufferState.current?.getOrgLocations[index]?.alias,
    isCollectionDefault:
      bufferState.current?.getOrgLocations[index]?.isCollectionDefault,
    isDeliveryDefault:
      bufferState.current?.getOrgLocations[index]?.isDeliveryDefault,
    selectedPort: bufferState.current?.getOrgLocations[index]?.selectedPort,
    type: bufferState.current?.getOrgLocations[index]?.type,
  };
  return checkIfStateIsDirty(toCompareAddress, toCompareBuffer);
};
