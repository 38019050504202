import { useNavigate } from "react-router-dom";
import LayoutTable from "../../layout/table/Table";
import { useTranslation } from "../../utils/hooks";
import {
  useNewFilters,
  useOpportunitiesNewActions,
} from "../../utils/opportunities/hooks";
import { useTableConfig } from "../../utils/tables/hooks";
import { tableModules, TABLE_LIMIT } from "../../utils/variables";
import WidgetOpportunitiesLayout from "./OpportunitiesLayout";
import { getTable } from "../../model/tables";
import { useUser } from "../../providers/AuthHooks";
import _ from "lodash";
import { getRoomSlug, WS_ROOM } from "../../utils/websockets/functions";
import WebsocketsWrapper from "../websockets/Wrapper";
import { useTableDataProvider } from "../../providers/TableContext";

/**
 * Table of new opportunities.
 * @param {object} props - Component properties.
 * @param {object} baseTableParams - Table params.
 * @returns {JSX.Element}
 */
const InnerWidgetOpportunitiesNew = ({ baseTableParams }) => {
  const navigate = useNavigate();
  const { deps, initialFilters } = useNewFilters();
  const user = useUser();
  const { fetchData } = useTableDataProvider();
  const { onRowClick, selectedRow } = useOpportunitiesNewActions();
  return (
    <div data-cy="opportunities-new-table">
      <WidgetOpportunitiesLayout
        filterProps={{
          deps,
          initialFilters,
        }}
        tableProps={{
          baseTableParams,
          onRowClick,
          selectedRow,
        }}
      />
      {user && (
        <WebsocketsWrapper
          callback={async (data) => {
            if (
              !_.isEmpty(data) &&
              data?.subscribeRoom?.type === WS_ROOM.opportunity
            ) {
              await fetchData();
            }
          }}
          room={getRoomSlug("/opportunities")}
        />
      )}
    </div>
  );
};

const WidgetOpportunitiesNew = () => {
  const { i18n } = useTranslation();
  const baseParams = {
    locale: i18n.language,
    limit: TABLE_LIMIT,
    tableModule: tableModules.OPPORTUNITIES_NEW,
  };
  const { tableParams } = useTableConfig(baseParams);

  return (
    <LayoutTable fnData={getTable} fnParameters={tableParams}>
      <InnerWidgetOpportunitiesNew baseTableParams={baseParams} />
    </LayoutTable>
  );
};

export default WidgetOpportunitiesNew;
