import { Box } from "@scrapadev/scrapad-front-sdk";
import Link from "../link/Link";
import styles from "./CardBox.module.scss";

/**
 * UI Cardbox component.
 * @param {Object} props - Component properties.
 * @param {String} props.title - Card title.
 * @param {String} [props.action] - Action text.
 * @param {Function} [props.href] - url to navigate.
 * @returns {JSX.Element}
 */
const CardBox = ({
  title,
  action,
  href,
  hasOverflow = true,
  maxHeight = 300,
  hasEmptyChild = false,
  children,
}) => {
  const scroll = hasOverflow ? { overflowY: "auto", maxHeight: maxHeight } : {};
  const emptyChildStyles = hasEmptyChild
    ? {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexGrow: 1,
      }
    : {};
  return (
    <Box
      style={{
        paddingTop: 20,
        paddingLeft: 15,
        paddingRight: 15,
        paddingBottom: 20,
        height: "100%",
      }}
    >
      <div className={styles["sc-cardbox"]}>
        <div className={styles["sc-cardbox--header"]}>
          {title && <h3 className={styles["sc-cardbox--title"]}>{title}</h3>}
          {action && href && (
            <Link
              href={href}
              style={{
                fontWeight: 600,
              }}
            >
              {action}
            </Link>
          )}
        </div>
        <div
          className={styles["sc-cardbox--content"]}
          style={{ ...scroll, ...emptyChildStyles, overflowX: "hidden" }}
        >
          {children}
        </div>
      </div>
    </Box>
  );
};

export default CardBox;
