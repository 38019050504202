import { useTranslation } from "../../../utils/hooks";
import {
  BREAKPOINT_LG,
  Modal,
  useMatchMedia,
  Container,
} from "@scrapadev/scrapad-front-sdk/dist/index";
import ModalText from "../../ui/modal/text/ModalText";

/**
 * Modal for user information.
 * @param {Object} props - Component properties.
 * @param {Object} [props.open=false] - Open/close modal status.
 * @param {Function} props.setOpen - Setter function to open / close.
 * @returns {JSX.Element}
 */
const ModalWalletActions = ({ open = false, setOpen, ...props }) => {
  const {
    onSubmit,
    title,
    description,
    okText,
    uuid,
    fetchData,
    uuidOrg,
    loading,
    setLoading,
    iban_id,
  } = props;
  const { t } = useTranslation(["common"], true);
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_LG}px)`);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (onSubmit)
      onSubmit(uuid, iban_id, setOpen, fetchData, uuidOrg, loading, setLoading);
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title={title}
      hasForm={true}
      onSubmit={handleSubmit}
      submitText={okText ? okText : t("save-changes")}
      cancelText={t("cancel")}
      maxWidth={650}
      loading={loading}
      modalStyles={{ width: matches ? "auto" : 650 }}
      submitButtonMode={"outline-info"}
      BodyComponent={
        <Container>
          <div className="row">
            <div className="col-12">
              <ModalText style={{ marginBottom: 0 }} text={description} />
            </div>
          </div>
        </Container>
      }
    />
  );
};

export default ModalWalletActions;
