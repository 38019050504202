import {
  BLUE_60,
  Flex,
  Modal,
  Row,
  Typography,
} from "@scrapadev/scrapad-front-sdk";
import React from "react";
import GreenDot from "../../ui/icons/general/GreenDot";
import { useTranslation } from "../../../utils/hooks";

function RolesInfo({ open, setOpen }) {
  const { t } = useTranslation("common");

  return (
    <Modal
      t={t}
      open={open}
      setOpen={setOpen}
      title={"Roles"}
      hasForm={false}
      hasActions={false}
      onSubmit={() => {}}
      submitText={t("save")}
      loading={false}
      maxWidth={700}
      BodyComponent={
        <>
          <Flex
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            <Typography>{t("roles_info_title")}</Typography>
            <Row gap={10} align="center">
              <GreenDot color={BLUE_60} />
              <Typography style={{ fontWeight: "bold", margin: 0 }}>
                {t("roles_info_admin_title")}
              </Typography>
            </Row>
            <Typography>{t("roles_info_admin_desc")}</Typography>
            <Row gap={10} align="center">
              <GreenDot color={BLUE_60} />
              <Typography style={{ fontWeight: "bold", margin: 0 }}>
                {t("roles_info_user_title")}
              </Typography>
            </Row>
            <Typography>{t("roles_info_user_desc")}</Typography>
          </Flex>
        </>
      }
    />
  );
}

export default RolesInfo;
