import NavBar from "../../components/ui/navbar/NavBar";
import {
  Breadcrumbs,
  Flex,
  Heading,
  List,
  ListItem,
  Row,
  Icon,
  Spacer,
  Switch,
  Typography,
  Spinner,
  Center,
  Padding,
  ClearButton,
  useMatchMedia,
  BREAKPOINT_SM,
  BREAKPOINT_LG,
  SCRAPBACK_BLUE_40,
  WARM_NEUTRAL_30,
  toastWarning,
} from "@scrapadev/scrapad-front-sdk";
import { useTranslation } from "../../utils/hooks";
import Container from "../../components/ui/container/Container";
import BackButtonWrap from "../../components/wrappers/back-button/BackButtonWrap";
import { useNotificationProvider } from "../../providers/notifications/NotificationsContext";
import { useEffect, useState } from "react";
import ModalFactory from "../../components/ui/modal/ModalFactory";
import styles from "./Preferences.module.scss";
import { TEXT_COLOR_LIGHT } from "@scrapadev/scrapad-front-sdk";
import NotificationsList from "../../components/ui/notifications/List";
import NotificationsListItem from "../../components/ui/notifications/ListItem";

function SwitchHandler({ name, checked, onChange, readOnly }) {
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_SM}px)`);
  return (
    <Flex
      flexDirection={matches ? "row" : "column"}
      justifyContent={matches ? "space-between" : "center"}
      style={{ gap: 20, width: "100%" }}
    >
      <Typography small reduced type="2" style={{ marginBottom: 0 }}>
        {name}
      </Typography>
      <Switch onChange={onChange} checked={checked} readOnly={readOnly} />
    </Flex>
  );
}

function PreferencesEntry() {
  const { t, i18n, values } = useTranslation("notifications");
  const { state, updateChannel, updatePreferencesCall } =
    useNotificationProvider();
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_SM}px)`);

  useEffect(() => {
    updatePreferencesCall(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    document.title = `${t("preferences")} - ${
      process.env.REACT_APP_SCRAPAD_APP_NAME
    }`;
  }, [values]);

  const handleTogglePreference = (channel) => (e) => {
    const checked = e.target.checked;
    checked
      ? updateChannel(t, i18n.language, {
          uuidCategory: channel.uuidCategory,
          uuidChannel: channel.uuidChannel,
        })
      : updateChannel(t, i18n.language, { selected: channel.selected }, "off");
  };

  return (
    <div className={styles["sc-preferences--entry"]}>
      <Container>
        {state.isLoading ? (
          <Padding>
            <Center>
              <Spinner />
            </Center>
          </Padding>
        ) : (
          state?.preferences?.length > 0 && (
            <NotificationsList>
              {state.preferences.map((preference, index) => {
                const itemsLength = state.preferences.length;
                return (
                  <>
                    <NotificationsListItem
                      title={preference?.categoryName}
                      description={preference?.categoryDesc}
                      reduceOnMobile={false}
                      style={{
                        padding: "32px 24px",
                      }}
                      sidebar={
                        <>
                          <Flex
                            flexDirection={matches ? "column" : "row-reverse"}
                            alignItems={matches ? "flex-end" : "center"}
                            style={{ gap: "70px" }}
                          >
                            {preference?.channelsPeferences?.map((channel) => {
                              return (
                                <SwitchHandler
                                  name={channel?.channelName}
                                  checked={channel.selected}
                                  readOnly={
                                    channel.channelName.toLowerCase() === "web"
                                  }
                                  onChange={
                                    channel.channelName.toLowerCase() === "web"
                                      ? () => toastWarning(t("web_warning"))
                                      : handleTogglePreference(channel)
                                  }
                                />
                              );
                            })}
                          </Flex>
                        </>
                      }
                    />
                  </>
                );
              })}
            </NotificationsList>
          )
        )}
      </Container>
    </div>
  );
}

const Preferences = () => {
  const [isPreferencesOpen, setIsPreferencesOpen] = useState(false);
  const { t } = useTranslation("notifications");
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_SM}px)`);
  const matchesLg = useMatchMedia(`(max-width: ${BREAKPOINT_LG}px)`);
  return (
    <>
      <NavBar />
      <main>
        <Container>
          {!matches && (
            <Breadcrumbs
              style={{ color: "#7E7E7E" }}
              startSidebar={<BackButtonWrap />}
              breadcrumbs={[t("notifications"), t("preferences_notifications")]}
            />
          )}
        </Container>
        <Container>
          <Heading style={{ marginBottom: 40 }}>
            {t("notification_preferences")}
          </Heading>
          <Row
            style={{
              width: "100%",
              flexDirection: matchesLg ? "column" : "row",
              marginBottom: 40,
            }}
            justify="space-between"
            align={matchesLg ? "flex-start" : "center"}
            gap={15}
          >
            <Typography typography="large" style={{ marginBottom: 0 }}>
              {t("select_channels_intro")}
            </Typography>
            <div className={styles["sc-prefrences--more"]}>
              <ClearButton
                onClick={() => {
                  setIsPreferencesOpen(!isPreferencesOpen);
                }}
              >
                <Icon name="info" color={SCRAPBACK_BLUE_40} />
                <Typography
                  typography="med-med"
                  style={{
                    marginBottom: 0,
                    color: SCRAPBACK_BLUE_40,
                    fontWeight: 600,
                    textDecoration: "underline",
                  }}
                >
                  {t("channels_more")}
                </Typography>
              </ClearButton>
            </div>
          </Row>
        </Container>
        <Spacer height={20} />
        <PreferencesEntry />
      </main>
      <ModalFactory
        type="notifications-help"
        open={isPreferencesOpen}
        setOpen={() => setIsPreferencesOpen(false)}
      />
    </>
  );
};

export default Preferences;
