import { Center, Empty } from "@scrapadev/scrapad-front-sdk";
import { useState } from "react";
import { useTranslation } from "../../../utils/hooks";
import WidgetWalletAccount from "./Account";
import WidgetWalletAccountsEdit from "./Edit";

const WidgetWalletWithdrawAccounts = ({
  state,
  isEdit,
  setIsEdit,
  permission,
  fetchData,
}) => {
  const { t } = useTranslation(["common"]);
  const [uuidEdit, setUuidEdit] = useState(null);
  if (!state?.moneyOutAccount)
    return (
      <Center>
        <Empty
          spacerHeight={10}
          heading={t("not-accounts-saved")}
          icon="bo"
          iconWidth={175}
          iconHeight={175}
        />
      </Center>
    );

  if (isEdit)
    return (
      <WidgetWalletAccountsEdit
        account={state?.moneyOutAccount}
        permission={permission}
        fetchData={fetchData}
        setIsEdit={setIsEdit}
      />
    );
  return (
    <>
      <WidgetWalletAccount
        uuid={state?.moneyOutAccount?.uuid}
        swift={state?.moneyOutAccount?.bicCode}
        iban={state?.moneyOutAccount?.viban}
        address={state?.moneyOutAccount?.address}
        files={state?.moneyOutAccount?.urlVIBANUser}
        holder={state?.moneyOutAccount?.holder}
        status={state?.moneyOutAccount?.status}
        key={state?.moneyOutAccount?.uuid}
        fetchData={fetchData}
        canEdit={true}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        setUuidEdit={setUuidEdit}
        permission={permission}
      />
    </>
  );
};

export default WidgetWalletWithdrawAccounts;
