import { useTranslation } from "../../../utils/hooks";
import WidgetFieldFiles from "../../fields/Files";
import InputFileWrapper from "../../../components/ui/input/file/Wrapper";
import { handleUploadWidgetFiles } from "../../../utils/functions";

const WidgetVerificationFilesLocal = ({
  state,
  dispatch,
  actions,
  hideControls,
  updateStateCallback,
  afterUploadInterceptor,
  setLoading,
  onDelete,
  required = false,
  disabled,
  documentTypeName,
  extraData = {},
  permission = () => false,
}) => {
  const { t } = useTranslation(["common"]);

  const handleAfterUpload = async (files) => {
    const resultFiles = await files;
    if (afterUploadInterceptor) {
      await afterUploadInterceptor(resultFiles, setLoading);
    }
    handleUploadWidgetFiles(resultFiles, state, dispatch, actions);
    if (updateStateCallback) updateStateCallback(state);
  };

  return (
    <>
      <div>
        <InputFileWrapper
          placeholder={t("select-drop-files")}
          dropPlaceholder={t("drop-files")}
          iconStyles={{}}
          afterUpload={handleAfterUpload}
          allowedExtension={[".pdf", ".jpeg", ".jpg", ".png"]}
          fileType={"kyc"}
          translation={{
            uploading: t("uploading"),
          }}
          readOnly={permission ? (permission() ? !permission() : true) : false}
        />
      </div>
      <WidgetFieldFiles
        id={extraData?.id}
        fetchData={extraData?.fetchData}
        setLoading={extraData?.setLoading}
        permission={permission}
        files={state?.files}
        dispatch={dispatch}
        actions={actions}
        hideControls={hideControls || !permission()}
        onDelete={onDelete}
        documentTypeName={documentTypeName}
      />
    </>
  );
};

export default WidgetVerificationFilesLocal;
