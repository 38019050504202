import { Label } from "@scrapadev/scrapad-front-sdk";
import InputCountries from "../../../components/ui/input/country/Country";
import { useTranslation } from "../../../utils/hooks";
import { dispatchAction } from "./functions";

/**
 * Helper component for address countries.
 * @param {Object} props - Component properties.
 * @param {Object} props.address - Address info.
 * @param {number} props.index - Index of current address.
 * @param {Object} props.state - Addresses state.
 * @param {Function} props.dispatch - Dispatch function.
 * @returns {JSX.Element}
 */
const WidgetAddressCountries = ({
  address,
  index,
  state,
  dispatch,
  permissionManage,
  ...props
}) => {
  const { t } = useTranslation(["common"]);

  return (
    <>
      <Label text={`${t("country")}`} noPadding>
        <InputCountries
          handleItemClick={(option, open, setOpen) => {
            const countryId = option.uuid.split("-")[0];
            dispatchAction(
              index,
              "countryCode",
              parseInt(countryId),
              state,
              dispatch
            );
            setTimeout(() => {
              setOpen(false);
            }, 5);
          }}
          value={address?.["countryCode"]}
          readOnly={permissionManage ? !permissionManage() : false}
          skipRequiredHelp
          {...props}
        />
      </Label>
    </>
  );
};

export default WidgetAddressCountries;

