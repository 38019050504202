import { gql } from "@apollo/client";
import _ from "lodash";
import { getGQLData } from "../utils/gqlbase";
import client from "../utils/gqlclient";
import { postClient } from "../utils/restclient";

export async function searchPublicAds(locale, term) {
  const data = await client.query({
    query: gqlSearchAd,
    variables: { lang: locale, term: term, offset: 0, limit: 10 },
  });
  return (data.data.searchByTerm || []).map((ad) => {
    return {
      isAd: ad.isAd,
      text: ad.text,
      uuid: ad.uuid,
    };
  });
}

export async function getCategoriesByObject({ locale }) {
  const data = await client.query({
    query: gqlGetCategories,
    variables: { lang: locale },
  });
  return {
    categories: (data.data.materialFamily || []).map((cat) => {
      return {
        name: cat.name,
        uuid: cat.uuid,
      };
    }),
  };
}

export async function getCategories(locale) {
  const data = await client.query({
    query: gqlGetCategories,
    variables: { lang: locale },
  });
  return (data.data.materialFamily || []).map((cat) => {
    return {
      name: cat.name,
      uuid: cat.uuid,
    };
  });
}

export async function getMaterials(locale, uuidFamily) {
  const data = await client.query({
    query: gqlGetMaterials,
    variables: { lang: locale, uuidFamily: uuidFamily },
  });
  return (data.data.materialComposition || []).map((mat) => {
    return {
      name: mat.name,
      uuid: mat.uuid,
    };
  });
}

export async function getType(locale, uuidFamily, uuidComposition) {
  const data = await client.query({
    query: gqlGetTypes,
    variables: {
      lang: locale,
      uuidFamily: uuidFamily,
      uuidComposition: uuidComposition,
    },
  });
  return (data.data.materialType || []).map((type) => {
    return {
      name: type.name,
      uuid: type.uuid,
    };
  });
}

export async function getAd(locale, adUuid) {
  const data = await client.query({
    query: gqlGetAd,
    variables: {
      lang: locale,
      uuid: adUuid,
    },
  });

  return data.data.getAd;
}

export async function getAdForCurrentTransactions({ locale, adUuid }) {
  const data = await client.query({
    query: gqlGetAdForCurrentTransactions,
    variables: {
      lang: locale,
      uuid: adUuid,
    },
  });

  return data.data.getAd;
}

export async function markAsFavorite(adUuid, active = false, authToken = "") {
  try {
    const response = await postClient({
      localhost: true,
      method: active ? "POST" : "DELETE",
      url: "/v2/api/internal/fav-ad",
      body: {
        uuidAd: adUuid,
      },
      auth: authToken,
    });
    return response;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function getEditAd({ locale, uuidAd }) {
  const data = await client.query({
    query: gqlGetEditAd,
    variables: {
      lang: locale,
      uuidAd,
    },
  });

  return {
    ...data?.data?.getAdForEdit,
    facilities: data?.data?.getAdForEdit?.address?.facilities || [],
  };

  /*const data = await Promise.resolve({
    data: {
      getAd: {
        status: "published",
        updateadAt: 12235436536346,
        views: 13,
        likes: 4,
        transactionsActive: 0,
        uuidOrg: "e1dd1baf-b8c4-43ab-becd-5137dfa61b59",
        type: "purchase",
        title: "titulo test",
        materialType: "50e8bd34-b22d-4fb5-b8c1-45b43b46661e",
        amount: "24",
        amountScale: "165bbc20-50ba-4316-871d-8a0e9e8e4b7f",
        price: "233",
        currency: "EUR",
        components: [
          { type: "%LME", operator: "less-than-or-equal", value: "3" },
          { type: "%LME", operator: "greater-than", value: "35" },
        ],
        lerCodes: [],
        description: "description text",
        images: [
          {
            name: "caracteristicas-metales.jpg",
            uuid: "b17548f6-7555-459e-982c-979e366083ee",
            url: "https://scrapad-dev-env.s3.amazonaws.com/private/ad/b17548f6-7555-459e-982c-979e366083ee?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIA2BKJCYFID3BB7SJ2%2F20230224%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20230224T174421Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEGwaCWV1LXdlc3QtMSJHMEUCIQDJVH7defBRFvpwArrQ1doideqdmykCKoRaD38tiaLYGQIgBRQM0fFYOQ11w3ka9R%2FCygtX3jcNapfIar4aL9tbAc8qzAQIFRABGgw2OTAwMzIzOTQ1NzYiDF92RTirXughDByXMiqpBFc8b3bGE3cNhCQXSYmfPI9Su%2FfuMQh26EPLeeoxjFq0YyEprhVAc1byyHV3W%2F2xvA5ennQQY%2F0DreL74WNaPSYJFizJcfRz%2FIuxQF4IpHAv0oOL0ZbDHitgqDZ0gdefsiDtEv8VkE%2FOB9ejkbik5ACTc%2BX8XChc45ASZOdQ1%2FjAb9SNZf3PPsR3zIeP6mJ1uRv09kP6ab4y%2B0Kk4u5qeWtiwsE%2BI5gqD2fCbFzRLn%2BH2zUoB3mdz68Pzed6qjYKh3MleU7Ctr%2FbI9iS25XG1o2knsOyP5iWWXfhHbUTSH52UI5Lf2LmEt1xvywSYxlt4LSyvnAj%2FNMYxNhde71Kiwo0st5%2FlhGdM5J8roRqyMQHg%2BfV4zaiqCEbdAvn9hXEdjb3VDvN%2BE%2BY2ieG1A6K9OFbBL%2BGqhaBM9W%2Bf9fyB1sBP18iLoCFDvM%2B6EFOMRoYJqRQMFAE24mmqKyBz3KpHnXxTlE%2FJ4PoKwvuJxaSoh2%2FIYqdJTqw97W1zpQUZtC95Itms1aBvyo0LpNE7Y3AXwBYxWXLHApgaSwzd2qlF8f1LmfUX0zZGRqJTAaE%2BKaVamXlCC093bBemgNCFb7XsKeFIEOLa5PtG%2BKvUoYLb5Dg3%2FoiMCQK%2FNTB01r%2FPelwVCyxC3EaVKG9hYJh4tstfgHTcYE1JbShrf79gpjU%2BJpAbtBO%2FgBzRo5i6aE0chZcYGv8zse4cDkCQ2anzWS2jdkEHSMn%2FfR9U4kw8sjinwY6qQGJNhnQHpcGoq5jwpxaFWx4JDOVP3vZ%2FCcCBcv0Fa0n6aMNw9FCGiQuCKa89PfRSnL32CASpldvRblJsenAdWLsDuM%2BwU9HaP67IMLmeBNRp8BhNV%2BmoD4AlmqBQFlrlK%2B6K6kkYgOuDuUt43dH7X6hqbBcY64UGYxhFjmSOdlL0MIFyDBNi6tMbLJin67aOY%2Bew8gRvu%2B48QXcqPZ2EF%2B1jrLlCkM11PA1&X-Amz-Signature=879c4baefb1509d024e29871f0704db7eac95ce46460cf19c6ddbb43c84bd118",
          },
        ],
        address: { uuid: "03bc0527-ebb1-43d6-8ceb-ccc65625c82f" },
        loadCharacteristics: [
          {
            type: "sacas",
            height: "23",
            width: "32",
            depth: "235",
            weight: "32",
          },
          {
            type: "palet",
            height: "23",
            width: "235",
            depth: "235",
            weight: "235",
          },
        ],
        materialQuestions: [
          {
            uuidQuestion: "1",
            label: "Límete de hierro (Fe)",
            type: "integer",
            value: ["12"],
          },
          {
            uuidQuestion: "2",
            label: "Límite de zinc (Zn)",
            type: "percentage",
            value: ["124"],
          },
        ],
      },
    },
  });*/
  //return data.data.getAd;
}

export async function getLerCodes({ locale, pattern }) {
  const data = await Promise.resolve({
    codes: ["1701", "1702", "1703", "1704", "1705"],
  });

  return data;
}

export async function getAdOrgUuid({ locale, org }) {
  const widget = await getGQLData(gqlGetAdOrgUuid, {
    lang: locale,
    uuidOrg: org,
  });
  return widget?.getOrgData;
}

export async function getMaterialQuestions({ locale, uuid }) {
  // type: "multiple-choice | yes-no | percentage | integer | single-choice",
  if (_.isEmpty(uuid)) {
    return [];
  }

  const data = await client.query({
    query: gqlGetMaterialQuestions,
    variables: {
      lang: locale,
      uuid: uuid,
    },
  });
  return data.data.materialQuestions;
}

export async function getAllAdsEdit({
  locale,
  offset = 0,
  limit = 12,
  filter,
}) {
  const variables = {
    lang: locale,
    offset: offset * limit,
    limit: limit,
  };
  if (filter) {
    variables.filter = filter;
  }
  const data = await client.query({
    query: gqlGetAllAdsEdit,
    variables: variables,
  });
  return {
    pagination: {
      current: data.data.getAllAdsEdit.pagination.current,
      total: data.data.getAllAdsEdit.pagination.total,
    },
    ads: (data.data.getAllAdsEdit.ads || []).map((ad) => {
      return {
        slug: ad.uuid,
        title: ad.title,
        description: ad.description,
        adType: ad.type,
        price: ad.price,
        currency: ad.currency,
        adImage: ad?.files?.[0]?.location,
        amount: ad.amount,
        materialComposition: ad?.materialComposition,
        materialType: ad?.materialType,
        status: ad.status,
        transactionsActive: ad.transactionsActive,
        isriCode: ad?.isriCode,
        frequency: ad?.frequency,
      };
    }),
  };
}

export async function getAdTranslations({ uuid }) {
  const data = await client.query({
    query: gqlGetTranslations,
    variables: {
      uuid: uuid,
    },
  });
  return data.data.getAdTranslations;
}

const gqlGetTranslations = gql`
  query ($uuid: String!) {
    getAdTranslations(uuidAd: $uuid) {
      code
      desc
      title
    }
  }
`;

const gqlGetMaterialQuestions = gql`
  query ($lang: String!, $uuid: String!) {
    materialQuestions(uuidMaterial: $uuid, lang: $lang) {
      label
      type
      uuidQuestion
      value
      options {
        label
        uuid
      }
    }
  }
`;

const gqlGetAdOrgUuid = gql`
  query ($lang: String!, $uuidOrg: UUID!) {
    getOrgData(lang: $lang, uuidOrg: $uuidOrg) {
      verificationState
      basicData {
        orgName
      }
    }
  }
`;

const gqlGetAd = gql`
  query ($lang: String!, $uuid: UUID!) {
    getAd(lang: $lang, uuid: $uuid) {
      amount
      canNegotiate
      country
      materialCompositions {
        materialDesc
        operator
        percentage
      }
      materialCharacteristics {
        description
        value
      }
      materialType
      materialComposition
      title
      description
      price
      currency
      images
      type
      timesFavourite
      timesShared
      timesVisited
      itsMine
      favouriteForMe
      loadCharacteristics {
        type
        value {
          weight
          width
          depth
          height
        }
      }
      lerCodes {
        name
        uuid
      }
      related {
        materialComposition
        materialType
        title
        description
        price
        currency
        images
        type
        uuid
      }
    }
  }
`;

const gqlSearchAd = gql`
  query ($lang: String!, $term: String!) {
    searchByTerm(lang: $lang, term: $term) {
      isAd
      text
      uuid
    }
  }
`;

const gqlGetCategories = gql`
  query ($lang: String!) {
    materialFamily(lang: $lang) {
      name
      uuid
    }
  }
`;

const gqlGetMaterials = gql`
  query ($lang: String!, $uuidFamily: UUID!) {
    materialComposition(lang: $lang, uuidFamily: $uuidFamily) {
      name
      uuid
    }
  }
`;

const gqlGetTypes = gql`
  query ($lang: String!, $uuidFamily: UUID!, $uuidComposition: UUID!) {
    materialType(
      lang: $lang
      uuidFamily: $uuidFamily
      uuidComposition: $uuidComposition
    ) {
      name
      uuid
    }
  }
`;

const gqlGetAdForCurrentTransactions = gql`
  query ($lang: String!, $uuid: UUID!) {
    getAd(lang: $lang, uuid: $uuid) {
      amount
      materialType
      materialComposition
      title
      price
      currency
      images
    }
  }
`;

const gqlGetEditAd = gql`
  query ($lang: String!, $uuidAd: String!) {
    getAdForEdit(lang: $lang, uuidAd: $uuidAd) {
      uuidOrg
      updatedadDate
      address {
        id
        city
        countryCode
        country
        street
        state
        postalCode
        port {
          uuid
          name
        }
        facilities
      }
      amount
      amountScale
      components {
        id
        materialDesc
        operator
        percentage
        type
        value
      }
      currency
      description
      files {
        location
        name
        uuid
      }
      incoterm {
        name
        uuid
      }
      lerCodes {
        name
        uuid
      }
      likes
      loadCharacteristics {
        type
        value {
          depth
          height
          weight
          width
        }
      }
      materialQuestions {
        label
        type
        uuidQuestion
        value
        options {
          label
          uuid
        }
      }
      materialTypeEdit {
        name
        uuid
      }
      originalText
      originalTitle
      paymentMethod {
        name
        uuid
      }
      price
      status
      title
      transactionsActive
      type
      uuid
      views
      materialComposition
      materialType
      isriCode
      frequency
      frequencyAmount
      scrapSize
      materialShape
      materialSize
      materialSizeScale
    }
  }
`;

const gqlGetAllAdsEdit = gql`
  query (
    $lang: String!
    $offset: Int!
    $limit: Int!
    $filter: FilterOpsTables
  ) {
    getAllAdsEdit(
      lang: $lang
      limit: $limit
      offset: $offset
      filter: $filter
    ) {
      ads {
        uuid
        title
        description
        type
        price
        currency
        materialComposition
        materialType
        isriCode
        frequency
        files {
          location
          name
          uuid
        }
        amount
        materialTypeEdit {
          name
          uuid
        }
        status
        transactionsActive
      }
      pagination {
        total
        current
      }
    }
  }
`;
