import { forwardRef } from "react";
import { useFiltersProvider } from "../../../providers/filters/FiltersContext";
import CatalogSearch from "../input/search/CatalogSearch";

const FilterSearch = (
  {
    catalogName,
    filterKey,
    inputProps = {},
    remote = false,
    permanentOpen = false,
    onChangeCallback,
    onClickCallback,
  },
  ref
) => {
  const { dispatch, actions, filterChanged } = useFiltersProvider();

  return (
    <CatalogSearch
      remote={remote}
      catalogName={catalogName}
      isFilterCatalog={permanentOpen}
      onClickCallback={(option, open, setOpen) => {
        filterChanged.current = true;
        dispatch({
          type: actions.ADD_FILTER,
          payload: {
            filter: { slug: filterKey },
            item: option,
          },
        });
        if (onClickCallback) onClickCallback(option, open, setOpen);
        if (ref?.current?.handleClose) ref.current?.handleClose(false);
      }}
      onChangeCallback={onChangeCallback}
      inputProps={inputProps}
    />
  );
};

export default forwardRef(FilterSearch);
