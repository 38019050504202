import { IconButton, Row, WARM_NEUTRAL_40 } from "@scrapadev/scrapad-front-sdk";
import React from "react";
import styles from "./DocumentManagerBreadcrumbs.module.scss";
import { useDocumentsProvider } from "../../../../../providers/documents/DocumentsContext";
import { useTranslation } from "../../../../../utils/hooks";

function DocumentManagerBreadcrumbs() {
  const { state, onBack, fetchData } = useDocumentsProvider();
  const { t } = useTranslation("common");

  return (
    <div className={styles["document_breadcrumbs"]}>
      <Row gap={15}>
        {state?.breadcrumbs.length > 0 ? (
          <IconButton
            onClick={() => {
              if (!state?.loading) {
                if (state?.breadcrumbs.length > 1) {
                  onBack(
                    state?.breadcrumbs[state?.breadcrumbs.length - 2]?.uuid
                  );
                } else if (state?.breadcrumbs.length > 0) {
                  fetchData(state?.transactionUuid, undefined, false, true);
                }
              }
            }}
            name="arrow_back"
            color={WARM_NEUTRAL_40}
          />
        ) : (
          <div style={{ height: "40px" }}></div>
        )}

        <div>
          <ul className={styles["bread"]}>
            <li
              className={styles["crumb"]}
              onClick={() => {
                fetchData(state?.transactionUuid, undefined, false, true);
              }}
            >
              {t("documents")}
            </li>
            {state?.breadcrumbs?.map((crumb, index) => (
              <li
                className={styles["crumb"]}
                onClick={() => {
                  if (
                    index < state?.breadcrumbs?.length - 1 &&
                    !state?.loading
                  ) {
                    onBack(crumb.uuid);
                  }
                }}
              >
                {crumb.name}
              </li>
            ))}
          </ul>
        </div>
      </Row>
    </div>
  );
}

export default DocumentManagerBreadcrumbs;
