import { useTranslation } from "../../../utils/hooks";
import styles from "./Header.module.scss";

const Header = ({ heading, subheading, subheading2 }) => {
  const { t } = useTranslation("home");

  return (
    <header className={styles["c-header"]}>
      <div className={`container ${styles["c-header__container"]}`}>
        <h1 className={styles["c-header__heading"]}>
          {heading ? heading : t("public-main-heading")}
        </h1>
      </div>
    </header>
  );
};

export default Header;
