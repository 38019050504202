import styles from "./InviteUser.module.scss";
import React, { useReducer, useState } from "react";
import { useTranslation } from "../../../utils/hooks";
import {
  BUTTON_LINK,
  ChipDropdown,
  ClearButton,
  Flex,
  IconItem,
  InputEmail,
  Label,
  Modal,
  Row,
  SCRAPBACK_BLUE_40,
  Spacer,
  toastError,
  toastSuccess,
  Typography,
} from "@scrapadev/scrapad-front-sdk/dist/index";
import { modalTypes, userRoleOptions } from "../../../utils/variables";
import ModalFactory from "../../ui/modal/ModalFactory";
import ModalText from "../../ui/modal/text/ModalText";
import { useHeaderProvider } from "../../../providers/header/HeaderContext";
import { useCRUD } from "../../../utils/crud/hooks";

const actions = {
  ADD_USER: "ADD_USER",
  EDIT_EMAIL: "EDIT_EMAIL",
  EDIT_ROLE: "EDIT_ROLE",
};

function reducer(state, action) {
  const type = action.type;
  const payload = action.payload;
  const index = payload?.index ?? 0;
  const newData = [...state];
  const user = newData[index];
  switch (type) {
    case actions.ADD_USER:
      return [...state, { email: "", role: "", roleName: "" }];
    case actions.EDIT_EMAIL:
      newData[index] = { ...user, email: payload.email };
      return newData;
    case actions.EDIT_ROLE:
      newData[index] = {
        ...user,
        role: payload.role,
        roleName: payload.roleName,
      };
      return newData;
    default:
      return state;
  }
}

const InviteItem = ({ invite, index, dispatch }) => {
  const { t, i18n } = useTranslation(["users", "common"]);
  const roleName = invite.roleName ?? t("select-value");
  const label = `${t("user")} ${index + 1} - ${t("business-email")}`;
  const textStyle = {
    textDecoration: "underline",
  };
  const buttonStyles = {
    display: "flex",
    justifyContent: "flex-end",
    minWidth: 120,
    position: "relative",
    top: 15,
  };
  const handleChangeRole = (item) => () => {
    dispatch({
      type: actions.EDIT_ROLE,
      payload: {
        index: index,
        role: item?.uuid,
        roleName: item?.text,
      },
    });
  };
  const handleChangeEmail = (e) => {
    dispatch({
      type: actions.EDIT_EMAIL,
      payload: { index: index, email: e.target.value },
    });
  };
  return (
    <>
      <Label text={label} light htmlFor={"email"} noPadding>
        <InputEmail
          id="email"
          name="email"
          placeholder={t("write-here")}
          required
          onChange={handleChangeEmail}
          mode="underline"
        />
      </Label>
      {/*<div>
        <ChipDropdown
          items={userRoleOptions
            .map((o) => ({
              uuid: o.uuid,
              text: t(o.text),
            }))
            .map((item) => (
              <ClearButton
                key={item.uuid}
                style={buttonStyles}
                onClick={handleChangeRole(item)}
              >
                {t(item.text)}
              </ClearButton>
            ))}
          type="clear"
          text={<span style={textStyle}>{roleName}</span>}
        />
      </div>*/}
    </>
  );
};

/**
 * Modal for invite a new user to the organization
 * @param {Object} props - Component properties.
 * @param {Object} [props.open=false] - Open/close modal status.
 * @param {Function} props.setOpen - Setter function to open / close.
 * @returns {JSX.Element}
 */
const ModalInviteUser = ({ open = false, setOpen, ...props }) => {
  const [invites, dispatch] = useReducer(reducer, [
    { email: "", role: "", roleName: "" },
  ]);
  const { t, i18n } = useTranslation(["users", "common"]);
  const { uuidOrg, fetchUsers } = props;
  const { state: headerState } = useHeaderProvider();
  const orgName = headerState?.basicData?.orgName ?? "";
  const { CRUD } = useCRUD();
  const [loading, setLoading] = useState(false);
  const [roleMoreInfo, setRoleMoreInfo] = useState(false);
  const description = t("enter-user-email").replace("[1]", orgName);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const errors = [];
    const promises = invites
      .filter((invite) => invite.email)
      .map((invite) => {
        return CRUD({
          endpoint: `org/${uuidOrg}/users?from_dashboard=true`,
          data: {
            email: `${invite.email}`,
            lang: i18n.language,
          },
          method: "POST",
          customError: () => {},
          errorCallback: (errorMessage) => {
            errors.push(invite.email);
          },
          successCallback: async () => {},
        });
      });
    await Promise.allSettled(promises);
    if (errors.length > 0) {
      toastError(`${t("one-or-more-request-failed")}: ${errors.join(", ")}`, {
        toastId: "error_invite_users",
      });
    } else {
      toastSuccess(
        t("saved-changes-ok"),
        { toastId: "invite_users" },
        "check_circle"
      );
    }
    if (typeof fetchUsers === "function") {
      fetchUsers();
    }
    setOpen(false);
  };
  return (
    <>
      <Modal
        t={t}
        open={open}
        setOpen={setOpen}
        title={t("invite-user")}
        hasForm={true}
        onSubmit={handleSubmit}
        submitText={t("send")}
        cancelText={t("cancel")}
        loading={loading}
        maxWidth={700}
        BodyComponent={
          <div className={styles["sc-business--invite"]}>
            <Typography noMargin typography="large">
              {description}
            </Typography>
            <Spacer height={25} />
            <Flex
              flexDirection="column"
              alignItems="flex-start"
              style={{ width: "100%" }}
            >
              {invites.map((invite, index) => (
                <React.Fragment key={index}>
                  <Row gap={15} style={{ width: "100%" }}>
                    <InviteItem
                      invite={invite}
                      index={index}
                      dispatch={dispatch}
                    />
                  </Row>
                  <Spacer height={10} />
                </React.Fragment>
              ))}
            </Flex>
            <IconItem
              name={"person_add"}
              onClick={() => {
                dispatch({ type: actions.ADD_USER });
              }}
              text={t("add_other_user")}
              color={SCRAPBACK_BLUE_40}
              textStyle={{
                ...BUTTON_LINK,
                color: SCRAPBACK_BLUE_40,
                textDecoration: "underline",
              }}
            />
          </div>
        }
      />
      {roleMoreInfo && (
        <ModalFactory
          setOpen={setRoleMoreInfo}
          open={roleMoreInfo}
          type={modalTypes.ROLES_INFO}
        />
      )}
    </>
  );
};

export default ModalInviteUser;
