import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { Navigate, Routes, Route } from "react-router-dom";
import "react-phone-input-2/lib/plain.css";
import "bootstrap/dist/css/bootstrap.css";
import "./styles/main.scss";
import Business from "./pages/business/Business";
import Ads from "./pages/ads/Ads";
import Transactions from "./pages/transactions/Transactions";
import Documents from "./pages/transactions/Documents";
import Login from "./pages/login/Login";
import { TransactionsProvider } from "./providers/transactions/TransactionsContext";
import TransactionDetail from "./pages/transactions/TransactionDetail";
import { LanguageProvider } from "./providers/i18n/i18nContext";
import { AuthProvider } from "./providers/AuthContext";
import "./index.css";
import "react-toastify/dist/ReactToastify.css";
import "@scrapadev/scrapad-front-sdk/dist/index-bo.css";
import "./debug/index.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Guard from "./components/auth/Guard";
import Recover from "./pages/recover/recover";
import BusinessDetail from "./pages/business/BusinessDetail";
import BusinessUsers from "./pages/business/BusinessUsers";
import { getUsers } from "./model/tables";
import BusinessAbout from "./pages/business/BusinessAbout";
import { DataProvider } from "./providers/data/DataContext";
import CustomRouter from "./router/Router";
import history from "./router/History";
import BusinessAddresses from "./pages/business/BusinessAddresses";
import Logistics from "./pages/logistics/Logistics";
import BusinessAds from "./pages/business/BusinessAds";
import BusinessVerification from "./pages/business/BusinessVerification";
import BusinessTableIDWrapper from "./wrappers/TableWrapper";
import BusinessTransactions from "./pages/business/BusinessTransactions";
import { useUser } from "./providers/AuthHooks";
import { QUERY_UPPER_LIMIT, storageKeys } from "./utils/variables";
import Favourites from "./pages/business/Favourites";
import Notifications from "./pages/notifications/Notifications";
import Preferences from "./pages/notifications/Preferences";
import PushNotificationLayout from "./components/ui/push-notifications/PushNotificationLayout";
import { ToastContainerSc } from "@scrapadev/scrapad-front-sdk";
import FiltersWrapper from "./components/ui/filters/Wrapper";
import { HeaderProvider } from "./providers/header/HeaderContext";
import PageWithOrgUuid from "./wrappers/PageWithOrgId";
import { getVerification } from "./model/business";
import AdsDetail from "./pages/ads/AdsDetail";
import { PaginationProvider } from "./providers/pagination/PaginationContext";
import AdsTranslation from "./pages/ads/AdsTranslation";
import {
  OperationsProvider,
  useOperationsProvider,
} from "./providers/organization/OperationsContext";
import BusinessWallet from "./pages/business/BusinessWallet";
import BusinessWalletAccounts from "./pages/business/BusinessWalletAccounts";
import NotFound from "./pages/notfound/NotFound";
import Finances from "./pages/finances/Finances";
import Users from "./pages/users/Users";
import RegisterIncomplete from "./pages/register-incomplete/RegisterIncomplete";
import { SortProvider } from "./providers/sort/SortContext";
import { BackNavigationProvider } from "./providers/navigation/BackNavigationContext";
import Opportunities from "./pages/opportunities/Opportunities";
import OpportunitiesDetail from "./pages/opportunities/OpportunitiesDetail";
import Opportunity from "./pages/opportunities/Opportunity";
import Costs from "./pages/transactions/Costs";
import TransactionTransport from "./pages/transactions/TransportDetail";
import TransactionQuotationsDetail from "./pages/transactions/QuotationsDetail";
import TransactionQuotationNewV2 from "./pages/transactions/QuotationsNew";
import { ApolloProvider } from "@apollo/client";
import { useScpApolloClient } from "./utils/gqlclient";
import { NotificationProvider } from "./providers/notifications/NotificationsContext";
import ChangeLogPanel from "./widgets/notifications/LogPanel";
import BusinessPreferences from "./pages/business/BusinessPreferences";

const RenderApp = () => {
  const user = useUser();
  const apolloClient = useScpApolloClient(user !== null);
  const browserLanguage = navigator.language.split("-")[0];
  const userDefinedLanguage = window.localStorage.getItem(
    storageKeys.USER_DEFINED_LANGUAGE
  );
  const preferencesLanguage = window.localStorage.getItem(storageKeys.LANGUAGE);
  const {
    canManageAds,
    canReadAds,
    canReadOrg,
    canReadWallet,
    canReadUsers,
    canReadFavs,
    canReadKYC,
    canReadAddress,
    canReadTransaction,
    canReadFinances,
    canReadLogistics,
    roleOperationsName,
    canReadUserRoles,
    canReadBusiness,
    canManageOpportunities,
  } = useOperationsProvider();
  return (
    <ApolloProvider client={apolloClient}>
      <HeaderProvider>
        <BackNavigationProvider>
          <LanguageProvider
            language={
              user
                ? userDefinedLanguage
                  ? userDefinedLanguage
                  : preferencesLanguage
                : browserLanguage
            }
          >
            <PushNotificationLayout>
              <NotificationProvider>
                <ToastContainerSc />
                <Routes>
                  <Route
                    path="/"
                    element={<Navigate replace to="/transactions" />}
                  />
                  <Route
                    path="transactions"
                    element={
                      <Guard
                        permission={canReadTransaction}
                        operationName={
                          roleOperationsName.MANAGEMENT_TRANSACTIONS
                        }
                        Component={
                          <PaginationProvider
                            storageKey={"transactionsPagination"}
                          >
                            <TransactionsProvider>
                              <FiltersWrapper
                                storageKey={"transactionsFilters"}
                              >
                                <SortProvider
                                  storageKey={"transactions"}
                                  keysMerged
                                >
                                  <Transactions />
                                </SortProvider>
                              </FiltersWrapper>
                            </TransactionsProvider>
                          </PaginationProvider>
                        }
                      />
                    }
                  />
                  <Route
                    path="transactions/:id"
                    element={
                      <Guard
                        permission={canReadTransaction}
                        operationName={
                          roleOperationsName.MANAGEMENT_TRANSACTIONS
                        }
                        Component={<TransactionDetail />}
                      />
                    }
                  />
                  <Route
                    path="transactions/:id/documents"
                    element={
                      <Guard
                        permission={canReadTransaction}
                        operationName={
                          roleOperationsName.MANAGEMENT_TRANSACTIONS
                        }
                        Component={<Documents />}
                      />
                    }
                  />
                  <Route
                    path="transactions/:id/costs"
                    element={
                      <Guard
                        permission={canReadTransaction}
                        operationName={
                          roleOperationsName.MANAGEMENT_TRANSACTIONS
                        }
                        Component={<Costs />}
                      />
                    }
                  />
                  <Route
                    path="transactions/:id/transport"
                    element={
                      <Guard
                        permission={canReadLogistics}
                        operationName={
                          roleOperationsName.MANAGEMENT_TRANSACTIONS
                        }
                        Component={<TransactionTransport />}
                      />
                    }
                  />
                  <Route
                    path="transactions/:id/quotations"
                    element={
                      <Guard
                        permission={canReadLogistics}
                        operationName={
                          roleOperationsName.MANAGEMENT_TRANSACTIONS
                        }
                        //Component={<TransactionQuotation />}
                        Component={<TransactionQuotationsDetail />}
                      />
                    }
                  />
                  <Route
                    path="transactions/:id/quotations/new"
                    element={
                      <Guard
                        permission={canReadLogistics}
                        operationName={
                          roleOperationsName.MANAGEMENT_TRANSACTIONS
                        }
                        Component={<TransactionQuotationNewV2 />}
                      />
                    }
                  />
                  <Route
                    path="/ads"
                    element={
                      <Guard
                        permission={canReadAds}
                        operationName={roleOperationsName.MANAGEMENT_ADS}
                        Component={
                          <PaginationProvider storageKey={"adsPagination"}>
                            <FiltersWrapper storageKey={"adsFilters"}>
                              <SortProvider storageKey={"ads"} keysMerged>
                                <Ads />
                              </SortProvider>
                            </FiltersWrapper>
                          </PaginationProvider>
                        }
                      />
                    }
                  />
                  <Route
                    path="/ads/:id"
                    element={
                      <Guard
                        permission={canReadAds}
                        operationName={roleOperationsName.MANAGEMENT_ADS}
                        Component={<AdsDetail />}
                      />
                    }
                  />
                  <Route
                    path="/ads/:id/translation"
                    element={
                      <Guard
                        permission={canManageAds}
                        operationName={roleOperationsName.MANAGEMENT_ADS}
                        Component={<AdsTranslation />}
                      />
                    }
                  />
                  <Route
                    path="/ads/:id/edit"
                    element={
                      <Guard
                        permission={canManageAds}
                        operationName={roleOperationsName.MANAGEMENT_ADS}
                        Component={<AdsDetail isEdit={true} />}
                      />
                    }
                  />
                  <Route
                    path="/notifications"
                    element={
                      <Guard
                        Component={<Guard Component={<Notifications />} />}
                      />
                    }
                  />
                  <Route
                    path="/notifications/preferences"
                    element={
                      <Guard
                        Component={<Guard Component={<Preferences />} />}
                      />
                    }
                  />
                  <Route
                    path="/business"
                    element={
                      <Guard
                        permission={canReadBusiness}
                        operationName={roleOperationsName.MANAGEMENT_ORG}
                        Component={
                          <PaginationProvider storageKey={"businessPagination"}>
                            <FiltersWrapper storageKey={"businessFilters"}>
                              <SortProvider storageKey={"business"} keysMerged>
                                <Business />
                              </SortProvider>
                            </FiltersWrapper>
                          </PaginationProvider>
                        }
                      />
                    }
                  />
                  <Route
                    path="business/:id"
                    element={
                      <Guard
                        //permission={canReadOrg}
                        //operationName={roleOperationsName.MANAGEMENT_ORG}
                        Component={<BusinessDetail />}
                      />
                    }
                  />
                  <Route
                    path="business/:id/users"
                    element={
                      <Guard
                        permission={canReadUsers}
                        operationName={roleOperationsName.MANAGEMENT_USER}
                        Component={
                          <BusinessTableIDWrapper
                            Component={BusinessUsers}
                            fnData={getUsers}
                            fnParameters={{ limit: QUERY_UPPER_LIMIT }}
                          />
                        }
                      />
                    }
                  />
                  <Route
                    path="business/:id/about"
                    element={
                      <Guard
                        permission={canReadOrg}
                        operationName={roleOperationsName.MANAGEMENT_ORG}
                        Component={<BusinessAbout />}
                      />
                    }
                  />
                  <Route
                    path="business/:id/verification"
                    element={
                      <Guard
                        permission={canReadKYC}
                        operationName={roleOperationsName.MANAGEMENT_KYC}
                        Component={
                          <PageWithOrgUuid
                            fnData={getVerification}
                            Component={BusinessVerification}
                            delegateLoading
                          />
                        }
                      />
                    }
                  />
                  <Route
                    path="business/:id/addresses"
                    element={
                      <Guard
                        permission={canReadAddress}
                        operationName={roleOperationsName.MANAGEMENT_ADDRESS}
                        Component={
                          <DataProvider>
                            <BusinessAddresses />
                          </DataProvider>
                        }
                      />
                    }
                  />
                  <Route
                    path="business/:id/transactions"
                    element={
                      <Guard
                        permission={canReadTransaction}
                        operationName={
                          roleOperationsName.MANAGEMENT_TRANSACTIONS
                        }
                        Component={
                          <PaginationProvider
                            storageKey={"businessTransactionsPagination"}
                          >
                            <FiltersWrapper
                              storageKey={"businessTransactionsFilters"}
                            >
                              <SortProvider
                                storageKey={"businessTransactions"}
                                keysMerged
                              >
                                <BusinessTransactions />
                              </SortProvider>
                            </FiltersWrapper>
                          </PaginationProvider>
                        }
                      />
                    }
                  />
                  <Route
                    path="business/:id/ads"
                    element={
                      <Guard
                        permission={canReadAds}
                        operationName={roleOperationsName.MANAGEMENT_ADS}
                        Component={
                          <PaginationProvider
                            storageKey={"businessAdsPagination"}
                          >
                            <FiltersWrapper storageKey={"businessAdsFilters"}>
                              <SortProvider
                                storageKey={"businessAds"}
                                keysMerged
                              >
                                <BusinessAds />
                              </SortProvider>
                            </FiltersWrapper>
                          </PaginationProvider>
                        }
                      />
                    }
                  />
                  <Route
                    path="business/:id/favourites"
                    element={
                      <Guard
                        permission={canReadFavs}
                        operationName={roleOperationsName.MANAGEMENT_FAVS}
                        Component={<Favourites />}
                      />
                    }
                  />
                  <Route
                    path="business/:id/wallet"
                    element={
                      <Guard
                        permission={canReadWallet}
                        operationName={roleOperationsName.MANAGEMENT_WALLET}
                        Component={
                          <PaginationProvider
                            storageKey={"movementsPagination"}
                          >
                            <BusinessWallet />
                          </PaginationProvider>
                        }
                      />
                    }
                  />
                  <Route
                    path="business/:id/last-movements"
                    element={
                      <Guard
                        permission={canReadWallet}
                        operationName={roleOperationsName.MANAGEMENT_WALLET}
                        Component={
                          <PaginationProvider
                            storageKey={"movementsPagination"}
                          >
                            <BusinessWallet hideWallet />
                          </PaginationProvider>
                        }
                      />
                    }
                  />
                  <Route
                    path="business/:id/wallet/accounts"
                    element={
                      <Guard
                        permission={canReadWallet}
                        operationName={roleOperationsName.MANAGEMENT_WALLET}
                        Component={<BusinessWalletAccounts />}
                      />
                    }
                  />
                  <Route
                    path="business/:id/preferences"
                    element={
                      <Guard
                        permission={canReadOrg}
                        operationName={roleOperationsName.MANAGEMENT_WALLET}
                        Component={<BusinessPreferences />}
                      />
                    }
                  />
                  <Route
                    path="finances"
                    element={
                      <Guard
                        permission={canReadFinances}
                        operationName={roleOperationsName.MANAGEMENT_FINANCES}
                        Component={<Finances />}
                      />
                    }
                  />
                  <Route
                    path="register-incomplete"
                    element={
                      <Guard
                        permission={canReadBusiness}
                        operationName={roleOperationsName.MANAGEMENT_BUSINESS}
                        Component={
                          <PaginationProvider
                            storageKey={"incompletePagination"}
                          >
                            <FiltersWrapper storageKey={"incompleteFilters"}>
                              <SortProvider
                                storageKey={"incomplete"}
                                keysMerged
                              >
                                <RegisterIncomplete />
                              </SortProvider>
                            </FiltersWrapper>
                          </PaginationProvider>
                        }
                      />
                    }
                  />
                  <Route
                    path="logistics"
                    element={
                      <Guard
                        permission={canReadLogistics}
                        operationName={roleOperationsName.MANAGEMENT_LOGISTICS}
                        Component={
                          <PaginationProvider
                            storageKey={"logisticsPagination"}
                          >
                            <FiltersWrapper storageKey={"logisticsfilters"}>
                              <SortProvider storageKey={"logistics"} keysMerged>
                                <Logistics />
                              </SortProvider>
                            </FiltersWrapper>
                          </PaginationProvider>
                        }
                      />
                    }
                  />
                  <Route
                    path="users"
                    element={
                      <Guard
                        permission={canReadUserRoles}
                        operationName={roleOperationsName.MANAGEMENT_USER_ROLES}
                        Component={
                          <PaginationProvider storageKey={"usersPagination"}>
                            <FiltersWrapper storageKey={"usersfilters"}>
                              <Users />
                            </FiltersWrapper>
                          </PaginationProvider>
                        }
                      />
                    }
                  />
                  <Route
                    path="/opportunities"
                    element={
                      <Guard
                        permission={canManageOpportunities}
                        operationName={
                          roleOperationsName.MANAGEMENT_OPPORTUNITIES
                        }
                        Component={<Opportunities />}
                      />
                    }
                  />
                  <Route
                    path="/opportunities/:id"
                    element={
                      <Guard
                        permission={canManageOpportunities}
                        operationName={
                          roleOperationsName.MANAGEMENT_OPPORTUNITIES
                        }
                        Component={<OpportunitiesDetail />}
                      />
                    }
                  />
                  <Route
                    path="opportunities/new"
                    element={
                      <Guard
                        permission={canManageOpportunities}
                        operationName={
                          roleOperationsName.MANAGEMENT_OPPORTUNITIES
                        }
                        Component={<Opportunity />}
                      />
                    }
                  />
                  <Route
                    path="opportunities/edit/:id"
                    element={
                      <Guard
                        permission={canManageOpportunities}
                        operationName={
                          roleOperationsName.MANAGEMENT_OPPORTUNITIES
                        }
                        Component={<Opportunity />}
                      />
                    }
                  />
                  <Route path="/login" element={<Login />} />
                  <Route path="/recover" element={<Recover />} />
                  <Route
                    path="*"
                    element={<Guard Component={<NotFound />} />}
                  />
                </Routes>
                {user && <ChangeLogPanel />}
              </NotificationProvider>
            </PushNotificationLayout>
          </LanguageProvider>
        </BackNavigationProvider>
      </HeaderProvider>
    </ApolloProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <OperationsProvider
      emptyPermissionAllowOperations={JSON.parse(
        process.env.REACT_APP_ALLOW_EMPTY_PERMISSION
      )}
    >
      <CustomRouter history={history}>
        <AuthProvider>
          <RenderApp />
        </AuthProvider>
      </CustomRouter>
    </OperationsProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
