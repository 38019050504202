import React from "react";

function TransactionsIcon() {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="50" height="50" rx="10" fill="#EBF3FF" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M31.6737 15.9476C32.2705 15.3508 33.2381 15.3508 33.8349 15.9476L37.9411 20.0539C38.4635 20.5762 38.5286 21.3827 38.1365 21.9759C38.074 22.0791 37.9983 22.1764 37.9092 22.2655L33.8029 26.3717C33.2062 26.9685 32.2386 26.9685 31.6418 26.3717C31.045 25.7749 31.045 24.8073 31.6418 24.2105L32.8555 22.9968H20.9334C20.005 22.9968 19.2524 22.2442 19.2524 21.3158C19.2524 20.3874 20.005 19.6348 20.9334 19.6348H33.1997L31.6737 18.1088C31.0769 17.512 31.0769 16.5444 31.6737 15.9476Z"
        fill="#63A1FF"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.7149 24.1996C17.1182 23.6028 16.1506 23.6028 15.5538 24.1996L11.4475 28.3058C10.9178 28.8356 10.8583 29.6576 11.2691 30.253C11.3285 30.3468 11.3991 30.4356 11.4809 30.5174L15.5871 34.6236C16.1839 35.2204 17.1515 35.2204 17.7483 34.6236C18.3451 34.0269 18.3451 33.0593 17.7483 32.4625L16.5346 31.2487H28.4567C29.3851 31.2487 30.1377 30.4961 30.1377 29.5677C30.1377 28.6393 29.3851 27.8867 28.4567 27.8867H16.1889L17.7149 26.3607C18.3117 25.7639 18.3117 24.7964 17.7149 24.1996Z"
        fill="#63A1FF"
      />
    </svg>
  );
}

export default TransactionsIcon;
