import CardBox from "../../../components/ui/box/CardBox";
import { useTranslation } from "../../../utils/hooks";
import _ from "lodash";
import InfoText from "../../../components/ui/text/InfoText";
import Flag from "react-world-flags";

/**
 * Contact info widget for dashboard.
 * @param {Object} props - Component properties.
 * @param {String} props.id - Business id.
 * @param {Object} props.state - Data state.
 * @returns {JSX.Element}
 */
const WidgetBusineesContact = ({ id, state }) => {
  const { t } = useTranslation(["common", "transactions"]);
  const orgName = state?.basicData?.orgName;
  const email = state?.legalReprenstative?.email;
  const country = state?.fiscalAddress?.country;
  const countryCode = state?.fiscalAddress?.countryCode;
  const prefix = state?.basicData?.prefix;
  const phone = state?.basicData?.phone;
  return (
    <CardBox
      title={t("contact-info")}
      action={t("see-more")}
      href={`/business/${id}/about`}
    >
      {orgName && <InfoText icon="domain" text={orgName} />}
      {email && <InfoText icon="mail" text={email} />}
      {country && <InfoText icon="location_on" text={country} />}
      {phone && (
        <InfoText
          leading={<Flag code={countryCode} width={18} height={12} />}
          icon="phone"
          text={`${prefix} ${phone}`}
        />
      )}
    </CardBox>
  );
};

export default WidgetBusineesContact;
