/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import styles from "./CompositionInput.module.scss";
import {
  BREAKPOINT_LG,
  DropDown,
  InputNumber,
  Label,
  Radio,
  Row,
  useMatchMedia,
} from "@scrapadev/scrapad-front-sdk";

import _ from "lodash";
import { useTranslation } from "../../../utils/hooks";
import { useGQL } from "../../../utils/gql/hooks";
import { getCatalogNoTerm } from "../../../model/filters";

function CompositionInput({
  onChange = () => {},
  value = [],
  required = false,
  type,
  disabled,
}) {
  const { t, i18n } = useTranslation(["common"]);
  const { data: materialCompositionData } = useGQL(getCatalogNoTerm, {
    locale: i18n.language,
    name: "material-composition",
  });

  const [innerValue, setInnerValue] = useState(
    value?.map((e) => ({ ...e, uuid: e?.type, value: e?.percentage }))
  );

  useEffect(() => {
    if (typeof onChange === "function") {
      onChange(innerValue);
    }
  }, [innerValue]);

  const handleItemClick = (option, open, setOpen) => {
    if (
      !innerValue?.some((e) => {
        if (e?.uuid) {
          return e?.uuid === option?.uuid;
        }
        if (e?.type) {
          return e?.type === option?.uuid;
        }
        return e === option?.uuid;
      })
    ) {
      const optionTransform = { ...option, type: option?.uuid };
      setInnerValue([...innerValue, optionTransform]);
    } else {
      setInnerValue(
        innerValue?.filter((e) => {
          if (e?.uuid) {
            return e?.uuid !== option?.uuid;
          }
          if (e?.type) {
            return e?.type === option?.uuid;
          }
          return e !== option?.uuid;
        })
      );
    }
    // setTimeout(() => {
    //   setOpen(false);
    // }, 100);
  };

  return (
    <div className={styles["composition_input"]}>
      <div className={styles["selector_wrap"]}>
        <Label
          text={
            type === "sale"
              ? t("set_composition_preferences_sale")
              : t("set_composition_preferences")
          }
          noPadding
          medium
          semiBold
        >
          <DropDown
            disabled={disabled}
            mode="underline"
            multiple
            newMultipleItemFormat
            required={required}
            styles={{ marginBottom: 0 }}
            value={innerValue?.map((v) => v?.text || v?.materialDesc || v)}
            handleItemClick={handleItemClick}
            options={(_.isEmpty(materialCompositionData?.errors)
              ? materialCompositionData
              : []
            )?.map((e) => ({
              uuid: e?.uuid,
              text: e?.name,
            }))}
            handleIsChecked={(option) => {
              return innerValue.find((f) => {
                if (f?.uuid || f?.type) {
                  return f?.uuid === option.uuid || f?.type === option.uuid;
                }
                return f === option.uuid;
              });
            }}
            placeholder={t("set_composition_preferences_placeholder")}
          />
        </Label>
      </div>
      <div className={styles["option_list"]}>
        {innerValue?.map((element, i) => (
          <CompositionInputItem
            isSale={type === "sale"}
            value={element}
            title={element?.text || element?.materialDesc || element}
            key={`cii_${element?.uuid}_${i}`}
            onChange={(e) => {
              let vCpy = _.cloneDeep(innerValue);
              vCpy[i] = {
                ...vCpy[i],
                ...e,
              };
              setInnerValue(vCpy);
            }}
          />
        ))}
      </div>
    </div>
  );
}

function CompositionInputItem({
  onChange = () => {},
  title,
  value = {},
  isSale = false,
}) {
  const { t } = useTranslation(["common"]);
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_LG}px)`);

  return (
    <Row
      align="center"
      gap={matches ? 0 : 15}
      wrap={matches}
      style={{ width: "100%", marginBottom: matches ? "25px" : 0 }}
    >
      <Label
        text={`${t("percentage")} ${t("of_")} ${title}`}
        style={{ width: matches ? "100%" : "50%" }}
        noPadding
        medium
        semiBold
      >
        <InputNumber
          mode="underline"
          min={0}
          placeholder="00.000000 %"
          value={value?.value}
          onChange={(e) => {
            onChange({
              ...value,
              value: e?.target?.value,
            });
          }}
        />
      </Label>
      <Row gap={matches ? 5 : 15} style={{ width: matches ? "100%" : "50%" }}>
        <Row align="center">
          <Radio
            id={`operator_${value?.type || value?.uuid || value}`}
            name={`operator_${value?.type || value?.uuid || value}`}
            text={isSale ? t("minimum") : t("minimum_required")}
            onChange={() => {
              onChange({
                ...value,
                operator: "greater-than",
              });
            }}
            checked={value?.operator === "greater-than"}
            style={{ cursor: "pointer" }}
            labelStyle={{ cursor: "pointer" }}
          />
        </Row>
        <Row align="center">
          <Radio
            id={`operator_2_${value?.type || value?.uuid || value}`}
            name={`operator_${value?.type || value?.uuid || value}`}
            text={isSale ? t("maximum") : t("maximum_admitted")}
            onChange={() => {
              onChange({
                ...value,
                operator: "less-than",
              });
            }}
            checked={value?.operator === "less-than"}
            style={{ cursor: "pointer" }}
            labelStyle={{ cursor: "pointer" }}
          />
        </Row>
      </Row>
    </Row>
  );
}

export default CompositionInput;
