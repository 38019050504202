import { TableManager } from "@scrapadev/scrapad-front-sdk";
import { useOpportunitiesTable } from "../../utils/opportunities/hooks";

/**
 * Helper component to show table on all the opportunities list.
 * @param {object} props - Component properties.
 * @param {object} props.actions - Table actions.
 * @param {object} props.selectedRow - Useref to store selected row.
 * @param {object} props.baseTableParams - Base params of table.
 * @returns {JSX.Element}
 */
const WidgetOpportunitiesTable = ({
  actions,
  selectedRow,
  baseTableParams,
  ...props
}) => {
  const { tableProps } = useOpportunitiesTable(baseTableParams);

  return (
    <TableManager
      {...tableProps}
      onClickActionCallback={(uuid, index, row) => {
        selectedRow.current = row;
      }}
      actions={actions}
      {...props}
    />
  );
};

export default WidgetOpportunitiesTable;
