import {
  BODY_SMALL_MED,
  Chip,
  WARM_NEUTRAL_20,
  WARM_NEUTRAL_60,
} from "@scrapadev/scrapad-front-sdk";
import { useTranslation } from "../../../utils/hooks";

/**
 * Helper component to render buy/sell chips in dashboard favorites.
 * @param {object} props - Component poperties.
 * @param {Array<string>} [props.type] - Ad type(s).
 * @returns {React.ReactNode}
 */
const FavoriteChip = ({ type }) => {
  const isBuy = type.includes("purchase");
  const isSell = type.includes("sale");
  const { t } = useTranslation("common");
  return (
    <>
      {isBuy && (
        <Chip
          text={t("buy")}
          style={{
            padding: "2px 10px",
            border: `1px solid ${WARM_NEUTRAL_20}`,
            background: "white",
            ...BODY_SMALL_MED,
            color: WARM_NEUTRAL_60,
          }}
        />
      )}
      {isSell && (
        <Chip
          text={t("sell")}
          style={{
            padding: "2px 10px",
            border: `1px solid ${WARM_NEUTRAL_20}`,
            background: "white",
            ...BODY_SMALL_MED,
            color: WARM_NEUTRAL_60,
          }}
        />
      )}
    </>
  );
};

export default FavoriteChip;
