import styles from "./Info.module.scss";
import { useRef, useState, useEffect } from "react";
import { useOutsideAlerter, useTranslation } from "../../../utils/hooks";
import LanguageSelector from "../language/LanguageSelector";
import {
  useSetDataUser,
  useSignOut,
  useUser,
} from "../../../providers/AuthHooks";
import NotificationsPreview from "./NotificationsPreview";
import { checkActiveRouteClass } from "../../../utils/routes/functions";
import { useLocation, useNavigate } from "react-router-dom";
import { useNotificationProvider } from "../../../providers/notifications/NotificationsContext";
import EditProfile from "./EditProfile";
import { BREAKPOINT_LG, useMatchMedia } from "@scrapadev/scrapad-front-sdk";

/**
 * Private Menu component.
 * @param {Object} props - Object properties.
 * @param {Boolean} [props.showNotifications=false] - Tells if notifications component must be shown.
 * @param {Boolean} [props.showBusinnes=false] - Tells if businnes component must be shown.
 * @param {Boolean} [props.showUser=false] - Tells if user component must be shown.
 * @returns {JSX.Element}
 */
const InnerInfo = ({
  showNotifications = true,
  showBusinnes = true,
  showUser = true,
}) => {
  const { t } = useTranslation("common");
  const [openNotifications, setOpenNotifications] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const user = useUser();
  const signOut = useSignOut();
  const setDataUser = useSetDataUser();
  const [openUser, setOpenUser] = useState(false);
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_LG}px)`);
  const wrapperRef = useRef(null);
  const location = useLocation();
  useOutsideAlerter(wrapperRef, () => setOpenUser(false));
  const { state, updateDataCall } = useNotificationProvider();
  const navigate = useNavigate();

  const handleUserDropdown = () => setOpenUser(!openUser);

  const handleLogOut = () => signOut();

  useEffect(() => {
    if (user) {
      updateDataCall({ unread: true });
    }
  }, []);

  const hasNotifications = state?.unreadNotifications?.length > 0 || false;

  return (
    <>
      <div className={[styles["c-navbar__info"]]} data-cy="navbar-info">
        <ul>
          {showBusinnes && (
            <li>
              <a href={`/`} className={styles["c-navbar__info-container"]}>
                <i
                  className={`${styles["c-navbar__info-icon"]} large material-icons-outlined icon`}
                >
                  domain
                </i>
                <span className={styles["c-navbar__info-name"]}>
                  {"Org Name"}
                </span>
              </a>
            </li>
          )}
          {showUser && (
            <li ref={wrapperRef}>
              <button
                type="button"
                className={styles["c-navbar__info-toggle"]}
                onClick={handleUserDropdown}
              >
                <div
                  className={`${styles["c-navbar__info-container"]} ${
                    styles["c-navbar__info-user"]
                  } ${openUser ? styles["c-navbar__info-opened"] : ""}`}
                >
                  <i
                    className={`${styles["c-navbar__info-icon"]} large material-icons-outlined icon`}
                  >
                    person
                  </i>
                  <span
                    className={styles["c-navbar__info-name"]}
                    data-cy="navbar-username"
                  >
                    {user !== null && user.userData.fullName}
                  </span>
                  <i
                    className={`${styles["c-navbar__info-trailing"]} large material-icons-outlined icon`}
                  >
                    {matches ? "chevron_right" : "arrow_drop_down"}
                  </i>
                </div>
              </button>
              {openUser && (
                <div className={styles["c-navbar__info--dropdown"]}>
                  <ul>
                    {/*<li>
                      <button
                        type="button"
                        onClick={() => setEditOpen(!editOpen)}
                      >
                        <i className={`large material-icons-outlined icon`}>
                          medical_information
                        </i>
                        {t("edit-data")}
                      </button>
              </li>*/}
                    <li>
                      <button type="button" onClick={handleLogOut}>
                        <i className={`large material-icons-outlined icon`}>
                          logout
                        </i>
                        {t("logout")}
                      </button>
                    </li>
                  </ul>
                  <hr style={{ marginTop: "15px" }} />
                  <LanguageSelector />
                </div>
              )}
            </li>
          )}
          {showNotifications && (
            <li
              onMouseEnter={() => {
                setOpenNotifications(true);
              }}
              onMouseLeave={() => {
                setOpenNotifications(false);
              }}
              onClick={() => {
                navigate("/notifications");
              }}
              style={{ position: "relative" }}
              className={checkActiveRouteClass(
                ["/notifications"],
                location,
                styles
              )}
            >
              <a
                href={"/notifications"}
                className={`${styles["c-navbar__info-container"]}`}
              >
                <i
                  className={`${styles["c-navbar__info-icon"]} ${styles["c-navbar__notifications-icon"]} large material-icons-outlined icon`}
                >
                  notifications
                </i>
                <span
                  className={
                    hasNotifications
                      ? styles["c-navbar__info--notifications-pending"]
                      : styles["c-navbar__info--notifications"]
                  }
                ></span>
              </a>
              {openNotifications && <NotificationsPreview />}
            </li>
          )}
          {showBusinnes && (
            <li>
              <a href={`/`} className={styles["c-navbar__info-container"]}>
                <i
                  className={`${styles["c-navbar__info-icon"]} large material-icons-outlined icon`}
                >
                  domain
                </i>
                <span className={styles["c-navbar__info-name"]}>
                  {"Org Name"}
                </span>
              </a>
            </li>
          )}
        </ul>
      </div>
      <EditProfile open={editOpen} setOpen={setEditOpen} />
    </>
  );
};

const Info = ({
  showNotifications = true,
  showBusinnes = true,
  showUser = true,
}) => {
  return (
    <InnerInfo
      showNotifications={showNotifications}
      showBusinnes={showBusinnes}
      showUser={showUser}
    />
  );
};

export default Info;
