import { toastError } from "@scrapadev/scrapad-front-sdk";
import { useEffect, useState } from "react";
import { getPreferencesPaymentMethods } from "../../model/business";
import { handleCRUD } from "../crud/functions";
import { useCRUD } from "../crud/hooks";
import { sleep } from "../functions";
import { useTranslation } from "../hooks";

/**
 * Custom hook to toggle business verification status. For basic and complete verification.
 * @returns {{handleChangeVerificationStatus: (type: string, active: boolean, fetchData: Function, loading: boolean, setLoading: (b: boolean) => void)}}
 */
export const useVerificationChangeStatus = () => {
  const { CRUD } = useCRUD();

  const handleChangeVerificationStatus = async (
    id,
    type,
    active,
    fetchData,
    loading,
    setLoading
  ) => {
    setLoading(true);
    await CRUD({
      endpoint: `org/${id}/verification/${id}?from_dashboard=true`,
      method: "PUT",
      data: {
        active,
        type,
      },
      errorCallback: (error) => {
        toastError(error);
        setLoading(false);
      },
      successCallback: async () => {
        await fetchData();
        setLoading(false);
      },
    });
  };

  const handleAssignedAgent = async ({
    id,
    agentAssigned,
    fetchData = async () => {},
    setLoading = () => {},
  }) => {
    setLoading(true);
    await CRUD({
      endpoint: `org/${id}/basic-data?from_dashboard=true`,
      method: "PUT",
      data: {
        assignedBo: agentAssigned,
      },
      errorCallback: (error) => {
        toastError(error);
        setLoading(false);
      },
      successCallback: async () => {
        await fetchData();
        setLoading(false);
      },
    });
  };

  return {
    handleChangeVerificationStatus,
    handleAssignedAgent,
  };
};

/**
 * Custom hook to manage business preferences currency.
 * @returns {{loading: bool, setLoading: (b:bool) => void, markCurrencyAsDefault: (uuid:string) => void}}
 */
export const useBusinessPreferencesCurrency = () => {
  const [loading, setLoading] = useState(false);
  const { CRUD } = useCRUD();

  const markCurrencyAsDefault = async (currency) => {
    if (!currency) return;
    setLoading(true);
    /*await CRUD({
      endpoint: ``,
      method: "PUT",
      data: {},
      errorCallback: (error) => {
        toastError(error);
        setLoading(false);
      },
      successCallback: async () => {
        //await fetchData();
        setLoading(false);
      },
    });*/
    await sleep(1500);
    console.log(currency);
    setLoading(false);
  };

  return {
    loading,
    setLoading,
    markCurrencyAsDefault,
  };
};

/**
 * Custom hook to manage business preferences payment methods.
 * @param {string} orgUuid - Organization uuid.
 * @returns {{loading: boolean, setLoading: (b:boolean?) => void, markPaymentMethodAsDefault: (uuid:string) => void, togglePaymentMethod: (uuid:string) => void, fetchData: () => void, actionLoading: boolean, setActionLoading: (b:boolean) => void, data: Array<{uuidPaymentMethod: string,paymentMethodDesc: string,priceDesc: string?,price: string?,active: boolean?,fav: boolean?}>}}
 */
export const useBusinessPreferencesPaymentMethods = (orgUuid) => {
  const { i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [data, setData] = useState([]);
  const { CRUD } = useCRUD();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    if (!orgUuid) return;
    setLoading(true);
    const data = await getPreferencesPaymentMethods({
      locale: i18n.language,
      org: orgUuid,
    });
    setData(data || []);
    setLoading(false);
  };

  const markPaymentMethodAsDefault = async (paymentMethod) => {
    if (!paymentMethod) return;
    setActionLoading(true);
    await CRUD({
      endpoint: `org/${orgUuid}/preferences?from_dashboard=true`,
      method: "PUT",
      data: {
        paymentMethod,
      },
      errorCallback: (error) => {
        toastError(error);
        setActionLoading(false);
      },
      successCallback: async () => {
        await fetchData();
        setActionLoading(false);
      },
    });
  };

  const togglePaymentMethod = async (paymentMethod, active) => {
    if (!paymentMethod) return;
    setActionLoading(true);
    await CRUD({
      endpoint: `org/${orgUuid}/org-payment-method/${paymentMethod}`,
      method: "PUT",
      data: {
        active,
      },
      errorCallback: (error) => {
        toastError(error);
        setActionLoading(false);
      },
      successCallback: async () => {
        await fetchData();
        setActionLoading(false);
      },
    });
  };

  return {
    data,
    loading,
    setLoading,
    actionLoading,
    setActionLoading,
    markPaymentMethodAsDefault,
    togglePaymentMethod,
    fetchData,
  };
};

/**
 * Custom hook to abstract the implementation of updating basic data to use in different places.
 * @returns {{updateBasicData: (id: string, state: object, successCallback: () => void, errorCallback: () => void, setLoading: (b: boolean) => void) => Promise<void>}}
 */
export const useBusinessUpdateInfo = () => {
  const { t, i18n } = useTranslation(["business", "common"]);
  const { CRUD } = useCRUD();

  const updateBasicData = async ({
    id,
    state,
    successCallback,
    errorCallback,
    setLoading,
  }) => {
    if (!state || !id) return;
    const data = { ...state, lang: i18n.language };
    delete data.__typename;
    await CRUD({
      endpoint: `org/${id}/basic-data`,
      method: "PUT",
      data: data,
      successCallback,
      errorCallback,
      messageOk: t("action-ok"),
      setLoading,
    });
  };

  return { updateBasicData };
};
