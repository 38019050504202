import { useTranslation } from "../../../utils/hooks";
import {
  BREAKPOINT_LG,
  DropDown,
  Modal,
  useMatchMedia,
  Container,
  AEditor,
  Center,
  Padding,
  Loading,
  Icon,
  Flex,
  InputPassword,
  InputPhoneWithPrefix,
  Label,
  InputText,
  Row,
  InputEmail,
} from "@scrapadev/scrapad-front-sdk/dist/index";
import styles from "./UserInfo.module.scss";
import ValidateGroup from "../../ui/input/validation/ValidateGroup";
import { orgStatusOptions, userRoleOptions } from "../../../utils/variables";
import { useEffect, useState } from "react";
import {
  countryCodesProps,
  renderComponentWithPermission,
} from "../../../utils/functions";

/**
 * Modal for user information.
 * @param {Object} props - Component properties.
 * @param {Object} [props.open=false] - Open/close modal status.
 * @param {Function} props.setOpen - Setter function to open / close.
 * @returns {JSX.Element}
 */
const UserInfo = ({ open = false, setOpen, ...props }) => {
  const {
    uuidUser,
    state,
    dispatch,
    actions,
    loading,
    setLoading,
    onSubmit,
    isProfile,
    permissionManage,
    permissionRead,
  } = props;
  const { t, i18n } = useTranslation(["common"], true);
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_LG}px)`);
  const [imageLoading, setImageLoading] = useState(false);
  const [roleChanges, setRoleChanges] = useState(0);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (onSubmit) onSubmit(state, imageLoading, setImageLoading);
  };

  useEffect(() => {
    setRoleChanges((prev) => prev++);
  }, [state.role]);

  const handleOnChange = (key) => (e) => {
    const obj = {};
    obj[key] = e.target.value;
    dispatch({ type: actions.UPDATE_INPUT, payload: obj });
  };

  const handlePrfixChange = (key) => (code, country, e) => {
    const obj = {};
    const value = `${country?.format?.substring(0, 1)}${
      country?.dialCode || code
    }`;
    obj[key] = value;
    dispatch({
      type: actions.UPDATE_INPUT,
      payload: obj,
    });
  };

  const handleItemSelected = (key) => (option, open, setOpen) => {
    const obj = {};
    obj[key] = option.uuid;
    dispatch({
      type: actions.UPDATE_INPUT,
      payload: obj,
    });
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAvatarChange = (image) => {
    dispatch({ type: actions.UPDATE_INPUT, payload: { avatar: image } });
  };

  if (!uuidUser) return <></>;

  return (
    <Modal
      open={open}
      setOpen={handleClose}
      title={t(isProfile ? "edit-your-data" : "user-data")}
      hasForm={true}
      onSubmit={handleSubmit}
      submitText={t("save-changes")}
      cancelText={t("cancel")}
      loading={imageLoading}
      maxWidth={900}
      modalStyles={{ width: matches ? "auto" : 900 }}
      submitProps={{
        permission: permissionManage,
        permissionBehavior: "disabled",
      }}
      actionsMode="end"
      BodyComponent={
        <div className={styles["sc-business--user-info"]}>
          {loading ? (
            <Container>
              <Center>
                <Padding>
                  <Loading />
                </Padding>
              </Center>
            </Container>
          ) : (
            <Container>
              <div className="row" style={{ alignItems: "center" }}>
                <div className={`col-md-auto col-12 ${styles["col-avatar"]}`}>
                  <AEditor
                    readOnly={permissionManage ? !permissionManage() : false}
                    imagePath={state?.avatar}
                    onChange={handleAvatarChange}
                    containerStyle={{
                      width: 145,
                      height: 145,
                    }}
                    avatarStyle={{
                      borderRadius: "50%",
                    }}
                  />
                </div>
                <div className="col-auto g-0" style={{ flex: 1 }}>
                  <Container>
                    <Row gap={20}>
                      {renderComponentWithPermission(
                        permissionRead,
                        <Label
                          light
                          htmlFor={"name"}
                          text={t("name")}
                          noPadding
                        >
                          <InputText
                            id="name"
                            name="name"
                            placeholder={t("write-here")}
                            value={state?.firstName}
                            onChange={handleOnChange("firstName")}
                            readOnly={
                              permissionManage ? !permissionManage() : false
                            }
                            mode="underline"
                          />
                        </Label>
                      )}
                      {renderComponentWithPermission(
                        permissionRead,
                        <Label
                          light
                          htmlFor={"fSurName"}
                          text={t("surname")}
                          noPadding
                        >
                          <InputText
                            id="fSurName"
                            name="fSurName"
                            placeholder={t("write-here")}
                            value={state?.lastName}
                            onChange={handleOnChange("lastName")}
                            readOnly={
                              permissionManage ? !permissionManage() : false
                            }
                            mode="underline"
                          />
                        </Label>
                      )}
                    </Row>
                  </Container>
                </div>
              </div>
              {!isProfile && (
                <Row gap={20}>
                  {renderComponentWithPermission(
                    permissionRead,
                    <Label light text={t("business-email")} noPadding>
                      <InputEmail
                        id="email"
                        name="email"
                        placeholder={t("write-here")}
                        value={state?.email}
                        onChange={handleOnChange("email")}
                        readOnly={
                          permissionManage ? !permissionManage() : false
                        }
                        mode="underline"
                      />
                    </Label>
                  )}
                  {renderComponentWithPermission(
                    permissionRead,
                    <Label light text={t("phone")} noPadding>
                      <InputPhoneWithPrefix
                        required={false}
                        prefixValue={state?.prefix}
                        prefixOnchange={handlePrfixChange("prefix")}
                        phoneValue={state?.phone}
                        phoneOnChange={handleOnChange("phone")}
                        readOnlyPhone={
                          permissionManage ? !permissionManage() : false
                        }
                        readOnlyPrefix={
                          permissionManage ? !permissionManage() : false
                        }
                        codesProps={countryCodesProps(state?.prefix, t, i18n, {
                          buttonStyle: {
                            borderColor: "#E8E4DE",
                            borderRadius: 0,
                            borderTop: 0,
                            borderLeft: 0,
                            borderRight: 0,
                          },
                        })}
                        labelInput={state?.prefix?.length > 4 ? "" : t("phone")}
                        prefixLabel="+XX"
                        mode="underline"
                      />
                    </Label>
                  )}
                </Row>
              )}
              {!isProfile && (
                <div className="row">
                  {renderComponentWithPermission(
                    permissionRead,
                    <Label light htmlFor={"position"} text={t("job")} noPadding>
                      <InputText
                        id="position"
                        name="position"
                        placeholder={t("write-here")}
                        value={state?.jobPosition}
                        onChange={handleOnChange("jobPosition")}
                        readOnly={
                          permissionManage ? !permissionManage() : false
                        }
                        mode="underline"
                      />
                    </Label>
                  )}
                </div>
              )}
              {renderComponentWithPermission(
                permissionRead,
                <Label light text={t("password")} noPadding>
                  <InputPassword
                    id="password"
                    name="password"
                    pattern="(?=[A-Za-z0-9@#$%^&+!=\-?!^*.,:;_\[\]\{\}\(\)><\/'\|~`]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+!=\-?!^*.,:;_\[\]\{\}\(\)><\/'\|~`])(?=.{8,}).*$"
                    placeholder={t("write-here")}
                    value={state?.password}
                    onChange={handleOnChange("password")}
                    readOnly={permissionManage ? !permissionManage() : false}
                    mode="underline"
                  />
                </Label>
              )}
              {renderComponentWithPermission(
                permissionRead,
                <div className="row">
                  <div className="col-12">
                    <Label
                      light
                      text={
                        <Flex alignItems={"center"}>
                          {t("repeat-passwor")}
                          <div className={`${styles["sc-label__hint"]}`}>
                            <Icon
                              name={"info"}
                              style={{ fontSize: "1.2rem", marginLeft: 10 }}
                            />
                            <div
                              className={`${styles["sc-label__hint__desc"]}`}
                            >
                              {t("password-policy")}
                            </div>
                          </div>
                        </Flex>
                      }
                      noPadding
                    >
                      <InputPassword
                        id="repeatedPassword"
                        name="repeatedPassword"
                        pattern="(?=[A-Za-z0-9@#$%^&+!=\-?!^*.,:;_\[\]\{\}\(\)><\/'\|~`]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+!=\-?!^*.,:;_\[\]\{\}\(\)><\/'\|~`])(?=.{8,}).*$"
                        placeholder={t("write-here")}
                        value={state?.repeatedPassword}
                        onChange={handleOnChange("repeatedPassword")}
                        readOnly={
                          permissionManage ? !permissionManage() : false
                        }
                        mode="underline"
                      />
                    </Label>
                  </div>
                </div>
              )}
              {renderComponentWithPermission(
                permissionRead,
                <div className="row">
                  <div className="col-12" style={{ marginTop: -10 }}>
                    <ValidateGroup
                      value={
                        state?.password === state?.repeatedPassword
                          ? 1
                          : undefined
                      }
                      customValidityMessage={t("passwords-not-match")}
                    />
                  </div>
                </div>
              )}

              {!isProfile && (
                <>
                  {renderComponentWithPermission(
                    permissionRead,
                    <Label light text={t("role")} noPadding>
                      <DropDown
                        id="role"
                        name="role"
                        placeholder={t("select-value")}
                        options={userRoleOptions.map((o) => ({
                          uuid: o.uuid,
                          text: t(o.text),
                        }))}
                        required={roleChanges > 0}
                        value={t(
                          userRoleOptions.find((f) => f.uuid === state?.role)
                            ?.text
                        )}
                        handleItemClick={handleItemSelected("role")}
                        readOnly={
                          permissionManage ? !permissionManage() : false
                        }
                        mode="underline"
                      />
                    </Label>
                  )}
                  {renderComponentWithPermission(
                    permissionRead,
                    <Label light text={t("acc-status")} noPadding>
                      <DropDown
                        id="acc_status"
                        name="acc_status"
                        placeholder={t("select-value")}
                        options={orgStatusOptions.map((o) => ({
                          uuid: o.uuid,
                          text: t(o.text),
                        }))}
                        value={t(
                          orgStatusOptions.find((f) => f.uuid === state?.status)
                            ?.text
                        )}
                        handleItemClick={handleItemSelected("status")}
                        readOnly={
                          permissionManage ? !permissionManage() : false
                        }
                        mode="underline"
                      />
                    </Label>
                  )}
                </>
              )}
            </Container>
          )}
        </div>
      }
    />
  );
};

export default UserInfo;
