import {
  CheckBox,
  ChipDropdown,
  ClearButton,
  Flex,
  Icon,
  SCRAPBACK_BLUE_40,
  WARM_NEUTRAL_20,
  WARM_NEUTRAL_30,
  WARM_NEUTRAL_40,
} from "@scrapadev/scrapad-front-sdk";
import { useFiltersProvider } from "../../../providers/filters/FiltersContext";
import { checkIfMultiple } from "../../../utils/filters/functions";
import { TEXT_COLOR_LIGHT } from "../../../utils/variables";
import FilterAutoComplete from "./Autocomplete";

const dropdownPropsSearchSelect = (searchRef, isMultipleFilter) => {
  return {
    onChipDropdownClick: (e, open, setOpen) => {
      e.stopPropagation();
      if (e.target.className.indexOf("sc-chip") > -1 || !isMultipleFilter) {
        setOpen(!open);
        if (searchRef) searchRef.current.handleClose = setOpen;
      }
    },
    dropDownStyle: {
      padding: 0,
    },
    dropDownItemStyle: {
      padding: 0,
      background: "white",
    },
  };
};

const FilterSelectionMultiple = ({
  filter,
  addFilter,
  chipProps,
  withSearch = false,
  textStyles = {},
  groupedStyle = {},
  itemSpanStyle = {},
  itemCheckStyle = {},
  itemCheckedStyle = {},
  autoCompleteInputProps = {},
  type,
}) => {
  const isCatalog = filter?.catalogName;
  const isGrouped = filter?.groupedChips;
  const selectedFilters = filter?.selected;
  const groupedCondition = isGrouped && selectedFilters.length > 0;
  const { dispatch, actions, filterChanged } = useFiltersProvider();
  const defaultGroupedStyle = {
    marginLeft: 5,
    borderRadius: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "1px 5px",
    minWidth: 17,
    boxSizing: "content-box",
    lineHeight: "13px",
    marginRight: -10,
  };
  const defaultSpanStyle = {
    minWidth: 16,
    width: 16,
    height: 16,
    marginRight: 13,
    paddingRight: 0,
  };
  const defaultCheckStyle = {
    paddingRight: 0,
    border: `2px solid ${TEXT_COLOR_LIGHT}`,
    borderRadius: 2,
    minWidth: 16,
    width: 16,
    height: 16,
  };
  const defaultCheckedStyle = {
    ...defaultCheckStyle,
    border: `2px solid ${SCRAPBACK_BLUE_40}`,
  };

  const autoCompleteCommonStyles = {
    itemSpanStyle: { ...defaultSpanStyle, ...itemSpanStyle },
    itemCheckStyle: { ...defaultCheckStyle, ...itemCheckStyle },
    itemCheckedStyle: { ...defaultCheckedStyle, ...itemCheckedStyle },
    textStyles: textStyles,
    inputProps: {
      ...autoCompleteInputProps,
      leadingIcon: isCatalog ? (
        <Icon
          name={"search"}
          color={WARM_NEUTRAL_40}
          style={{
            fontSize: 22,
            position: "relative",
            left: -25,
            top: 0,
            zIndex: 9,
          }}
        />
      ) : undefined,
      useSearch: withSearch,
      containerStyles: {
        padding: isCatalog ? "0 15px 15px 15px" : 0,
        border: `0.5px solid ${WARM_NEUTRAL_20}`,
        borderRadius: 8,
        top: isCatalog ? 0 : 15,
      },
      resultsStyles: isCatalog
        ? {
            paddingBottom: 15,
            paddingLeft: 15,
            paddingRight: 15,
            paddingTop: 0,
            border: "none",
            overflowX: "hidden",
            boxShadow: "none",
            borderRadius: 0,
            maxHeight: 200,
            overflowY: "auto",
            top: 0,
            left: 0,
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
          }
        : {
            paddingBottom: 15,
            paddingLeft: 15,
            paddingRight: 15,
            paddingTop: 0,
            overflowX: "hidden",
          },
      resultsItemStyles: isCatalog ? {} : {},
      leadingStyles: isCatalog
        ? {
            left: 30,
            top: 12,
          }
        : {},
      resultsListStyles: isCatalog
        ? {}
        : {
            marginTop: -10,
            padding: withSearch ? "12px 0 0 0" : "12px 10px 7px 10px",
          },
      inputStyles: isCatalog
        ? {
            borderBottom: `0.5px solid ${WARM_NEUTRAL_30}`,
            borderTop: "none",
            borderLeft: "none",
            borderRight: "none",
            borderRadius: 0,
            outline: "none",
            position: "relative",
            top: 0,
            left: 0,
            paddingTop: 12,
            paddingBottom: 12,
            paddingRight: 40,
            paddingLeft: 44,
            marginBottom: 32,
          }
        : {
            padding: 0,
            display: "none",
          },
    },
  };

  const isMultipleFilter = checkIfMultiple(filter);

  const renderText = () => {
    return filter.label && typeof filter.label === "function"
      ? filter.label(filter)
      : filter?.label;
  };

  const renderGrouped = () => {
    return (
      <>
        {renderText()}
        <span style={{ ...defaultGroupedStyle, ...groupedStyle }}>
          {filter?.selected?.length}
        </span>
      </>
    );
  };

  return (
    <ChipDropdown
      type={type}
      component={groupedCondition ? renderGrouped() : undefined}
      text={renderText()}
      customRenderItem={(item, openDropdown, setOpenDropdown) => {
        const isChecked = filter?.selected?.find((f) => f.uuid === item.uuid);
        return (
          <ClearButton
            style={{ padding: 0 }}
            onClick={(e) => {
              if (isMultipleFilter) {
                e.stopPropagation();
              }
              if (filter.addFilter) {
                filter.addFilter(item, filter);
              } else {
                if (addFilter && !item.component) {
                  addFilter(item, filter);
                }
              }
            }}
          >
            {item.component ? (
              item.component
            ) : (
              <Flex>
                <span style={{ ...defaultSpanStyle, ...itemSpanStyle }}>
                  <CheckBox
                    checked={isChecked}
                    style={
                      isChecked
                        ? { ...defaultCheckStyle, ...itemCheckedStyle }
                        : { ...defaultCheckStyle, ...itemCheckStyle }
                    }
                  />
                </span>
                <span style={textStyles}>{item?.name}</span>
              </Flex>
            )}
          </ClearButton>
        );
      }}
      items={[
        {
          uuid: 1,
          component: filter?.catalogName ? (
            <FilterAutoComplete
              filter={filter}
              catalogName={filter?.catalogName}
              filterKey={filter?.slug}
              ref={filter?.ref}
              {...autoCompleteCommonStyles}
            />
          ) : (
            <FilterAutoComplete
              filter={filter}
              options={filter?.items}
              handleItemClick={(option) => {
                filterChanged.current = true;
                dispatch({
                  type: isMultipleFilter
                    ? actions.ADD_FILTER
                    : actions.ADD_UNIQUE_FILTER,
                  payload: {
                    filter: { slug: filter?.slug },
                    item: option,
                  },
                });
              }}
              {...autoCompleteCommonStyles}
            />
          ),
        },
      ]}
      {...(filter.dropDownProps
        ? {
            ...dropdownPropsSearchSelect(filter?.ref, isMultipleFilter),
            ...filter.dropDownProps,
          }
        : { ...dropdownPropsSearchSelect(filter?.ref, isMultipleFilter) })}
      {...chipProps}
    />
  );
};

export default FilterSelectionMultiple;
