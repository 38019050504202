import {
  Alert,
  Flex,
  Icon,
  Typography,
  WARM_NEUTRAL_50,
  BODY_LARGE,
  BODY_SMALL,
  WARNING_COLOR,
} from "@scrapadev/scrapad-front-sdk";
import CardBox from "../../../components/ui/box/CardBox";
import { useTranslation } from "../../../utils/hooks";
import VerificationValidationSwitch from "../verification/ValidationSwitch";

/**
 * Verification widget for dashboard.
 * @param {Object} props - Component properties.
 * @param {String} props.id - Business id.
 * @param {Object} props.state - Data state.
 * @returns {JSX.Element}
 */
const WidgetBusinessVerification = ({ id, state }) => {
  const { t, tMultiple } = useTranslation(["common", "transactions"]);
  const validation = state?.validation;
  const pending = validation
    ?.map((v) => v?.pendingDocs)
    ?.reduce((acc, current) => acc + current, 0);
  const parseText = (accepted, total) =>
    tMultiple("accepted-documents-plural", [accepted, total]);
  return (
    <CardBox
      title={t("verification")}
      action={t("see-more")}
      href={`/business/${id}/verification`}
    >
      {pending && pending > 0 ? (
        <Alert
          type="info"
          style={{ margin: "0 0 20px 0" }}
          iconStyle={{ fontSize: "1.1rem" }}
        >
          <Flex justifyContent={"space-between"}>
            {tMultiple(
              state?.pending > 1
                ? "pending-documents-plural"
                : "pending-documents",
              [pending]
            )}
          </Flex>
        </Alert>
      ) : (
        <></>
      )}
      {validation?.map((v) => {
        const type = v?.verificationType || "";
        const validatedDocuments = v?.validatedDocs || 0;
        const totalDocuments = v?.totalDocs || 0;
        return (
          <Flex
            key={type}
            justifyContent={"space-between"}
            style={{ marginBottom: 15 }}
          >
            <Flex style={{ gap: 10 }}>
              <VerificationValidationSwitch
                id={id}
                type={type}
                showLabel={false}
              />
              <Typography
                style={{
                  fontWeight: 500,
                  margin: 0,
                }}
              >
                {t(type)}
              </Typography>
            </Flex>
            <Flex style={{ gap: 10 }}>
              <Typography
                typography={"small"}
                style={{
                  ...BODY_SMALL,
                  color: WARM_NEUTRAL_50,
                  margin: 0,
                }}
              >
                {parseText(validatedDocuments, totalDocuments)}
              </Typography>
            </Flex>
          </Flex>
        );
      })}
    </CardBox>
  );
};

export default WidgetBusinessVerification;
