import { adStatus } from "../variables";

export const adTypeFilterItems = [
  { uuid: "purchase", name: "buy" },
  {
    uuid: "sale",
    name: "sell",
  },
];

export const adStatusItems = [
  adStatus.PUBLISHED,
  adStatus.DRAFT,
  adStatus.REVIEW_PENDING,
  adStatus.COVERED,
  adStatus.BLOCKED,
  adStatus.REMOVED,
];

export const transactionsPhaseItems = [
  "NEGOTIATION",
  "FINISH",
  "TRANSPORT",
  "CONTRACT",
];

export const transactionsNegotiation = ["pending", "launched"];

export const transactionsContracts = ["sign_pending", "deposit_pending"];

export const transactionsTransport = [
  "pending",
  "load_coordinated",
  "load_confirmed",
  "unload_coordinated",
  "unload_confirmed",
];

export const businessVerificationItems = [
  "not_started",
  "pending",
  "closed",
  "documentation_error",
  "verified",
];
