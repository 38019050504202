import { useState } from "react";
import { useTranslation } from "../../../utils/hooks";
import {
  BREAKPOINT_LG,
  Modal,
  useMatchMedia,
} from "@scrapadev/scrapad-front-sdk/dist/index";
import ModalText from "../../ui/modal/text/ModalText";

/**
 * Modal for show a warning when data needs to be saved at leaving the page.
 * @param {Object} props - Component properties.
 * @param {Object} [props.open=false] - Open/close modal status.
 * @param {Function} props.setOpen - Setter function to open / close.
 * @returns {JSX.Element}
 */
const ModalSaveData = ({ open = false, setOpen, ...props }) => {
  const { t } = useTranslation(["common"]);
  const width = 800;
  const [loading, setLoading] = useState(false);
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_LG}px)`);
  const unblockModal = props.unblockModal ?? function () {};
  const saveAndExit = props.saveAndExit ?? false;
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (typeof props.submitCallback === "function")
      props.submitCallback(e, loading, setLoading, unblockModal);
  };
  return (
    <Modal
      t={t}
      open={open}
      setOpen={setOpen}
      title={t("save-before-leave")}
      hasForm={true}
      onCancel={props.onCancel}
      onSubmit={handleSubmit}
      submitText={saveAndExit ? t("save_and_exit") : t("keep-editing")}
      cancelText={t("discard-changes")}
      loading={loading}
      maxWidth={width}
      modalStyles={{ width: matches ? "auto" : width }}
      BodyComponent={<ModalText text={t("save_and_exit_desc")} />}
    />
  );
};

export default ModalSaveData;
