import { Label, WARM_NEUTRAL_60 } from "@scrapadev/scrapad-front-sdk";

const LoginLabel = ({ children, hint, ...props }) => {
  const moreInfoStyles = { color: WARM_NEUTRAL_60 };
  const headingStyles = { margin: 0 };
  const cmpProps = hint
    ? {
        moreInfoStyles,
        headingStyles,
        useHintTooltip: true,
        hintTooltipProps: {
          alignment: "bottom",
          style: {
            maxWidth: 320,
          },
        },
        hintProps: {
          color: WARM_NEUTRAL_60,
          style: {
            fontSize: 16,
            position: "relative",
          },
        },
      }
    : {
        moreInfoStyles,
        headingStyles,
      };
  return (
    <Label hint={hint} {...cmpProps} {...props}>
      {children}
    </Label>
  );
};

export default LoginLabel;
