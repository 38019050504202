import React from "react";
import Alert from "../alert/Alert";
import styles from "./LoginWrap.module.scss";
import { Heading, Typography } from "@scrapadev/scrapad-front-sdk";

function LoginWrap({
  children,
  title,
  description,
  errorMessage,
  sucessMessage,
}) {
  return (
    <div className={`${styles["c-login-wrap"]}`}>
      <div className={`${styles["c-login-wrap__inner"]}`}>
        {title ? (
          <Heading type={3} style={{ marginBottom: 40, textAlign: "center" }}>
            {title}
          </Heading>
        ) : (
          <></>
        )}
        {description ? (
          <Typography style={{ marginBottom: 40 }}>{description}</Typography>
        ) : (
          <></>
        )}
        {children}

        {errorMessage ? (
          <>
            <Alert type="error">{errorMessage}</Alert>
          </>
        ) : (
          <></>
        )}
        {sucessMessage ? (
          <>
            <Alert type="sucess">{sucessMessage}</Alert>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default LoginWrap;
