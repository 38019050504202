/**
 * Helper function to build data for creating a favorite material.
 * @param {Object} modalState - New material data.
 * @returns {Array<{uuidParent: String, uuidMaterial: String, uuidMaterialTypes: String, adType: String}, Boolean>}
 */
export const buildAddFavoriteMaterialsData = (modalState) => {
  let error = false;
  const { adType, category, material, type } = modalState.materials;
  const data = {
    material_id: "1",
    ad_type: [],
  };
  if (adType?.sale) data.ad_type.push("sale");
  if (adType?.purchase) data.ad_type.push("purchase");
  if (type?.uuidSelected?.length > 0) data.material_types = type?.uuidSelected;
  if (!data.material_id) error = true;
  if (!adType.sale && !adType.purchase) error = true;
  return [data, error];
};

/**
 * Helper function to build data for editing a favorite material.
 * @param {Object} modalState - New material data.
 * @returns {Array<{uuidParent: String, uuidMaterial: String, uuidMaterialTypes: String, adType: String}, Boolean>}
 */
export const buildEditFavoriteMaterialsData = (modalState) => {
  let error = false;
  const { adType } = modalState.materials;
  const data = {
    ad_type: [],
  };
  if (adType?.sale) data.ad_type.push("sale");
  if (adType?.purchase) data.ad_type.push("purchase");
  if (!adType.sale && !adType.purchase) error = true;
  return [data, error];
};

export const getLinkToMarketplace = (material, type, i18n) => {
  const materialName = material?.material || "";
  const value = type?.value || "";
  const adType = type?.adType || "";
  const label = type.label;
  const name = label === "ALL" ? materialName : label;
  return `${process.env.REACT_APP_MARKETPLACE_URL}/${i18n.language}/?materialCategory=${value}&adType=${adType}&label=${name}`;
};
