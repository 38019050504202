import { Chip, ChipDropdown, InputDate } from "@scrapadev/scrapad-front-sdk";
import { useEffect, useRef, useState } from "react";
import { capitalizeFirstLetter, formatDate } from "../../../utils/functions";
import { useTranslation } from "../../../utils/hooks";
import { actions } from "../reducer/reducer";

const NotificationsFilterDate = ({ data, dispatchData }) => {
  const { t, i18n } = useTranslation(["common"]);
  const [startDate, setStartDate] = useState(
    data?.filters?.date?.selected?.from
  );
  const [endDate, setEndDate] = useState(data?.filters?.date?.selected?.to);
  const dateRangeNumberClicks = useRef(0);
  const handleDateFilter = (date) => {
    const [start, end] = date;
    dateRangeNumberClicks.current++;
    setStartDate(start);
    setEndDate(end);
    if (dateRangeNumberClicks.current === 2) {
      dispatchData({
        type: actions.ADD_FILTER_DATE,
        payload: { from: start.getTime(), to: end.getTime() },
      });
    }
  };

  const handleDeleteDateFilter = () => {
    dispatchData({ type: actions.DELETE_FILTER_DATE });
  };

  return (
    <>
      <ChipDropdown
        text={data.filters.date.label}
        items={[
          <InputDate
            inline
            selectsRange
            value={startDate}
            startDate={startDate}
            endDate={endDate}
            handleOnChange={handleDateFilter}
            languageCode={i18n.language}
            numberOfYears={10}
            yearsBackwards
          />,
        ]}
        onChipDropdownClick={(e, open, setOpen) => {
          e.stopPropagation();
          setOpen(true);
          if (
            e.target.classList.contains("react-datepicker__day") &&
            dateRangeNumberClicks.current === 2
          ) {
            setOpen(false);
            setStartDate(null);
            setEndDate(null);
            dateRangeNumberClicks.current = 0;
          }
        }}
        dropDownStyle={{
          padding: 0,
          boxShadow: "none",
        }}
        dropDownItemStyle={{
          padding: 0,
        }}
      />
      {data.filters.date.selected.from && data.filters.date.selected.to && (
        <Chip
          text={`${capitalizeFirstLetter(
            formatDate(
              data.filters.date.selected.from,
              i18n,
              false,
              "dd/MM/yyyy"
            )
          )} - ${capitalizeFirstLetter(
            formatDate(data.filters.date.selected.to, i18n, false, "dd/MM/yyyy")
          )}`}
          handleClick={handleDeleteDateFilter}
          showIcon
        />
      )}
    </>
  );
};

export default NotificationsFilterDate;
