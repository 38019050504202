import {
  BREAKPOINT_SM,
  ClearButton,
  Icon,
  Row,
  SCRAPBACK_BLUE_40,
  Typography,
  useMatchMedia,
  WARM_NEUTRAL_30,
} from "@scrapadev/scrapad-front-sdk";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "../../../utils/hooks";

const NotificationsPreferencesLink = () => {
  const { t } = useTranslation("notifications");
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_SM}px)`);
  const navigate = useNavigate();
  const buttonStyles = matches
    ? {
        width: 46,
        height: 32,
        background: "white",
        border: `0.5px solid ${WARM_NEUTRAL_30}`,
        borderRadius: 25,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }
    : {};
  return (
    <ClearButton onClick={() => navigate("/notifications/preferences")}>
      <Row gap={10} align="center" style={{ curor: "pointer" }}>
        <Icon
          name="settings"
          color={SCRAPBACK_BLUE_40}
          style={{
            fontSize: 16,
            ...buttonStyles,
          }}
        />
        {!matches && (
          <Typography
            bold
            typography="small-reg"
            style={{
              marginBottom: 0,
              color: SCRAPBACK_BLUE_40,
            }}
          >
            {t("preferences")}
          </Typography>
        )}
      </Row>
    </ClearButton>
  );
};

export default NotificationsPreferencesLink;
