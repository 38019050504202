import {
  WARM_NEUTRAL_10,
  WARM_NEUTRAL_40,
  WARM_NEUTRAL_50,
  WARM_NEUTRAL_80,
} from "@scrapadev/scrapad-front-sdk";
import { addMonths } from "date-fns";
import { FlagMap } from "../components/ui/icons/general/Flags";

export const DEFAULT_LANGUAGE = "es";

// COLORS
export const GRAY_100 = WARM_NEUTRAL_10;
export const TEXT_COLOR = WARM_NEUTRAL_80;
export const TEXT_COLOR_LIGHT = WARM_NEUTRAL_50;
export const RED_COLOR_100 = "#E15050";
export const YELLOW_COLOR_100 = "#FFC800";
export const GREEN_COLOR_100 = "#60C386";
export const GRAY_NEUTRAL_40 = WARM_NEUTRAL_40;
export const WARNING_COLOR = "#D1A400";
export const WARNING_BG = "#FFF4CC";
export const ERROR_COLOR = RED_COLOR_100;
export const ERROR_BG = "#F9DCDC";
export const SUCCESS_COLOR = GREEN_COLOR_100;
export const SUCCESS_BG = "#DFF3E7";
export const INFO_COLOR = "#63A1FF";
export const INFO_BG = "#E2ECFA";
export const BLUE_COLOR = "#3784F6";

export const modalTypes = {
  TRANSACTIONS_ANSWER: "transactions-answer",
  TRANSACTIONS_RAW_WEIGHT: "transactions-raw-weight",
  END_TRANSACTION: "end-transaction",
  INVITE_USER: "invite_user",
  USER_INFO: "user_info",
  ADD_MATERIAL: "add_material",
  SAVE_DATA: "save_data",
  VERIFICATION_DOCUMENT_DETAILS: "verification_document_details",
  VERIFICATION_WARNING: "verification_warning",
  DASHBOARD_NEW_ADDRESS: "dashboard_new_address",
  FAVORITES_EDIT_SEARCH: "favorites_edit_search",
  FAVORITES_EDIT_MATERIAL: "favorites_edit_material",
  FAVORITES_DELETE: "favorites_delete",
  BLOCK_ORG_WARNING: "block_org_warning",
  USER_WARNING: "user_warning",
  ADS_TRANSACTIONS: "ads_transactions",
  ADS_ACTIONS: "ads_actions",
  ADS_TRANSLATIONS: "ads_translations",
  WALLET_BALACE_ACTIONS: "wallet_balance_actions",
  FINANCES_LINK: "finances_link",
  FINANCES_COMMENT: "finances_comment",
  LOGISTICS_ADD_EDIT_PROVIDER: "logistics_add_edit_provider",
  LOGISTICS_DELETE_PROVIDER: "logistics_delete_provider",
  CHANGE_FEE: "change_fee",
  LOGISTICS_INCORPORATE_TRANSACTION: "logistics_incorporate_transaction",
  LOGISTICS_VIEW_QUOTATION: "logistics_view_quotation",
  LOGISTICS_ADD_QUOTATION: "logistics_add_quotation",
  LOGISTICS_ADD_QUOTATION_WARN: "logistics_add_quotation_warn",
  LOGISTICS_EDIT_ROUTE: "logistics_edit_route",
  LOGISTICS_EDIT_LOAD_CHARACTERISTICS: "logistics_edit_load_characteristics",
  INCOTERM_INFO: "incoterm_info",
  PAYMENT_METHOD_INFO: "payment_method_info",
  CHANGE_ICOTERM: "change_icoterm",
  CHANGE_PAYMENT_METHOD: "change_payment_method",
  MARK_PAYMENTS: "mark_payments",
  MANUAL_PAYMENTS_DEPOSIT: "manual_payments_deposit",
  CHANGE_FINANCIATION: "change_financiation",
  OPPORTUNITY_COUNTER_OFFER: "OPPORTUNITY_COUNTER_OFFER",
  OPPORTUNITY_REQUEST_INFORMATION: "OPPORTUNITY_REQUEST_INFORMATION",
  NIF_ALREADY_EXISTS: "NIF_ALREADY_EXISTS",
  LOGISTIC_CHANGE_TRACKING_STATUS: "LOGISTIC_CHANGE_TRACKING_STATUS",
  LOGISTIC_PRICING_UPLOAD: "LOGISTIC_PRICING_UPLOAD",
  OPPORTUNITY_OFFER_EDIT_PAYMENT_CONDITIONS:
    "OPPORTUNITY_OFFER_EDIT_PAYMENT_CONDITIONS",
  ROLES_INFO: "ROLES_INFO",
};

export const storageKeys = {
  LANGUAGE: "language",
  USER_DEFINED_LANGUAGE: "user_language",
};

export const transactionTypeInfo = {
  seller: { color: "#60C386", name: "seller", short_name: "V" },
  buyer: { color: "#2A336B", name: "buyer", short_name: "C" },
  selling: { color: "#60C386", name: "selling", short_name: "V" },
  buying: { color: "#2A336B", name: "buying", short_name: "C" },
  system: { color: "#28809B", name: "system", short_name: "S" },
  scrapad: { color: "#28809B", name: "scrapad", short_name: "S" },
  logistics: { color: "#6D50E1", name: "logistics", short_name: "L" },
  bo: { color: "#63A1FF", name: "bo", short_name: "B" },
  finances: { color: "#3B5FBB", name: "finances", short_name: "F" },
  unverified: { color: "#63A1FF", name: "unverified", short_name: "" },
  incomplete: { color: "#FFC800", name: "incomplete", short_name: "" },
  blocked: { color: RED_COLOR_100, name: "blocked", short_name: "" },
  documentation_error: {
    color: RED_COLOR_100,
    name: "blocked",
    short_name: "",
  },
  verified: { color: "#60C386", name: "verfied", short_name: "" },
  removed: { color: "#B1B1B1", name: "removed", short_name: "" },
  pending: { color: "#63A1FF", name: "removed", short_name: "" },
};

export const formEvents = {
  SEND_MD: "SEND_MD",
  REPLY_MD: "REPLY_MD",
  CREATE_NOTE: "CREATE_NOTE",
  RETURN_PAYMENT_TOLERANCE: "RETURN_PAYMENT_TOLERANCE",
  RETURN_DEPOSIT: "RETURN_DEPOSIT",
  RETURN_PAYMENT: "RETURN_PAYMENT",
  ADD_GROSS_WEIGHT: "ADD_GROSS_WEIGHT",
  ADD_SAMPLE_REQUEST_DATA: "ADD_SAMPLE_REQUEST_DATA",
  UPDATE_SAMPLE_REQUEST_DATA: "UPDATE_SAMPLE_REQUEST_DATA",
  ADD_VERIFICATION: "ADD_VERIFICATION",
  UPDATE_VERIFICATION: "UPDATE_VERIFICATION",
  ADD_LOGISTIC: "ADD_LOGISTIC",
  UPDATE_LOGISTIC: "UPDATE_LOGISTIC",
  TRANSPORT_COLLECTION_DATE_UPDATED: "TRANSPORT_COLLECTION_DATE_UPDATED",
  TRANSPORT_DELIVERY_DATE_UPDATED: "TRANSPORT_DELIVERY_DATE_UPDATED",
  TRANSPORT_TRANSIT_DATE_UPDATED: "TRANSPORT_TRANSIT_DATE_UPDATED",
  PURCHASE_SALE_SCRAPAD_OK: "PURCHASE_SALE_SCRAPAD_OK",
  PURCHASE_SALE_SCRAPAD_KO: "PURCHASE_SALE_SCRAPAD_KO",
  END_TRANSACTION: "CANCEL_OFFER_FROM_BO",
  CHANGE_FEE: "CHANGE_FEE",
  CHANGE_ICOTERM: "CHANGE_INCOTERM",
  CHANGE_PAYMENT_METHOD: "CHANGE_PAYMENT_METHOD",
  MARK_DEPOSIT_RECEIVED: "MARK_DEPOSIT_RECEIVED",
  MAKE_PAYMENT: "MAKE_PAYMENT",
  REQUEST_DEPOSIT: "REQUEST_DEPOSIT",
  SET_QUOTATION_FINANCING: "SET_QUOTATION_FINANCING",
  DEPOSIT_DONE: "DEPOSIT_DONE",
};

export const transportCostStates = {
  PENDING: "PENDING",
  LAUNCHED: "LAUNCHED",
  EXPIRATED: "EXPIRATED",
  PENDING_COORDINATION: "PENDING_COORDINATION",
  COORDINATE: "COORDINATE",
};

export const transportLoadUnloadStates = {
  PENDING_COORDINATION: "PENDING_COORDINATION",
  COORDINATE: "COORDINATE",
  CONFIRMED: "CONFIRMED",
  TICKET: "TICKET",
  BL_DRAFT_SENDED: "BL_DRAFT_SENDED",
  BL_SENDED: "BL_SENDED",
  DOCUMENTATION_SENDED: "DOCUMENTATION_SENDED",
  UNLOADED: "UNLOADED",
  SHIPMENT_PENDING: "SHIPMENT_PENDING",
  SHIPMENT_CONFIRMED: "SHIPMENT_CONFIRMED",
  ARRIVE_PENDING: "ARRIVE_PENDING",
  ARRIVE_CONFIRMED: "ARRIVE_CONFIRMED",
  PENDING_PORT_ARRIVAL: "PENDING_PORT_ARRIVAL",
  CONFIRMED_PORT_ARRIVAL: "CONFIRMED_PORT_ARRIVAL",
  DISPATCHED: "DISPATCHED",
  PENDING_DISPATCH: "PENDING_DISPATCH",
};

export const transportBoardingStates = {
  PENDING_DOCUMENTATION: "PENDING_DOCUMENTATION",
  PENDING_DISPATCH: "PENDING_DISPATCH",
  DOCUMENTATION_SENDED: "DOCUMENTATION_SENDED",
  DISPATCHED: "DISPATCHED",
  BOARDING_CONFIRMED: "CONFIRMED",
  TICKET: "TICKET",
};

export const responseTypes = {
  NEED_REPLY: "NEED_REPLY",
  REPORT_PROBLEM: "REPORT_PROBLEM",
  PURCHASE_SALE_PENDING_SCRAPAD: "PURCHASE_SALE_PENDING_SCRAPAD",
  PURCHASE_SALE_PENDING_SCRAPAD_READ: "PURCHASE_SALE_PENDING_SCRAPAD_READ",
  PURCHASE_SALE_CONTRACT: "PURCHASE_SALE_CONTRACT",
  CONFIRM_DEPOSIT: "CONFIRM_DEPOSIT",
};

export const TrackingStatusTypes = {
  load: {
    text: "load",
    items: [
      {
        text: "pending_coordination",
        slug: transportLoadUnloadStates.PENDING_COORDINATION,
        type: "load",
      },
      {
        text: "coordinated-2",
        slug: transportLoadUnloadStates.COORDINATE,
        type: "load",
      },
      {
        text: "confirmed-f",
        slug: transportLoadUnloadStates.CONFIRMED,
        type: "load",
      },
    ],
  },
  shipment: {
    text: "boarding",
    items: [
      {
        text: "documentation_pending",
        slug: transportBoardingStates.PENDING_DOCUMENTATION,
        type: "boarding",
      },
      {
        text: "dispatch_pending",
        slug: transportBoardingStates.PENDING_DISPATCH,
        type: "boarding",
      },
      {
        text: "documentation_sended",
        slug: transportBoardingStates.DOCUMENTATION_SENDED,
        type: "boarding",
      },
      {
        text: "dispatched",
        slug: transportBoardingStates.DISPATCHED,
        type: "boarding",
      },
      {
        text: "confirmed",
        slug: transportBoardingStates.BOARDING_CONFIRMED,
        type: "boarding",
      },
    ],
  },
  unload: {
    text: "unload",
    items: [
      {
        slug: transportLoadUnloadStates.PENDING_PORT_ARRIVAL,
        text: "port-arrival-pending",
        type: "unload",
      },
      {
        slug: transportLoadUnloadStates.CONFIRMED_PORT_ARRIVAL,
        text: "port-arrival-confirmed",
        type: "unload",
      },
      {
        slug: transportLoadUnloadStates.PENDING_DISPATCH,
        text: "dispatch-pending",
        type: "unload",
      },
      {
        slug: transportLoadUnloadStates.DISPATCHED,
        text: "dispatched",
        type: "unload",
      },
      {
        slug: transportLoadUnloadStates.PENDING_COORDINATION,
        text: "pending-coordinated",
        type: "unload",
      },
      {
        slug: transportLoadUnloadStates.COORDINATE,
        text: "coordinated-2",
        type: "unload",
      },
      {
        slug: transportLoadUnloadStates.CONFIRMED,
        text: "confirmed-f",
        type: "unload",
      },
      {
        slug: transportLoadUnloadStates.TICKET,
        text: "ticket",
        type: "unload",
      },
    ],
  },
};

export const logActions = {
  NEGOTIATION: "offers",
  MESSAGE: "messages",
  CONTRACT: "contracts",
  SAMPLE_REQUEST: "samples",
  LOGISTIC: "transport",
  TRANSPORT: "transport",
  VERIFICATION: "verification",
  PAYMENTS: "payments",
  PAYMENT: "payments",
  NOTES: "notes",
  FINISH: "finish",
};

export const logTypes = {
  seller: "seller",
  buyer: "buyer",
  logistics: "logistics",
  finances: "finances",
  bo: "bo",
  system: "system",
  scrapad: "system",
};

export const OWNER_TYPES = {
  seller: "seller",
  buyer: "buyer",
};

export const rawEvents = [
  formEvents.ADD_VERIFICATION,
  formEvents.UPDATE_VERIFICATION,
  formEvents.ADD_LOGISTIC,
  formEvents.UPDATE_LOGISTIC,
  formEvents.TRANSPORT_DELIVERY_DATE_UPDATED,
  formEvents.TRANSPORT_COLLECTION_DATE_UPDATED,
  formEvents.ADD_SAMPLE_REQUEST_DATA,
  formEvents.UPDATE_SAMPLE_REQUEST_DATA,
  formEvents.RETURN_DEPOSIT,
  formEvents.RETURN_PAYMENT,
  formEvents.RETURN_PAYMENT_TOLERANCE,
];

export const rawVerificationEvents = [
  formEvents.ADD_VERIFICATION,
  formEvents.UPDATE_VERIFICATION,
];

export const rawLogisticsEvents = [
  formEvents.ADD_LOGISTIC,
  formEvents.UPDATE_LOGISTIC,
  formEvents.TRANSPORT_DELIVERY_DATE_UPDATED,
  formEvents.TRANSPORT_COLLECTION_DATE_UPDATED,
];
export const rawSampelsEvents = [
  formEvents.ADD_SAMPLE_REQUEST_DATA,
  formEvents.UPDATE_SAMPLE_REQUEST_DATA,
];
export const rawReturnEvents = [
  formEvents.RETURN_DEPOSIT,
  formEvents.RETURN_PAYMENT,
  formEvents.RETURN_PAYMENT_TOLERANCE,
];

export const mapSlugToChip = {
  pending: "info2",
  invitation_sended: "info2",
  blocked: "error",
  banned: "error",
  active: "success",
  selling: "seller",
  seller: "seller",
  buying: "buyer",
  buyer: "buyer",
  published: "info",
  covered: "info3",
  unverified: "info2",
  "incomplete-register": "warning",
  removed: "removed",
  draft: "temporal",
  "invitation-requested": "warning",
};

export const businessSteps = [
  { label: "general_information", to: "/about" },
  { label: "addresses", to: "/" },
  { label: "users", to: "../users" },
  { label: "verification", to: "/" },
  { label: "ads", to: "/" },
  { label: "transactions", to: "/transactions" },
  { label: "favorites", to: "/" },
];

export const adsDefaults = {
  values: [],
  uuidSelected: [],
  nameSelected: [],
  options: [],
  open: false,
};

export const currencySymbolMap = {
  EUR: "€",
  USD: "$",
};
export const transactionFormMessageDefault = {
  destination: { seller: false, buyer: false },
  files: [],
  content: "",
  hiddenDestination: "",
  loading: false,
};
export const transactionFormNotesDefault = {
  files: [],
  content: "",
  loading: false,
  hiddenDestination: "",
  destinations: [],
};
export const transactionFormVerificationDefault = {
  suplier: "",
  price: "",
  state: "",
  loading: false,
  update: false,
};
export const transactionFormTransportDefault = {
  files: [],
  suplier: "",
  untilDate: addMonths(new Date().getTime(), 1).getTime(),
  numLote: "",
  unitPrice: "",
  estimatedDate: null,
  estimatedSchedule: {
    from: "",
    to: "",
  },
  state: "",
  stateName: "",
  loading: false,
  update: false,
  incoterm: "",
  shippingDeadline: "",
  equipment: [],
};
export const transactionFormSamplesDefault = {
  deliveryPrice: "",
  estimatedDate: null,
  state: "",
  loading: false,
  update: false,
};
export const transactionFormReturnDefault = {
  destination: { seller: false, buyer: true },
  hiddenDestination: "",
  valueTolerance: "",
  valueBail: "",
  valueOther: "",
  comments: "",
  content: "",
  loading: false,
  update: false,
};

export const transactionFormFinancesDefault = {
  destination: { seller: false, buyer: true },
  hiddenDestination: "",
  amount: "",
  concept: "",
  deposit: "",
  loading: false,
  update: false,
};

export const verificationStatusToChip = {
  success: "success",
  expired: "error",
  illegible: "error",
  incorrect: "error",
  rejected: "error",
  "pending-file-save": "warning",
  draft: "info4",
  received: "info2",
  "on-hold": "info2",
  accepted: "success",
  unreadable: "error",
  "wrong-type": "error",
  "wrong-name": "error",
  duplicated: "error",
  unknown: "normal",
};

export const verificationStatusToChange = {
  "on-hold": "draft-pure",
  received: "draft-pure",
  accepted: "launched",
  rejected: "expired",
  unreadable: "expired",
  expired: "expired",
  "wrong-type": "expired",
  "wrong-name": "expired",
  draft: "purple20",
  duplicated: "expired",
  unknown: "normal",
};

export const addressesEquipment = [
  { uuid: "cf0197fb-a0f4-4465-8278-aa966648f7e3", label: "dock" },
  { uuid: "a8ad28f9-8aa2-4084-b48d-d47a1f034df4", label: "pit" },
  { uuid: "c0284465-6188-4e60-a36b-24af08f55181", label: "pallet_truck" },
  { uuid: "755d44f8-2fd6-4770-a162-1540814cd8c1", label: "octopus" },
  { uuid: "22b2bb9a-3a0c-46a8-a9a2-5597f5664a97", label: "crane" },
];

export const orgStatus = {
  ACTIVE: "active",
  BANNED: "banned",
  UNVERIFIED: "unverified",
  INCOMPLETE: "incomplete-register",
  KYC_COMPLETE: "kyc-complete",
  CLOSED: "closed",
};

export const orgStatusOptions = [
  { uuid: "active", text: "active" },
  { uuid: "banned", text: "blocked" },
  //{ uuid: "invitation-requested", text: "invitation-requested" },
];

export const financesStatusOptions = [
  { uuid: "identified", text: "identified" },
  { uuid: "returned", text: "returned" },
];

export const userStatusOptions = [
  { uuid: "active", text: "active" },
  { uuid: "banned", text: "blocked" },
];

export const userRoleOptions = [
  { uuid: "org-admin", text: "admin" },
  { uuid: "user", text: "user" },
];

export const financesIncomeToChip = {
  unidentified: "yellow20",
  identified: "success",
  returned: "blue20",
};

export const TOAST_CONTAINER_ID = "push_toast_container";

export const adStatus = {
  REVIEW_PENDING: "review-pending",
  PUBLISHED: "published",
  PUBLISHED_WITH_SCHEDULED: "published-with-scheduled",
  DRAFT: "draft",
  BLOCKED: "blocked",
  COVERED: "demand-covered",
  REMOVED: "deleted",
  CANCELED: "canceled",
  CLONE: "clone",
};

export const mapSlugToChipDouble = {
  [adStatus.PUBLISHED]: "published",
  [adStatus.DRAFT]: "info-corp",
  [adStatus.BLOCKED]: "blocked",
  [adStatus.REMOVED]: "blocked",
  [adStatus.REVIEW_PENDING]: "moderation",
  [adStatus.COVERED]: "covered",
};

export const mapSlugToChipDoubleGrid = {
  [adStatus.PUBLISHED]: "launched",
  [adStatus.DRAFT]: "draft-pure",
  [adStatus.BLOCKED]: "expired",
  [adStatus.CANCELED]: "expired",
  [adStatus.REMOVED]: "expired",
  [adStatus.REVIEW_PENDING]: "review-pending",
  [adStatus.COVERED]: "dark",
};

export const mapSlugToChipDoubleTable = {
  [adStatus.PUBLISHED]: "blue20",
  [adStatus.DRAFT]: "purple20",
  [adStatus.BLOCKED]: "red20",
  [adStatus.CANCELED]: "red20",
  [adStatus.REMOVED]: "gray10",
  [adStatus.REVIEW_PENDING]: "yellow20",
  [adStatus.COVERED]: "gray10",
};

export const ADS_LIMIT = 12;
export const TABLE_LIMIT = 12;
export const QUERY_UPPER_LIMIT = 32767;

export const VERIFICATION_STATUS = {
  onHold: "on-hold",
  received: "received",
  accepted: "accepted",
  rejected: "rejected",
  unreadable: "unreadable",
  expired: "expired",
  wrongType: "wrong-type",
  wrongName: "wrong-name",
  duplicated: "duplicated",
  unknown: "unknown",
  draft: "draft",
};

export const mapVerificationSlugToChip = {
  [VERIFICATION_STATUS.onHold]: "info2",
  [VERIFICATION_STATUS.received]: "received",
  [VERIFICATION_STATUS.accepted]: "success",
  [VERIFICATION_STATUS.rejected]: "error",
  [VERIFICATION_STATUS.unreadable]: "error",
  [VERIFICATION_STATUS.expired]: "error",
  [VERIFICATION_STATUS.wrongType]: "error",
  [VERIFICATION_STATUS.wrongName]: "error",
  [VERIFICATION_STATUS.duplicated]: "error",
  [VERIFICATION_STATUS.unknown]: "temporal",
  [VERIFICATION_STATUS.draft]: "info2",
};

export const adsActionsTypes = {
  SHARE: "share",
  EDIT: "edit",
  PUBLISH: "publish",
  UNPUBLISH: "unpublish",
  CANT_UNPUBLISH: "cantunpublish",
  CLONE: "clone",
  BLOCK: "block",
  UNBLOCK: "unblock",
  RESTORE: "restore",
  DEMAND_COVERED: "demand_covered",
};

export const LoadCharacteristicasTypes = {
  SACAS: "sacas",
  PALET: "palet",
  GRANEL: "granel",
};

export const currencyOptionsMap = {
  EUR: {
    name: "€",
    uuid: "EUR",
    icon: <span>{FlagMap["EUR"]}</span>,
  },
  USD: {
    name: "$/t",
    uuid: "USD",
    icon: <span>{FlagMap["USD"]}</span>,
  },
  "%LME": {
    name: "%LME",
    uuid: "%LME",
    icon: <></>,
  },
  "%FEAF": {
    name: "%FEAF",
    uuid: "%FEAF",
    icon: <></>,
  },
  "%PLATTS": {
    name: "%PLATTS",
    uuid: "%PLATTS",
    icon: <></>,
  },
  "%BDSV": {
    name: "%BDSV",
    uuid: "%BDSV",
    icon: <></>,
  },
};

export const currencyOptions = [
  {
    name: "€",
    uuid: "EUR",
    icon: <span>{FlagMap["EUR"]}</span>,
  },
  {
    name: "$/t",
    uuid: "USD",
    icon: <span>{FlagMap["USD"]}</span>,
  },
  {
    name: "%LME",
    uuid: "%LME",
    icon: <></>,
  },
  {
    name: "%FEAF",
    uuid: "%FEAF",
    icon: <></>,
  },
  {
    name: "%PLATTS",
    uuid: "%PLATTS",
    icon: <></>,
  },
  {
    name: "%BDSV",
    uuid: "%BDSV",
    icon: <></>,
  },
];

export const mapOperators = {
  "less-than": "<",
  "less-than-or-equal": "<=",
  "greater-than": ">",
  "greater-than-or-equal": ">=",
  equal: "=",
};

export const operatorsOptions = [
  {
    uuid: "less-than",
    text: "<",
  },
  {
    uuid: "less-than-or-equal",
    text: "<=",
  },
  {
    uuid: "greater-than",
    text: ">",
  },
  {
    uuid: "greater-than-or-equal",
    text: ">=",
  },
  {
    uuid: "equal",
    text: "=",
  },
];

export const questionsTypes = {
  SINGLE_CHOICE: "single-choice",
  INTEGER: "integer",
  YES_NO: "yes-no",
  PERCENTAGE: "percentage",
  MULTIPLE_CHOICE: "multiple-choice",
};

export const currencySymbolMapWithWeight = {
  EUR: "€/t",
  USD: "$/t",
  "%LME": "%LME",
};

export const financesCurrencyOptions = [
  {
    uuid: "EUR",
    text: "€",
  },
  {
    uuid: "USD",
    text: "$",
  },
];

export const adResponseStatus = {
  CREATED: "CREATED",
  UPDATED: "UPDATED",
  MODERATED: "MODERATED",
};

export const publicPathsThatDontTriggerRedirectoToLogin = ["/recover"];

export const selectedOrgKey = "selectedOrg";

export const tableModules = {
  TRANSPORTERS: "transporters",
  ORGANIZATIONS: "organizations",
  TRANSACTIONS: "transactions",
  DOCUMENTS: "documents",
  USERS: "users",
  ADS: "all-ads",
  QUOTATIONS: "quotations",
  ROLES_USERS: "",
  PAYMENT_TRACKING: "payment-tracking",
  OPPORTUNITIES_CREATED: "opportunities-created",
  OPPORTUNITIES_PARTICIPATING: "opportunities-participating",
  OPPORTUNITIES_NEW: "opportunities-new",
  DASHBOARD_OPPORTUNITIES_CREATED: "my-opportunities-dashboard",
  DASHBOARD_OPPORTUNITIES_PARTICIPATING:
    "opportunities-participating-dashboard",
  LOGISTICS_PRICING_TABLE: "logistics-pricing",
};

export const languages = [
  { uuid: "es", text: "es" },
  { uuid: "en", text: "en" },
  { uuid: "fr", text: "fr" },
];

export const adTypes = {
  SALE: "sale",
  PURCHASE: "purchase",
};

export const transactionFinancesTypes = {
  EDIT: "EDIT",
  REQUEST: "request",
  MAKE: "make",
};

export const clientTypes = {
  SELLER: "seller",
  BUYER: "buyer",
};

export const depositPendingTypes = {
  FIRST_DEPOSIT_PENDING: "FIRST_DEPOSIT_PENDING",
  SECOND_DEPOSIT_PENDING: "SECOND_DEPOSIT_PENDING",
};

export const shippingTypesStates = {
  SEA: "sea",
  LAND: "land",
};

export const MAX_UPLOAD_SIZE = 52428800;

export const LEGAL_FORMS = {
  "legal-entity": "legal-entity",
  "self-employed": "self-employed",
};

export const RoleType = {
  commercial: "commercial",
  logistics: "logistics",
  superadmin: "superadmin",
  finances: "finances",
  trader: "trader",
};

export const termsConditionsTypes = {
  TERMS_CONDITIONS_BUYER: "terms_conditions_buyer",
  TERMS_CONDITIONS_SELLER: "terms_conditions_seller",
};

export const negotiationType = {
  TRANSACTION: "transaction",
  OPPORTUNITY: "opportunity",
};

export const TYPE_ICON_NAME = {
  [negotiationType.TRANSACTION]: "swap_horiz",
  [negotiationType.OPPORTUNITY]: "campaign",
};

export const LOAD_POSSIBILITY = {
  container: "57369bb1-e22f-4711-924b-dde80b8addd3",
  tarp: "1c1d2e48-f22d-43f3-8a94-e9d9bce316f6",
  dump_truck: "5c077d6f-4c4e-4b5c-b17d-f6f832a6e804",
  none: "none",
};

export const frequencyOptions = {
  WEEKLY: 0.25,
  BIWEEKLY: 0.5,
  MONTHLY: 1,
  OTHER: 2,
};

export const frequencyOptionsMap = {
  0.25: "week",
  0.5: "biweekly_single",
  1: "month",
  2: "months",
};
