import {
  BREAKPOINT_MD,
  Switch,
  TEXT_COLOR,
  useMatchMedia,
} from "@scrapadev/scrapad-front-sdk";
import { useState } from "react";
import ModalFactory from "../../../components/ui/modal/ModalFactory";
import { useVerificationChangeStatus } from "../../../utils/business/hooks";
import { useTranslation } from "../../../utils/hooks";
import { modalTypes, orgStatus } from "../../../utils/variables";
import { verificationTypes } from "../../../utils/verification/variables";

const BasicVerificationValidation = ({ state, fetchData, id, showLabel }) => {
  const { t } = useTranslation("verification");
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_MD}px)`);
  const [open, setOpen] = useState(false);
  const { handleChangeVerificationStatus } = useVerificationChangeStatus();
  const checked =
    state?.getOrgData?.verificationState === orgStatus.ACTIVE ||
    state?.getOrgData?.verificationState === orgStatus.KYC_COMPLETE;

  return (
    <div style={matches ? { marginTop: 8 } : {}}>
      <Switch
        isLabelInLeft
        label={showLabel ? t("basic-verification-active") : undefined}
        labelStyle={{
          color: TEXT_COLOR,
          fontSize: 13,
          lineHeight: "19px",
        }}
        checked={checked}
        onChange={() => {
          setOpen(true);
        }}
      />
      <ModalFactory
        type={modalTypes.VERIFICATION_WARNING}
        open={open}
        setOpen={setOpen}
        checked={checked}
        title={
          checked
            ? t("about-disable-verification")
            : t("about-enable-verification")
        }
        bodyText={
          checked
            ? t("disable-verification-text")
            : t("enable-verification-text")
        }
        handleSubmit={(loading, setLoading) => {
          handleChangeVerificationStatus(
            id,
            verificationTypes.BASIC,
            !checked,
            fetchData,
            loading,
            setLoading
          );
        }}
      />
    </div>
  );
};

export default BasicVerificationValidation;
