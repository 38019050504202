import { List } from "@scrapadev/scrapad-front-sdk";

/**
 * Component to show the list container.
 * @returns {React.ReactNode}
 */
const NotificationsList = ({ children }) => {
  return <List style={{ gap: 0 }}>{children}</List>;
};

export default NotificationsList;
