import { LinkStyled, WARM_NEUTRAL_50 } from "@scrapadev/scrapad-front-sdk";

const LoginLink = ({ href, underlined, children }) => {
  const linkStyles = {
    color: WARM_NEUTRAL_50,
    fontSize: 13,
    lineHeight: 1.5,
  };
  return (
    <LinkStyled
      href={href}
      style={{
        textDecoration: underlined ? "underline" : "none",
        ...linkStyles,
      }}
    >
      {children}
    </LinkStyled>
  );
};

export default LoginLink;
