import {
  Center,
  Container,
  Loading,
  MiniTabs,
  Row,
  Spacer,
} from "@scrapadev/scrapad-front-sdk";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import GoBack from "../../components/ui/back/GoBack";
import Header from "../../components/ui/header/Header";
import NavBar from "../../components/ui/navbar/NavBar";
import Steps from "../../components/ui/steps/Steps";
import LayoutDetail from "../../layout/detail/Detail";
import { getHeaderInfo } from "../../model/business";
import { getOrgWalletAccounts } from "../../model/wallet";
import { useHeaderProvider } from "../../providers/header/HeaderContext";
import { useOperationsProvider } from "../../providers/organization/OperationsContext";
import { useTranslation } from "../../utils/hooks";
import { businessSteps } from "../../utils/router/functions";
import WidgetWalletEnterAccounts from "../../widgets/business/wallet/Enter";
import WidgetWalletWithdrawAccounts from "../../widgets/business/wallet/Withdraw";
import WidgetDataWrapper from "../../widgets/wrappers/DataWrapper";
import { printVerified } from "../../utils/functions";

const InnerBusinessWalletAccounts = ({ state, loading, fetchData }) => {
  const { id } = useParams();
  const { t, i18n } = useTranslation(["common", "transactions", "users"]);
  const { state: headerState } = useHeaderProvider(
    i18n.language,
    id,
    getHeaderInfo
  );
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const { canManageWallet, canReadWallet } = useOperationsProvider();

  let tabs = [
    {
      tab: <>{t("money-out-account")}</>,
      panel: (
        <WidgetWalletWithdrawAccounts
          state={state}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          permission={canManageWallet}
          fetchData={fetchData}
        />
      ),
    },
  ];

  if (!JSON.parse(process.env.REACT_APP_DISABLE_MONEY_IN)) {
    tabs.unshift({
      tab: <>{t("money-in-account")}</>,
      panel: <WidgetWalletEnterAccounts state={state} />,
    });
  }

  return (
    <>
      <NavBar />
      <LayoutDetail
        backgroundColor="#fff"
        headerComponent={
          <Container>
            <div className="row">
              <div className="col-12">
                <Header
                  title={
                    <Row gap={10}>
                      {printVerified(headerState?.verificationState, t)}
                      <span>{headerState?.basicData?.orgName}</span>
                    </Row>
                  }
                  goBack
                  breadcrumbs={[
                    {
                      description: t("business"),
                      onClick: () => navigate("/business", { replace: true }),
                    },
                    {
                      description: headerState?.basicData?.orgName,
                      onClick: () =>
                        navigate(`/business/${id}`, { replace: true }),
                    },
                    { description: t("wallet") },
                  ]}
                  subHeaderLeft={
                    <Steps steps={businessSteps(id)} currentStep={7} />
                  }
                  lowerLeftStyles={{ marginBottom: 0 }}
                  fullSubHeaderLeft
                />
              </div>
            </div>
          </Container>
        }
        bodyComponent={
          <Container>
            {loading ? (
              <Center>
                <Loading />
              </Center>
            ) : (
              <>
                <GoBack
                  customNavigate={() => navigate(`/business/${id}/wallet`)}
                  style={isEdit ? { display: "none" } : {}}
                />
                <div style={{ display: isEdit ? "none" : "block" }}>
                  <Spacer height={20} />
                </div>
                <MiniTabs
                  tabListProps={
                    isEdit
                      ? {
                          style: { display: "none" },
                        }
                      : {}
                  }
                  tabs={tabs}
                  currentTab={currentTab}
                  changeTab={(index) => {
                    setCurrentTab(index);
                    setIsEdit(false);
                  }}
                  spacer={isEdit ? undefined : <Spacer height={25} />}
                />
              </>
            )}
          </Container>
        }
      />
    </>
  );
};

const BusinessWalletAccounts = () => {
  const { id } = useParams();
  return (
    <WidgetDataWrapper
      fnData={getOrgWalletAccounts}
      fnParameters={{
        org: id,
      }}
      Component={InnerBusinessWalletAccounts}
      delegateLoading
    />
  );
};

export default BusinessWalletAccounts;
