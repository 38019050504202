import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "../../../utils/hooks";
import styles from "./Steps.module.scss";

/**
 * UI Steps component.
 * @param {Object} props - Component properties.
 * @param {Array} props.steps - All the steps to show.
 * @param {Number} props.currentStep - Selected value.
 * @param {boolean} [props.noBorderBottom=false] - Removes blue border bottom.
 * @returns {JSX.Element}
 */
const Steps = ({
  steps,
  currentStep = 0,
  checkCurrentByLabel = false,
  minimal,
  noBorderBottom = false,
}) => {
  const { t } = useTranslation(["common", "transactions"]);
  const navigate = useNavigate();
  const navRef = useRef(null);

  const handleNavigate = (to) => (e) => {
    navigate(to);
  };

  if (!steps) return <></>;
  return (
    <nav
      ref={navRef}
      className={`${styles["sc-steps"]} ${minimal ? styles["minimal"] : ""}`}
    >
      <ul>
        {steps.map((step, index) => {
          return (
            <li
              key={index}
              title={t(step.label)}
              className={
                checkCurrentByLabel
                  ? step.label === currentStep
                    ? styles["active"]
                    : ""
                  : index === currentStep
                  ? styles["active"]
                  : ""
              }
              onClick={handleNavigate(step.to)}
              style={noBorderBottom ? { borderBottom: "none" } : {}}
            >
              <span>{t(step.label)}</span>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default Steps;
