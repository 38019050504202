import {
  Alert,
  Box,
  Container,
  Heading,
  Row,
  //Spacer,
  WARM_NEUTRAL_10,
} from "@scrapadev/scrapad-front-sdk";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../components/ui/header/Header";
import NavBar from "../../components/ui/navbar/NavBar";
import LayoutDetail from "../../layout/detail/Detail";
import { useHeaderProvider } from "../../providers/header/HeaderContext";
import { useSteps, useTranslation } from "../../utils/hooks";
import { getHeaderInfo } from "../../model/business";
import Steps from "../../components/ui/steps/Steps";
import PreferencesBlockWrapper from "../../widgets/business/preferences/BlockWrapper";
//import PreferencesCurrency from "../../widgets/business/preferences/Currency";
import PreferencesPaymentMethods from "../../widgets/business/preferences/PaymentMethods";
import { printVerified } from "../../utils/functions";

/**
 * Business preferences page.
 * @returns {React.ReactNode}
 */
const BusinessPreferences = () => {
  const { id } = useParams();
  const { t, i18n } = useTranslation(["common"]);
  const { state: headerState } = useHeaderProvider(
    i18n.language,
    id,
    getHeaderInfo
  );
  const navigate = useNavigate();
  const { steps } = useSteps(id);
  return (
    <>
      <NavBar />
      <LayoutDetail
        backgroundColor="#fff"
        headerComponent={
          <Container>
            <div className="row">
              <div className="col-12">
                <Header
                  title={
                    <Row gap={10}>
                      {printVerified(headerState?.verificationState, t)}
                      <span>{headerState?.basicData?.orgName}</span>
                    </Row>
                  }
                  goBack
                  breadcrumbs={[
                    {
                      description: t("business"),
                      onClick: () => navigate("/business", { replace: true }),
                    },
                    {
                      description: headerState?.basicData?.orgName,
                      onClick: () =>
                        navigate(`/business/${id}`, { replace: true }),
                    },
                    { description: t("configuration") },
                  ]}
                  subHeaderLeft={
                    // <Steps steps={businessSteps(id)} currentStep={8} />
                    <Steps
                      steps={steps}
                      currentStep={"configuration"}
                      checkCurrentByLabel
                    />
                  }
                  lowerLeftStyles={{ marginBottom: 0 }}
                  fullSubHeaderLeft
                />
              </div>
            </div>
          </Container>
        }
        bodyComponent={
          <Container>
            <div className="row">
              <div className="col-lg-8 col-12">
                <Box
                  style={{
                    padding: 15,
                    borderRadius: 10,
                    backgroundColor: WARM_NEUTRAL_10,
                  }}
                >
                  <Heading type={3} style={{ marginBottom: 20 }}>
                    {t("preferences")}
                  </Heading>
                  <Alert type="info" style={{ margin: "0 0 20px 0" }}>
                    {t("preferences_applied_ads_transactions")}
                  </Alert>
                  {/* TODO Commented until new revision */}
                  {/*<PreferencesBlockWrapper title={t("currency")}>
                    <PreferencesCurrency orgUuid={id} />
                </PreferencesBlockWrapper>*/}
                  {/*<Spacer />*/}
                  <PreferencesBlockWrapper
                    title={t("payment_conditions")}
                    subtitle={`(${t("apply_only_purchase_ads")})`}
                  >
                    <PreferencesPaymentMethods orgUuid={id} />
                  </PreferencesBlockWrapper>
                </Box>
              </div>
            </div>
          </Container>
        }
      />
    </>
  );
};

export default BusinessPreferences;
