import { Button, toastWarning, TooltipSc } from "@scrapadev/scrapad-front-sdk";
import _ from "lodash";
import { useState } from "react";
import {
  canNotPublishBasic,
  canNotPublishKyc,
} from "../../utils/ads/functions";
import { useTranslation } from "../../utils/hooks";
import { adStatus, LoadCharacteristicasTypes } from "../../utils/variables";

export const checkFormRequiredFields = (
  state,
  basic = false,
  showToast = false,
  t = (e) => e
) => {
  let isValidated = true;
  let errorFields = [];

  if (!state?.type) {
    isValidated = false;
    errorFields.push("type");
  }
  if (!state?.title) {
    isValidated = false;
    errorFields.push("title");
  }
  if (!state?.description) {
    isValidated = false;
    errorFields.push("description");
  }
  if (!state?.amountScale) {
    isValidated = false;
    errorFields.push("amountScale");
  }

  if (basic) {
    errorFields.forEach((ef) => {
      toastWarning(`${t(ef)} ${t("required")}`);
    });
    return [isValidated, errorFields];
  }

  if (!state?.materialTypeEdit) {
    isValidated = false;
    errorFields.push("materialTypeEdit");
  }

  if (!state?.amount) {
    isValidated = false;
    errorFields.push("amount");
  }

  if (!state?.price) {
    isValidated = false;
    errorFields.push("price");
  }

  if (state?.files?.length === 0 && state?.type === "sale") {
    isValidated = false;
    errorFields.push("files");
  }

  if (state?.facilities?.length === 0 || _.isEmpty(state?.facilities)) {
    isValidated = false;
    errorFields.push("facilities");
  }

  if (state?.type === "sell" || state?.type === "sale") {
    // if (state?.components?.length === 0) {
    //   isValidated = false;
    //   errorFields.push("components");
    // }
    if (
      state?.loadCharacteristics?.length === 0 ||
      state?.loadCharacteristics?.length === 3
    ) {
      isValidated = false;
      errorFields.push("loadCharacteristics");
    }

    if (state?.loadCharacteristics?.length > 0) {
      let attrValid = true;
      state?.loadCharacteristics?.forEach((lc) => {
        if (lc.type !== "granel") {
          if (!lc.value) {
            attrValid = false;
          }
          if (
            parseInt(lc.value?.weight) < 1 ||
            parseInt(lc.value?.height) < 1 ||
            parseInt(lc.value?.weight) < 1 ||
            parseInt(lc.value?.width) < 1
          ) {
            attrValid = false;
          }
        }
      });
      if (!attrValid) {
        isValidated = false;
        errorFields.push("loadCharacteristics");
      }
    }
  } else {
    if (!state?.incoterm || _.isEmpty(state?.incoterm)) {
      isValidated = false;
      errorFields.push("incoterm");
    }

    if (!state?.paymentMethod || _.isEmpty(state?.paymentMethod)) {
      isValidated = false;
      errorFields.push("paymentMethod");
    }
  }

  if (state?.loadCharacteristics?.length > 0) {
    const granel = state?.loadCharacteristics?.find(
      (f) => f?.type === LoadCharacteristicasTypes.GRANEL
    );
    const saca = state?.loadCharacteristics?.find(
      (f) => f?.type === LoadCharacteristicasTypes.SACAS
    );
    const paletizada = state?.loadCharacteristics?.find(
      (f) => f?.type === LoadCharacteristicasTypes.PALET
    );
    if (granel) {
      if (parseInt(granel?.value?.weight) < 0) {
        isValidated = false;
        errorFields.push("load_preferences_granel");
      }
    }
    if (saca) {
      if (
        !saca?.value ||
        parseInt(saca?.value?.weight) < 1 ||
        parseInt(saca?.value?.depth) < 1 ||
        parseInt(saca?.value?.height) < 1 ||
        parseInt(saca?.value?.width) < 1
      ) {
        isValidated = false;
        errorFields.push("load_preferences_saca");
      }
    }
    if (paletizada) {
      if (
        !paletizada?.value ||
        parseInt(paletizada?.value?.weight) < 1 ||
        parseInt(paletizada?.value?.depth) < 1 ||
        parseInt(paletizada?.value?.height) < 1 ||
        parseInt(paletizada?.value?.width) < 1
      ) {
        isValidated = false;
        errorFields.push("load_preferences_palet");
      }
    }
  }

  if (state?.components?.length > 0) {
    const incompletes = state?.components?.filter(
      (f) => !f?.type || !f?.operator || !f?.value
    );
    if (incompletes?.length > 0) {
      isValidated = false;
      errorFields.push("component_incomplete");
    }
  }

  if (!state?.address?.id) {
    if (
      state?.address?.["is-port"] ||
      !_.isEmpty(
        state?.address?.port?.uuid
          ? state?.address?.port?.uuid?.trim().replace(/\s/g, "")
          : state?.address?.port?.trim().replace(/\s/g, "")
      )
    ) {
      if (
        _.isEmpty(
          state?.address?.port?.uuid
            ? state?.address?.port?.uuid?.trim()?.replace(/\s/g, "")
            : state?.address?.port?.trim()?.replace(/\s/g, "")
        )
      ) {
        isValidated = false;
        errorFields.push("port");
      }
    } else {
      if (
        !state?.address?.street ||
        !state?.address?.city ||
        !state?.address?.["countryCode"] ||
        !state?.address?.["postalCode"]
      ) {
        isValidated = false;
        errorFields.push("address");
      }
    }
  }
  if (state?.frequencyAmount > 0) {
    if (!(state?.frequency > 0)) {
      isValidated = false;
      errorFields.push("frequency");
    }
  }

  if (showToast) {
    errorFields.forEach((ef) => {
      if (typeof toastWarning == "function") {
        toastWarning(`${t(ef)} ${t("required")}`);
      }
    });
  }

  return [isValidated, errorFields];
};

const DisabledButton = ({ text }) => {
  return (
    <Button
      component="button"
      type="button"
      text={text}
      disabled
      style={{
        marginLeft: 10,
      }}
    />
  );
};

const ButtonWithRequiredTooltip = ({ t, children }) => {
  return (
    <TooltipSc
      alignment={"bottom"}
      content={t("fill-required")}
      action="hover"
      hide={false}
      style={{
        justifyContent: "center",
        minWidth: 0,
        marginLeft: 10,
      }}
    >
      {children}
    </TooltipSc>
  );
};

const AdStatusAction = ({
  uuid,
  ad,
  verificationState,
  status,
  isEdit,
  isDirty,
  handlePublishChanges,
  handleDemandCovered,
  handleSaveDraft,
  fetchData,
  setOpen,
  bufferState,
}) => {
  const { t } = useTranslation(["ads", "common"]);
  const [loading, setLoading] = useState(false);
  const handlePublishstatus = () => {
    if (canNotPublishBasic(verificationState)) {
      return (
        <TooltipSc
          alignment={"bottom"}
          content={t("need-basic-verification")}
          action="hover"
          hide={false}
          style={{
            justifyContent: "center",
            minWidth: 0,
          }}
        >
          <DisabledButton text={t("publish-changes")} />
        </TooltipSc>
      );
    }
    if (canNotPublishKyc(ad, verificationState)) {
      return (
        <TooltipSc
          alignment={"bottom"}
          content={t("need-full-verification")}
          action="hover"
          hide={false}
          style={{
            justifyContent: "center",
            minWidth: 0,
          }}
        >
          <DisabledButton text={t("publish-changes")} />
        </TooltipSc>
      );
    }
    if (checkFormRequiredFields(ad)[0]) {
      return (
        <>
          <Button
            loading={loading}
            component="button"
            type="button"
            //form="adForm"
            text={t("publish-changes")}
            style={{
              marginLeft: 10,
            }}
            onClick={() => {
              parseInt(ad?.amount) === 0
                ? handleDemandCovered()
                : handlePublishChanges({ ad, uuid, setLoading, fetchData });
            }}
          />
        </>
      );
    } else {
      return (
        <ButtonWithRequiredTooltip t={t}>
          <DisabledButton text={t("publish-changes")} />
        </ButtonWithRequiredTooltip>
      );
    }
  };

  if (!ad) return <></>;

  if (status === adStatus.DRAFT) {
    return (
      <Button
        component="button"
        type="button"
        text={t("save-draft")}
        loading={loading}
        style={{
          marginLeft: 10,
        }}
        onClick={() => {
          handleSaveDraft({ ad, uuid, setLoading, fetchData });
          bufferState.current = { ...ad };
        }}
      />
    );
  }

  if (status === adStatus.COVERED && isEdit) {
    return (
      <Button
        component="button"
        type="button"
        text={t("save-draft")}
        loading={loading}
        style={{
          marginLeft: 10,
        }}
        disabled={parseInt(ad?.amount) === 0}
        onClick={() => {
          handleSaveDraft({ ad, uuid, setLoading, fetchData });
          bufferState.current = { ...ad };
        }}
      />
    );
  }

  if (status === adStatus.REVIEW_PENDING && isEdit) {
    if (checkFormRequiredFields(ad)[0]) {
      return (
        <Button
          component="button"
          type="button"
          text={t("save-changes")}
          style={{
            marginLeft: 10,
          }}
          loading={loading}
          onClick={() => {
            handlePublishChanges({ ad, uuid, setLoading, fetchData });
            bufferState.current = { ...ad };
          }}
        />
      );
    } else {
      return (
        <ButtonWithRequiredTooltip t={t}>
          <DisabledButton text={t("save-changes")} />
        </ButtonWithRequiredTooltip>
      );
    }
  }

  if (status === adStatus.PUBLISHED) {
    return isEdit ? handlePublishstatus() : <></>;
  }
  return <></>;
};

export default AdStatusAction;
