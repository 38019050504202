import { toastError } from "@scrapadev/scrapad-front-sdk";
import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  useLayoutEffect,
} from "react";
import { UNSAFE_NavigationContext } from "react-router-dom";
import { getHeaderInfo } from "../model/business";
import { useSetDataUser, useSignOut } from "../providers/AuthHooks";
import { actions, useHeaderProvider } from "../providers/header/HeaderContext";
import { LanguageContext } from "../providers/i18n/i18nContext";
import { useOperationsProvider } from "../providers/organization/OperationsContext";
import history from "../router/History";
import { debounce, getOffset, isImageUrl } from "./functions";
import { gqlWrapper } from "./gqlwrapper";
import { DEFAULT_LANGUAGE, storageKeys } from "./variables";

/**
 * Hook that alerts clicks outside of the passed ref
 */
export function useOutsideAlerter(ref, callBack = () => {}) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callBack();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, callBack]);
}

export const useClickOnBody = (
  ref,
  callBack = () => {},
  isNativeRef = false
) => {
  const isBinded = useRef(false);
  useEffect(() => {
    if (!isBinded.current) {
      window.addEventListener("click", handleClickBody);
      isBinded.current = true;
    }

    return () => {
      if (isBinded.current) {
        window.removeEventListener("click", handleClickBody);
        isBinded.current = false;
      }
    };
  }, [ref]);

  const handleClickBody = (event) => {
    event.stopPropagation();
    const innerRef = isNativeRef ? ref : ref.current;
    if (innerRef && !innerRef.contains(event.target)) {
      callBack();
    }
  };
};

/**
 * Custom hook to use window.matchMedia
 * @param {String} condition - Matchmedia condition
 * @returns {Boolean}
 */
export const useMatchMedia = (condition) => {
  const [matches, setMaches] = useState(false);
  const query = useRef(null);

  useEffect(() => {
    if (!query.current) {
      query.current = window.matchMedia(condition);
    }
    query.current.addListener(handleMatchMedia);
    setMaches(query.current.matches);
    return () => {
      query.current.removeListener(handleMatchMedia);
    };
  }, []);

  const handleMatchMedia = (mq) => setMaches(mq.matches);

  return matches;
};

// Hook
export function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    if (typeof window !== "undefined") {
      function handleResize() {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }

      window.addEventListener("resize", handleResize);

      handleResize();

      return () => window.removeEventListener("resize", handleResize);
    }
  }, []);
  return windowSize;
}

export const useTranslation = (namespaces, loadAtInitialization) => {
  const { innerLanguage, setInnerLanguage, getLanguageVariable } =
    useContext(LanguageContext);

  const getAllStrings = (lang, namespace) => {
    let allStrings = {};
    try {
      allStrings = require(`../locales/${lang}/${namespace}.json`);
    } catch {
      allStrings = require(`../locales/${DEFAULT_LANGUAGE}/${namespace}.json`);
    }
    return allStrings;
  };

  const loadTranslations = () => {
    let buffer = {};
    if (namespaces !== undefined) {
      const lang =
        innerLanguage &&
        innerLanguage !== undefined &&
        innerLanguage !== "undefined" &&
        innerLanguage !== null
          ? innerLanguage
          : DEFAULT_LANGUAGE;
      if (Array.isArray(namespaces)) {
        namespaces.forEach((namespace) => {
          buffer = { ...buffer, ...getAllStrings(lang, namespace) };
        });
      } else {
        buffer = { ...buffer, ...getAllStrings(lang, namespaces) };
      }
      return buffer;
    }
    return [];
  };

  const [values, setValues] = useState(
    loadAtInitialization ? loadTranslations() : []
  );

  useEffect(() => {
    setValues(loadTranslations());
  }, [innerLanguage]);

  const t = (code) => {
    const value = values[code];
    return value ? value : code;
  };
  const tMultiple = (code, variables = []) => {
    if (!code || variables.length === 0) return code;
    const translated = values[code];
    return translated ? formatTranslateString(t, translated, variables) : code;
  };

  const formatTranslateString = (translate, input, variables) => {
    return input.replace(/{(\d+)}/g, function (match, number) {
      return typeof variables[number - 1] != "undefined"
        ? translate(variables[number - 1])
        : match;
    });
  };
  const changeLanguage = (newLanguage) => {
    window.localStorage.setItem(storageKeys.USER_DEFINED_LANGUAGE, newLanguage);
    setInnerLanguage(newLanguage);
  };

  return {
    t,
    tMultiple,
    i18n: { language: innerLanguage, changeLanguage, getLanguageVariable },
    values,
  };
};

/**
 * Reposition of dropdowns in a table hook
 * @param {Number} [topOffset=0] - Top offset of dropdown
 * @param {Number} [leftOffset=0] - Left offset of dropdown
 * @returns {Object}
 */
export const useDropDown = (topOffset = 0, leftOffset = 0) => {
  const dropRef = useRef(null);
  const buttonRef = useRef(null);
  const containerRef = useRef(null);
  const [openDropdown, setOpenDropdown] = useState(false);
  const body = document.querySelector("body");

  useClickOnBody(containerRef, () => setOpenDropdown(false));

  useEffect(() => {
    window.addEventListener("scroll", calculateApplyPosition);
    window.addEventListener("resize", calculateApplyPosition);
    return () => {
      window.removeEventListener("scroll", calculateApplyPosition);
      window.removeEventListener("resize", calculateApplyPosition);
    };
  }, []);

  useLayoutEffect(() => {
    if (openDropdown) calculateApplyPosition();
  }, [openDropdown]);

  const drawBottom = () => {
    const containerPosition = containerRef.current.getBoundingClientRect();
    dropRef.current.style.top = `${containerPosition.top + topOffset}px`;
    dropRef.current.style.left = `${containerPosition.left}px`;
    dropRef.current.dataset.draw = "bottom";
  };

  const drawTop = (dropRect) => {
    const containerPosition = containerRef.current.getBoundingClientRect();
    dropRef.current.style.top = `${containerPosition.top - dropRect.height}px`;
    dropRef.current.style.left = `${containerPosition.left + leftOffset}px`;
    dropRef.current.dataset.draw = "top";
  };

  const calculateApplyPosition = () => {
    if (buttonRef.current && containerRef.current && dropRef.current) {
      const dropRect = dropRef.current.getBoundingClientRect();
      if (dropRef.current.dataset.draw === "top") {
        drawTop(dropRect);
        return;
      }
      if (dropRef.current.dataset.draw === "bottom") {
        drawBottom();
        return;
      }
      dropRect.bottom > body.offsetHeight ? drawTop(dropRect) : drawBottom();
    }
  };

  return {
    openDropdown,
    setOpenDropdown,
    dropRef,
    buttonRef,
    containerRef,
  };
};

export const useAutoComplete = (position, topOffset = 0) => {
  const nodeRef = useRef(null);
  const containerRef = useRef(null);
  const [open, setOpen] = useState(false);
  const body = document.querySelector("body");

  useEffect(() => {
    window.addEventListener("scroll", calculateApplyPosition);
    window.addEventListener("resize", calculateApplyPosition);
    return () => {
      window.removeEventListener("scroll", calculateApplyPosition);
      window.removeEventListener("resize", calculateApplyPosition);
    };
  }, []);

  useLayoutEffect(() => {
    if (open) calculateApplyPosition();
  }, [open]);

  const drawTop = (results) => {
    const containerPosition = containerRef.current.getBoundingClientRect();
    results.style.top = `${
      containerPosition.top - results.offsetHeight + topOffset
    }px`;
    results.style.left = `${containerPosition.left}px`;
    nodeRef.current.dataset.draw = "top";
  };

  const drawBottom = (results) => {
    const h = nodeRef.current.offsetHeight;
    const position = nodeRef.current.getBoundingClientRect();
    results.style.top = `${position.top + h + topOffset}px`;
    results.style.left = `${position.left}px`;
    nodeRef.current.dataset.draw = "bottom";
  };

  const calculateApplyPosition = () => {
    if (nodeRef.current && containerRef.current && position === "fixed") {
      const results = containerRef.current.querySelector(
        ".c-autocomplete__results-ref"
      );
      if (results) {
        const dropRect = results.getBoundingClientRect();
        const w = nodeRef.current.offsetWidth;
        results.style.width = `${w}px`;
        if (nodeRef.current.dataset.draw === "top") {
          drawTop(results);
          return;
        }
        if (nodeRef.current.dataset.draw === "bottom") {
          drawBottom(results);
          return;
        }
        dropRect.bottom > body.offsetHeight
          ? drawTop(results)
          : drawBottom(results);
      }
    }
  };

  const resultsCallbackRef = (node) => {
    if (!node) return;
    const iter = nodeRef.current.classList.values();
    let invalid = false;
    for (const i of iter) {
      if (i.indexOf("c-input-invalid") > -1) {
        invalid = true;
        break;
      }
    }
    invalid
      ? (node.style.marginTop = "-2.6rem")
      : position === "fixed"
      ? (node.style.marginTop = 0)
      : (node.style.marginTop = "");
  };

  return {
    nodeRef,
    containerRef,
    resultsCallbackRef,
    open,
    setOpen,
    calculateApplyPosition,
  };
};

/**
 * Custom hook for resize event
 * @param {Function} callback - Callback to execute.
 * @param {Number} [interval=250] - Debounce interval.
 */
export const useDebouncedResize = (callback, interval = 250) => {
  const fnDebounce = debounce(callback, interval);

  useEffect(() => {
    window.addEventListener("resize", fnDebounce);
    return () => {
      window.removeEventListener("resize", fnDebounce);
    };
  }, []);
};

/**
 * Custom hook for setInterval.
 * @param {Function} callback - Callback to execute.
 * @param {Number} [interval=250] - Callback interval.
 */
export const useInterval = (callback, interval = 250) => {
  const intervalID = useRef(0);
  useEffect(() => {
    intervalID.current = setInterval(callback, interval);
    return () => {
      clearInterval(intervalID.current);
    };
  }, []);
  return intervalID.current;
};

export const useIntervalWithStateUpdate = (callback, interval = 250) => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    let id = setInterval(tick, interval);
    return () => clearInterval(id);
  }, [interval]);
};

/**
 * Custom hook for listening url changes
 * @param {Function} callBack - Callback to execute
 */
export const useHistoryListen = (callBack) => {
  useEffect(() => {
    const unlisten = history.listen((loc) => {
      if (callBack) callBack(history, loc);
    });
    return unlisten;
  }, []);
};

/**
 * Custom hook for blocking navigation and retry when conditions met.
 * @param {Function} callBack - Callback to execute.
 * @param {boolean} mustBlockLeaving - Tells if changing page must be blocked.
 */
export const useHistoryBlock = (callBack, mustBlockLeaving) => {
  const unblock = useRef(null);
  const action = useRef(null);
  const location = useRef(null);
  const retry = useRef(null);
  useEffect(() => {
    unblock.current = history.block((tx) => {
      action.current = tx.action;
      location.current = tx.location;
      retry.current = tx.retry;
      if (callBack)
        callBack(
          action.current,
          location.current,
          retry.current,
          unblock.current
        );
    });
    if (!mustBlockLeaving) {
      unblock.current();
    }
    return () => {
      unblock.current();
    };
  }, [mustBlockLeaving]);

  return {
    unblock: unblock.current,
    action: action.current,
    location: location.current,
    retry: retry.current,
  };
};

/**
 * Custom hook for Interception observer API
 * @param {Element} node - Node to check.
 * @param {Function} callBack - Callback function.
 * @param {Element} [root=document] - Root element.
 * @param {String} [rootMargin="0px 0px 0px 0px"] - Detection margin.
 * @param {Number|Array<Number>} [threshold=0] - Threshold of visualization.
 */
export const useInterceptionObserver = (
  node,
  callback,
  root = document,
  rootMargin = "0px 0px 0px 0px",
  threshold = 0
) => {
  const observer = useRef(null);
  const isBounded = useRef(false);
  useEffect(() => {
    if (node && !isBounded.current) {
      observer.current = new IntersectionObserver(callback, {
        root,
        rootMargin,
        threshold,
      });
      observer.current.observe(node);
      isBounded.current = true;
    }
    return () => {
      if (node) {
        observer.current.unobserve(node);
      }
    };
  }, [node]);

  return {
    observer: observer.current,
  };
};

export const useSteps = (id, bypassCheck = false) => {
  const steps = useRef(null);
  const {
    canReadAds,
    canReadOrg,
    canReadFavs,
    canReadUsers,
    canReadKYC,
    canReadWallet,
    canReadAddress,
    canReadTransaction,
  } = useOperationsProvider();

  useEffect(() => {
    steps.current = [];

    if (canReadOrg() || bypassCheck) {
      steps.current.push({
        label: "general_information",
        to: `/business/${id}/about`,
      });
    }

    if (canReadAddress()) {
      steps.current.push({
        label: "addresses",
        to: `/business/${id}/addresses`,
      });
    }

    if (canReadUsers() || bypassCheck) {
      steps.current.push({ label: "users", to: `/business/${id}/users` });
    }
    if (canReadKYC() || bypassCheck) {
      steps.current.push({
        label: "verification",
        to: `/business/${id}/verification`,
      });
    }
    if (canReadAds() || bypassCheck) {
      steps.current.push({ label: "ads", to: `/business/${id}/ads` });
    }
    if (canReadTransaction) {
      steps.current.push({
        label: "transactions",
        to: `/business/${id}/transactions`,
      });
    }
    if (canReadFavs() || bypassCheck) {
      steps.current.push({
        label: "favorites",
        to: `/business/${id}/favourites`,
      });
    }
    // if (canReadWallet() || bypassCheck) {
    //   steps.current.push({ label: "wallet", to: `/business/${id}/wallet` });
    // }
    if (canReadWallet() || bypassCheck) {
      steps.current.push({
        label: "last-movements",
        to: `/business/${id}/last-movements`,
      });
    }
    if (canReadOrg() || bypassCheck) {
      steps.current.push({
        label: "configuration",
        to: `/business/${id}/preferences`,
      });
    }
  }, []);

  return {
    steps: steps.current,
  };
};
/**
 * Custom hook to get the first image index that its an actual image from an list.
 * @param {Array} files - Array of files to check.
 * @returns {number} - the index of the image.
 */
export const useIsImageFromlist = (files = []) => {
  const imageIndex = useRef(-1);

  useEffect(() => {
    getImage();
  }, [files]);

  const getImage = async () => {
    const images = files?.map((f) => f.location);
    if (!images || images?.length === 0) return;
    imageIndex.current = 0;
    for (let i = 0; i < images.length; i++) {
      const isImage = await isImageUrl(images[i]);
      if (isImage) {
        imageIndex.current = i;
        break;
      }
    }
  };

  return {
    imageIndex: imageIndex.current,
  };
};

export const useStepsTransaction = (id, bypassCheck = false) => {
  const steps = useRef(null);
  const {
    canReadAds,
    canReadOrg,
    canReadFavs,
    canReadUsers,
    canReadKYC,
    canReadWallet,
    canReadAddress,
    canReadTransaction,
    canReadLogistics,
  } = useOperationsProvider();

  useEffect(() => {
    steps.current = [];

    if (canReadTransaction()) {
      steps.current.push({
        label: "historical",
        to: `/transactions/${id}`,
      });
      if (canReadLogistics()) {
        steps.current.push({
          label: "transport",
          to: `/transactions/${id}/transport`,
        });
      }
      steps.current.push({
        label: "costs",
        to: `/transactions/${id}/costs`,
      });

      steps.current.push({
        label: "documents",
        to: `/transactions/${id}/documents`,
      });

      // if (canReadLogistics() && JSON.parse(process.env.REACT_APP_QUOTATIONS)) {
      //   steps.current.push({
      //     label: "quotations",
      //     to: `/transactions/${id}/quotations`,
      //   });
      // }
    }
  }, []);

  return {
    steps: steps.current,
  };
};
