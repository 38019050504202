import {
  BREAKPOINT_LG,
  Button,
  Container,
  Flex,
  IconItem,
  Row,
  Spacer,
  TableManager,
  useMatchMedia,
} from "@scrapadev/scrapad-front-sdk";
import { useRef, useState } from "react";
import ModalFactory from "../../components/ui/modal/ModalFactory";
import LayoutTable from "../../layout/table/Table";
import { getTable } from "../../model/tables";
import { DataProvider } from "../../providers/data/DataContext";
import {
  PaginationProvider,
  usePaginationProvider,
} from "../../providers/pagination/PaginationContext";
import { useFiltersProvider } from "../../providers/filters/FiltersContext";
import { useTableDataProvider } from "../../providers/TableContext";
import { useDocumentTitle } from "../../utils/document/hooks";
import { useTranslation } from "../../utils/hooks";
import { useTableActions, useTableConfig } from "../../utils/tables/hooks";
import {
  modalTypes,
  RoleType,
  TABLE_LIMIT,
  tableModules,
} from "../../utils/variables";
import { useOperationsProvider } from "../../providers/organization/OperationsContext";
import {
  SortProvider,
  useSortProvider,
} from "../../providers/sort/SortContext";
import { applySortToFilters } from "../../utils/tables/functions";
import FiltersWrapper from "../../components/ui/filters/Wrapper";
import { useUser } from "../../providers/AuthHooks";
import { useDateFilter, usePortsFilter } from "../../utils/filters/hooks";
import _ from "lodash";
import Filters from "../../components/ui/filters/Filters";

export const useLogisticsPricingFilters = () => {
  const { t } = useTranslation(["common"], true);
  const validUntil = useDateFilter("valid_date", t("valid_until"));
  const destination = usePortsFilter("valid_date", t("destination"), []);
  const origin = usePortsFilter("valid_date", t("origin"), []);

  return {
    initialFilters: {
      origin_port: origin,
      destination_port: destination,
      valid_date: validUntil,
    },
  };
};

const InnerLogisticsPricingTable = ({ baseTableParams }) => {
  const user = useUser();
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_LG}px)`);
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const rowData = useRef({});
  const rowUuid = useRef(null);
  const { t, i18n } = useTranslation(["logistics", "common"], true);
  const { data, setData, loading, setLoading, fetchData } =
    useTableDataProvider();
  const { handlePagination } = usePaginationProvider();
  const { buildFiltersAndFetch, getStoredFilters } = useFiltersProvider();
  const { state: sortState, handleSorting, buildSort } = useSortProvider();
  const { deps, initialFilters } = useLogisticsPricingFilters();
  const filters = buildFiltersAndFetch(getStoredFilters() || {});
  const { canManageLogistics } = useOperationsProvider();
  const sortFields = buildSort(sortState);

  useTableActions({
    paginationCallback: (paginationState) => {
      const newFilters = buildFiltersAndFetch(getStoredFilters() || {});
      const params = {
        ...baseTableParams,
        sort: sortState?.field ? sortState : undefined,
        offset: paginationState?.current,
      };
      if (Object.keys(newFilters).length > 0) {
        params.filter = newFilters;
      }
      fetchData({
        ...params,
        filter: applySortToFilters(
          newFilters,
          sortFields,
          "REACT_APP_SORT_LOGISTICS"
        ),
      });
    },
  });

  useDocumentTitle(`${t("logistics")}`);

  const handleManageLogistics = () => {
    if (!canManageLogistics) return true;
    return canManageLogistics();
  };

  const submitcallback = async () => {
    setLoading(true);
    await fetchData();
    setLoading(false);
  };

  return (
    <Container isFluid>
      <Spacer height={25} />
      <Flex alignItems={"center"} flexDirection={"row"} style={{ gap: "15px" }}>
        <Flex
          justifyContent={"flex-start"}
          flexDirection={"column"}
          alignItems={"flex-start"}
          style={{ width: "100%" }}
        >
          <Filters
            showChips
            chipsBelowFilters
            containerStyles={{
              justifyContent: "space-between",
              width: "100%",
            }}
            innerContainerStyles={{
              gap: 10,
            }}
            canDownload={user?.userData?.role === RoleType.superadmin}
            fileName={"logistic_pricing"}
            exportProps={{
              fnData: getTable,
              fnParameters: {
                ...baseTableParams,
                filter: applySortToFilters(filters, sortFields),
              },
              fileName: "logistic_pricing",
            }}
            initialFilters={initialFilters}
            deps={deps}
          />
        </Flex>
        <Row gap={15} justify="space-between" style={{ width: "180px" }}>
          <Button
            component="button"
            type="button"
            mode={"info"}
            text={t("upload_estimate")}
            onClick={() => setOpen(!open)}
            style={{ paddingLeft: "20px", paddingRight: "20px" }}
          />
        </Row>
      </Flex>

      <Spacer height={25} />
      <div className="row">
        <div className="col-12">
          <TableManager
            // isInfoTable
            loading={loading}
            data={data}
            setData={setData}
            emptyText={t("empty-data")}
            rowTriggerPageChange={false}
            onClickActionCallback={(uuid, index, row) => {}}
            externalSort={handleSorting}
            sorting={buildSort(sortState)}
            itemsProps={{
              text: {
                translate: (value, colRef) => {
                  if (colRef === "notification_language") return t(value);
                  return value;
                },
              },
            }}
            pagination={
              data?.pagination
                ? {
                    currentPage: Math.ceil(
                      data?.pagination?.current / TABLE_LIMIT
                    ),
                    totalPages: Math.ceil(
                      data?.pagination?.total / TABLE_LIMIT
                    ),
                    threshold: 3,
                    onChangePage: handlePagination,
                    onChangePrev: handlePagination,
                    onChangeNext: handlePagination,
                  }
                : undefined
            }
            actions={
              handleManageLogistics()
                ? [
                    {
                      component: (
                        <IconItem
                          name="edit"
                          text={t("edit")}
                          color={"#7E7E7E"}
                          textStyle={{ fontWeight: 300 }}
                          onClick={() => {
                            setIsEdit(true);
                            setOpen(!open);
                          }}
                        />
                      ),
                    },
                    {
                      component: (
                        <IconItem
                          name="delete"
                          text={t("delete")}
                          color={"#7E7E7E"}
                          textStyle={{ fontWeight: 300 }}
                          onClick={() => setOpenDelete(!openDelete)}
                        />
                      ),
                    },
                  ]
                : []
            }
          />
        </div>
      </div>
      {handleManageLogistics() ? (
        <>
          {open && (
            <DataProvider>
              <ModalFactory
                type={modalTypes.LOGISTIC_PRICING_UPLOAD}
                open={open}
                setOpen={() => {
                  setOpen(false);
                }}
                isEdit={isEdit}
                data={rowData.current}
                uuid={rowUuid.current}
                fetchData={fetchData}
                submitCallback={submitcallback}
              />
            </DataProvider>
          )}
        </>
      ) : (
        <></>
      )}
    </Container>
  );
};

const LogisticsPricingTable = () => {
  const keyPricingTable = "logisticsPricingTable";
  return (
    <PaginationProvider storageKey={keyPricingTable} keysMerged>
      <FiltersWrapper storageKey={keyPricingTable} keysMerged>
        <SortProvider storageKey={keyPricingTable} keysMerged>
          <LogisticsPricingLayout />
        </SortProvider>
      </FiltersWrapper>
    </PaginationProvider>
  );
};

const LogisticsPricingLayout = () => {
  const { i18n } = useTranslation();
  const baseParams = {
    locale: i18n.language,
    limit: TABLE_LIMIT,
    tableModule: tableModules.LOGISTICS_PRICING_TABLE,
  };
  const { tableParams } = useTableConfig(baseParams);

  return (
    <LayoutTable fnData={getTable} fnParameters={tableParams}>
      <InnerLogisticsPricingTable baseTableParams={baseParams} />
    </LayoutTable>
  );
};

export default LogisticsPricingTable;
