import React from "react";

function FavoritesIcon() {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="50" height="50" rx="10" fill="#EBF3FF" />
      <path
        d="M14.2617 26.8631C12.8603 25.391 12 23.3989 12 21.2059C12 16.6739 15.6739 13 20.2059 13C22.2235 13 24.071 13.7281 25.5 14.936C26.929 13.7281 28.7765 13 30.7941 13C35.3261 13 39 16.6739 39 21.2059C39 23.3989 38.1397 25.391 36.7383 26.8631C36.6574 26.9948 36.56 27.1218 36.4451 27.2418L27.0604 37.0463C26.2099 37.9348 24.7901 37.9348 23.9396 37.0463L14.5549 27.2418C14.44 27.1218 14.3426 26.9948 14.2617 26.8631Z"
        fill="#63A1FF"
      />
    </svg>
  );
}

export default FavoritesIcon;
