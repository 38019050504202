import { Button, InputPassword, Spacer } from "@scrapadev/scrapad-front-sdk";
import VerificationInput from "react-verification-input";
import { useTranslation } from "../../utils/hooks";
import BackButton from "../ui/back-button/BackButton";
import LoginInput from "../ui/login/LoginInput";
import LoginLabel from "../ui/login/LoginLabel";
import styles from "./ResetPassword.module.scss";

export const ResetPwdForm = (props) => {
  const { t } = useTranslation(["login", "common"]);

  return (
    <>
      <form method="POST" onSubmit={props.doChangePwd}>
        <LoginLabel text={t("confirm-code")} small>
          <VerificationInput
            classNames={{
              container: styles["container"],
              character: styles["character"],
              characterInactive: styles["character--inactive"],
              characterSelected: styles["character--selected"],
              characterFilled: styles["character--filled"],
            }}
            inputProps={{
              name: "code",
              id: "code",
            }}
          />
        </LoginLabel>
        <LoginLabel text={t("password")} hint={t("password-policy")} small>
          <LoginInput
            element={
              <InputPassword
                mode="underline"
                name="password"
                required={true}
                id="password"
              />
            }
          />
        </LoginLabel>
        <LoginLabel text={t("repeat-password")} small>
          <LoginInput
            element={
              <InputPassword
                mode="underline"
                name="passwordRepeat"
                required={true}
                id="passwordRepeat"
              />
            }
          />
        </LoginLabel>
        <Spacer height={10} />
        <LoginLabel noPadding>
          <Button
            component="button"
            fullwidth={true}
            type="submit"
            text={t("change_password")}
          />
        </LoginLabel>
      </form>
      <BackButton />
    </>
  );
};
