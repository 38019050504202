import { useNavigate } from "react-router-dom";
import {
  BREAKPOINT_LG,
  Center,
  Flex,
  Heading,
  Loading,
  Padding,
  Paper,
  Row,
  TooltipSc,
  Typography,
  useMatchMedia,
  WARM_NEUTRAL_40,
} from "@scrapadev/scrapad-front-sdk/dist/index";
import Container from "../../components/ui/container/Container";
import Header from "../../components/ui/header/Header";
import Icon from "../../components/ui/icon/Icon";
import NavBar from "../../components/ui/navbar/NavBar";
import Steps from "../../components/ui/steps/Steps";
import LayoutDetail from "../../layout/detail/Detail";
import { useSteps, useTranslation } from "../../utils/hooks";
import { SUCCESS_COLOR } from "../../utils/variables";
import WidgetDataWrapper from "../../widgets/wrappers/DataWrapper";
import {
  getBusinessAbout,
  getHeaderInfo,
  getVerificationFilesModule,
  getWalletMoneyOut,
} from "../../model/business";
import WidgetVerificationFiles from "../../widgets/business/verification/Files";
import { useState } from "react";
import HistoryBlockWrapper from "../../wrappers/HistoryBlockWrapper";
import { handleCRUD } from "../../utils/crud/functions";
import { useSetDataUser, useSignOut } from "../../providers/AuthHooks";
import { useHeaderProvider } from "../../providers/header/HeaderContext";
import { useOperationsProvider } from "../../providers/organization/OperationsContext";
import VerificationValidationSwitch from "../../widgets/business/verification/ValidationSwitch";
import { verificationTypes } from "../../utils/verification/variables";
import LegalRepresentative from "../../widgets/business/verification/LegalRepresentative";
import Fiscal from "../../widgets/business/verification/Fiscal";
import BankAccount from "../../widgets/business/verification/BankAccount";
import { printVerified } from "../../utils/functions";

const Approved = () => {
  return (
    <Icon
      name={"check_circle"}
      color={SUCCESS_COLOR}
      style={{ marginRight: 12, fontSize: "1rem" }}
    />
  );
};

const Tooltip = ({ content }) => {
  const { t } = useTranslation(["verification"]);
  if (!content) return <></>;
  return (
    <TooltipSc
      content={<>{t(content)}</>}
      style={{ maxWidth: 250 }}
      elementStyles={{ width: 18, height: 18, marginLeft: 10 }}
    >
      <Icon name="info" color={"#777986"} style={{ fontSize: 18 }} />
    </TooltipSc>
  );
};

const BusinessVerification = ({
  id,
  state,
  loading,
  setLoading,
  fetchData,
}) => {
  const { t, i18n } = useTranslation([
    "common",
    "transactions",
    "verification",
    "business",
  ]);
  const navigate = useNavigate();
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_LG}px)`);
  const { steps } = useSteps(id);
  const [isDirty, setIsDirty] = useState([0, 0, 0, 0, 0, 0]);
  const signOut = useSignOut();
  const setDataUser = useSetDataUser();
  const kycDocuments = state?.getKycDocuments;
  const basicFields = kycDocuments?.filter((f) => f?.providerUse === "basic");
  const lemonwayFields = kycDocuments?.filter(
    (f) => f?.providerUse === "complete"
  );

  const { state: headerState } = useHeaderProvider(
    i18n.language,
    id,
    getHeaderInfo
  );
  const { canManageKYC } = useOperationsProvider();

  const fieldToTooltip = (field) => {
    const mapped = {
      "waste-management-license": "tooltip-waste-license",
      "commercial-register": "tooltip-business-registry",
      "certificate-of-incorporation": "tooltip-business-constitution",
      "capital-breakdown": "tooltip-business-ownership",
    };
    return mapped[field] || null;
  };

  const deleteDocument = async (uuid) => {
    if (uuid) {
      await handleCRUD({
        endpoint: `org/${id}/verification/${uuid}`,
        method: "DELETE",
        successCallback: async () => {
          setLoading(true);
          await fetchData();
          setLoading(false);
        },
        messageOk: t("action-ok"),
        signOut,
        setDataUser,
        t: t,
      });
    }
  };

  return (
    <>
      <NavBar />
      <LayoutDetail
        backgroundColor={"#fff"}
        headerComponent={
          <Container>
            <div className="row">
              <div className="col-12">
                <Header
                  title={
                    <Row gap={10}>
                      {printVerified(headerState?.verificationState, t)}
                      <span>{headerState?.basicData?.orgName}</span>
                    </Row>
                  }
                  goBack
                  breadcrumbs={[
                    {
                      description: t("business"),
                      onClick: () => navigate("/business", { replace: true }),
                    },
                    {
                      description: headerState?.basicData?.orgName,
                      onClick: () =>
                        navigate(`/business/${id}`, { replace: true }),
                    },
                    { description: t("verification") },
                  ]}
                  fullSubHeaderLeft
                  subHeaderLeft={
                    <Steps
                      steps={steps}
                      currentStep={"verification"}
                      checkCurrentByLabel
                    />
                  }
                />
              </div>
            </div>
          </Container>
        }
        bodyComponent={
          <HistoryBlockWrapper
            mustBlockLeaving={isDirty.find((f) => f === 1)}
            submitCallback={(modalOpen, setModalOpen) =>
              (e, loading, setLoading) => {
                setModalOpen(false);
              }}
          >
            {loading ? (
              <Center>
                <Padding>
                  <Loading />
                </Padding>
              </Center>
            ) : (
              <Container>
                <Paper
                  style={{
                    padding: matches ? "15px 7px" : "15px 25px",
                    gap: "15px",
                    marginBottom: "20px",
                    alignItems: "stretch",
                  }}
                >
                  <Row gap={15} align="center" justify="space-between">
                    <Heading
                      type={3}
                      style={{ marginBottom: "0px", paddingLeft: 0 }}
                    >
                      {t("basic-verification")}
                    </Heading>
                    {canManageKYC() ? (
                      <Flex justifyContent={"flex-end"}>
                        <VerificationValidationSwitch
                          id={id}
                          type={verificationTypes.BASIC}
                        />
                      </Flex>
                    ) : undefined}
                  </Row>

                  <Paper
                    whiteMode
                    style={{
                      padding: matches ? "20px 0px" : "20px 15px",
                      alignItems: "stretch",
                    }}
                  >
                    <Heading type={5} style={{ marginBottom: "10px" }}>
                      {t("legal-representative", { ns: "business" })}
                    </Heading>
                    <WidgetDataWrapper
                      id={`${id}_legal`}
                      fnData={getBusinessAbout}
                      fnParameters={{
                        org: id,
                        module: "legalReprenstative",
                        signOut: signOut,
                        setDataUser: setDataUser,
                        locale: i18n.language,
                      }}
                      Component={LegalRepresentative}
                    />
                  </Paper>
                  <Paper
                    whiteMode
                    style={{
                      padding: matches ? "20px 0px" : "20px 15px",
                      alignItems: "stretch",
                    }}
                  >
                    <Heading type={5} style={{ marginBottom: "10px" }}>
                      {t("tax-residence-of-business", { ns: "business" })}
                    </Heading>
                    <WidgetDataWrapper
                      id={`${id}_fiscal`}
                      fnData={getBusinessAbout}
                      fnParameters={{
                        org: id,
                        module: "fiscalAddress",
                        signOut: signOut,
                        setDataUser: setDataUser,
                        locale: i18n.language,
                      }}
                      Component={Fiscal}
                    />
                  </Paper>
                  {basicFields?.map((basic, i) => {
                    return (
                      <Paper
                        key={`basic_v_field_${i}`}
                        whiteMode
                        style={{
                          padding: matches ? "15px 7px" : "20px 25px",
                          alignItems: "stretch",
                        }}
                      >
                        <Heading type={5} style={{ marginBottom: "20px" }}>
                          <Row gap={5}>
                            {basic?.description}
                            {basic?.helpContent && (
                              <TooltipSc content={basic?.helpContent}>
                                <Icon
                                  name="help_outline"
                                  color={WARM_NEUTRAL_40}
                                  style={{
                                    fontSize: "18px",
                                  }}
                                />
                              </TooltipSc>
                            )}
                          </Row>
                        </Heading>
                        <WidgetDataWrapper
                          id={id}
                          fnData={getVerificationFilesModule}
                          fnParameters={{
                            files: basic?.documents
                              ? [...basic?.documents]
                              : [],
                          }}
                          Component={WidgetVerificationFiles}
                          extraData={{
                            permission: canManageKYC,
                            fetchData: fetchData,
                            setLoading: setLoading,
                            description: basic?.description,
                            canEdit: true,
                            id: id,
                            afterUploadAllFiles: async (files) => {
                              if (files && files.length > 0) {
                                setLoading(true);
                                handleCRUD({
                                  endpoint: `org/${id}/verification`,
                                  data: {
                                    files: files.map((f) => ({
                                      id: f.uuid,
                                      type: basic?.type,
                                    })),
                                  },
                                  method: "POST",
                                  setDataUser,
                                  signOut,
                                  successCallback: async () => {
                                    await fetchData();
                                    setLoading(false);
                                  },
                                });
                              }
                            },
                          }}
                        />
                      </Paper>
                    );
                  })}
                </Paper>
                <Paper
                  style={{
                    padding: matches ? "15px 7px" : "15px 25px",
                    gap: "15px",
                    marginBottom: "20px",
                    alignItems: "stretch",
                  }}
                >
                  <Row gap={15} align="center" justify="space-between">
                    <Heading
                      type={3}
                      style={{ marginBottom: "0px", paddingLeft: 0 }}
                    >
                      {t("full-verification")}
                    </Heading>
                    {canManageKYC() ? (
                      <Flex justifyContent={"flex-end"}>
                        <VerificationValidationSwitch
                          id={id}
                          type={verificationTypes.COMPLETE}
                        />
                      </Flex>
                    ) : undefined}
                  </Row>
                  <Paper
                    whiteMode
                    style={{
                      padding: matches ? "20px 0px" : "20px 15px",
                      alignItems: "stretch",
                    }}
                  >
                    <WidgetDataWrapper
                      id={id}
                      disabled={false}
                      fnData={getWalletMoneyOut}
                      fnParameters={{
                        org: id,
                        signOut: signOut,
                        setDataUser: setDataUser,
                        locale: i18n.language,
                      }}
                      Component={BankAccount}
                      updateStateCallback={() => {}}
                      parentCallback={async () => {
                        setLoading(true);
                        await fetchData();
                        setLoading(false);
                      }}
                      completeFields={lemonwayFields}
                      deleteDocument={deleteDocument}
                    />
                  </Paper>

                  {/* {lemonwayFields?.map((lemonway, i) => {
                    return (
                      <Paper
                        key={`full_v_field_${i}`}
                        whiteMode
                        style={{
                          padding: matches ? "15px 7px" : "20px 25px",
                          alignItems: "stretch",
                        }}
                      >
                        <Heading type={5} style={{ marginBottom: "20px" }}>
                          <Row gap={5}>
                            {lemonway?.isCompleted ? renderApproved() : <></>}
                            {lemonway?.description}
                            {lemonway?.helpContent && (
                              <TooltipSc
                                content={
                                  <Typography
                                    type="2"
                                    style={{
                                      margin: 0,
                                      color: "#fff",
                                      maxWidth: "300px",
                                    }}
                                  >
                                    {lemonway?.helpContent}
                                  </Typography>
                                }
                              >
                                <Icon
                                  name="help_outline"
                                  color="#9E9E9E"
                                  style={{
                                    fontSize: "18px",
                                  }}
                                />
                              </TooltipSc>
                            )}
                          </Row>
                        </Heading>

                        <WidgetDataWrapper
                          id={id}
                          fnData={getVerificationFilesModule}
                          fnParameters={{
                            files: lemonway?.documents
                              ? [...lemonway?.documents]
                              : [],
                          }}
                          Component={WidgetVerificationFiles}
                          extraData={{
                            canEdit: true,
                            permission: canManageKYC,
                            afterUploadAllFiles: (files) => {
                              if (files && files.length > 0) {
                                setLoading(true);
                                handleCRUD({
                                  endpoint: `org/${id}/verification`,
                                  data: {
                                    files: files.map((f) => ({
                                      id: f.uuid,
                                      type: lemonway?.type,
                                    })),
                                  },
                                  method: "POST",
                                  setDataUser,
                                  signOut,
                                  successCallback: async () => {
                                    await fetchData();
                                    setLoading(false);
                                  },
                                });
                              }
                            },
                            fetchData: fetchData,
                            setLoading: setLoading,
                            id: id,
                          }}
                        />
                      </Paper>
                    );
                  })} */}
                </Paper>
              </Container>
            )}
          </HistoryBlockWrapper>
        }
      />
    </>
  );
};

export default BusinessVerification;
