import { ClearButton, WARM_NEUTRAL_40 } from "@scrapadev/scrapad-front-sdk";
import { useNavigate } from "react-router-dom";
import Icon from "../icon/Icon";
import React from "react";

/**
 * UI Go Back component.
 * @param {object} props - Component properties.
 * @param {Function} [props.customNavigate] - A custom navigate callback to replace navigate -1.
 * @param {React.CSSProperties} [props.style={}] - Custom styles.
 * @returns {JSX.Element}
 */
const GoBack = ({ customNavigate, style = {} }) => {
  const navigate = useNavigate();
  return (
    <div style={style}>
      <ClearButton
        type="button"
        onClick={() => (customNavigate ? customNavigate() : navigate(-1))}
      >
        <Icon name={"keyboard_backspace"} color={WARM_NEUTRAL_40} />
      </ClearButton>
    </div>
  );
};

export default GoBack;
