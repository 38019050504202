import {
  Center,
  Loading,
  Spacer,
  TableManager,
} from "@scrapadev/scrapad-front-sdk";
import ModalDeleteOpportunity from "../../../components/modals/opportunities/Delete";
import ResultsText from "../../../components/ui/text/ResultsText";
import LayoutTable from "../../../layout/table/Table";
import { getTable } from "../../../model/tables";
import { useTranslation } from "../../../utils/hooks";
import {
  useOpportunitiesByMeActions,
  useOpportunitiesTable,
} from "../../../utils/opportunities/hooks";
import { tableModules } from "../../../utils/variables";
import WidgetBusinessEmpty from "./Empty";

const InnerWidgetOpportunitiesByMe = ({ baseTableParams }) => {
  const { t, i18n } = useTranslation(["common"]);
  const {
    openDeleteConfirmation,
    setOpenDeleteConfirmation,
    actions,
    onRowClick,
    selectedRow,
  } = useOpportunitiesByMeActions();
  const { data, fetchData, loading, tableProps } =
    useOpportunitiesTable(baseTableParams);
  const defaults = {
    pagination: {
      initial: 1,
      current: baseTableParams.limit ?? 4,
      total: data?.pagination?.total ?? 16,
    },
  };
  if (loading) {
    return (
      <Center>
        <Loading />
      </Center>
    );
  }
  if (data?.body?.length === 0) {
    return (
      <WidgetBusinessEmpty type={"opportunities"} text={t("empty-data")} />
    );
  }
  return (
    <>
      <Spacer height={15} />
      <TableManager
        {...tableProps}
        actions={actions}
        pagination={undefined}
        sorting={undefined}
        externalSort={undefined}
        onRowClick={onRowClick}
        selectedRow={selectedRow}
      />
      <Spacer height={20} />
      <ResultsText
        initial={defaults.pagination.initial}
        current={defaults.pagination.current}
        total={defaults.pagination.total}
      />
      {openDeleteConfirmation && (
        <ModalDeleteOpportunity
          open={openDeleteConfirmation}
          setOpen={setOpenDeleteConfirmation}
          data={{
            key: selectedRow.current.uuid || "",
          }}
          successCallback={fetchData}
        />
      )}
    </>
  );
};

const WidgetOpportunitiesByMe = ({ id }) => {
  const { i18n } = useTranslation(["common"]);
  const baseParams = {
    locale: i18n.language,
    limit: 4,
    filter: {
      ops: [
        {
          field: "uuidOrg",
          value: id,
        },
      ],
    },
    tableModule: tableModules.DASHBOARD_OPPORTUNITIES_CREATED,
  };

  return (
    <LayoutTable fnData={getTable} fnParameters={baseParams}>
      <InnerWidgetOpportunitiesByMe baseTableParams={baseParams} />
    </LayoutTable>
  );
};

export default WidgetOpportunitiesByMe;
