import { modalTypes, TEXT_COLOR_LIGHT } from "../../../utils/variables";
import ModalFactory from "../../../components/ui/modal/ModalFactory";
import { DataProvider } from "../../../providers/data/DataContext";
import { useState } from "react";
import {
  BREAKPOINT_LG,
  Favorite,
  MaterialsCard,
  MaterialsItem,
  useMatchMedia,
  toastError,
  Empty,
  Padding,
  Spacer,
  Paper,
  WARM_NEUTRAL_50,
} from "@scrapadev/scrapad-front-sdk";
import { useTranslation } from "../../../utils/hooks";
import Separator from "../../../components/ui/separator/Separator";
import {
  buildAddFavoriteMaterialsData,
  buildEditFavoriteMaterialsData,
  getLinkToMarketplace,
} from "../../../utils/favorites/functions";
import { handleCRUD } from "../../../utils/crud/functions";
import { useSetDataUser, useSignOut } from "../../../providers/AuthHooks";

const WidgetFavouritesMaterials = ({
  id,
  state,
  extraData,
  fetchData,
  setLoading,
}) => {
  const { t, i18n } = useTranslation(["common", "business"]);
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_LG}px)`);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [editData, setEditdata] = useState(false);
  const [deleteData, setDeletedata] = useState(false);
  const materials = state?.getFavouriteMaterials;
  const setDataUser = useSetDataUser();
  const signOut = useSignOut();
  const { permission = null } = extraData || {};

  const handleEdit = (adType, key) => () => {
    const type = { sale: false, purchase: false };
    if (adType.includes("sale")) type.purchase = true;
    if (adType.includes("purchase")) type.sale = true;
    setEditdata({
      adType: type,
      key,
    });
    setOpenEditModal(!openEditModal);
  };

  const handleDelete = (index, key) => () => {
    setDeletedata({ key });
    setOpenDeleteModal(!openDeleteModal);
  };

  const printTranslation = (type) => {
    const isBothBuyAndSell =
      type.adType?.includes("purchase") && type.adType?.includes("sale");
    const translations = {
      buy:
        type.label === "ALL" || isBothBuyAndSell
          ? t("want_to_buy")
          : t("i_want_buy"),
      sell:
        type.label === "ALL" || isBothBuyAndSell
          ? t("want_to_sell")
          : t("i_want_sell"),
    };
    return translations;
  };

  return (
    <>
      {!materials || materials?.length === 0 ? (
        <Padding>
          <Empty icon="bo" heading={t("section-empty")} />
        </Padding>
      ) : (
        <Paper style={{ alignItems: "stretch" }}>
          {materials?.map((material, i) => {
            return (
              <>
                <MaterialsCard
                  key={`fav_material_${i}`}
                  title={material?.material}
                  titleStyles={{
                    marginBottom: 10,
                  }}
                  extraTitle={undefined}
                  extraTitleProps={{
                    justifyContent: "space-between",
                    style: { width: "100%", flexWrap: "wrap" },
                  }}
                  boxStyles={{ marginBottom: 0 }}
                >
                  {material.materialsType?.map((type, index) => {
                    return (
                      <>
                        <MaterialsItem
                          structure="column"
                          beforeMenuComponent={
                            <>
                              <Favorite
                                uuid={index + 1}
                                active
                                containerStyles={{
                                  borderRadius: 20,
                                  cursor: "pointer",
                                }}
                                buttonStyles={{
                                  border: "none",
                                }}
                                onClick={
                                  permission
                                    ? permission()
                                      ? handleDelete(type?.adType, type?.key)
                                      : undefined
                                    : undefined
                                }
                              />
                              <Separator />
                            </>
                          }
                          title={
                            type.label === "ALL" ? t(type.label) : type.label
                          }
                          isSell={type.adType?.includes("purchase")}
                          isBuy={type.adType?.includes("sale")}
                          handleEdit={handleEdit(type?.adType, type?.key)}
                          handleRemove={handleDelete(type?.adType, type?.key)}
                          itemsIconProps={{
                            color: TEXT_COLOR_LIGHT,
                            iconStyle: { fontSize: "1.25rem" },
                            border: "none",
                          }}
                          translation={printTranslation(type)}
                          iconColor={WARM_NEUTRAL_50}
                          matches={matches}
                          hasActions={
                            permission ? (permission() ? true : false) : true
                          }
                          numberOfAds={
                            type?.relatedAds > 0 ? type?.relatedAds : undefined
                          }
                          numberOfAdsOnClick={() => {
                            window.open(
                              getLinkToMarketplace(material, type, i18n),
                              "_blank"
                            );
                          }}
                        />
                        {index + 1 < material.materialsType?.length && (
                          <Spacer height={15} />
                        )}
                      </>
                    );
                  })}
                </MaterialsCard>
              </>
            );
          })}
        </Paper>
      )}
      <DataProvider>
        <ModalFactory
          type={modalTypes.ADD_MATERIAL}
          open={extraData.openModalMaterial}
          setOpen={extraData.setOpenModalMaterial}
          onSubmit={(modalState) => {
            const [data, error] = buildAddFavoriteMaterialsData(modalState);
            if (error) {
              toastError(t("data-error"));
              return;
            }
            setLoading(true);
            handleCRUD({
              endpoint: `org/${id}/material`,
              method: "POST",
              data,
              successCallback: () => fetchData(),
              messageOk: t("action-ok"),
              signOut,
              setDataUser,
              setLoading,
            });
          }}
        />
      </DataProvider>
      <DataProvider>
        <ModalFactory
          type={modalTypes.FAVORITES_EDIT_MATERIAL}
          open={openEditModal}
          setOpen={setOpenEditModal}
          data={editData}
          onSubmit={(modalState, key) => {
            const [data, error] = buildEditFavoriteMaterialsData(modalState);
            if (error) {
              toastError(t("data-error"));
              return;
            }
            setLoading(true);
            handleCRUD({
              endpoint: `org/${id}/material/${key}`,
              method: "PUT",
              data,
              successCallback: () => fetchData(),
              messageOk: t("action-ok"),
              signOut,
              setDataUser,
              setLoading,
            });
          }}
        />
      </DataProvider>
      {openDeleteModal && (
        <ModalFactory
          type={modalTypes.FAVORITES_DELETE}
          open={openDeleteModal}
          setOpen={setOpenDeleteModal}
          data={deleteData}
          onSubmit={(key) => {
            setLoading(true);
            handleCRUD({
              endpoint: `org/${id}/material/${key}`,
              method: "DELETE",
              successCallback: () => fetchData(),
              messageOk: t("action-ok"),
              signOut,
              setDataUser,
              setLoading,
            });
          }}
        />
      )}
    </>
  );
};

export default WidgetFavouritesMaterials;
