import {
  Center,
  Loading,
  Padding,
  Heading,
  Flex,
} from "@scrapadev/scrapad-front-sdk";
import GoBack from "../back/GoBack";
import Breadcrumbs from "../breadcrumbs/Breadcrumbs";
import styles from "./Header.module.scss";

const Header = ({
  title,
  titleSmall = false,
  afterTitle,
  goBack = false,
  breadcrumbs = [],
  titleRightComponent,
  subtitle,
  subHeaderLeft,
  fullSubHeaderLeft,
  subHeaderRight,
  fullSubHeaderRight,
  containerStyles = {},
  upperStyles = {},
  middleStyles = {},
  middleRightStyles = {},
  lowerStyles = {},
  lowerLeftStyles = {},
  lowerRightStyles = {},
  loading,
}) => {
  return (
    <Flex
      alignItems={"stretch"}
      justifyContent={"center"}
      flexDirection={"column"}
      style={containerStyles}
    >
      {loading ? (
        <Center>
          <Padding>
            <Loading />
          </Padding>
        </Center>
      ) : (
        <>
          {breadcrumbs.length > 0 && (
            <Flex
              style={{
                marginTop: 40,
                gap: 10,
                flexWrap: "wrap",
                ...upperStyles,
              }}
              justifyContent={"flex-start"}
            >
              {goBack && <GoBack />}
              {breadcrumbs.length > 0 && <Breadcrumbs items={breadcrumbs} />}
            </Flex>
          )}

          <div className={styles["sc-header--middle"]} style={middleStyles}>
            <Heading
              weight="bold"
              type={titleSmall ? 2 : 1}
              style={{
                marginBottom: 0,
              }}
            >
              {title}
              {afterTitle && afterTitle}
            </Heading>
            <div style={{ marginLeft: "auto", ...middleRightStyles }}>
              {titleRightComponent && titleRightComponent}
            </div>
          </div>
          <div className={styles["sc-header--lower"]} style={lowerStyles}>
            {(subtitle || subHeaderLeft) && (
              <div
                className={styles["sc-header--lower-left"]}
                style={
                  fullSubHeaderLeft
                    ? { flexGrow: 1, maxWidth: "100%", ...lowerLeftStyles }
                    : lowerLeftStyles
                }
              >
                {subtitle && (
                  <h2 className={styles["sc-header--subtitle"]}>{subtitle}</h2>
                )}
                {subHeaderLeft && subHeaderLeft}
              </div>
            )}

            {subHeaderRight && (
              <div
                className={styles["sc-header--lower-right"]}
                style={
                  fullSubHeaderRight
                    ? { flexGrow: 1, maxWidth: "100%", ...lowerRightStyles }
                    : lowerRightStyles
                }
              >
                {subHeaderRight}
              </div>
            )}
          </div>
        </>
      )}
    </Flex>
  );
};

export default Header;
