import {
  InputNumber,
  Label,
  Row,
  Typography,
  WARM_NEUTRAL_50,
} from "@scrapadev/scrapad-front-sdk";
import React from "react";
import { useTranslation } from "../../utils/hooks";

function LoadCharacteristicsAttributes({
  label,
  onWidthChange,
  widthProps,
  onHeightChange,
  heightProps,
  onDepthChange,
  depthProps,
  onWeightChange,
  weightProps,
  onVolumeChange,
  volumeProps,
  hint,
  readOnly,
}) {
  const { t } = useTranslation("common");

  return (
    <Label
      dark
      small
      text={label}
      hint={hint}
      useHintTooltip
      hintTooltipProps={{
        alignment: "bottom",
        style: {
          maxWidth: 320,
        },
      }}
      hintProps={{
        color: "#777986",
        style: {
          fontSize: 16,
          position: "relative",
          top: 3,
        },
      }}
    >
      <div className="row" style={{ alignItems: "flex-end" }}>
        {onVolumeChange && (
          <div className="col-12">
            <Label
              text={
                <span>
                  {t("load-volume")}{" "}
                  <span style={{ color: WARM_NEUTRAL_50 }}>
                    ({t("optional")})
                  </span>
                </span>
              }
              style={{ paddingBottom: 0 }}
            >
              <Row gap={5}>
                <InputNumber
                  step={0.01}
                  readOnly={readOnly}
                  onChange={onVolumeChange}
                  style={{ marginBottom: 0 }}
                  mode="underline"
                  {...volumeProps}
                />
                <Typography style={{ margin: 0 }}>m³</Typography>
              </Row>
            </Label>
          </div>
        )}
        {onHeightChange && (
          <div className="col-md-3 col-4">
            <Label text="Largo" style={{ paddingBottom: 0 }}>
              <Row gap={5}>
                <InputNumber
                  readOnly={readOnly}
                  onChange={onHeightChange}
                  style={{ marginBottom: 0 }}
                  mode="underline"
                  {...heightProps}
                />
                <Typography style={{ margin: 0 }}>cm</Typography>
              </Row>
            </Label>
          </div>
        )}
        {onWidthChange && (
          <div className="col-md-3 col-4">
            <Label text="Ancho" style={{ paddingBottom: 0 }}>
              <Row gap={5}>
                <InputNumber
                  readOnly={readOnly}
                  onChange={onWidthChange}
                  style={{ marginBottom: 0 }}
                  mode="underline"
                  {...widthProps}
                />
                <Typography style={{ margin: 0 }}>cm</Typography>
              </Row>
            </Label>
          </div>
        )}
        {onDepthChange && (
          <div className="col-md-3 col-4">
            <Label text="Alto" style={{ paddingBottom: 0 }}>
              <Row gap={5}>
                <InputNumber
                  readOnly={readOnly}
                  onChange={onDepthChange}
                  style={{ marginBottom: 0 }}
                  mode="underline"
                  {...depthProps}
                />
                <Typography style={{ margin: 0 }}>cm</Typography>
              </Row>
            </Label>
          </div>
        )}
        {onWeightChange && (
          <div className="col-md-3 col-12">
            <Label text="Carga por bulto" style={{ paddingBottom: 0 }}>
              <Row gap={5}>
                <InputNumber
                  readOnly={readOnly}
                  onChange={onWeightChange}
                  style={{ marginBottom: 0 }}
                  mode="underline"
                  {...weightProps}
                />
                <Typography style={{ margin: 0 }}>kg</Typography>
              </Row>
            </Label>
          </div>
        )}
      </div>
    </Label>
  );
}

export default LoadCharacteristicsAttributes;
