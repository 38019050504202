import { useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Container from "../../components/ui/container/Container";
import Filters from "../../components/ui/filters/Filters";
import {
  Empty,
  Flex,
  Row,
  Switch,
  TableManager,
} from "@scrapadev/scrapad-front-sdk/dist/index";
import Header from "../../components/ui/header/Header";
import NavBar from "../../components/ui/navbar/NavBar";
import SearchFilterExport from "../../components/ui/search-filter-export/SearchFilterExport";
import Steps from "../../components/ui/steps/Steps";
import LayoutDetail from "../../layout/detail/Detail";
import { useTableDataProvider } from "../../providers/TableContext";
import { useSteps, useTranslation } from "../../utils/hooks";
import styles from "./BusinessTransactions.module.scss";
import { useDocumentTitle } from "../../utils/document/hooks";
import {
  QUERY_UPPER_LIMIT,
  selectedOrgKey,
  tableModules,
  TABLE_LIMIT,
  transactionTypeInfo,
} from "../../utils/variables";
import { useFiltersProvider } from "../../providers/filters/FiltersContext";
import { getHeaderInfo } from "../../model/business";
import { useHeaderProvider } from "../../providers/header/HeaderContext";
import { usePaginationProvider } from "../../providers/pagination/PaginationContext";
import { getTransactionsByObject } from "../../model/tables";
import LayoutTable from "../../layout/table/Table";
import {
  useDateFilter,
  useMaterialsFilter,
  usePhaseFilter,
  useTurnFilter,
} from "../../utils/filters/hooks";
import { useGQL } from "../../utils/gql/hooks";
import { getCatalog } from "../../model/filters";
import { useSortProvider } from "../../providers/sort/SortContext";
import { useTableActions } from "../../utils/tables/hooks";
import { applySortToFilters } from "../../utils/tables/functions";
import { printVerified } from "../../utils/functions";

const key = "businessTransactionsPagination";

const InnerBusinessTransactions = () => {
  const { t, i18n, values } = useTranslation(["common", "transactions"], true);
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, setData, dataBuffer, loading, fetchData } =
    useTableDataProvider();
  useDocumentTitle(`${t("transactions")}`, values);
  const sortedField = useRef(null);
  const { buildFiltersAndFetch, getStoredFilters } = useFiltersProvider();
  const { handlePagination } = usePaginationProvider();
  const { state: sortState, handleSorting, buildSort } = useSortProvider();
  const sortFields = buildSort(sortState);
  const sortedDirection = useRef(null);
  const { state } = useHeaderProvider(i18n.language, id, getHeaderInfo);
  const { steps } = useSteps(id);
  const dateFilterStart = useDateFilter("dates", t("start-date"));
  const materialsFilter = useMaterialsFilter("materials", t("material"));
  const phaseFilter = usePhaseFilter();
  const turnFilter = useTurnFilter();
  const dateFilterLastMovement = useDateFilter(
    "lastMovement",
    t("last-action")
  );
  const { data: catalogData } = useGQL(
    [getCatalog, getCatalog],
    [
      {
        locale: i18n.language,
        name: "ad",
      },
      {
        locale: i18n.language,
        name: "org",
      },
    ]
  );
  const storedfilters = buildFiltersAndFetch(getStoredFilters() || {});
  const filters =
    Object.keys(storedfilters).length > 0
      ? {
          ...storedfilters,
          ops: [...(storedfilters?.ops || []), { field: "uuidOrg", value: id }],
        }
      : { ops: [{ field: "uuidOrg", value: id }] };

  useTableActions({
    paginationCallback: (paginationState) => {
      const newFilters = buildFiltersAndFetch(
        getStoredFilters() || {},
        undefined,
        id
          ? [
              {
                field: "uuidOrg",
                value: id,
              },
            ]
          : undefined
      );
      fetchData({
        locale: i18n.language,
        limit: TABLE_LIMIT,
        offset: paginationState?.current,
        tableModule: tableModules.ADS,
        filter: applySortToFilters(
          newFilters,
          sortFields,
          "REACT_APP_SORT_TRANSACTIONS"
        ),
      });
    },
  });

  return (
    <>
      <NavBar />
      <LayoutDetail
        backgroundColor={"#fff"}
        headerStyle={{ marginBottom: 0 }}
        headerComponent={
          <Container>
            <div className="row">
              <div className="col-12">
                <Header
                  title={
                    <Row gap={10}>
                      {printVerified(state?.verificationState, t)}
                      <span>{state?.basicData?.orgName}</span>
                    </Row>
                  }
                  goBack
                  breadcrumbs={[
                    {
                      description: t("business"),
                      onClick: () => navigate("/business", { replace: true }),
                    },
                    {
                      description: state?.basicData?.orgName,
                      onClick: () =>
                        navigate(`/business/${id}`, { replace: true }),
                    },
                    { description: t("transactions") },
                  ]}
                  fullSubHeaderLeft
                  subHeaderLeft={
                    <Flex alignItems="normal" flexDirection="column">
                      <Steps
                        steps={steps}
                        currentStep={"transactions"}
                        checkCurrentByLabel
                      />
                      <Flex
                        justifyContent="space-between"
                        className={styles["container"]}
                      >
                        {JSON.parse(
                          process.env.REACT_APP_SHOW_ARCHIVED_TRANSACTIONS
                        ) && (
                          <div>
                            <Switch label="Mostrar transacciones archivadas" />
                          </div>
                        )}
                        <SearchFilterExport
                          filterKey="transactionText"
                          searchPlaceholder={t("search")}
                          exportData={data}
                          hasSeparator={false}
                          exportProps={{
                            fnData: getTransactionsByObject,
                            fnParameters: {
                              uuidOrg: id,
                              locale: i18n.language,
                              limit: QUERY_UPPER_LIMIT,
                              offset: 0,
                              filter: applySortToFilters(
                                filters,
                                sortFields,
                                "REACT_APP_SORT_TRANSACTIONS"
                              ),
                            },
                            fileName: "transactions",
                          }}
                        />
                      </Flex>
                      <div className={styles["sc-filters--container"]}>
                        <Filters
                          deps={[catalogData]}
                          uuidOrg={id}
                          initialFilters={{
                            transactionText: {
                              external: true,
                            },
                            dates: dateFilterStart,
                            lastMovement: dateFilterLastMovement,
                            materials: materialsFilter,
                            phase: phaseFilter,
                            turn: turnFilter,
                          }}
                        />
                      </div>
                    </Flex>
                  }
                />
              </div>
            </div>
          </Container>
        }
        bodyComponent={
          <Container>
            <div className="row">
              <div className="col-12">
                <TableManager
                  loading={loading}
                  data={data}
                  setData={setData}
                  emptyText={t("empty-transactions")}
                  rowTriggerPageChange={false}
                  onRowClick={(uuid) => {
                    navigate(`/transactions/${uuid}`);
                  }}
                  onAfterSort={(field, sortedAsc) => {
                    sortedField.current = { ...field };
                    sortedDirection.current = sortedAsc;
                  }}
                  externalSort={
                    JSON.parse(process.env.REACT_APP_SORT_TRANSACTIONS)
                      ? handleSorting
                      : undefined
                  }
                  customEmptyElement={
                    dataBuffer.current?.body?.length === 0 && (
                      <Empty icon="bo" heading={t("section-empty")} />
                    )
                  }
                  sorting={buildSort(sortState)}
                  pagination={{
                    currentPage: Math.ceil(
                      data?.pagination?.current / TABLE_LIMIT
                    ),
                    totalPages: Math.ceil(
                      data?.pagination?.total / TABLE_LIMIT
                    ),
                    threshold: 3,
                    onChangePage: handlePagination,
                    onChangePrev: handlePagination,
                    onChangeNext: handlePagination,
                  }}
                  itemsProps={{
                    chip: {
                      mapTransactionValues: transactionTypeInfo,
                      translate: (code) => {
                        if (!code) return "";
                        if (code.includes("#")) {
                          const parsed = code.split("#");
                          const literals = parsed[1].split(",");
                          return literals.map((l) => t(l)).join(", ");
                        }
                        return t(code);
                      },
                    },
                    split: {
                      mapTransactionValues: transactionTypeInfo,
                    },
                    date: {
                      languageData: i18n.getLanguageVariable(i18n.language),
                    },
                  }}
                />
              </div>
            </div>
          </Container>
        }
      />
    </>
  );
};

const BusinessTransactions = () => {
  const { id } = useParams();
  const { getStoredPaginationIfBackPressed } = usePaginationProvider();
  const { getStoredFilters, buildFiltersAndFetch } = useFiltersProvider();
  const { state: sortState, buildSort } = useSortProvider();
  const hasDifferentUuidOrg =
    getStoredFilters()?.[selectedOrgKey] &&
    getStoredFilters()?.[selectedOrgKey]?.data !== id;
  const storedfilters = buildFiltersAndFetch(getStoredFilters() || {});
  const sortFields = buildSort(sortState);
  const filters =
    Object.keys(storedfilters).length === 0 || hasDifferentUuidOrg
      ? { ops: [{ field: "uuidOrg", value: id }] }
      : {
          ...storedfilters,
          ops: [...(storedfilters?.ops || []), { field: "uuidOrg", value: id }],
        };
  const tableParams = {
    limit: TABLE_LIMIT,
    uuidOrg: id,
    offset:
      getStoredPaginationIfBackPressed(key)?.uuidOrg &&
      getStoredPaginationIfBackPressed(key)?.uuidOrg !== id
        ? 0
        : getStoredPaginationIfBackPressed(key)?.current
        ? getStoredPaginationIfBackPressed(key)?.current
        : 0,
  };

  if (Object.keys(filters).length > 0) {
    tableParams.filter = filters;
  }

  return (
    <LayoutTable
      fnData={getTransactionsByObject}
      fnParameters={{
        ...tableParams,
        filter: applySortToFilters(
          tableParams.filter,
          sortFields,
          "REACT_APP_SORT_TRANSACTIONS"
        ),
      }}
    >
      <InnerBusinessTransactions />
    </LayoutTable>
  );
};

export default BusinessTransactions;
