import LayoutTable from "../../layout/table/Table";
import { useTranslation } from "../../utils/hooks";
import { useTableConfig } from "../../utils/tables/hooks";
import { tableModules, TABLE_LIMIT } from "../../utils/variables";
import ModalDeleteOpportunity from "../../components/modals/opportunities/Delete";
import { useNavigate } from "react-router-dom";
import WidgetOpportunitiesLayout from "./OpportunitiesLayout";
import {
  useByMeFilters,
  useOpportunitiesByMeActions,
} from "../../utils/opportunities/hooks";
import { getTable } from "../../model/tables";
import { useTableDataProvider } from "../../providers/TableContext";
import { useUser } from "../../providers/AuthHooks";
import WebsocketsWrapper from "../websockets/Wrapper";
import { getRoomSlug, WS_ROOM } from "../../utils/websockets/functions";
import _ from "lodash";

/**
 * Table of opportunities created by the bo user.
 * @param {object} props - Component properties.
 * @param {object} baseTableParams - Table params.
 * @returns {JSX.Element}
 */
const InnerWidgetOpportunitiesByMe = ({ baseTableParams }) => {
  const { t } = useTranslation(["common"], true);
  const navigate = useNavigate();
  const { deps, initialFilters } = useByMeFilters();
  const { fetchData } = useTableDataProvider();
  const user = useUser();
  const {
    openDeleteConfirmation,
    setOpenDeleteConfirmation,
    actions,
    onRowClick,
    selectedRow,
  } = useOpportunitiesByMeActions();

  return (
    <div data-cy="opportunities-byme-table">
      <WidgetOpportunitiesLayout
        filterProps={{
          deps,
          initialFilters,
        }}
        tableProps={{
          actions,
          baseTableParams,
          selectedRow,
          onRowClick: onRowClick,
        }}
      />
      <ModalDeleteOpportunity
        open={openDeleteConfirmation}
        setOpen={setOpenDeleteConfirmation}
        data={{
          key: selectedRow.current.uuid || "",
        }}
        successCallback={fetchData}
      />
      {user && (
        <WebsocketsWrapper
          callback={async (data) => {
            if (
              !_.isEmpty(data) &&
              data?.subscribeRoom?.type === WS_ROOM.opportunity_created
            ) {
              await fetchData();
            }
          }}
          room={getRoomSlug("/opportunities_created")}
        />
      )}
    </div>
  );
};

const WidgetOpportunitiesByMe = () => {
  const { i18n } = useTranslation();
  const baseParams = {
    locale: i18n.language,
    limit: TABLE_LIMIT,
    tableModule: tableModules.OPPORTUNITIES_CREATED,
  };
  const { tableParams } = useTableConfig(baseParams);

  return (
    <LayoutTable fnData={getTable} fnParameters={tableParams}>
      <InnerWidgetOpportunitiesByMe baseTableParams={baseParams} />
    </LayoutTable>
  );
};

export default WidgetOpportunitiesByMe;
