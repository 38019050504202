import React from "react";
import { useTranslation } from "../../../utils/hooks";
import { useHeaderProvider } from "../../../providers/header/HeaderContext";
import {
  BREAKPOINT_LG,
  InputText,
  Label,
  Row,
  useMatchMedia,
} from "@scrapadev/scrapad-front-sdk";
import { renderDisabledComponentWithTooltip } from "../../../utils/functions";
import { orgStatus } from "../../../utils/variables";
import { handleOnChange } from "./functions";

const WidgetAddressLocation = ({
  address,
  dispatch,
  index,
  state,
  withTooltipReadOnly,
  extraElement = <></>,
}) => {
  const { t } = useTranslation(["talk", "common", "business"]);
  const { state: headerState } = useHeaderProvider();
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_LG}px)`);

  const renderwithTooltip = (cmp) => {
    if (!headerState) return cmp;
    if (!withTooltipReadOnly) return cmp;
    return renderDisabledComponentWithTooltip(
      cmp,
      headerState?.verificationState === orgStatus.KYC_COMPLETE,
      t
    );
  };

  return (
    <>
      <Row gap={20} wrap={matches}>
        <Label text={t("street-number")} style={{ paddingBottom: 0 }}>
          {renderwithTooltip(
            <InputText
              value={address?.street}
              onChange={handleOnChange(index, "street", state, dispatch)}
              placeholder={t("write-here")}
              mode="underline"
              required
            />
          )}
        </Label>
        <Label text={t("postal-code")} style={{ paddingBottom: 0 }}>
          {renderwithTooltip(
            <InputText
              value={address?.postalCode}
              onChange={handleOnChange(index, "postalCode", state, dispatch)}
              placeholder={t("write-here")}
              mode="underline"
              required
            />
          )}
        </Label>
      </Row>
      <Row gap={20} wrap={matches}>
        <Label text={t("town")} style={{ paddingBottom: 0 }}>
          {renderwithTooltip(
            <InputText
              value={address?.city}
              onChange={handleOnChange(index, "city", state, dispatch)}
              placeholder={t("write-here")}
              mode="underline"
              required
            />
          )}
        </Label>
        {extraElement}
      </Row>
    </>
  );
};

export default WidgetAddressLocation;

