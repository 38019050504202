import React from "react";
import {
  Icon,
  Flex,
  Row,
  Typography,
  SCRAPBACK_BLUE_50,
  TEXT_COLOR_LIGHT,
  WARM_NEUTRAL_70,
} from "@scrapadev/scrapad-front-sdk";
import { useTranslation } from "../../../utils/hooks";

function NotificationsPreferencesHelp() {
  const { t } = useTranslation("notifications");

  return (
    <Flex
      flexDirection="column"
      alignItems={"flex-start"}
      justifyContent="flex-start"
      style={{ gap: "25px" }}
    >
      <Typography typography="large" style={{ margin: 0 }}>
        {t("notifications_channels_desc")}
      </Typography>
      <Flex
        flexDirection="column"
        alignItems={"flex-start"}
        justifyContent="flex-start"
        style={{ gap: "25px" }}
      >
        <HelpItem
          icon={"notifications"}
          label={t("web")}
          description={t("web_desc")}
          color={SCRAPBACK_BLUE_50}
        />
        <HelpItem
          icon={"calendar_view_day"}
          label={t("push")}
          description={t("push_desc")}
          color={SCRAPBACK_BLUE_50}
        />
        <HelpItem
          icon={"mail"}
          label={t("email")}
          description={t("email_desc")}
          color={SCRAPBACK_BLUE_50}
        />
      </Flex>
    </Flex>
  );
}

function HelpItem({ icon, label, color, description }) {
  return (
    <Flex
      flexDirection="column"
      alignItems={"flex-start"}
      justifyContent="flex-start"
      style={{ gap: 12 }}
    >
      <Row gap={10}>
        <Icon name={icon} color={color} style={{ fontSize: 20 }} />
        <Typography
          typography="large"
          style={{
            margin: 0,
            fontWeight: 600,
            color: WARM_NEUTRAL_70,
            fontSize: 14,
          }}
        >
          {label}
        </Typography>
      </Row>
      <Typography
        typography="large"
        style={{
          color: TEXT_COLOR_LIGHT,
        }}
      >
        {description}
      </Typography>
    </Flex>
  );
}

export default NotificationsPreferencesHelp;
