import { Chip } from "@scrapadev/scrapad-front-sdk";
import { useFiltersProvider } from "../../../providers/filters/FiltersContext";
import { useTranslation } from "../../../utils/hooks";

/**
 * Component to show chips in a grouped form.
 * @param {object} props - Component properties
 * @param {object} props.filter - Current filter
 * @returns {JSX.Element}
 */
const FilterGroupedChips = ({ filter }) => {
  const { dispatch, actions, filterChanged } = useFiltersProvider();
  const { t } = useTranslation("common");
  const selectedWithoutParent =
    filter?.selected?.filter((f) => !f.parent) || [];
  const items = filter?.items || [];

  const renderText = (selectedSubItems, selected) => {
    const item = items?.find((f) => f?.uuid === selected?.uuid);
    if (!selectedSubItems || selectedSubItems.length === 0)
      return `${selected?.name}`;
    if (selectedSubItems.length === item?.subItems.length)
      return `${selected?.name} (${t("all")})`;
    return `${selected?.name} (${selectedSubItems.length})`;
  };

  return selectedWithoutParent?.map((selected) => {
    const selectedSubItems = filter?.selected.filter(
      (f) => f?.parent === selected.uuid
    );
    return (
      <Chip
        key={selected?.uuid}
        type="outlined"
        text={renderText(selectedSubItems, selected)}
        showIcon
        handleClick={() => {
          filterChanged.current = true;
          dispatch({
            type: actions.REMOVE_MULTIPLE_FILTER,
            payload: {
              filter: { slug: filter?.slug },
              item: {
                uuids: [
                  ...selectedSubItems.map((f) => f?.uuid),
                  selected?.uuid,
                ],
              },
            },
          });
        }}
      />
    );
  });
};

export default FilterGroupedChips;
