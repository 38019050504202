import styles from "./Search.module.scss";
import Icon from "../../icon/Icon";
import { useRef, useState } from "react";
import {
  ClearButton,
  Flex,
  Separator,
  InputText,
  IconButton,
  WARM_NEUTRAL_30,
  WARM_NEUTRAL_60,
} from "@scrapadev/scrapad-front-sdk";
import _ from "lodash";

/**
 * UI Search component.
 * @param {Object} props - Component properties.
 * @returns {JSX.Element}
 */
const Search = ({
  initialValue,
  value,
  controlled = false,
  onEnterCallback,
  onCancelSearch,
  onApplySearch,
  isLocalSearch,
  hasTrailingSearch,
  trailinCancelIcon,
  minimal,
  whiteMode,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const [innerValue, setInnerValue] = useState(
    initialValue ? initialValue : value
  );
  const [searched, setSearched] = useState(value !== "" || initialValue !== "");

  const inputRef = useRef();

  if (value !== innerValue) setInnerValue(value);

  const handleInputPaddingRight = () => {
    if (!controlled) return 16;
    if (searched) return 50;
    return controlled && innerValue !== "" ? 100 : 16;
  };

  return (
    <div
      className={`${styles["sc-search"]} ${minimal ? styles["minimal"] : ""} ${
        open && styles["open"]
      } ${!_.isEmpty(inputRef?.current?.value) && styles["filtering"]}
      ${whiteMode ? styles["whiteMode"] : ""}
      `}
    >
      {minimal && (
        <IconButton
          name="search"
          color={WARM_NEUTRAL_60}
          onClick={() => setOpen(!open)}
        />
      )}
      <InputText
        ref={inputRef}
        className={styles["search_input"]}
        leadingIcon={
          props.leadingIcon ? (
            props.leadingIcon
          ) : innerValue !== "" ? (
            <></>
          ) : (
            <Icon
              name={"search"}
              color={WARM_NEUTRAL_30}
              style={{ fontSize: "20px" }}
            />
          )
        }
        trailingCallback={() => setInnerValue("")}
        trailingIcon={
          controlled ? (
            innerValue && innerValue !== "" ? (
              <Flex style={{ position: "relative", top: searched ? -2 : -4 }}>
                {hasTrailingSearch && searched === false && (
                  <>
                    <ClearButton
                      onClick={() => {
                        setSearched(true);
                        if (onApplySearch)
                          onApplySearch(innerValue, setInnerValue);
                      }}
                    >
                      <Icon
                        name="search"
                        color={WARM_NEUTRAL_30}
                        style={{ fontSize: 18 }}
                      />
                    </ClearButton>
                    <Separator />
                  </>
                )}
                <ClearButton
                  onClick={() => {
                    setSearched(false);
                    if (onCancelSearch)
                      onCancelSearch(innerValue, setInnerValue);
                  }}
                >
                  {trailinCancelIcon ? (
                    trailinCancelIcon
                  ) : (
                    <Icon
                      name="close"
                      color={WARM_NEUTRAL_30}
                      style={{ fontSize: 18 }}
                    />
                  )}
                </ClearButton>
              </Flex>
            ) : undefined
          ) : undefined
        }
        onKeyPress={(e) => {
          if (e.key === "Enter" && onEnterCallback) {
            setSearched(true);
            onEnterCallback(e);
          }
        }}
        style={{
          paddingLeft: isLocalSearch || innerValue === "" ? 45 : 16,
          paddingRight: handleInputPaddingRight(),
          ...(props.style || {}),
        }}
        value={controlled ? innerValue : undefined}
        {...props}
      />
    </div>
  );
};

export default Search;
