import deepmerge from "deepmerge";
import { useTranslation } from "../../utils/hooks";
import {
  Alert,
  Chip,
  Container,
  DropDown,
  Flex,
  InputNumber,
  InputText,
  Label,
  Row,
  Spacer,
  Typography,
  StepperAdaptative,
  StepperAdaptativeItem,
  Paper,
  Radio,
  WARM_NEUTRAL_30,
  InfoBox,
  Disclaimer,
  BLUE_40,
  InputCounter,
  InputFile,
  toastError,
  Heading,
  BLUE_30,
  WARM_NEUTRAL_60,
  Spinner,
  Divider,
  WARM_NEUTRAL_40,
  TextArea,
} from "@scrapadev/scrapad-front-sdk";
import {
  getChipFormat,
  handleOnChange,
  uploadFile,
} from "../../utils/functions";
import _ from "lodash";
import {
  adStatus,
  currencyOptions,
  frequencyOptions,
  LOAD_POSSIBILITY,
  LoadCharacteristicasTypes,
  modalTypes,
  questionsTypes,
} from "../../utils/variables";
import CatalogSearch from "../../components/ui/input/search/CatalogSearch";
import FilesList from "../fields/FilesList";
import LoadCharacteristicsAttributes from "../../widgets/ads/LoadCharacteristicsAttributes";
import Questions from "../../components/ui/input/search/Questions";
import { useGQL } from "../../utils/gql/hooks";
import { getCatalogNoTerm } from "../../model/filters";
import { useEffect, useRef, useState } from "react";
import { getMaterialQuestions } from "../../model/ads";
import { checkFormRequiredFields } from "./AdStatusAction";
import {
  canNotPublishBasic,
  canNotPublishKyc,
} from "../../utils/ads/functions";
import ModalFactory from "../../components/ui/modal/ModalFactory";
import { FlagMap } from "../../components/ui/icons/general/Flags";
import AddressFormV3 from "../../wrappers/address/AddressFormV3";
import LoadPossibilities from "../business/addresses/LoadPossibilities";
import ListTile from "../../components/ui/list-tile/ListTile";
import CompositionInput from "../../components/ui/composition-input/CompositionInput";
import { getPreferencesPaymentMethods } from "../../model/business";

const TITLE_MAX_LENGTH = 80;

const canEdit = (state, key, isEdit) => {
  if (!state.status) return false;
  if (state?.status === adStatus.BLOCKED || state?.status === adStatus.REMOVED)
    return false;
  if (
    state?.status === adStatus.COVERED ||
    (state?.status === adStatus.PUBLISHED &&
      state.transactionsActive &&
      state.transactionsActive > 0)
  ) {
    if (isEdit && (key === "amount" || key === "price" || key === "currency"))
      return true;
    else return false;
  }
  return isEdit ? true : false;
};

const AdFormV2 = ({
  index,
  state,
  dispatch,
  actions,
  fetchData,
  extraData,
  headerHeight,
  setHeaderHeight,
  isEdit,
  isMobile,
  isDirty,
  verificationState,
  ...props
}) => {
  const { t, i18n } = useTranslation(["common", "ads"], true);
  const [modalType, setModalType] = useState(false);
  const [isPort, setIsPort] = useState(state?.address?.port);
  const [errorFieldsList, setErrorFieldsList] = useState([]);

  const [isPublishing, setIsPublishing] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [stepOneError, setStepOneError] = useState(false);
  const [stepTwoError, setStepTwoError] = useState(false);
  const [stepThreeError, setStepThreeError] = useState(false);
  const [haveTriedToPublish, setHaveTriedToPublish] = useState(false);
  const [uploading, setUploading] = useState(false);
  const filesBuffer = useRef([]);
  const [paymentMethodsData, setPaymentMethodData] = useState(undefined);
  const [portName, setPortName] = useState();

  const { data: equipmentsData, loading: equipmentsDataLoading } = useGQL(
    getCatalogNoTerm,
    {
      locale: i18n.language,
      name: "equipments",
    }
  );

  const handleItemSelected = (key) => (option, open, setOpen) => {
    const obj = {};
    obj[key] = option.uuid;
    dispatch({
      type: actions.UPDATE_INPUT,
      payload: obj,
    });
    setOpen(!open);
  };

  const { data: materialQuestionData, fetchData: fetchMaterialQuestions } =
    useGQL(getMaterialQuestions, {}, true);

  const { data: currencyList } = useGQL(getCatalogNoTerm, {
    locale: i18n.language,
    name: "currency",
  });

  const fetchPaymentMethodData = async ({ locale = "es", org }) => {
    const data = await getPreferencesPaymentMethods({
      locale: locale,
      org: org,
    });
    setPaymentMethodData(data?.filter((e) => e.active) || []);
  };

  useEffect(() => {
    if (state.uuidOrg && !_.isEmpty(state) && _.isEmpty(paymentMethodsData)) {
      fetchPaymentMethodData({
        locale: i18n.language,
        org: state.uuidOrg,
      });
    }

    const errorFields = checkFormRequiredFields(state);
    if (!_.isEmpty(errorFields[1])) {
      setErrorFieldsList(errorFields[1]);
      handleErrors(errorFields[1]);
    } else {
      setStepOneError(false);
      setStepTwoError(false);
      setStepThreeError(false);
      setErrorFieldsList([]);
    }
  }, [state]);

  useEffect(() => {
    if (materialQuestionData) {
      const newState = deepmerge([], state);
      const mqd = materialQuestionData || [];
      newState["materialQuestions"] = [...mqd];
      dispatch({ type: actions.UPDATE_INPUT, payload: newState });
    }
  }, [materialQuestionData]);

  const handleAddress =
    (dispatch = () => {}, cleanFacilities = false) =>
    (e = {}, isFav = false, keepId = false) => {
      const newState = deepmerge([], state);
      if (e?.port) {
        newState["address"] = { ...newState?.address, ...e };
      } else {
        if (isFav) {
          newState["address"] = { ...e };
        } else {
          newState["address"] = { ...newState?.address, ...e };
          if (!keepId) delete newState["address"]["id"];
        }
      }
      if (cleanFacilities) {
        newState["facilities"] = [];
      }

      dispatch({ type: actions.UPDATE_INPUT, payload: newState });
    };

  const handleAddLerCode = (e) => {
    const newState = deepmerge([], state);
    const lerCodesCpy = newState?.lerCodes || [];
    newState["lerCodes"] = [...lerCodesCpy, e || ""];
    dispatch({
      type: actions.UPDATE_INPUT,
      payload: newState,
    });
  };

  const handleRemoveCollection = (tIndex, key) => {
    const newState = deepmerge([], state);
    newState[key] = newState[key].filter((element, i) => i !== tIndex);
    dispatch({
      type: actions.UPDATE_INPUT,
      payload: newState,
    });
  };

  const handleAddImage = (e) => {
    const newState = deepmerge([], state);
    const filesCpy = newState?.files || [];
    newState["files"] = [...filesCpy, e || ""];
    dispatch({
      type: actions.UPDATE_INPUT,
      payload: newState,
    });
  };

  const handleLoadCharacteristics = (key, value = {}, clean = false) => {
    const newState = deepmerge([], state);
    if (clean) {
      newState["loadCharacteristics"] = [];
    }

    if (state?.type !== "purchase") {
      if (key !== LoadCharacteristicasTypes.GRANEL) {
        newState["loadCharacteristics"] = newState[
          "loadCharacteristics"
        ].filter((e) => e.type !== LoadCharacteristicasTypes.GRANEL);
      }
    }
    const index = newState["loadCharacteristics"]?.findIndex((lc) => {
      return lc?.type === key;
    });

    if (index !== -1) {
      if (value?.type) {
        newState["loadCharacteristics"] = newState[
          "loadCharacteristics"
        ].filter((lc) => lc?.type !== key);
      } else {
        newState["loadCharacteristics"] = newState["loadCharacteristics"].map(
          (lc) => {
            if (lc.type === key) {
              lc.value = { ...lc.value, ...value };
            }
            return lc;
          }
        );
      }
    } else {
      newState["loadCharacteristics"].push(value);
    }

    dispatch({
      type: actions.UPDATE_INPUT,
      payload: newState,
    });
  };

  const handleDeleteImage = (e) => {
    const newState = deepmerge([], state);
    newState["files"] = newState["files"]?.filter(
      (element) => e.uuid !== element.uuid
    );
    dispatch({
      type: actions.UPDATE_INPUT,
      payload: newState,
    });
  };

  const handleSortImage = (dragIndex, hoverIndex) => {
    if (state?.files?.length === 0) return;
    const newState = deepmerge([], state);
    const bkState = deepmerge([], state);
    newState?.files?.splice(dragIndex, 1);
    newState?.files?.splice(hoverIndex, 0, bkState?.files?.[dragIndex]);
    filesBuffer.current = [...newState?.files];
  };

  const handleQuestionOnChange = (q) => (e) => {
    const newState = deepmerge([], state);
    const materialQuestionsCpy = newState?.materialQuestions || [];
    newState["materialQuestions"] = [...materialQuestionsCpy];

    const exist = newState["materialQuestions"].filter(
      (mq) => mq.uuidQuestion === q.uuidQuestion
    );

    if (exist.length > 0) {
      if (q?.type === questionsTypes.MULTIPLE_CHOICE) {
        newState["materialQuestions"] = newState["materialQuestions"].map(
          (mq) => {
            if (mq.uuidQuestion === q.uuidQuestion) {
              const valueExist = mq.value?.filter(
                (mqf) => mqf === e.target.value
              );
              if (valueExist?.length > 0) {
                mq.value = mq.value.filter((mqf) => mqf !== e.target.value);
              } else {
                if (_.isEmpty(mq.value)) {
                  mq.value = [];
                }
                mq.value.push(e.target.value);
              }
              return mq;
            }
            return mq;
          }
        );
      } else if (q?.type === questionsTypes.YES_NO) {
        newState["materialQuestions"] = newState["materialQuestions"].map(
          (mq) => {
            if (mq.uuidQuestion === q.uuidQuestion) {
              mq.value = [mq.value ? !mq.value[0] : true];
              return mq;
            }
            return mq;
          }
        );
      } else {
        newState["materialQuestions"] = newState["materialQuestions"].map(
          (mq) => {
            if (mq.uuidQuestion === q.uuidQuestion) {
              mq.value = [e.target.value];
              return mq;
            }
            return mq;
          }
        );
      }
    } else {
      if (q?.type === questionsTypes.MULTIPLE_CHOICE) {
        const newValue = { ...q };
        newValue.value = [e.target.value];
        newState["materialQuestions"].push(newValue);
      } else if (q?.type === questionsTypes.YES_NO) {
        const newValue = { ...q };
        newValue.value = [true];
        newState["materialQuestions"].push(newValue);
      } else {
        const newValue = { ...q };
        newValue.value = [e.target.value];
        newState["materialQuestions"].push(newValue);
      }
    }

    dispatch({
      type: actions.UPDATE_INPUT,
      payload: newState,
    });
  };

  const handleItemFacilitiesClick = (type, text) => {
    const found = state?.facilities?.find((f) => f === type);
    if (type === LOAD_POSSIBILITY.none) {
      dispatch({
        type: actions.UPDATE_INPUT,
        payload: {
          facilities: [],
        },
      });
    } else {
      let value =
        state?.facilities?.filter(
          (f) => f !== type && f !== LOAD_POSSIBILITY.none
        ) || [];
      if (!found) {
        value = [...value, type];
      }
      dispatch({
        type: actions.UPDATE_INPUT,
        payload: {
          facilities: value,
        },
      });
    }
  };

  const renderAlert = () => {
    if (canNotPublishBasic(verificationState)) {
      return <Alert type="warning">{t("cant-publish-basic")}</Alert>;
    }
    if (canNotPublishKyc(state, verificationState)) {
      return <Alert type="warning">{t("cant-publish-full")}</Alert>;
    }
    if (!checkFormRequiredFields(state)[0]) {
      return <Alert type="error">{t("fill-required-fields")}</Alert>;
    }
  };

  const handleErrors = (list = []) => {
    if (!_.isEmpty(list)) {
      list.forEach((e) => {
        switch (e) {
          case "type":
          case "title":
          case "description":
          case "amount":
          case "amountScale":
          case "materialTypeEdit":
          case "price":
          case "files":
            // setActiveStep(0);
            // isStepChanged = true;
            setStepOneError(true);
            break;
          case "loadCharacteristics":
          case "load_preferences_granel":
          case "load_preferences_saca":
          case "load_preferences_palet":
          case "incoterm":
          case "address":
          case "port":
          case "facilities":
            // if (!isStepChanged) {
            //   setActiveStep(1);
            //   isStepChanged = true;
            // }
            setStepTwoError(true);
            break;
          case "paymentMethod":
          case "component_incomplete":
            // if (!isStepChanged) {
            //   setActiveStep(2);
            //   isStepChanged = true;
            // }
            setStepThreeError(true);
            break;
          default:
            break;
        }
      });
    }
  };

  const isGranelSelected = !_.isEmpty(
    state?.loadCharacteristics?.filter(
      (lc) => lc.type === LoadCharacteristicasTypes.GRANEL
    )
  );
  const isSacasSelected = !_.isEmpty(
    state?.loadCharacteristics?.filter(
      (lc) => lc.type === LoadCharacteristicasTypes.SACAS
    )
  );
  const isPaletSelected = !_.isEmpty(
    state?.loadCharacteristics?.filter(
      (lc) => lc.type === LoadCharacteristicasTypes.PALET
    )
  );

  return (
    <>
      <Container>
        <div className="row">
          <div className="col-12">
            {renderAlert()}
            <Spacer />
          </div>
        </div>
        <StepperAdaptative
          header={undefined}
          onStepChange={(e) => {
            setActiveStep(e);
          }}
          lastNextButtonText={t("ad-publish")}
          onLastButtonClick={() => {
            if (!isPublishing) {
              setHaveTriedToPublish(true);
              //   handlePublishChanges({
              //     ad: state,
              //     uuid: id,
              //     setLoading: setIsPublishing,
              //   });
            }
          }}
          lastButtonProps={{
            disabled: isPublishing,
            isLoading: isPublishing,
          }}
          prevButtonProps={{
            disabled: isPublishing,
            isLoading: isPublishing,
          }}
          prevButtonText={t("save-draft")}
          onPrevClick={() => {
            if (!isPublishing) {
              //   handleSaveDraft({
              //     ad: state,
              //     uuid: id,
              //     setLoading: setIsPublishing,
              //   });
            }
          }}
          mode={isMobile ? "ACCORDION" : undefined}
          active={activeStep}
          bottomArea={<></>}
        >
          <StepperAdaptativeItem
            title={t("general_information")}
            description={t("fullfill_all_basic_data")}
            isReady={!stepOneError && haveTriedToPublish}
            isReadyText={t("ready_to_publish")}
            needModeration={false}
            needModerationText={t("need_moderation")}
            haveError={stepOneError}
            haveErrorText={t("need_more_info_to_publish")}
          >
            <Flex
              flexDirection="column"
              alignItems="stretch"
              style={{ gap: "5px" }}
            >
              <Paper
                whiteMode
                style={{
                  padding: isMobile ? "20px 10px" : "20px 15px",
                  marginTop: "15px",
                  alignItems: "stretch",
                  gap: "25px",
                }}
              >
                <Label
                  text={`${t("what-do-you-wanna-do")}`}
                  bold
                  dark
                  error={errorFieldsList.find((e) => e === "type")}
                  errorAtBottom
                  requiredErrorText={t("required_field")}
                  noPadding
                >
                  <Row
                    style={{
                      width: "auto",
                      justifyContent: "space-evenly",
                      alignItems: "center",
                    }}
                    gap={20}
                  >
                    <span style={{ cursor: "pointer" }}>
                      <Radio
                        id="ad_type_form_1"
                        name="ad_type_form"
                        text={t("purchase_1")}
                        value="purchase"
                        checked={state?.type === "purchase"}
                        required
                        disabled={!canEdit(state, "type", isEdit)}
                        onChange={
                          canEdit(state, "type", isEdit)
                            ? () =>
                                handleOnChange(
                                  "type",
                                  dispatch
                                )({
                                  target: {
                                    value:
                                      state?.type === "purchase"
                                        ? undefined
                                        : "purchase",
                                  },
                                })
                            : undefined
                        }
                        labelStyle={{
                          cursor: "pointer",
                        }}
                      />
                    </span>
                    <span style={{ cursor: "pointer" }}>
                      <Radio
                        id="ad_type_form_2"
                        name="ad_type_form"
                        text={t("sale_1")}
                        value="sale"
                        checked={state?.type === "sale"}
                        required
                        disabled={!canEdit(state, "type", isEdit)}
                        onChange={
                          canEdit(state, "type", isEdit)
                            ? () =>
                                handleOnChange(
                                  "type",
                                  dispatch
                                )({
                                  target: {
                                    value:
                                      state?.type === "sale"
                                        ? undefined
                                        : "sale",
                                  },
                                })
                            : undefined
                        }
                        labelStyle={{
                          cursor: "pointer",
                        }}
                      />
                    </span>
                  </Row>
                </Label>
                <Label
                  text={
                    <Row justify="space-between" style={{ width: "100%" }}>
                      <span>{`${t("title")}`}</span>
                      <Typography
                        small
                        style={{
                          fontWeight: 500,
                          color: WARM_NEUTRAL_30,
                          margin: 0,
                        }}
                      >
                        {state?.originalTitle?.length || 0}/{TITLE_MAX_LENGTH}{" "}
                        {t("characters")}
                      </Typography>
                    </Row>
                  }
                  headingStyles={{ width: "100%" }}
                  spanStyles={{ width: "100%" }}
                  error={errorFieldsList.find((e) => e === "title")}
                  medium
                  semiBold
                  style={{
                    width: "100%",
                  }}
                  noPadding
                  errorAtBottom
                  requiredErrorText={t("required_field")}
                >
                  <InputText
                    required
                    mode="underline"
                    readOnly={!canEdit(state, "title", isEdit)}
                    placeholder={t("ad-title-placeholder")}
                    value={state?.originalTitle}
                    onChange={(e) => {
                      handleOnChange("title", dispatch)(e);
                      handleOnChange("originalTitle", dispatch)(e);
                    }}
                    maxlength={TITLE_MAX_LENGTH}
                    style={{
                      marginBottom: "0",
                    }}
                    {...{
                      "data-cy": "title_field",
                    }}
                  />
                </Label>
                <Label
                  text={`${t("material")}`}
                  medium
                  semiBold
                  error={errorFieldsList.find((e) => e === "materialTypeEdit")}
                  style={{
                    width: "100%",
                  }}
                  noPadding
                  errorAtBottom
                  requiredErrorText={t("required_field")}
                >
                  <CatalogSearch
                    inputProps={{
                      readOnly: !canEdit(state, "materialTypeEdit", isEdit),
                      disabled: !canEdit(state, "materialTypeEdit", isEdit),
                      required: true,
                      style: {
                        marginBottom: "0",
                      },
                      "data-cy": "materialTypeEdit_input",
                    }}
                    mode="underline"
                    initialValue={
                      state?.materialTypeEdit?.name || state?.materialTypeEdit
                    }
                    catalogName={"material-autocomplete"}
                    onClickCallback={
                      canEdit(state, "materialTypeEdit", isEdit)
                        ? (option, open, setOpen) => {
                            setTimeout(() => {
                              setOpen(false);
                            }, 100);

                            handleOnChange(
                              "materialTypeEdit",
                              dispatch
                            )({
                              target: {
                                value: option,
                              },
                            });

                            fetchMaterialQuestions({
                              uuid: option.uuid,
                              locale: i18n.language,
                            });
                          }
                        : undefined
                    }
                    {...{
                      "data-cy": "materialTypeEdit_input",
                    }}
                  />
                </Label>
                <Label
                  text={`${t(
                    state?.type === "purchase"
                      ? "ad-amount-wish"
                      : "ad-amount-available"
                  )} (t)`}
                  medium
                  semiBold
                  error={errorFieldsList.find((e) => e === "amount")}
                  errorAtBottom
                  requiredErrorText={t("required_field")}
                  style={{
                    width: "100%",
                  }}
                  noPadding
                >
                  <Row gap={15}>
                    <InputNumber
                      mode="underline"
                      min={1}
                      step={0.01}
                      readOnly={!canEdit(state, "amount", isEdit)}
                      required={true}
                      placeholder={t("write-here")}
                      name="amount"
                      onChange={handleOnChange("amount", dispatch)}
                      value={state?.amount}
                      style={{
                        marginBottom: "0",
                      }}
                      {...{
                        "data-cy": "amount_field",
                      }}
                    />
                    <div style={{ maxWidth: "40px" }}>
                      <InputText
                        mode="underline"
                        value={"t"}
                        readOnly
                        style={{
                          marginBottom: "0",
                          paddingLeft: "10px",
                        }}
                      />
                    </div>
                  </Row>
                </Label>
                <div>
                  <Label
                    text={`${t("ad-proposed-price-negotiate")}`}
                    medium
                    semiBold
                    style={{ width: "100%" }}
                    noPadding
                  ></Label>
                  <Row gap={15}>
                    <div style={{ width: "50%" }}>
                      <InputNumber
                        mode="underline"
                        readOnly={!canEdit(state, "price", isEdit)}
                        min={1}
                        step={0.01}
                        required={true}
                        placeholder={t("write-here")}
                        name="price"
                        onChange={handleOnChange("price", dispatch)}
                        value={state?.price}
                        style={{
                          marginBottom: "0",
                        }}
                        {...{
                          "data-cy": "price_field",
                        }}
                      />
                    </div>
                    <div style={{ width: "50%" }}>
                      <DropDown
                        mode="underline"
                        readOnly={!canEdit(state, "currency", isEdit)}
                        id="currency"
                        name="currency"
                        placeholder={t("select-value")}
                        // options={currencyOptions?.map((o) => ({
                        //   uuid: o.uuid,
                        //   text: t(o.text),
                        // }))}
                        leadingIcon={
                          _.isEmpty(FlagMap[state?.currency]) ? undefined : (
                            <span style={{ lineHeight: "normal" }}>
                              {FlagMap[state?.currency]}
                            </span>
                          )
                        }
                        options={
                          currencyList
                            ? currencyList.map((item) => ({
                                uuid: item.uuid,
                                text: item?.name?.includes("%")
                                  ? item.name
                                  : `${item.name}/t`,
                              }))
                            : currencyOptions
                        }
                        required={true}
                        value={
                          currencyList
                            ? currencyList
                                ?.find((f) => f.uuid === state?.currency)
                                ?.name.includes("%")
                              ? currencyList?.find(
                                  (f) => f.uuid === state?.currency
                                )?.name
                              : `${
                                  currencyList?.find(
                                    (f) => f.uuid === state?.currency
                                  )?.name
                                }/t`
                            : currencyOptions
                                ?.find((f) => f.uuid === state?.currency)
                                ?.name.includes("%")
                            ? currencyOptions?.find(
                                (f) => f.uuid === state?.currency
                              )?.name
                            : `${
                                currencyOptions?.find(
                                  (f) => f.uuid === state?.currency
                                )?.name
                              }/t`
                        }
                        handleItemClick={handleItemSelected("currency")}
                        style={{
                          cursor: "pointer",
                          marginBottom: "0",
                        }}
                      />
                    </div>
                  </Row>
                  {errorFieldsList.find((e) => e === "price") && (
                    <Label
                      error={true}
                      errorAtBottom
                      requiredErrorText={t("required_field")}
                    />
                  )}
                </div>

                <Label
                  text={`${t("description")}`}
                  medium
                  semiBold
                  error={errorFieldsList.find((e) => e === "description")}
                  noPadding
                  errorAtBottom
                  requiredErrorText={t("required_field")}
                >
                  <TextArea
                    mode="underline"
                    readOnly={!canEdit(state, "description", isEdit)}
                    placeholder={t("ad-material-other-details")}
                    value={state?.originalText}
                    onChange={(e) => {
                      handleOnChange("description", dispatch)(e);
                      handleOnChange("originalText", dispatch)(e);
                    }}
                    style={{
                      marginBottom: 0,
                      paddingLeft: 0,
                    }}
                    rows="1"
                    {...{
                      "data-cy": "description_field",
                    }}
                  />
                </Label>
              </Paper>
              <Paper
                whiteMode
                style={{
                  padding: isMobile ? "20px 10px" : "20px 15px",
                  marginTop: "15px",
                  alignItems: "stretch",
                  gap: "25px",
                }}
              >
                <Label
                  text={
                    <Row align="center" gap={10}>
                      {`${t("recurrence")}`} <InfoBox>{t("optional")}</InfoBox>{" "}
                    </Row>
                  }
                  dark
                  bold
                  error={errorFieldsList.find((e) => e === "frequency")}
                  noPadding
                  errorAtBottom
                  requiredErrorText={t("required_field")}
                >
                  <Disclaimer
                    hint
                    text={t(
                      state?.type === "purchase"
                        ? "frequency_disclaimer"
                        : "frequency_disclaimer_sale"
                    )}
                    style={{
                      border: 0,
                      marginTop: 0,
                      paddingTop: 0,
                      marginBottom: 20,
                      color: BLUE_40,
                    }}
                  />
                  <Label
                    text={`${t("recurrence_amount")} (t)`}
                    medium
                    semiBold
                    errorAtBottom
                    requiredErrorText={t("required_field")}
                    style={{
                      width: "100%",
                    }}
                    noPadding={!errorFieldsList.find((e) => e === "amount")}
                  >
                    <Row
                      gap={15}
                      style={{
                        paddingBottom: errorFieldsList.find(
                          (e) => e === "amount"
                        )
                          ? 0
                          : 15,
                      }}
                    >
                      <InputNumber
                        mode="underline"
                        min={1}
                        step={0.01}
                        readOnly={!canEdit(state, "frequencyAmount", isEdit)}
                        required={true}
                        placeholder={t("write-here")}
                        name="frequencyAmount"
                        onChange={handleOnChange("frequencyAmount", dispatch)}
                        value={state?.frequencyAmount}
                        style={{
                          marginBottom: "0",
                        }}
                        {...{
                          "data-cy": "frequencyAmount_field",
                        }}
                      />
                      <div style={{ maxWidth: "40px" }}>
                        <InputText
                          mode="underline"
                          value={"t"}
                          readOnly
                          style={{
                            marginBottom: "0",
                            paddingLeft: "10px",
                          }}
                        />
                      </div>
                    </Row>
                  </Label>
                  <Label
                    text={t("frequency")}
                    error={errorFieldsList.find((e) => e === "recurrence")}
                    dark
                    bold
                    noPadding
                  >
                    <Row
                      style={{
                        width: "auto",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                      }}
                      gap={isMobile ? 10 : 15}
                      wrap={true}
                    >
                      <Chip
                        disabled={!canEdit(state, "type", isEdit)}
                        type={
                          state?.frequency === frequencyOptions.WEEKLY
                            ? !canEdit(state, "frequency", isEdit)
                              ? "gray40"
                              : "white-outlined-active"
                            : "white-outlined"
                        }
                        text={t("weekly")}
                        handleClick={
                          canEdit(state, "frequency", isEdit)
                            ? () =>
                                handleOnChange(
                                  "frequency",
                                  dispatch
                                )({
                                  target: {
                                    value:
                                      state?.frequency ===
                                      frequencyOptions.WEEKLY
                                        ? undefined
                                        : frequencyOptions.WEEKLY,
                                  },
                                })
                            : undefined
                        }
                        style={getChipFormat(
                          state?.frequency === frequencyOptions.WEEKLY &&
                            isMobile,
                          isMobile
                        )}
                        showIcon={
                          state?.frequency === frequencyOptions.WEEKLY &&
                          !isMobile
                        }
                      />
                      <Chip
                        disabled={!canEdit(state, "type", isEdit)}
                        type={
                          state?.frequency === frequencyOptions.BIWEEKLY
                            ? !canEdit(state, "frequency", isEdit)
                              ? "gray40"
                              : "white-outlined-active"
                            : "white-outlined"
                        }
                        text={t("biweekly")}
                        handleClick={
                          canEdit(state, "frequency", isEdit)
                            ? () =>
                                handleOnChange(
                                  "frequency",
                                  dispatch
                                )({
                                  target: {
                                    value:
                                      state?.frequency ===
                                      frequencyOptions.BIWEEKLY
                                        ? undefined
                                        : frequencyOptions.BIWEEKLY,
                                  },
                                })
                            : undefined
                        }
                        style={getChipFormat(
                          state?.frequency === frequencyOptions.BIWEEKLY &&
                            isMobile,
                          isMobile
                        )}
                        showIcon={
                          state?.frequency === frequencyOptions.BIWEEKLY &&
                          !isMobile
                        }
                      />
                      <Chip
                        disabled={!canEdit(state, "type", isEdit)}
                        type={
                          state?.frequency === frequencyOptions.MONTHLY
                            ? !canEdit(state, "frequency", isEdit)
                              ? "gray40"
                              : "white-outlined-active"
                            : "white-outlined"
                        }
                        text={t("monthly")}
                        handleClick={
                          canEdit(state, "frequency", isEdit)
                            ? () =>
                                handleOnChange(
                                  "frequency",
                                  dispatch
                                )({
                                  target: {
                                    value:
                                      state?.frequency ===
                                      frequencyOptions.MONTHLY
                                        ? undefined
                                        : frequencyOptions.MONTHLY,
                                  },
                                })
                            : undefined
                        }
                        style={getChipFormat(
                          state?.frequency === frequencyOptions.MONTHLY &&
                            isMobile,
                          isMobile
                        )}
                        showIcon={
                          state?.frequency === frequencyOptions.MONTHLY &&
                          !isMobile
                        }
                      />
                      <Chip
                        disabled={!canEdit(state, "type", isEdit)}
                        type={
                          state?.frequency > frequencyOptions.MONTHLY
                            ? !canEdit(state, "frequency", isEdit)
                              ? "gray40"
                              : "white-outlined-active"
                            : "white-outlined"
                        }
                        text={t("other_frecuency")}
                        handleClick={
                          canEdit(state, "frequency", isEdit)
                            ? () =>
                                handleOnChange(
                                  "frequency",
                                  dispatch
                                )({
                                  target: {
                                    value:
                                      state?.frequency >
                                      frequencyOptions.MONTHLY
                                        ? undefined
                                        : frequencyOptions.OTHER,
                                  },
                                })
                            : undefined
                        }
                        style={getChipFormat(
                          state?.frequency > frequencyOptions.MONTHLY &&
                            isMobile,
                          isMobile
                        )}
                        showIcon={
                          state?.frequency > frequencyOptions.MONTHLY &&
                          !isMobile
                        }
                      />
                    </Row>
                  </Label>
                </Label>
                {state?.frequency > frequencyOptions.MONTHLY && (
                  <>
                    <Label
                      text={t("how_many_months")}
                      medium
                      semiBold
                      noPadding
                    >
                      <InputCounter
                        value={state?.frequency || 0}
                        name={"months"}
                        min={frequencyOptions.OTHER}
                        onChange={(value) => {
                          handleOnChange(
                            "frequency",
                            dispatch
                          )({
                            target: {
                              value,
                            },
                          });
                        }}
                      />
                    </Label>
                  </>
                )}
              </Paper>
              <Paper
                whiteMode
                style={{
                  padding: isMobile ? "20px 10px" : "20px 15px",
                  marginTop: "15px",
                  alignItems: "stretch",
                  gap: "10px",
                }}
              >
                <Label
                  text={
                    <Row align="center" gap={10}>
                      {`${t("pictures")}`}{" "}
                      {state?.type !== "sale" && (
                        <InfoBox>{t("optional")}</InfoBox>
                      )}
                    </Row>
                  }
                  dark
                  bold
                  error={errorFieldsList.find((e) => e === "files")}
                  errorAtBottom
                  requiredErrorText={t("required_field")}
                  style={{
                    paddingBottom: 0,
                    pointerEvents: !canEdit(state, "files", isEdit)
                      ? "none"
                      : "auto",
                  }}
                >
                  <>
                    <Disclaimer
                      hint
                      text={t("images_disclaimer")}
                      style={{
                        border: 0,
                        marginTop: 0,
                        paddingTop: 0,
                        marginBottom: "10px",
                        color: BLUE_40,
                      }}
                    />
                    <div>
                      <InputFile
                        {...{
                          "data-cy": "image_input",
                        }}
                        mode="cloud"
                        placeholder={t("select-drop-files")}
                        readOnly={!canEdit(state, "files", isEdit)}
                        files={state?.files}
                        translation={{
                          drop: t("drop-files"),
                          placeholder: t("select-drop-files"),
                          uploading: t("uploading"),
                          upload_files: t("upload_files"),
                        }}
                        iconStyles={{
                          background: !canEdit(state, "files", isEdit)
                            ? "#f3f4f8"
                            : "#F5F5F5",
                          borderRadius: 15,
                          padding: "7px 12px",
                        }}
                        loading={uploading}
                        handleUpload={async (e) => {
                          setUploading(true);
                          let parsedFiles = [];
                          for (let file of e.target.files) {
                            try {
                              const [uploadResponse, response2] =
                                await uploadFile({
                                  file: file,
                                  type: "ad",
                                });
                              parsedFiles.push({
                                name: file.name,
                                uuid: `${uploadResponse.uuid_file}`,
                                url: `${uploadResponse.location}`,
                                cdn: `${uploadResponse.cdn}`,
                              });
                            } catch (error) {
                              toastError(
                                t(
                                  "upload-file-error",
                                  { toastId: "file_upload" },
                                  "sentiment_dissatisfied"
                                )
                              );
                            }
                          }

                          setUploading(false);

                          if (!_.isEmpty(parsedFiles)) {
                            handleAddImage(parsedFiles[0]);
                          }
                        }}
                      />
                    </div>
                  </>
                </Label>
                <FilesList
                  readOnly={!canEdit(state, "images", isEdit)}
                  files={state?.files?.map((f, index) => ({
                    ...f,
                    index,
                  }))}
                  handleDrop={() => {
                    dispatch({
                      type: actions.UPDATE_INPUT,
                      payload: { ...state, files: filesBuffer.current },
                    });
                    filesBuffer.current = [];
                  }}
                  handleSort={handleSortImage}
                  handleDelete={
                    canEdit(state, "files", isEdit)
                      ? handleDeleteImage
                      : undefined
                  }
                  imageBoxMode={true}
                />
              </Paper>
            </Flex>
          </StepperAdaptativeItem>
          <StepperAdaptativeItem
            title={t("transport")}
            description={t("transport_desc")}
            isReady={!stepTwoError && haveTriedToPublish}
            isReadyText={t("ready_to_publish")}
            needModeration={false}
            needModerationText={t("need_moderation")}
            haveError={stepTwoError}
            haveErrorText={t("need_more_info_to_publish")}
          >
            <Flex
              flexDirection="column"
              alignItems="stretch"
              style={{ gap: "5px" }}
            >
              <Paper
                whiteMode
                style={{
                  padding: isMobile ? "20px 10px" : "20px 15px",
                  marginTop: "15px",
                  alignItems: "stretch",
                  gap: "25px",
                }}
              >
                <Heading type={6} style={{ margin: 0 }}>
                  {state?.type === "purchase"
                    ? t("delivery_conditions_simple")
                    : t("collection_point")}
                </Heading>
                {state?.type === "purchase" && (
                  <Label
                    text={`${t("incoterm")}`}
                    medium
                    semiBold
                    error={errorFieldsList.find((e) => e === "incoterm")}
                    noPadding
                    errorAtBottom
                    requiredErrorText={t("required_field")}
                    onMoreInfo={() => {
                      setModalType(modalTypes.INCOTERM_INFO);
                    }}
                    moreInfoText={t("more-information")}
                  >
                    <CatalogSearch
                      mode="underline"
                      withSearch
                      avoidReload
                      preload
                      withDescription
                      inputProps={{
                        readOnly: !canEdit(state, "", isEdit),
                        required: true,
                        style: {
                          marginBottom: "0",
                        },
                      }}
                      initialValue={state?.incoterm?.name}
                      catalogName={"incoterms-purchase"}
                      onClickCallback={(
                        option,
                        open,
                        setOpen,
                        value,
                        setValue
                      ) => {
                        if (option.extra === "port") {
                          setIsPort(true);
                        } else {
                          setIsPort(false);
                          handleAddress(
                            dispatch,
                            true
                          )({
                            port: undefined,
                          });
                        }
                        handleOnChange(
                          "incoterm",
                          dispatch
                        )({
                          target: {
                            value: option.uuid,
                          },
                        });
                        setTimeout(() => {
                          setOpen(false);
                        }, 100);
                      }}
                    />
                  </Label>
                )}

                {state?.type === "sale" && (
                  <>
                    <Row gap={25}>
                      <div>
                        <Row>
                          <Radio
                            disabled={!canEdit(state, "type", isEdit)}
                            id={`is_ground`}
                            name={`origin_source`}
                            text={t("address")}
                            value="false"
                            onChange={() => {
                              setIsPort(false);
                              setPortName(undefined);
                              handleAddress(dispatch, true)({}, true);
                            }}
                            checked={!isPort}
                            style={{ cursor: "pointer" }}
                          />
                        </Row>
                      </div>
                      <div>
                        <Row>
                          <Radio
                            disabled={!canEdit(state, "type", isEdit)}
                            id={`is_port`}
                            name={`origin_source`}
                            text={t("port")}
                            value="true"
                            onChange={() => {
                              setIsPort(true);
                              setPortName(undefined);
                              handleAddress(dispatch, true)({}, true);
                            }}
                            checked={isPort}
                            style={{ cursor: "pointer" }}
                          />
                        </Row>
                      </div>
                    </Row>
                  </>
                )}
                {!isPort && (
                  <div>
                    <Label
                      text={`${t(
                        state?.type === "sale"
                          ? "address-load"
                          : "address-unload"
                      )}`}
                      medium
                      semiBold
                      error={errorFieldsList.find((e) => e === "address")}
                      errorAtBottom
                      requiredErrorText={t("required_field")}
                      style={{ margin: 0, paddingBottom: "0px" }}
                    />
                    <AddressFormV3
                      avoidPort={true}
                      uuidOrg={state.uuidOrg}
                      id={state?.address?.uuid || state?.address?.id}
                      state={state}
                      type={state?.type}
                      onFacilitiesAlreadySetted={(setted) => {}}
                      streetProps={{
                        readOnly: !canEdit(state, "", isEdit),
                        required: true,
                        placeholder: t("write-here"),
                        name: "street",
                        onChange: (e) =>
                          handleAddress(dispatch)({
                            street: e.target.value,
                          }),
                        value: state?.address?.street,
                      }}
                      townProps={{
                        readOnly: !canEdit(state, "", isEdit),
                        required: true,
                        placeholder: t("write-here"),
                        name: "city",
                        onChange: (e) =>
                          handleAddress(dispatch)({
                            city: e.target.value,
                          }),
                        value: state?.address?.city,
                      }}
                      countryProps={{
                        readOnly: !canEdit(state, "", isEdit),
                        required: true,
                        placeholder: t("write-here"),
                        name: "countryCode",
                        onChangeCountry: (option, open, setOpen) => {
                          handleAddress(dispatch)({
                            countryCode: option?.uuid?.split("-")?.[0],
                          });
                          setTimeout(() => setOpen(false), 100);
                        },
                        value: state?.address?.["countryCode"],
                      }}
                      provinceProps={{
                        readOnly: !canEdit(state, "", isEdit),
                        required: true,
                        placeholder: t("write-here"),
                        name: "state",
                        onChange: (e) =>
                          handleAddress(dispatch)({
                            state: e.target.value,
                          }),
                        value: state?.address?.state,
                      }}
                      zipProps={{
                        readOnly: !canEdit(state, "", isEdit),
                        required: true,
                        placeholder: t("write-here"),
                        name: "postalCode",
                        onChange: (e) =>
                          handleAddress(dispatch)({
                            postalCode: e.target.value,
                          }),
                        value: state?.address?.["postalCode"],
                      }}
                      markFavorite={(id, facilities) => (e) => {
                        handleOnChange(
                          "address",
                          dispatch
                        )({
                          target: {
                            value: id ? { id } : {},
                          },
                        });

                        handleOnChange(
                          "facilities",
                          dispatch
                        )({
                          target: {
                            value: facilities || [],
                          },
                        });
                      }}
                      savedDirectionProps={{
                        readOnly: !canEdit(state, "", isEdit),
                        onChange: canEdit(state, "", isEdit)
                          ? (option, open, setOpen) => {
                              handleAddress(dispatch)(
                                {
                                  id: option?.uuid,
                                },
                                true
                              );
                              handleOnChange(
                                "facilities",
                                dispatch
                              )({
                                target: {
                                  value: option?.facilities || [],
                                },
                              });
                            }
                          : undefined,
                        val: state?.address?.uuid || state?.address?.id,
                      }}
                    />
                  </div>
                )}

                {isPort && (
                  <>
                    <Label
                      text={`${t(
                        state?.type === "sale"
                          ? "collection_port"
                          : "delivery_port"
                      )}`}
                      medium
                      semiBold
                      error={errorFieldsList.find(
                        (e) => e === "port" || e === "address"
                      )}
                      errorAtBottom
                      requiredErrorText={t("required_field")}
                      noPadding
                    >
                      <CatalogSearch
                        mode="underline"
                        remote={true}
                        id="SelectedPort"
                        inputProps={{
                          readOnly: !canEdit(state, "", isEdit),
                          required: true,
                          placeholder: t("write-or-select"),
                        }}
                        initialValue={
                          portName
                            ? portName
                            : state?.address?.port?.name
                            ? state?.address?.port?.name
                            : state?.address?.port
                        }
                        catalogName={"ports"}
                        avoidInitalLoad={true}
                        onClickCallback={(
                          option,
                          open,
                          setOpen,
                          value,
                          setValue
                        ) => {
                          handleAddress(
                            dispatch,
                            true
                          )({
                            port: option.uuid,
                          });
                          setPortName(option?.name);
                          setTimeout(() => {
                            setOpen(false);
                          }, 100);
                        }}
                      />
                    </Label>
                  </>
                )}
              </Paper>
              <Paper
                whiteMode
                style={{
                  padding: isMobile ? "20px 10px" : "20px 15px",
                  marginTop: "15px",
                  alignItems: "stretch",
                  gap: "25px",
                }}
              >
                <div>
                  <Label
                    text={""}
                    bold
                    dark
                    error={errorFieldsList.find((e) => e === "facilities")}
                    errorAtBottom
                    requiredErrorText={t("required_field")}
                    noPadding
                  >
                    <LoadPossibilities
                      title={t(
                        state?.type !== "sale"
                          ? "unload_possibilities"
                          : "load_possibilities"
                      )}
                      description={t("load_unload_options")}
                      data={(equipmentsData || []).map((item) => {
                        return { type: item.uuid, text: t(item.name) };
                      })}
                      handleClick={({ uuid }, text) =>
                        handleItemFacilitiesClick(uuid, text)
                      }
                      //   showIconFn={(type) => state?.facilities?.includes(type)}
                      //   typeFn={(type) => state?.facilities?.includes(type)}
                      showIconFn={({ uuid }) =>
                        state?.facilities?.includes(uuid)
                      }
                      typeFn={({ uuid }) => state?.facilities?.includes(uuid)}
                    />
                  </Label>
                  <Disclaimer
                    hint
                    text={t("saved_info")}
                    style={{
                      color: BLUE_30,
                      border: 0,
                      marginTop: "5px",
                    }}
                  />
                </div>
              </Paper>
              <Paper
                whiteMode
                style={{
                  padding: isMobile ? "20px 10px" : "20px 15px",
                  marginTop: "15px",
                  alignItems: "stretch",
                  gap: "25px",
                }}
              >
                <div>
                  <Label
                    text={
                      <Row
                        align="center"
                        justify="flex-start"
                        gap={10}
                        style={{ lineHeight: "normal" }}
                      >
                        <span>{t("ad-load-preferences")}</span>
                        {state?.type === "purchase" && (
                          <InfoBox>{t("optional")}</InfoBox>
                        )}
                      </Row>
                    }
                    dark
                    bold
                    error={errorFieldsList.find(
                      (e) => e === "loadCharacteristics"
                    )}
                    errorAtBottom
                    requiredErrorText={t("required_field")}
                  >
                    <Typography
                      small
                      style={{
                        color: WARM_NEUTRAL_60,
                        fontWeight: 500,
                        marginTop: "5px",
                      }}
                    >
                      {state?.type === "purchase"
                        ? t("how_collect_cargo")
                        : t("how_prepare_cargo")}
                    </Typography>
                    <Row
                      style={{
                        width: "100%",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                      }}
                      gap={isMobile ? 10 : 15}
                      wrap
                    >
                      <Chip
                        type={
                          isGranelSelected
                            ? !canEdit(state, "", isEdit)
                              ? "gray40"
                              : "white-outlined-active"
                            : "white-outlined"
                        }
                        showIcon={
                          isGranelSelected && !isMobile
                            ? !canEdit(state, "", isEdit)
                              ? false
                              : true
                            : false
                        }
                        text={t(LoadCharacteristicasTypes.GRANEL)}
                        handleClick={
                          canEdit(state, "", isEdit)
                            ? () => {
                                handleLoadCharacteristics(
                                  LoadCharacteristicasTypes.GRANEL,
                                  {
                                    type: LoadCharacteristicasTypes.GRANEL,
                                    value: {
                                      weight: 0,
                                    },
                                  },
                                  state?.type !== "purchase"
                                );
                              }
                            : undefined
                        }
                        style={getChipFormat(isGranelSelected, isMobile)}
                      />
                      <Chip
                        type={
                          isSacasSelected
                            ? !canEdit(state, "", isEdit)
                              ? "gray40"
                              : "white-outlined-active"
                            : "white-outlined"
                        }
                        showIcon={
                          isSacasSelected && !isMobile
                            ? !canEdit(state, "", isEdit)
                              ? false
                              : true
                            : false
                        }
                        text={t(LoadCharacteristicasTypes.SACAS)}
                        handleClick={
                          canEdit(state, "", isEdit)
                            ? () => {
                                handleLoadCharacteristics(
                                  LoadCharacteristicasTypes.SACAS,
                                  {
                                    type: LoadCharacteristicasTypes.SACAS,
                                  },
                                  false
                                );
                              }
                            : undefined
                        }
                        style={getChipFormat(isSacasSelected, isMobile)}
                      />
                      <Chip
                        type={
                          isPaletSelected
                            ? !canEdit(state, "", isEdit)
                              ? "gray40"
                              : "white-outlined-active"
                            : "white-outlined"
                        }
                        showIcon={
                          isPaletSelected && !isMobile
                            ? !canEdit(state, "", isEdit)
                              ? false
                              : true
                            : false
                        }
                        text={t("paletizada")}
                        handleClick={
                          canEdit(state, "", isEdit)
                            ? () => {
                                handleLoadCharacteristics(
                                  LoadCharacteristicasTypes.PALET,
                                  {
                                    type: LoadCharacteristicasTypes.PALET,
                                  },
                                  false
                                );
                              }
                            : undefined
                        }
                        style={getChipFormat(isPaletSelected, isMobile)}
                      />
                    </Row>
                  </Label>
                  {!_.isEmpty(
                    state?.loadCharacteristics?.filter(
                      (lc) => lc.type === LoadCharacteristicasTypes.GRANEL
                    )
                  ) && (
                    <LoadCharacteristicsAttributes
                      volumeProps={{
                        required: false,
                        value: state?.loadCharacteristics?.find(
                          (f) => f.type === LoadCharacteristicasTypes.GRANEL
                        )?.value?.weight,
                      }}
                      onVolumeChange={(e) => {
                        handleLoadCharacteristics(
                          LoadCharacteristicasTypes.GRANEL,
                          {
                            weight: e?.target?.value * 1,
                          }
                        );
                      }}
                    />
                  )}
                  {!_.isEmpty(
                    state?.loadCharacteristics?.filter(
                      (lc) => lc.type === LoadCharacteristicasTypes.SACAS
                    )
                  ) && (
                    <LoadCharacteristicsAttributes
                      readOnly={!canEdit(state, "", isEdit)}
                      label={`${t("ad-saca-carateristics")}`}
                      weightProps={{
                        required: state?.type === "sale",
                        value: state?.loadCharacteristics?.find(
                          (f) => f.type === LoadCharacteristicasTypes.SACAS
                        )?.value?.weight,
                      }}
                      onWeightChange={(e) => {
                        handleLoadCharacteristics(
                          LoadCharacteristicasTypes.SACAS,
                          {
                            weight: e?.target?.value * 1,
                          }
                        );
                      }}
                      depthProps={{
                        required: state?.type === "sale",
                        value: state?.loadCharacteristics?.find(
                          (f) => f.type === LoadCharacteristicasTypes.SACAS
                        )?.value?.depth,
                      }}
                      onDepthChange={(e) => {
                        handleLoadCharacteristics(
                          LoadCharacteristicasTypes.SACAS,
                          {
                            depth: e?.target?.value * 1,
                          }
                        );
                      }}
                      heightProps={{
                        required: state?.type === "sale",
                        value: state?.loadCharacteristics?.find(
                          (f) => f.type === LoadCharacteristicasTypes.SACAS
                        )?.value?.height,
                      }}
                      onHeightChange={(e) => {
                        handleLoadCharacteristics(
                          LoadCharacteristicasTypes.SACAS,
                          {
                            height: e?.target?.value * 1,
                          }
                        );
                      }}
                      widthProps={{
                        required: state?.type === "sale",
                        value: state?.loadCharacteristics?.find(
                          (f) => f.type === LoadCharacteristicasTypes.SACAS
                        )?.value?.width,
                      }}
                      onWidthChange={(e) => {
                        handleLoadCharacteristics(
                          LoadCharacteristicasTypes.SACAS,
                          {
                            width: e?.target?.value * 1,
                          }
                        );
                      }}
                      //hint={t("ad_saca_pale_tooltip")}
                      labelProps={{
                        error: errorFieldsList.find(
                          (e) => e === "load_preferences_saca"
                        ),
                      }}
                    />
                  )}
                  {!_.isEmpty(
                    state?.loadCharacteristics?.filter(
                      (lc) => lc.type === LoadCharacteristicasTypes.PALET
                    )
                  ) && (
                    <LoadCharacteristicsAttributes
                      readOnly={!canEdit(state, "", isEdit)}
                      label={t("ad-pale-carateristics")}
                      weightLabel={t("palet_bulk_load")}
                      weightProps={{
                        required: state?.type === "sale",
                        value: state?.loadCharacteristics?.find(
                          (f) => f.type === LoadCharacteristicasTypes.PALET
                        )?.value?.weight,
                      }}
                      onWeightChange={(e) => {
                        handleLoadCharacteristics(
                          LoadCharacteristicasTypes.PALET,
                          {
                            weight: e?.target?.value * 1,
                          }
                        );
                      }}
                      depthProps={{
                        required: state?.type === "sale",
                        value: state?.loadCharacteristics?.find(
                          (f) => f.type === LoadCharacteristicasTypes.PALET
                        )?.value?.depth,
                      }}
                      onDepthChange={(e) => {
                        handleLoadCharacteristics(
                          LoadCharacteristicasTypes.PALET,
                          {
                            depth: e?.target?.value * 1,
                          }
                        );
                      }}
                      heightProps={{
                        required: state?.type === "sale",
                        value: state?.loadCharacteristics?.find(
                          (f) => f.type === LoadCharacteristicasTypes.PALET
                        )?.value?.height,
                      }}
                      onHeightChange={(e) => {
                        handleLoadCharacteristics(
                          LoadCharacteristicasTypes.PALET,
                          {
                            height: e?.target?.value * 1,
                          }
                        );
                      }}
                      widthProps={{
                        required: state?.type === "sale",
                        value: state?.loadCharacteristics?.find(
                          (f) => f.type === LoadCharacteristicasTypes.PALET
                        )?.value?.width,
                      }}
                      onWidthChange={(e) => {
                        handleLoadCharacteristics(
                          LoadCharacteristicasTypes.PALET,
                          {
                            width: e?.target?.value * 1,
                          }
                        );
                      }}
                      //hint={t("ad_saca_pale_tooltip")}
                      labelProps={{
                        error: errorFieldsList.find(
                          (e) => e === "load_preferences_palet"
                        ),
                      }}
                    />
                  )}
                </div>
              </Paper>
            </Flex>
          </StepperAdaptativeItem>
          {state?.type === "purchase" && (
            <StepperAdaptativeItem
              title={t("payment_conditions")}
              description={t("payment_conditions_desc_2")}
              isReady={!stepThreeError && haveTriedToPublish}
              isReadyText={t("ready_to_publish")}
              needModeration={false}
              needModerationText={t("need_moderation")}
              haveError={stepThreeError}
              haveErrorText={t("need_more_info_to_publish")}
            >
              <Paper
                whiteMode
                style={{
                  padding: isMobile ? "20px 10px" : "20px 15px",
                  marginTop: "15px",
                  alignItems: "stretch",
                  gap: "10px",
                }}
              >
                {paymentMethodsData === undefined ||
                paymentMethodsData === null ? (
                  <Spinner />
                ) : (
                  <>
                    {Array.isArray(paymentMethodsData) ? (
                      paymentMethodsData?.map((pm, i) => (
                        <>
                          <ListTile
                            key={`nov4_pm_${pm?.uuidPaymentMethod}`}
                            radio={true}
                            name={"payment-method"}
                            value={pm?.uuidPaymentMethod}
                            checked={
                              state?.paymentMethod?.uuid
                                ? state?.paymentMethod?.uuid ===
                                  pm?.uuidPaymentMethod
                                : state?.paymentMethod === pm?.uuidPaymentMethod
                            }
                            title={pm?.paymentMethodDesc}
                            subtitle={pm?.priceDesc}
                            required={true}
                            onClick={() => {
                              handleOnChange(
                                "paymentMethod",
                                dispatch
                              )({
                                target: {
                                  value: pm?.uuidPaymentMethod,
                                },
                              });
                            }}
                          />
                          {paymentMethodsData.length - 1 > i ? (
                            <Divider style={{ margin: 0 }} />
                          ) : (
                            <></>
                          )}
                        </>
                      ))
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </Paper>
            </StepperAdaptativeItem>
          )}
          <StepperAdaptativeItem
            title={
              <Row
                align="center"
                justify="flex-start"
                gap={10}
                style={{ lineHeight: "normal" }}
              >
                <span>{t("specifications")}</span>
                <InfoBox>{t("optional")}</InfoBox>
              </Row>
            }
            description={t("specifications_desc")}
          >
            <div>
              <Paper
                whiteMode
                style={{
                  padding: isMobile ? "20px 10px" : "20px 15px",
                  marginTop: "15px",
                  alignItems: "stretch",
                  gap: "10px",
                }}
              >
                <Heading type={6}>{t("composition_preferences")}</Heading>
                {!_.isEmpty(state?.materialQuestions) && (
                  <Questions
                    readOnly={!canEdit(state, "materialQuestions", isEdit)}
                    type={
                      state?.materialTypeEdit?.uuid
                        ? state?.materialTypeEdit?.uuid
                        : state?.materialTypeEdit
                    }
                    stateValue={state?.materialQuestions}
                    onChange={handleQuestionOnChange}
                  />
                )}

                <CompositionInput
                  disabled={!canEdit(state, "type", isEdit)}
                  type={state?.type}
                  value={state?.components}
                  onChange={(e) => {
                    handleOnChange(
                      "components",
                      dispatch
                    )({
                      target: {
                        value: e,
                      },
                    });
                  }}
                />
                {errorFieldsList.find((e) => e === "component_incomplete") && (
                  <>
                    <Label
                      errorAtBottom
                      error={true}
                      requiredErrorText={t("required_field")}
                      noPadding
                    />
                  </>
                )}
              </Paper>

              <Paper
                whiteMode
                style={{
                  padding: isMobile ? "20px 10px" : "20px 15px",
                  marginTop: "15px",
                  alignItems: "stretch",
                  gap: "15px",
                }}
              >
                <>
                  <Label
                    text={t("ler-code")}
                    medium
                    semiBold
                    hint={t("ad_ler_tooltip")}
                    useHintTooltip
                    hintTooltipProps={{
                      alignment: "bottom",
                      style: {
                        maxWidth: 320,
                      },
                    }}
                    hintProps={{
                      color: WARM_NEUTRAL_40,
                      style: {
                        fontSize: 16,
                        position: "relative",
                        top: 0,
                        left: "-2px",
                      },
                    }}
                    noPadding
                  >
                    <CatalogSearch
                      mode="underline"
                      remote={true}
                      inputProps={{
                        readOnly: !canEdit(state, "lerCodes", isEdit),
                        style: {
                          marginBottom: 0,
                        },
                      }}
                      catalogName={"ler"}
                      optionsAdapter={(data) => {
                        return data.map((d) => {
                          return {
                            uuid: d.uuid,
                            name: `${d.uuid} ${d.name}`,
                          };
                        });
                      }}
                      cleanAfterSelected
                      onClickCallback={(
                        option,
                        open,
                        setOpen,
                        value,
                        setValue
                      ) => {
                        handleAddLerCode(option?.uuid);
                        setTimeout(() => {
                          setOpen(false);
                        }, 100);
                      }}
                    />
                  </Label>
                  {!_.isEmpty(state?.lerCodes) && (
                    <Label
                      style={{
                        paddingBottom: "20px",
                      }}
                    >
                      <Row gap={15} wrap>
                        {state?.lerCodes.map((e, i) => (
                          <Chip
                            key={`ler_code_${i}`}
                            text={e?.name || e}
                            showIcon={canEdit(state, "lerCodes", isEdit)}
                            handleClick={
                              canEdit(state, "lerCodes", isEdit)
                                ? () => handleRemoveCollection(i, "lerCodes")
                                : undefined
                            }
                            type={
                              !canEdit(state, "lerCodes", isEdit)
                                ? "gray40"
                                : "white-outlined"
                            }
                          />
                        ))}
                      </Row>
                    </Label>
                  )}
                </>
              </Paper>
              <Paper
                whiteMode
                style={{
                  padding: isMobile ? "20px 10px" : "20px 15px",
                  marginTop: "15px",
                  alignItems: "stretch",
                  gap: "10px",
                }}
              >
                <Heading type={6}>
                  {state?.type === "purchase"
                    ? t("format_preferences")
                    : t("format")}
                </Heading>
                <Label text={t("scrap_size")} medium semiBold>
                  <Row gap={25}>
                    <Row align="center">
                      <Radio
                        disabled={!canEdit(state, "type", isEdit)}
                        id={`scrap_size_1`}
                        name={`scrap_size`}
                        text={t("short")}
                        onChange={() => {
                          handleOnChange(
                            "scrapSize",
                            dispatch
                          )({
                            target: {
                              value: "short",
                            },
                          });
                        }}
                        checked={state?.scrapSize === "short"}
                        style={{ cursor: "pointer" }}
                        labelStyle={{ cursor: "pointer" }}
                      />
                    </Row>
                    <Row align="center">
                      <Radio
                        disabled={!canEdit(state, "type", isEdit)}
                        id={`scrap_size_2`}
                        name={`scrap_size`}
                        text={t("long_")}
                        onChange={() => {
                          handleOnChange(
                            "scrapSize",
                            dispatch
                          )({
                            target: {
                              value: "long",
                            },
                          });
                        }}
                        checked={state?.scrapSize === "long"}
                        style={{ cursor: "pointer" }}
                        labelStyle={{ cursor: "pointer" }}
                      />
                    </Row>
                  </Row>
                </Label>
                <Label
                  text={
                    state?.type === "purchase"
                      ? t("which_material_format_purchase")
                      : t("which_material_format_sale")
                  }
                  noPadding
                  medium
                  semiBold
                >
                  <Row
                    style={{
                      width: "auto",
                      justifyContent: "space-evenly",
                      alignItems: "center",
                    }}
                    gap={isMobile ? 10 : 15}
                    wrap
                  >
                    <Chip
                      disabled={!canEdit(state, "type", isEdit)}
                      type={
                        state?.materialShape === "loose"
                          ? !canEdit(state, "materialShape", isEdit)
                            ? "gray40"
                            : "white-outlined-active"
                          : "white-outlined"
                      }
                      text={t("loose")}
                      handleClick={
                        canEdit(state, "materialShape", isEdit)
                          ? () =>
                              handleOnChange(
                                "materialShape",
                                dispatch
                              )({
                                target: {
                                  value:
                                    state?.materialShape === "loose"
                                      ? undefined
                                      : "loose",
                                },
                              })
                          : undefined
                      }
                      style={getChipFormat(
                        state?.materialShape === "loose",
                        isMobile
                      )}
                      showIcon={state?.materialShape === "loose" && !isMobile}
                    />
                    <Chip
                      disabled={!canEdit(state, "type", isEdit)}
                      type={
                        state?.materialShape === "packed"
                          ? !canEdit(state, "materialShape", isEdit)
                            ? "gray40"
                            : "white-outlined-active"
                          : "white-outlined"
                      }
                      text={t("packed")}
                      handleClick={
                        canEdit(state, "materialShape", isEdit)
                          ? () =>
                              handleOnChange(
                                "materialShape",
                                dispatch
                              )({
                                target: {
                                  value:
                                    state?.materialShape === "packed"
                                      ? undefined
                                      : "packed",
                                },
                              })
                          : undefined
                      }
                      style={getChipFormat(
                        state?.materialShape === "packed",
                        isMobile
                      )}
                      showIcon={state?.materialShape === "packed" && !isMobile}
                    />
                    <Chip
                      disabled={!canEdit(state, "type", isEdit)}
                      type={
                        state?.materialShape === "fragmented"
                          ? !canEdit(state, "materialShape", isEdit)
                            ? "gray40"
                            : "white-outlined-active"
                          : "white-outlined"
                      }
                      text={t("fragmented")}
                      handleClick={
                        canEdit(state, "materialShape", isEdit)
                          ? () =>
                              handleOnChange(
                                "materialShape",
                                dispatch
                              )({
                                target: {
                                  value:
                                    state?.materialShape === "fragmented"
                                      ? undefined
                                      : "fragmented",
                                },
                              })
                          : undefined
                      }
                      style={getChipFormat(
                        state?.materialShape === "fragmented",
                        isMobile
                      )}
                      showIcon={
                        state?.materialShape === "fragmented" && !isMobile
                      }
                    />
                  </Row>
                </Label>
                {state?.type === "sale" && (
                  <>
                    <Label
                      text={t("material_size")}
                      medium
                      semiBold
                      style={{ paddingTop: "10px" }}
                    >
                      <Row gap={10}>
                        <div style={{ width: "100%" }}>
                          <InputNumber
                            disabled={!canEdit(state, "type", isEdit)}
                            mode="underline"
                            value={state?.materialSize}
                            onChange={handleOnChange("materialSize", dispatch)}
                            placeholder={t("write-here")}
                          />
                        </div>
                        <div style={{ width: "100%" }}>
                          <DropDown
                            disabled={!canEdit(state, "type", isEdit)}
                            mode="underline"
                            options={[
                              { uuid: "cm", text: t("centimeters") },
                              { uuid: "mm", text: t("milimeters") },
                            ]}
                            value={state?.materialSizeScale}
                            handleItemClick={handleItemSelected(
                              "materialSizeScale"
                            )}
                          />
                        </div>
                      </Row>
                    </Label>
                  </>
                )}
              </Paper>
            </div>
          </StepperAdaptativeItem>
        </StepperAdaptative>
      </Container>
      <ModalFactory open={modalType} setOpen={setModalType} type={modalType} />
    </>
  );
};

export default AdFormV2;
