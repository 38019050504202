import React from "react";

function AdsIcon() {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="50" height="50" rx="10" fill="#EBF3FF" />
      <path
        d="M14.1044 12.3639C13.5212 12.3645 13.0398 12.8202 13.0073 13.4025L12.3685 24.8429C12.3514 25.15 12.453 25.4148 12.623 25.6139L12.6106 25.6257L23.8203 37.4665C25.2943 39.0236 27.7514 39.0909 29.3085 37.6168L37.3903 29.9658C38.9474 28.4917 39.0147 26.0346 37.5406 24.4775L26.3309 12.6367L26.3186 12.6484C26.1291 12.4678 25.8702 12.3518 25.5626 12.3522L14.1044 12.3639Z"
        fill="#63A1FF"
      />
      <circle
        cx="19.4101"
        cy="19.5101"
        r="1.98696"
        transform="rotate(-45 19.4101 19.5101)"
        fill="#EBF3FF"
      />
    </svg>
  );
}

export default AdsIcon;
