import { Typography } from "@scrapadev/scrapad-front-sdk";
import { actions } from "../../../providers/data/actions/data";
import { useDataProvider } from "../../../providers/data/DataContext";
import { useTranslation } from "../../../utils/hooks";
import Chips from "../chip/Chips";

const AdsSelected = ({ skipRenderMaterial = true, skipRenderType }) => {
  const { tMultiple } = useTranslation("common");
  const { state, dispatch } = useDataProvider();
  const renderThreshold = 3;
  const totalChips = state?.materials?.type?.nameSelected?.length;
  const difference = totalChips - renderThreshold;
  const restOfMaterials = tMultiple(
    difference === 1 ? "more_materials" : "more_materials_plural",
    [difference]
  );

  const handleRemoveMaterial = (index) => {
    dispatch({
      type: actions.UPDATE_INPUT,
      payload: {
        materials: {
          ...state?.materials,
          material: {
            ...state?.materials?.material,
            uuidSelected: state?.materials?.material.uuidSelected.filter(
              (f, idx) => index !== idx
            ),
            nameSelected: state?.materials?.material.nameSelected.filter(
              (f, idx) => index !== idx
            ),
            values: [],
          },
          type: {
            ...state?.materials?.type,
            open: false,
          },
        },
      },
    });
  };

  const handleRemoveType = (index) => {
    dispatch({
      type: actions.UPDATE_INPUT,
      payload: {
        materials: {
          ...state?.materials,
          type: {
            ...state?.materials?.type,
            uuidSelected: state?.materials?.type.uuidSelected.filter(
              (f, idx) => index !== idx
            ),
            nameSelected: state?.materials?.type.nameSelected.filter(
              (f, idx) => index !== idx
            ),
            values: state?.materials?.type.values.filter(
              (f, idx) => index !== idx
            ),
          },
        },
      },
    });
  };

  const renderMaterial = () => {
    if (skipRenderMaterial) return <></>;
    return state?.materials?.material?.uuidSelected?.length > 0 &&
      state?.materials?.type?.uuidSelected?.length === 0 ? (
      <Chips
        items={state?.materials?.material?.nameSelected}
        handleRemove={handleRemoveMaterial}
      />
    ) : (
      <></>
    );
  };

  const renderType = () => {
    if (skipRenderType) return <></>;
    const chipsToRender = state?.materials?.type?.nameSelected?.slice(
      0,
      renderThreshold
    );
    return chipsToRender.length > 0 ? (
      <Chips items={chipsToRender} handleRemove={handleRemoveType} />
    ) : (
      <></>
    );
  };

  return (
    <>
      {renderMaterial()}
      {renderType()}
      {totalChips > 3 && (
        <Typography
          style={{ marginBottom: 20, marginTop: -10 }}
          typography="large"
        >
          {restOfMaterials}
        </Typography>
      )}
    </>
  );
};

export default AdsSelected;
