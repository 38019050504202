import {
  Center,
  Empty,
  FeaturedStar,
  TooltipSc,
} from "@scrapadev/scrapad-front-sdk";
import CardBox from "../../../components/ui/box/CardBox";
import InfoText from "../../../components/ui/text/InfoText";
import { useTranslation } from "../../../utils/hooks";
import WidgetBusinessEmpty from "./Empty";

/**
 * Addresses widget for dashboard.
 * @param {Object} props - Component properties.
 * @param {String} props.id - Business id.
 * @param {Array} props.keys - Data state keys.
 * @param {Object} props.state - Data state.
 * @returns {JSX.Element}
 */
const WidgetBusinessAddresses = ({ id, state }) => {
  const { t } = useTranslation(["common"]);
  const deliveryAddress = state?.getOrgLocations;
  const hasAddress = deliveryAddress && deliveryAddress.length > 0;

  const renderAddressData = (address) => {
    if (!address) {
      return "";
    }
    return `${address?.street}, ${address?.postalCode}, ${address?.city} (${address?.country})`;
  };

  return (
    <CardBox
      title={t("addresses")}
      action={t("see-more")}
      href={`/business/${id}/addresses`}
      maxHeight={230}
    >
      {hasAddress ? (
        deliveryAddress?.map((address, index) => {
          const k = address?.id || index;
          const isPort = address?.port;
          const isDefaultDirection =
            address?.isCollectionDefault || address?.isDeliveryDefault;
          const name = isPort
            ? address?.port?.name || ""
            : renderAddressData(address);
          return (
            <InfoText
              key={k}
              icon={isPort ? "directions_boat" : "location_on"}
              text={name}
              trailing={
                isDefaultDirection ? (
                  <TooltipSc
                    action="hover"
                    content={t("default_address")}
                    fadeOutDelay={4000}
                  >
                    <FeaturedStar />
                  </TooltipSc>
                ) : undefined
              }
              widthAuto
              gap={10}
            />
          );
        })
      ) : (
        <Center>
          <WidgetBusinessEmpty type={"address"} text={t("empty-section")} />
        </Center>
      )}
    </CardBox>
  );
};

export default WidgetBusinessAddresses;
