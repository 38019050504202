import { useEffect, useState } from "react";
import { useTranslation } from "../../../utils/hooks";
import {
  Chip,
  Modal,
  Container,
  Flex,
  ClearButton,
  ChipDropdown,
  Loading,
  Padding,
  Center,
  BREAKPOINT_LG,
  Label,
  InputText,
  useMatchMedia,
  Typography,
  WARM_NEUTRAL_50,
} from "@scrapadev/scrapad-front-sdk";
import Text from "../../ui/text/Text";
import {
  verificationStatusToChange,
  verificationStatusToChip,
} from "../../../utils/variables";
import { handleCRUD } from "../../../utils/crud/functions";
import { parseDate } from "../../../utils/date/functions";

/**
 * Modal for documents detail in verification page.
 * @param {Object} props - Component properties.
 * @param {Object} [props.open=false] - Open/close modal status.
 * @param {Function} props.setOpen - Setter function to open / close.
 * @returns {JSX.Element}
 */
const ModalDocumentDetails = ({ open, setOpen, ...props }) => {
  const {
    uuidFile,
    state,
    dispatch,
    actions,
    loading,
    setLoading,
    canEdit,
    onSubmit,
    permission = null,
    description,
  } = props;
  const { t, i18n } = useTranslation(["common", "verification"]);
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_LG}px)`);
  const handleSubmit = async (e) => {
    e.preventDefault();
    onSubmit(state, uuidFile);
  };

  const handleComments = (e) => {
    dispatch({
      type: actions.UPDATE_INPUT,
      payload: { ...state, scrapadComment: e.target.value },
    });
  };

  const permissionCheck = () => {
    if (!permission) return true;
    return permission();
  };

  const renderChip = () => {
    return (
      <Chip
        type={verificationStatusToChange[state?.state]}
        text={t(state?.state)}
        small
      />
    );
  };

  const renderChips = () => {
    if (!state?.state) return <></>;
    if (canEdit) {
      if (permissionCheck()) {
        return (
          <ChipDropdown
            items={Object.keys(verificationStatusToChange).map((key, index) => (
              <ClearButton
                key={index}
                onClick={() => {
                  dispatch({
                    type: actions.UPDATE_INPUT,
                    payload: { ...state, state: key },
                  });
                }}
              >
                {t(key)}
              </ClearButton>
            ))}
            type={verificationStatusToChange[state?.state]}
            text={t(state?.state)}
            small
          />
        );
      } else {
        return renderChip();
      }
    } else {
      return renderChip();
    }
  };

  return (
    <Modal
      t={t}
      open={open}
      setOpen={() => {
        setOpen(false);
      }}
      title={"Detalles del documento"}
      hasForm={true}
      onSubmit={handleSubmit}
      submitText={t("save")}
      cancelText={t("cancel")}
      submitProps={{
        permission,
        permissionBehavior: "disabled",
      }}
      loading={loading}
      maxWidth={700}
      modalStyles={{ width: matches ? "auto" : 570 }}
      titleStyles={{ marginBottom: -5 }}
      BodyComponent={
        state && (
          <div style={{ margin: "0 -12px" }}>
            {loading ? (
              <Container>
                <Center>
                  <Padding>
                    <Loading />
                  </Padding>
                </Center>
              </Container>
            ) : (
              <Container>
                <div className="row">
                  <div className="col-12" style={{ marginBottom: 20 }}>
                    <Flex
                      alignItems="flex-start"
                      justifyContent="center"
                      flexDirection="column"
                      style={{
                        gap: 5,
                      }}
                    >
                      <Flex
                        alignItems="center"
                        justifyContent="flex-start"
                        style={{ gap: 10 }}
                      >
                        <Text>{state?.fileName && state?.fileName}</Text>
                        <Flex justifyContent={"flex-start"}>
                          {renderChips()}
                        </Flex>
                      </Flex>
                      {description && (
                        <Typography
                          typography="large"
                          noMargin
                          style={{
                            color: WARM_NEUTRAL_50,
                          }}
                        >
                          {description}
                        </Typography>
                      )}
                    </Flex>
                  </div>
                </div>
                {!canEdit && (
                  <>
                    {state?.providerId && (
                      <div className="row">
                        <div className="col-12" style={{ marginBottom: 20 }}>
                          <Label text={t("provider-id")} noPadding>
                            <Text>{state?.providerId}</Text>
                          </Label>
                        </div>
                      </div>
                    )}
                  </>
                )}
                <div className="row">
                  <div className="col-12" style={{ marginBottom: 20 }}>
                    <Label text={t("date-request")} small light noPadding>
                      <Text>
                        {state?.requestDate &&
                          parseDate(state?.requestDate, i18n)}
                      </Text>
                    </Label>
                  </div>
                  <div className="col-12" style={{ marginBottom: 20 }}>
                    <Label text={t("date-answer")} small light noPadding>
                      <Text>
                        {state?.responseDate &&
                          parseDate(state?.responseDate, i18n)}
                      </Text>
                    </Label>
                  </div>
                </div>
                {!canEdit && state?.comment && (
                  <div className="row">
                    <div className="col-12" style={{ marginBottom: 20 }}>
                      <Label
                        text={t("lemonway-comments")}
                        small
                        light
                        noPadding
                      >
                        <Text>{state?.comment}</Text>
                      </Label>
                    </div>
                  </div>
                )}
                <div className="row">
                  <div className="col-12">
                    <Label text={t("inner-comments")} small light noPadding>
                      <InputText
                        mode="underline"
                        placeholder={t("write-here")}
                        value={state?.scrapadComment}
                        onChange={handleComments}
                        readOnly={
                          permission
                            ? permission()
                              ? !permission()
                              : true
                            : false
                        }
                      />
                    </Label>
                  </div>
                </div>
              </Container>
            )}
          </div>
        )
      }
    />
  );
};

export default ModalDocumentDetails;
