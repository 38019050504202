import {
  Container,
  Flex,
  Heading,
  WARM_NEUTRAL_30,
} from "@scrapadev/scrapad-front-sdk";
import CardBox from "../../../components/ui/box/CardBox";
import { useTranslation } from "../../../utils/hooks";
import WidgetBusinessEmpty from "./Empty";
import WidgetFavoriteAds from "./FavoriteAds";
import WidgetFavoriteMaterial from "./FavoriteMaterial";
import styles from "./Favorites.module.scss";

/**
 * Favorites widget for dashboard.
 * @param {Object} props - Component properties.
 * @param {String} props.id - Business id.
 * @returns {JSX.Element}
 */
const WidgetBusinessFavorites = ({ id, state }) => {
  const { t } = useTranslation(["common", "transactions"]);
  const ads = state?.data?.[0]?.ads || [];
  const searchs = state?.data?.[1]?.searchs || [];
  const materials = state?.data?.[2]?.getFavouriteMaterials || [];
  const hasAds = ads?.length !== 0;
  const totalAds = ads?.length;
  const hasMaterials = materials?.length !== 0;
  const totalMaterials = materials?.length;
  const adsToShow = hasAds ? ads.slice(0, 3) : [];
  const materialsToShow = hasMaterials ? materials.slice(0, 3) : [];
  const isEmpty = JSON.parse(
    process.env.REACT_APP_FAVORITES_SHOW_VIEW_ADS_STORED_SEARCHS
  )
    ? ads?.length === 0 && searchs?.length === 0 && materials?.length === 0
    : ads?.length === 0 && materials?.length === 0;
  if (state)
    return (
      <CardBox
        title={t("favorites")}
        action={t("see-more")}
        href={`/business/${id}/favourites`}
        hasOverflow={false}
      >
        <Container zeroPadding>
          <div className="row">
            <div className="col-12 col-lg-6">
              {hasAds ? (
                <Flex
                  flexDirection="column"
                  alignItems={"flex-start"}
                  style={{ width: "100%" }}
                >
                  <Heading type={5}>
                    {t("ads")}
                    <span className={styles["total-ads"]}> ({totalAds})</span>
                  </Heading>
                  <WidgetFavoriteAds ads={adsToShow} totalAds={totalAds} />
                </Flex>
              ) : (
                <WidgetBusinessEmpty
                  type={"favorite"}
                  text={t("no-favorite-ads")}
                />
              )}
            </div>
            <div className="col-12 col-lg-6">
              {hasMaterials ? (
                <Flex
                  flexDirection="column"
                  alignItems={"flex-start"}
                  style={{ width: "100%" }}
                >
                  <Heading type={5} style={{ marginBottom: 30 }}>
                    {t("materials")}
                    <span className={styles["total-ads"]}>
                      {" "}
                      ({totalMaterials})
                    </span>
                  </Heading>
                  <WidgetFavoriteMaterial materials={materialsToShow} />
                </Flex>
              ) : (
                <WidgetBusinessEmpty
                  type={"favorite"}
                  text={t("no-favorite-material")}
                />
              )}
            </div>
          </div>
        </Container>
      </CardBox>
    );
};

export default WidgetBusinessFavorites;
