import { Chip, Flex, Spacer } from "@scrapadev/scrapad-front-sdk";
import { useFiltersProvider } from "../../../providers/filters/FiltersContext";

const FiltersSelectedBelow = ({ removeFilter }) => {
  const {
    state: filters,
    dispatch,
    actions,
    filterChanged,
  } = useFiltersProvider();

  if (!filters) return <></>;

  const keys = Object.keys(filters);

  if (!keys || keys.length === 0) return <></>;

  return (
    <>
      <Spacer height={10} />
      <Flex
        alignItems={"center"}
        justifyContent={"flex-start"}
        style={{ flexWrap: "wrap", gap: 10 }}
      >
        {filters &&
          keys.map((key) => {
            const filter = filters[key];
            const selectedFilters = filter?.selected || [];
            return selectedFilters.map((selected) => {
              return (
                <Chip
                  key={`${key}-${selected?.uuid}`}
                  type="outlined"
                  text={selected?.name}
                  showIcon
                  handleClick={() => {
                    if (filter.removeFilter) {
                      filter.removeFilter(
                        selected,
                        filter,
                        dispatch,
                        actions,
                        filterChanged
                      );
                    } else {
                      if (removeFilter) removeFilter(selected, filter);
                      else {
                        filterChanged.current = true;
                        dispatch({
                          type: actions.REMOVE_FILTER,
                          payload: { item: selected, filter },
                        });
                      }
                    }
                  }}
                />
              );
            });
          })}
      </Flex>
    </>
  );
};

export default FiltersSelectedBelow;
