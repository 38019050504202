import {
  BREAKPOINT_LG,
  Button,
  CardButton,
  Center,
  Container,
  Empty,
  Flex,
  Heading,
  Loading,
  Spacer,
  Typography,
  useMatchMedia,
} from "@scrapadev/scrapad-front-sdk";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getHeaderInfo } from "../../../model/business";
import { useHeaderProvider } from "../../../providers/header/HeaderContext";
import { parseDate } from "../../../utils/date/functions";
import { useTranslation } from "../../../utils/hooks";
import { orgStatus, TEXT_COLOR } from "../../../utils/variables";
import { getBalanceWithOperator } from "../../../utils/wallet/functions";
import {
  typeToWalletOrigin,
  WALLET_LIMIT,
} from "../../../utils/wallet/variables";
import WidgetWalletMovement from "./Movement";

const WidgetWalletBalance = ({
  id,
  state,
  dispatch,
  actions,
  loading,
  setLoading,
  fetchData,
  hideWallet,
}) => {
  const { t, i18n } = useTranslation([
    "common",
    "transactions",
    "users",
    "wallet",
  ]);
  const navigate = useNavigate();
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_LG}px)`);
  const [current, setCurrent] = useState(0);
  const [moreLoading, setMoreLoading] = useState(false);
  const [isEndReached, setIsEndReached] = useState(false);
  const { state: headerState } = useHeaderProvider(
    i18n.language,
    id,
    getHeaderInfo
  );
  const hasNotWallet =
    headerState?.verificationState !== orgStatus.KYC_COMPLETE || hideWallet;
  const balanceText = state?.balance
    ? `${t("current-balance")}: ${getBalanceWithOperator(state?.balance)} €`
    : `${t("current-balance")}: 0 €`;

  return (
    <Container>
      {hasNotWallet ? (
        <></>
      ) : (
        <div className="row">
          <div
            className="col-12 col-lg-4"
            style={{ marginBottom: matches ? 15 : 0 }}
          >
            <CardButton
              type={2}
              text={balanceText}
              iconColor={TEXT_COLOR}
              buttonStyle={{ cursor: "default" }}
            />
          </div>
          <div
            className="col-12 col-lg-4"
            style={{ marginBottom: matches ? 15 : 0 }}
          >
            <CardButton
              type={2}
              text={t("accounts")}
              boxStyle={{ width: "100%" }}
              onClick={() => navigate(`/business/${id}/wallet/accounts`)}
              hasArrow
            />
          </div>
          {/*<div
          className="col-12 col-lg-4"
          style={{ marginBottom: matches ? 15 : 0 }}
        >
          <CardButton
            type={2}
            text={t("cards")}
            boxStyle={{ width: "100%" }}
            onClick={() => {}}
            hasArrow
          />
          </div>*/}
        </div>
      )}

      <Spacer height={32} />
      {!hasNotWallet && (
        <div className="row">
          <div className="col-12">
            <Flex justifyContent={"space-between"} style={{ marginBottom: 30 }}>
              <Heading type={3} style={{ marginBottom: 0 }}>
                {t("movements")}
              </Heading>
            </Flex>
          </div>
        </div>
      )}

      <div className="row">
        {loading ? (
          <Center>
            <Loading />
          </Center>
        ) : state?.transactions && state?.transactions.length > 0 ? (
          <Flex
            flexDirection={"column"}
            alignItems={"flex-start"}
            justifyContent={"center"}
          >
            {state?.transactions?.map((transaction) => {
              return (
                <div className="col-12">
                  <WidgetWalletMovement
                    uuid={transaction?.uuid}
                    key={transaction?.uuid}
                    title={transaction?.type}
                    answer={`${parseDate(transaction?.date * 1000, i18n)} - ${t(
                      typeToWalletOrigin[transaction?.type]
                    )}`}
                    amount={transaction?.amount}
                    status={transaction?.status}
                    type={transaction?.type}
                    origin={typeToWalletOrigin[transaction?.type]}
                    iban_id={state?.moneyOutAccount?.uuid}
                    fetchData={fetchData}
                    hideIcon
                  />
                </div>
              );
            })}
            <Spacer height={20} />
            {isEndReached ? (
              <Center>
                <Typography>{t("no-more-results")}</Typography>
              </Center>
            ) : (
              <Button
                mode="normal"
                text={t("load-more")}
                fullwidth
                component="button"
                type="button"
                loading={moreLoading}
                onClick={async () => {
                  let newCurrent = current + 1;
                  setMoreLoading(true);
                  setCurrent(newCurrent);
                  const results = await fetchData(
                    false,
                    {
                      org: id,
                      offset: newCurrent,
                    },
                    true
                  );
                  dispatch({
                    type: actions.UPDATE_INPUT,
                    payload: {
                      transactions: [
                        ...state?.transactions,
                        ...results?.transactions,
                      ],
                    },
                  });
                  if (
                    results?.transactions.length === 0 ||
                    results?.transactions.length < WALLET_LIMIT
                  ) {
                    setIsEndReached(true);
                  }
                  setMoreLoading(false);
                }}
              />
            )}
          </Flex>
        ) : (
          <div className="row">
            <div className="col-12">
              <Center>
                <Empty
                  spacerHeight={10}
                  heading={t("section-empty")}
                  icon="bo"
                  iconWidth={220}
                  iconHeight={220}
                />
              </Center>
            </div>
          </div>
        )}
      </div>
    </Container>
  );
};

export default WidgetWalletBalance;
