import {
  Divider,
  Flex,
  TEXT_COLOR_LIGHT,
  WARM_NEUTRAL_20,
  WARM_NEUTRAL_50,
  WARM_NEUTRAL_60,
  Typography,
} from "@scrapadev/scrapad-front-sdk";
import React from "react";
import MiniImage from "../../../components/ui/image/MiniImage";
import ResultsText from "../../../components/ui/text/ResultsText";
import { useTranslation } from "../../../utils/hooks";
import FavoriteChip from "./FavoriteChip";

/**
 * Component to render three of all favourite ads.
 * @param {object} props - Component poperties.
 * @param {Array} props.ads - three first Ads to render.
 * @param {number} [props.totalAds=0] - Total number of ads exceeding the three that the component render.
 * @returns {React.ReactNode}
 */
const WidgetFavoriteAds = ({ ads, totalAds = 0 }) => {
  const { t } = useTranslation(["common"]);
  return (
    <>
      {ads?.map((ad, index) => {
        const image = ad?.images?.[0] || "";
        const title = ad?.title || "";
        const material = `${ad?.materialComposition} - ${ad?.materialType}`;
        const type = ad?.type ? [ad?.type] : [];
        return (
          <>
            <Flex
              alignItems={"flex-start"}
              justifyContent={"flex-start"}
              style={{ gap: 12 }}
            >
              <MiniImage width={62} height={62} src={image} />
              <Flex
                flexDirection="column"
                alignItems={"flex-start"}
                justifyContent={"flex-start"}
                style={{ gap: 10 }}
              >
                <Typography noMargin typography={"small-med"}>
                  {title}
                </Typography>
                <Typography
                  noMargin
                  typography={"small-reg"}
                  style={{ color: WARM_NEUTRAL_50 }}
                >
                  {material}
                </Typography>
                <Flex
                  alignItems={"flex-start"}
                  justifyContent={"flex-start"}
                  style={{ gap: 10 }}
                >
                  <FavoriteChip type={type} />
                </Flex>
              </Flex>
            </Flex>
            <Divider
              style={{
                borderColor: WARM_NEUTRAL_20,
                width: "100%",
              }}
            />
          </>
        );
      })}
      <ResultsText current={ads?.length} total={totalAds} />
    </>
  );
};

export default WidgetFavoriteAds;
