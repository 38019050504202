import { Card, Empty, Grid } from "@scrapadev/scrapad-front-sdk";
import Text from "../../../components/ui/text/Text";
import { useTranslation } from "../../../utils/hooks";

const WidgetFavouritesAds = ({ state }) => {
  const { t, i18n } = useTranslation("common");

  return state?.ads?.length === 0 ? (
    <Empty icon="bo" heading={t("section-empty")} />
  ) : (
    <Grid style={{ padding: 0, maxWidth: "none" }}>
      {state?.ads?.map((ad) => {
        return (
          <Card
            uuid={ad?.uuid}
            title={ad?.title}
            subheading={ad?.materialComposition}
            description={ad?.materialType}
            isFav={true}
            price={ad?.price}
            currencySymbol={`${ad?.currency}`}
            noPriceComponent={<Text>{t("no-proposed-price")}</Text>}
            link={`${process.env.REACT_APP_MARKETPLACE_URL}/${i18n.language}/ad/${ad?.uuid}`}
            linkProps={{ target: "_blank" }}
            isSell={ad?.type === "sale"}
            featuredImage={ad?.images[0]}
            hideOnFav
            hideOnShare
          />
        );
      })}
    </Grid>
  );
};

export default WidgetFavouritesAds;
