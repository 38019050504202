import React from "react";
import {
  Divider,
  Flex,
  LINK_SMALL,
  Typography,
  WARM_NEUTRAL_20,
  TEXT_COLOR_LIGHT,
} from "@scrapadev/scrapad-front-sdk";
import { useTranslation } from "../../../utils/hooks";
import Link from "../../../components/ui/link/Link";
import FavoriteChip from "./FavoriteChip";
import { getLinkToMarketplace } from "../../../utils/favorites/functions";

/**
 * Component to render three of all favourite materials.
 * @param {object} props - Component poperties.
 * @param {Array} props.materials - Three first materials to render.
 * @returns {React.ReactNode}
 */
const WidgetFavoriteMaterial = ({ materials }) => {
  const { t, i18n } = useTranslation("common");

  return materials?.map((material, index) => {
    const materialName = material?.material || "";
    const materialsType = material?.materialsType || [];
    return materialsType.map((materialType) => {
      const type = materialType?.adType || "";
      const adType = type ? type.split(",") : [];
      const label = materialType.label;
      const name = label === "ALL" ? materialName : label;
      const relatedAds = materialsType?.relatedAds || 0;
      const adLiteral =
        relatedAds == 1 ? t("ad").toLowerCase() : t("ads").toLowerCase();
      return (
        <>
          <Flex
            alignItems={"flex-start"}
            justifyContent={"space-between"}
            style={{ width: "100%" }}
          >
            <Flex
              flexDirection={"column"}
              alignItems={"flex-start"}
              justifyContent={"flex-start"}
            >
              <Typography typography="small-reg" style={{ marginBottom: 5 }}>
                {name}
              </Typography>
              {relatedAds > 0 ? (
                <Link
                  href={getLinkToMarketplace(material, materialType, i18n)}
                  target={"_blank"}
                  style={{ ...LINK_SMALL, color: TEXT_COLOR_LIGHT }}
                >
                  {`${t("view")} ${relatedAds} ${adLiteral}`}
                </Link>
              ) : (
                <Typography
                  noMargin
                  typography="link-small"
                  style={{
                    color: TEXT_COLOR_LIGHT,
                  }}
                >
                  {t("no-ads")}
                </Typography>
              )}
            </Flex>
            <Flex
              alignItems={"flex-start"}
              justifyContent={"flex-start"}
              style={{ gap: 10 }}
            >
              <FavoriteChip type={adType} />
            </Flex>
          </Flex>
          {index + 1 < materials.length && (
            <Divider
              style={{
                borderColor: WARM_NEUTRAL_20,
                width: "100%",
                margin: "32px 0",
              }}
            />
          )}
        </>
      );
    });
  });
};

export default WidgetFavoriteMaterial;
