import React from "react";
import {
  Row,
  Icon,
  Typography,
  ClearButton,
  SCRAPBACK_BLUE_40,
} from "@scrapadev/scrapad-front-sdk";
import { useTranslation } from "../../../utils/hooks";

function MarkAsRead({ onClick }) {
  const { t } = useTranslation("notifications");
  return (
    <ClearButton onClick={onClick}>
      <Row gap={10} align="center" style={{ curor: "pointer" }}>
        <Icon
          name="drafts"
          color={SCRAPBACK_BLUE_40}
          style={{
            fontSize: "18px",
          }}
        />
        <Typography
          typography="med-med"
          style={{
            marginBottom: 0,
            color: SCRAPBACK_BLUE_40,
            fontWeight: 600,
            textDecoration: "underline",
          }}
        >
          {t("mark_read")}
        </Typography>
      </Row>
    </ClearButton>
  );
}

export default MarkAsRead;
