import { CheckBox, Flex, Radio, Row } from "@scrapadev/scrapad-front-sdk";
import { useTranslation } from "../../../utils/hooks";

const WidgetAddressesBySea = ({
  index = 0,
  text,
  checked = false,
  onChange,
  alternative = false,
  style = {},
  ...props
}) => {
  const { t } = useTranslation(["common"]);
  const handleOnChange = (e) => {
    if (onChange) {
      onChange(e);
    }
  };

  if (alternative) {
    return (
      <Row gap={25} style={{ paddingBottom: "20px", ...style }}>
        <Row>
          <Radio
            id={`is_ground_${index}`}
            name={`is_ground_${index}`}
            text={t("address")}
            value="false"
            onChange={() => {
              handleOnChange({
                target: {
                  checked: false,
                },
              });
            }}
            checked={!checked}
            style={{ cursor: "pointer" }}
          />
        </Row>
        <Row>
          <Radio
            id={`is_port_${index}`}
            name={`is_ground_${index}`}
            text={t("port")}
            value="true"
            onChange={() => {
              handleOnChange({
                target: {
                  checked: true,
                },
              });
            }}
            checked={checked}
            style={{ cursor: "pointer" }}
          />
        </Row>
      </Row>
    );
  }

  return (
    <Flex style={{ marginBottom: 16, ...style }} justifyContent={"flex-start"}>
      <CheckBox
        text={text}
        id={`IsPort${index}`}
        checked={checked}
        onChange={handleOnChange}
        {...props}
      />
    </Flex>
  );
};

export default WidgetAddressesBySea;
