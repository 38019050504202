import { gql } from "@apollo/client";
import { getGQLData } from "../utils/gqlbase";
import _ from "lodash";

export async function getHeaderInfo({ locale, org }) {
  const widget = await getGQLData(gqlGetHeaderInfo, {
    lang: locale,
    uuidOrg: org,
  });
  return widget?.getOrgData;
}

export async function getBusinessDashboardContact({ locale, org }) {
  const widget = await getGQLData(gqlGetDashboardInfo, {
    lang: locale,
    uuidOrg: org,
  });
  return widget?.getOrgData;
}

export async function getBusinessDashboardVerification({
  locale,
  org,
  module,
}) {
  const widget = await getGQLData(gqlGetDashboardVerification, {
    moduleOps: {
      lang: locale,
      module: module,
      org: org,
    },
  });
  return widget?.getDashboard;
}

export async function getBusinessDetailAddresses({ locale, module, org }) {
  const widget = await getGQLData(
    gqlGetDashboardAddress,
    {
      lang: locale,
      uuidOrg: org,
    },
    module
  );
  return widget;
}

export async function getBusinessAddresses({ locale, module, org }) {
  const widget = await getGQLData(
    gqlGetAddress,
    {
      lang: locale,
      uuidOrg: org,
    },
    module
  );
  return widget;
}

export async function getWallet({ locale, module, org }) {
  const widget = await getGQLData(
    gqlGetDashboardWallet,
    {
      moduleOps: {
        lang: locale,
        module: module,
        org: org,
      },
    },
    module
  );
  return widget?.getDashboard;
}

export async function getBusinessDashboardUsers({ locale, module, org }) {
  const widget = await getGQLData(gqlGetDashboardUsers, {
    moduleOps: {
      lang: locale,
      module: module,
      org: org,
    },
  });
  return widget?.getDashboard;
}

export async function getBusinessAbout({ locale, org, module }) {
  const query = {
    basicData: gqlBusinessAboutInfoData,
    legalReprenstative: gqlBusinessAboutLegalData,
    fiscalAddress: gqlBusinessAboutFiscalData,
  };
  const widget = await getGQLData(query[module], {
    lang: locale,
    uuidOrg: org,
  });
  return widget?.getOrgData?.[module];
}

export async function getBusinessModel() {
  return {
    basicData: {
      orgName: "",
      commercialName: "",
      legalForm: "",
      fiscalId: "",
      countryCode: undefined,
      prefix: "",
      phone: "",
      webSite: "",
      activity: "",
    },
    legalReprenstative: {
      firstName: "",
      lastName: "",
      email: "",
      identityCard: "",
      prefix: "",
      phone: "",
    },
    fiscalAddress: {
      city: "",
      state: "",
      street: "",
      postalCode: "",
      countryCode: undefined,
      port: "",
      facilities: [],
    },
  };
}

export async function getAdsTable(locale) {
  const data = await Promise.resolve({
    data: {
      tableData: {
        columns: [
          {
            name: "Título",
            filterable: true,
            sortable: true,
            ref: "title",
            __typename: "Column",
          },
          {
            name: "Creación",
            filterable: true,
            sortable: true,
            ref: "created",
            __typename: "Column",
          },
          {
            name: "Material",
            filterable: true,
            sortable: true,
            ref: "material",
            __typename: "Column",
          },
          {
            name: "País",
            filterable: true,
            sortable: true,
            ref: "country",
            __typename: "Column",
          },
          {
            name: "Actualizado",
            filterable: true,
            sortable: true,
            ref: "updated",
            __typename: "Column",
          },
          {
            name: "Estado",
            filterable: true,
            sortable: true,
            ref: "status",
            __typename: "Column",
          },
        ],
        rows: [
          {
            uuid: "a1be958d-db9d-4cc8-8c02-833df777f7a5",
            cells: [
              {
                value:
                  "seller#PC, PMMA, retales de plancha y PE 500 viruta en colores azul, negro y blanco",
                format: "slplit",
                colRef: "title",
                __typename: "Cell",
              },
              {
                value: "1670241687765.000000",
                format: "date",
                colRef: "created",
                __typename: "Cell",
              },
              {
                value: "Acero inoxidable#Recorte serie 5000",
                format: "text_double",
                colRef: "material",
                __typename: "Cell",
              },
              {
                value: "España",
                format: "text",
                colRef: "country",
                __typename: "Cell",
              },
              {
                value: "1.670241687765e+12",
                format: "date",
                colRef: "updated",
                __typename: "Cell",
              },
              {
                value: "published#3",
                format: "chip_double",
                colRef: "status",
                __typename: "Cell",
              },
            ],
            __typename: "Row",
          },
          {
            uuid: "d073f429-071e-4ce6-8186-c113e85a92a7",
            cells: [
              {
                value: "buyer#Cajas de cartón",
                format: "slplit",
                colRef: "title",
                __typename: "Cell",
              },
              {
                value: "1570241687765.000000",
                format: "date",
                colRef: "created",
                __typename: "Cell",
              },
              {
                value: "Epoxy#Resinas térmicas",
                format: "text_double",
                colRef: "material",
                __typename: "Cell",
              },
              {
                value: "España",
                format: "text",
                colRef: "country",
                __typename: "Cell",
              },
              {
                value: "1.670241687765e+12",
                format: "date",
                colRef: "updated",
                __typename: "Cell",
              },
              {
                value: "demand-covered#8",
                format: "chip_double",
                colRef: "status",
                __typename: "Cell",
              },
            ],
            __typename: "Row",
          },
        ],
        __typename: "Table",
      },
    },
  });
  return data.errors ? { errors: data.errors } : data.data.tableData;
}

export async function getVerification({ org }) {
  const widget = await getGQLData(gqlBusinessVerification, {
    uuidOrg: org,
  });
  return widget;
}

export async function getVerificationFilesModule({ files = [] }) {
  const data = await Promise.resolve({
    data: {
      infoData: {
        files,
      },
    },
  });
  return data.errors ? { errors: data.errors } : data.data.infoData;
}

export async function getFavouritesSearchs() {
  const data = await Promise.resolve({
    data: {
      infoData: {
        searchs: [1],
      },
    },
  });
  return data.errors ? { errors: data.errors } : data.data.infoData;
}

export async function getFavoriteAds({ locale, org }) {
  const widget = await getGQLData(gqlBusinessFavoriteAds, {
    lang: locale,
    uuidOrg: org,
  });
  return widget?.getFavouriteAdsBO;
}

export async function getFavoriteMaterials({ locale, org }) {
  const widget = await getGQLData(gqlBusinessFavoriteMaterials, {
    lang: locale,
    uuidOrg: org,
  });
  return widget;
}

export async function getUserData({ locale, org, user }) {
  const widget = await getGQLData(gqlBusinessUserData, {
    lang: locale,
    uuidOrg: org,
    uuidUser: user,
  });
  return widget?.getUserData;
}

export async function getVerificationDetail({ uuidValidation }) {
  const widget = await getGQLData(gqlBusinessVerificationDetail, {
    uuidValidation,
  });
  return widget?.getKycValidationDetail;
}

export async function getValidationInfo({ locale, org }) {
  const widget = await getGQLData(gqlGetValidationInfo, {
    lang: locale,
    uuidOrg: org,
  });
  return widget;
}

export async function getFavAddresses({ locale, module, org }) {
  const widget = await getGQLData(
    gqlGetFavAddress,
    {
      lang: locale,
      uuidOrg: org,
    },
    module
  );
  return widget;
}

export async function getPreferencesPaymentMethods({ locale, org }) {
  const widget = await getGQLData(gqlPaymentMethodsForOrg, {
    lang: locale,
    uuidOrg: org,
  });
  return widget?.getPaymentMethodsForOrg;
}

const gqlGetFavAddress = gql`
  query ($lang: String!, $uuidOrg: UUID!) {
    getOrgLocations(lang: $lang, uuidOrg: $uuidOrg) {
      id
      alias
      type
      city
      state
      street
      postalCode
      country
      countryCode
      facilities
      port {
        uuid
        name
      }
      facilities
    }
  }
`;

const gqlGetValidationInfo = gql`
  query ($lang: String!, $uuidOrg: UUID!) {
    getOrgData(lang: $lang, uuidOrg: $uuidOrg) {
      verificationState
    }
  }
`;

const gqlGetHeaderInfo = gql`
  query ($lang: String!, $uuidOrg: UUID!) {
    getOrgData(lang: $lang, uuidOrg: $uuidOrg) {
      verificationState
      basicData {
        orgName
        createdDate
        state
        assignedBO {
          name
          uuid
        }
      }
    }
  }
`;

const gqlGetDashboardInfo = gql`
  query ($lang: String!, $uuidOrg: UUID!) {
    getOrgData(lang: $lang, uuidOrg: $uuidOrg) {
      basicData {
        orgName
        prefix
        phone
        assignedBO {
          name
          uuid
        }
      }
      legalReprenstative {
        email
      }
      fiscalAddress {
        city
        state
        street
        postalCode
        countryCode
        country
        port {
          uuid
          name
        }
      }
    }
  }
`;

const gqlGetDashboardUsers = gql`
  query ($moduleOps: ModuleOps) {
    getDashboard(ModuleOps: $moduleOps) {
      usersByOrg {
        uuidPerson
        userName
        avatar
        role
        status
      }
    }
  }
`;

const gqlGetDashboardWallet = gql`
  query ($moduleOps: ModuleOps) {
    getDashboard(ModuleOps: $moduleOps) {
      lastTransactions {
        pending
        balance
        vibanDeposit
        vibanWithdraw
        paymentTransaction {
          uuid
          type
          desc
          status
          amount
          date
        }
      }
    }
  }
`;

const gqlGetDashboardAddress = gql`
  query ($lang: String!, $uuidOrg: UUID!) {
    getOrgLocations(lang: $lang, uuidOrg: $uuidOrg) {
      id
      city
      street
      postalCode
      country
      isDeliveryDefault
      isCollectionDefault
      port {
        uuid
        name
      }
    }
  }
`;

const gqlGetAddress = gql`
  query ($lang: String!, $uuidOrg: UUID!) {
    getOrgLocations(lang: $lang, uuidOrg: $uuidOrg) {
      id
      alias
      city
      state
      street
      postalCode
      country
      countryCode
      country
      type
      timetables {
        from
        to
        daysWeek
      }
      tel
      prefix
      isDeliveryDefault
      isCollectionDefault
      facilities
      port {
        uuid
        name
      }
    }
  }
`;

const gqlGetDashboardVerification = gql`
  query ($moduleOps: ModuleOps) {
    getDashboard(ModuleOps: $moduleOps) {
      validation {
        pendingDocs
        totalDocs
        validatedDocs
        verificationType
      }
    }
  }
`;

const gqlBusinessAboutInfoData = gql`
  query ($lang: String!, $uuidOrg: UUID!) {
    getOrgData(lang: $lang, uuidOrg: $uuidOrg) {
      basicData {
        orgName
        commercialName
        legalForm
        fiscalId
        countryCode
        prefix
        phone
        webSite
        activity
      }
    }
  }
`;

const gqlBusinessAboutLegalData = gql`
  query ($lang: String!, $uuidOrg: UUID!) {
    getOrgData(lang: $lang, uuidOrg: $uuidOrg) {
      legalReprenstative {
        id
        firstName
        lastName
        birthDate
        email
        residenceCountry
        nationality
        identityCard
        birthPlace
        birthCountry
      }
    }
  }
`;

const gqlBusinessAboutFiscalData = gql`
  query ($lang: String!, $uuidOrg: UUID!) {
    getOrgData(lang: $lang, uuidOrg: $uuidOrg) {
      fiscalAddress {
        city
        state
        street
        postalCode
        countryCode
      }
    }
  }
`;

const gqlBusinessUserData = gql`
  query ($lang: String!, $uuidOrg: String!, $uuidUser: String!) {
    getUserData(uuidOrg: $uuidOrg, uuidUser: $uuidUser, lang: $lang) {
      avatar
      firstName
      jobPosition
      lastName
      prefix
      phone
      email
      role
      status
    }
  }
`;

const gqlBusinessFavoriteAds = gql`
  query ($lang: String!, $uuidOrg: String!) {
    getFavouriteAdsBO(uuidOrg: $uuidOrg, lang: $lang) {
      ads {
        uuid
        description
        title
        images
        materialComposition
        materialType
        amount
        currency
        type
        images
        price
      }
    }
  }
`;

const gqlBusinessFavoriteMaterials = gql`
  query ($lang: String!, $uuidOrg: String!) {
    getFavouriteMaterials(uuidOrg: $uuidOrg, lang: $lang) {
      material
      materialsType {
        adType
        key
        label
        value
        relatedAds
      }
    }
  }
`;

const gqlBusinessVerification = gql`
  query ($uuidOrg: String!) {
    getKycDocuments(uuidOrg: $uuidOrg) {
      type
      isCompleted
      providerUse
      description
      helpContent
      documents {
        fileLocation
        fileName
        id
        state
        comment
        requestDate
        responseDate
        scrapadComment
      }
    }
  }
`;

const gqlBusinessVerificationDetail = gql`
  query ($uuidValidation: String!) {
    getKycValidationDetail(uuidValidationDoc: $uuidValidation) {
      fileLocation
      fileName
      id
      state
      comment
      requestDate
      responseDate
      scrapadComment
      providerId
    }
  }
`;

const gqlPaymentMethodsForOrg = gql`
  query ($uuidOrg: String!, $lang: String!) {
    getPaymentMethodsForOrg(uuidOrg: $uuidOrg, lang: $lang) {
      uuidPaymentMethod
      paymentMethodDesc
      priceDesc
      price
      pricePerTon
      active
      fav
    }
  }
`;

export async function getVerificationsFilesModule({ files = [] }) {
  const data = await Promise.resolve({
    data: {
      infoData: {
        files: files,
      },
    },
  });
  return data.errors ? { errors: data.errors } : data.data.infoData;
}

export async function getWalletMoneyOut({ org, limit = 10, offset = 0 }) {
  const widget = await getGQLData(gqlGetWalletMoneyOutForOrg, {
    uuidOrg: org,
    limit: limit,
    offset: offset,
  });
  if (_.isEmpty(widget?.getWalletForOrg?.moneyOutAccount)) {
    return {
      uuid: null,
      address: "",
      bicCode: "",
      viban: "",
      holder: "",
    };
  }
  return widget?.getWalletForOrg?.moneyOutAccount;
}

const gqlGetWalletMoneyOutForOrg = gql`
  query ($uuidOrg: String!, $limit: Int!, $offset: Int!) {
    getWalletForOrg(uuidOrg: $uuidOrg, limit: $limit, offset: $offset) {
      moneyOutAccount {
        uuid
        address
        bicCode
        status
        viban
        holder
      }
    }
  }
`;
