import React, { useEffect, useReducer } from "react";
import Container from "../../components/ui/container/Container";
import NavBar from "../../components/ui/navbar/NavBar";
import { useTranslation } from "../../utils/hooks";
import {
  Heading,
  Spacer,
  Row,
  Typography,
  Flex,
  Pagination,
  BREAKPOINT_SM,
  useMatchMedia,
  WARM_NEUTRAL_05,
} from "@scrapadev/scrapad-front-sdk";
import MarkAsRead from "../../components/ui/mark-as-read/MarkAsRead";
import { useNotificationProvider } from "../../providers/notifications/NotificationsContext";
import NotificationsFilterType from "./filters/Type";
import NotificationsFilterDate from "./filters/Date";
import NotificationsPreferencesLink from "./preferences/Link";
import { actions, reducer } from "./reducer/reducer";
import { formatDate } from "../../utils/functions";
import _ from "lodash";
import EmptyNotifications from "../../components/ui/empty/Notifications";
import { TOAST_CONTAINER_ID } from "../../utils/variables";
import NotificationsList from "../../components/ui/notifications/List";
import NotificationsListItem from "../../components/ui/notifications/ListItem";

function Notifications() {
  const { t } = useTranslation("notifications");
  return (
    <>
      <NavBar mobileTitle={t("notifications")} />
      <NotificationsLayout />
    </>
  );
}

function NotificationsLayout() {
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_SM}px)`);
  const { t, i18n, values } = useTranslation(["common", "notifications"], true);
  const { state, updateDataCall, readAll, readOne } = useNotificationProvider();
  const [data, dispatchData] = useReducer(reducer, {
    pagination: { current: 0 },
    filters: {
      type: {
        current: "Tipo de notificación",
        selected: [],
        options: [
          { slug: "transactions", label: t("transactions") },
          { slug: "company", label: t("business") },
          { slug: "ads", label: t("ad") },
          { slug: "favourites", label: t("favorite") },
        ],
      },
      date: {
        label: t("date"),
        selected: {
          from: null,
          to: null,
        },
      },
    },
  });

  useEffect(() => {
    document.title = `${t("notifications")} - ${
      process.env.REACT_APP_SCRAPAD_APP_NAME
    }`;
  }, [values]);

  useEffect(() => {
    dispatchData({
      type: actions.UPDATE_FILTER_DATE,
      payload: { label: t("date") },
    });
  }, [values]);

  useEffect(() => {
    setTimeout(() => {
      if (_.isEmpty(state?.notifications)) {
        handleUpdateData();
      }
    }, 1000);
  }, []);

  useEffect(() => {
    handleUpdateData();
  }, [i18n.language, data]);

  const handleUpdateData = () => {
    const updateData = { offset: data.pagination.current };
    if (data.filters.date.selected)
      updateData["byDate"] = data.filters.date.selected;
    if (data.filters.type.selected.length > 0)
      updateData["byType"] = data.filters.type.selected.map((i) => i.slug);
    updateDataCall(updateData);
  };

  return (
    <main>
      <Spacer height={matches ? 0 : 50} />
      {!matches && (
        <Container>
          <Row justify={"space-between"} gap={15}>
            <Heading style={{ marginBottom: 40 }}>{t("notifications")}</Heading>
            <NotificationsPreferencesLink />
          </Row>
        </Container>
      )}
      <Container>
        <Flex justifyContent={matches ? "space-between" : "flex-start"}>
          <Flex
            flexWrap="wrap"
            justifyContent={"flex-start"}
            style={{ gap: "10px", flexWrap: "wrap" }}
          >
            <NotificationsFilterType data={data} dispatchData={dispatchData} />
            <NotificationsFilterDate data={data} dispatchData={dispatchData} />
          </Flex>
          {matches && <NotificationsPreferencesLink />}
        </Flex>
      </Container>
      <Spacer height={30} />
      <Container>
        <Row gap={15}>
          <MarkAsRead
            onClick={() => {
              readAll(t, data.pagination.current);
            }}
          />
        </Row>
      </Container>
      <Spacer height={15} />
      <Container>
        <>
          {state?.notifications?.length > 0 ? (
            <>
              <NotificationsList>
                {state.notifications.map((notification) => {
                  const date = formatDate(
                    notification?.createdDate,
                    i18n,
                    false,
                    "dd MMM yyyy, kk:mm"
                  );
                  return (
                    <>
                      <NotificationsListItem
                        style={{
                          backgroundColor: !notification?.read
                            ? WARM_NEUTRAL_05
                            : "#fff",
                          padding: "32px 20px",
                        }}
                        onClick={() =>
                          notification?.read
                            ? undefined
                            : readOne(
                                t,
                                notification?.id,
                                data.pagination.current
                              )
                        }
                        key={notification?.id}
                        badgeActive={!notification?.read}
                        title={notification?.title}
                        headerRightSide={
                          <Typography
                            typography="small"
                            color="light"
                            style={{
                              margin: 0,
                            }}
                          >
                            {`${t(notification?.category)} - ${date}`}
                          </Typography>
                        }
                        description={
                          <span
                            dangerouslySetInnerHTML={{
                              __html: notification?.content,
                            }}
                          ></span>
                        }
                        notEllipsisOnDesktop
                      />
                    </>
                  );
                })}
              </NotificationsList>
            </>
          ) : (
            <EmptyNotifications />
          )}
        </>
        <Spacer height={50} />
        {state?.notifications?.length > 0 && (
          <>
            <Pagination
              currentPage={data.pagination.current}
              totalPages={Math.ceil(state?.pagination?.total / 12)}
              threshold={3}
              onChangePage={(curr) => {
                dispatchData({
                  type: actions.UPDATE_PAGINATION,
                  payload: curr,
                });
                // updateDataCall({ offset: curr });
              }}
              onChangePrev={(prev) => {
                dispatchData({
                  type: actions.UPDATE_PAGINATION,
                  payload: prev,
                });
                // updateDataCall({ offset: prev });
              }}
              onChangeNext={(next) => {
                dispatchData({
                  type: actions.UPDATE_PAGINATION,
                  payload: next,
                });
                // updateDataCall({ offset: next });
              }}
            />
            <Spacer height={50} />
          </>
        )}
      </Container>
    </main>
  );
}

export default Notifications;
