import {
  LINK_MEDIUM_REG,
  SCRAPBACK_BLUE_40,
} from "@scrapadev/scrapad-front-sdk";
import React from "react";

const Link = ({
  href = "",
  target = "_self",
  style = {},
  children,
  ...props
}) => {
  return (
    <a
      href={href}
      target={target}
      style={{
        ...LINK_MEDIUM_REG,
        color: SCRAPBACK_BLUE_40,
        ...style,
      }}
      {...props}
    >
      {children}
    </a>
  );
};

export default Link;
