import { useState } from "react";
import { useTranslation } from "../../utils/hooks";
import LoginWrap from "../../components/ui/login/LoginWrap";
import Header from "../../components/public/header/Header";
import { RecoverForm } from "../../components/forms/recover-form";
import { ResetPwdForm } from "../../components/forms/resetpwd-form";
import { useNavigate } from "react-router-dom";
import { confirmPwd, forgotPwd } from "../../model/cognito";

const Recover = () => {
  const [errorMessage, setErrorMessage] = useState(false);
  const [emailUser, setEmailUser] = useState(null);
  const [verificationPhase, setVerificationPhase] = useState(null);
  const { t } = useTranslation(["login", "common"]);
  const navigate = useNavigate();

  const doRecover = async (event) => {
    event.preventDefault();
    setErrorMessage(null);
    setEmailUser(event.target.email.value);
    const data = {
      email: event.target.email.value,
    };

    try {
      await forgotPwd(data);
      setVerificationPhase(true);
    } catch (e) {
      setErrorMessage(t(e.code || "default-auth-error", { ns: "login" }));
      console.log(e);
    }
  };

  const doChangePwd = async (event) => {
    event.preventDefault();
    setErrorMessage(null);

    if (event.target.password.value !== event.target.passwordRepeat.value) {
      setErrorMessage(t("password-does-not-match", { ns: "login" }));
      return;
    }
    const data = {
      newpwd: event.target.password.value,
      code: event.target.code.value,
      email: emailUser,
    };

    try {
      await confirmPwd(data);
      navigate("/login", { replace: true });
    } catch (e) {
      setErrorMessage(t(e.code || "default-auth-error", { ns: "login" }));
      console.log(e);
    }
  };

  return (
    <>
      <Header
        heading={t("login-heading", { ns: "login" })}
        subheading={t("login-subheading", { ns: "login" })}
        subheading2={t("login-subheading-2", { ns: "login" })}
      />
      <main>
        {verificationPhase ? (
          <LoginWrap
            title={t("recover-title")}
            description={t("recover_enter_password_desc")}
            errorMessage={errorMessage}
          >
            <ResetPwdForm doChangePwd={doChangePwd}></ResetPwdForm>
          </LoginWrap>
        ) : (
          <LoginWrap
            title={t("recover-title")}
            description={t("recover-sub-title")}
            errorMessage={errorMessage}
          >
            <RecoverForm doRecover={doRecover}></RecoverForm>
          </LoginWrap>
        )}
      </main>
    </>
  );
};

export default Recover;
