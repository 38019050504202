import {
  AutoComplete,
  AutoCompleteV2,
  SearchAutoComplete,
} from "@scrapadev/scrapad-front-sdk";
import { useEffect, useRef, useState } from "react";
import { getCatalog } from "../../../../model/filters";
import { useGQL } from "../../../../utils/gql/hooks";
import { useTranslation } from "../../../../utils/hooks";
import _ from "lodash";
import { sleep } from "../../../../utils/functions";
import Select from "../select/Select";

const CatalogSearch = ({
  preload = false,
  remote,
  initialValue,
  catalogName,
  onChangeCallback,
  onClickCallback,
  optionsAdapter,
  valueAdapter,
  cleanAfterSelected,
  isFilterCatalog = false,
  inputProps = {},
  searchProps = {},
  searchInputProps = {},
  withSearch = false,
  avoidReload = false,
  threshold = 2,
  browserSelect,
  v2,
  reloadOnInitialValueChange = false,
  withDescription = false,
  onInitialValueMatch = () => {},
  ...props
}) => {
  const { t, i18n } = useTranslation("common");
  const { data, setData, fetchData } = useGQL(getCatalog, {}, true);
  const [value, setValue] = useState(initialValue);
  const nodeRefValue = useRef(initialValue);
  const localSearchProps = {
    handleOnChangeSearch: undefined,
    searchValue: undefined,
    ...searchProps,
  };
  const loadedRef = useRef(false);

  useEffect(() => {
    if (preload || (initialValue && !data)) {
      initialLoad();
    }
  }, []);

  useEffect(() => {
    if (!_.isEmpty(data) && typeof onInitialValueMatch === "function") {
      const initialSelected = data.filter((e) => {
        if (e?.uuid === initialValue) {
          return true;
        }
        if (!_.isEmpty(e?.subSelector)) {
          return !_.isEmpty(
            e?.subSelector?.filter((ss) => ss?.uuid === initialValue)
          );
        }
        return false;
      });
      if (initialSelected?.length > 0) {
        if (_.isEmpty(initialSelected[0]?.subSelector)) {
          if (!loadedRef.current) {
            onInitialValueMatch(initialSelected[0]);
            setValue(initialSelected[0]?.name);
            loadedRef.current = true;
          }
        } else {
          const subValue = initialSelected[0]?.subSelector?.filter(
            (e) => e?.uuid === initialValue
          );
          if (subValue?.length > 0) {
            if (!loadedRef.current) {
              onInitialValueMatch(subValue[0]);
              setValue(subValue[0]?.name);
              loadedRef.current = true;
            }
          }
        }
      }
    }
  }, [data, initialValue]);

  const initialLoad = async () => {
    setValue(initialValue);
    const params = {
      locale: i18n.language,
      name: catalogName,
    };
    if (!preload) {
      params.term = initialValue;
    }
    await fetchData(params);
  };

  const handleOnChange = async (e, open, setOpen, loading, setLoading) => {
    nodeRefValue.current = e.target?.value;
    if (!avoidReload) {
      const val = e.target.value;
      setValue(val);
      setLoading(true);
      if (
        (val.length > threshold && !preload) ||
        (val.length > threshold && onInitialValueMatch)
      ) {
        await fetchData({
          locale: i18n.language,
          name: catalogName,
          term: val,
        });
      }
      setLoading(false);
      if (onChangeCallback) {
        onChangeCallback(
          e,
          open,
          setOpen,
          loading,
          setLoading,
          value,
          setValue,
          nodeRefValue.current
        );
      }

      if (_.isEmpty(val)) {
        setData([]);
        if (onClickCallback) {
          await sleep(150);
          onClickCallback(
            {
              uuid: undefined,
            },
            open,
            setOpen,
            value,
            setValue,
            e
          );
        }
      }
    }
  };

  const handleItemClick = (option, open, setOpen, e) => {
    if (!props.multiple) {
      setValue(
        cleanAfterSelected
          ? ""
          : valueAdapter
          ? valueAdapter(option.name)
          : option.name
      );
    }
    if (cleanAfterSelected) setData([]);
    if (onClickCallback)
      onClickCallback(option, open, setOpen, value, setValue, e);
  };

  const applyInputProps = () => {
    if (isFilterCatalog) {
      const newProps = { ...(inputProps || {}) };
      const margin = value ? {} : { marginBottom: 0 };
      newProps.inputStyles = {
        ...margin,
        ...(inputProps.inputStyles || {}),
      };
      return newProps;
    }
    return inputProps;
  };

  const noResultsFound = () => <li>{t("write-to-results")}</li>;

  if (browserSelect) {
    return (
      <Select
        onChange={(e) => {
          if (onClickCallback)
            onClickCallback(
              data.find((d) => d?.uuid === e.target.value),
              false,
              () => {},
              "",
              () => {}
            );
        }}
        options={
          _.isEmpty(data?.errors)
            ? optionsAdapter
              ? optionsAdapter(data || [])
              : (data || []).map((d, i) => {
                  if (i === 0) {
                    return (
                      <>
                        <option value={""} disabled selected hidden>
                          {t("write-here")}
                        </option>
                        <option
                          key={`${catalogName}_option_${i}`}
                          value={d?.uuid}
                        >
                          {d?.name}{" "}
                          {withDescription ? d?.description || d?.help : ""}
                        </option>
                      </>
                    );
                  }
                  return (
                    <option key={`${catalogName}_option_${i}`} value={d?.uuid}>
                      {d?.name}
                    </option>
                  );
                })
            : []
        }
        autocomplete="off"
        {...inputProps}
      />
    );
  }
  if (withSearch) {
    return (
      <SearchAutoComplete
        placeholder={t("select-value")}
        handleOnChange={handleOnChange}
        value={value}
        handleOnClickWithEvent={(e, value, open, setOpen) => setOpen(!open)}
        options={
          _.isEmpty(data?.errors)
            ? optionsAdapter
              ? optionsAdapter(data || [])
              : data?.map((e) => ({
                  ...e,
                  uuid: e?.uuid,
                  name: withDescription
                    ? `${e?.name} - ${e?.description || e?.help}`
                    : e?.name,
                })) || []
            : []
        }
        model={{ uuid: "uuid", text: "name" }}
        handleItemClick={handleItemClick}
        renderNoResultsItem={
          remote && nodeRefValue.current.length <= threshold
            ? noResultsFound
            : undefined
        }
        autocomplete="off"
        defaultOpen={isFilterCatalog}
        {...inputProps}
        inputProps={{ placeholder: t("write-or-select"), ...searchInputProps }}
        {...localSearchProps}
      />
    );
  }

  if (v2) {
    return (
      <>
        <AutoCompleteV2
          remote={remote}
          placeholder={t("write-here")}
          handleOnChange={handleOnChange}
          value={value}
          handleOnClickWithEvent={(e, value, open, setOpen) => {
            if (!isFilterCatalog) setOpen(!open);
          }}
          options={
            _.isEmpty(data?.errors)
              ? optionsAdapter
                ? optionsAdapter(data || [])
                : data?.map((e) => ({
                    ...e,
                    uuid: e?.uuid,
                    name: withDescription
                      ? `${e?.name} - ${e?.description || e?.help}`
                      : e?.name,
                  })) || []
              : []
          }
          model={{ uuid: "uuid", text: "name" }}
          handleItemClick={handleItemClick}
          renderNoResultsItem={
            remote && nodeRefValue.current?.length <= threshold
              ? noResultsFound
              : undefined
          }
          autocomplete="off"
          {...applyInputProps()}
          {...props}
        />
      </>
    );
  }

  return (
    <AutoComplete
      remote={remote}
      placeholder={`${t("write-here")} ${props?.required ? "*" : ""}`}
      handleOnChange={handleOnChange}
      value={value}
      handleOnClickWithEvent={(e, value, open, setOpen) => {
        if (!isFilterCatalog) setOpen(!open);
      }}
      options={
        _.isEmpty(data?.errors)
          ? optionsAdapter
            ? optionsAdapter(data || [])
            : data?.map((e) => ({
                ...e,
                uuid: e?.uuid,
                name: withDescription
                  ? `${e?.name} - ${e?.description || e?.help}`
                  : e?.name,
              })) || []
          : []
      }
      model={{ uuid: "uuid", text: "name" }}
      handleItemClick={handleItemClick}
      renderNoResultsItem={
        remote && nodeRefValue.current?.length <= threshold
          ? noResultsFound
          : undefined
      }
      autocomplete="off"
      defaultOpen={isFilterCatalog}
      {...applyInputProps()}
      {...props}
    />
  );
};

export default CatalogSearch;
